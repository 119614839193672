import { Container, Typography } from '@app/components';
import { TranslationKey } from '@app/locales/defaultTranslation';
import { clsxm } from '@app/styles/clsxm';
import { ECodeType } from '@app/swagger-types';
import { SwipeableDrawer } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { QRCodeFormSchema } from '../../api/qr-code.form.schema.api';
import { CODE_TYPE_ITEMS_ALL } from '../../constants';
import { QRCodeTypeItemType } from '../../qr-code.types';
import { SelectQRCodeType } from './SelectQRCodeType';
import { CreateBulkButton } from '@app/domain/plan/components/bulk-button/CreateBulkButton';

export const SelectQRCodeTypeContainer: React.FC<{
  isEditMode?: boolean;
  className?: string;
  forWidgetMode?: boolean;
}> = ({ isEditMode, className, forWidgetMode }) => {
  const { t } = useTranslation();
  const { watch, setValue, clearErrors } = useFormContext<QRCodeFormSchema>();
  const codeType = watch().codeType;
  const selectedItemType = CODE_TYPE_ITEMS_ALL.find((e) => e.value === codeType);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const renderTypeItem = ({
    itemType,
    hideOnMobile,
    showExpandArrow,
  }: {
    itemType: QRCodeTypeItemType;
    hideOnMobile?: boolean;
    showExpandArrow?: boolean;
  }) => {
    const { value, icon } = itemType;
    const { titleKey, subTitleKey } = getTranslationKeyForType(value);
    if (!titleKey || !subTitleKey) {
      return null;
    }
    return (
      <SelectQRCodeType
        key={value}
        value={value}
        data-testid={`select-qr-code-type-${value.toLowerCase()}`}
        className={clsxm(hideOnMobile && 'hidden sm:block')}
        forWidgetMode={forWidgetMode}
        onExpand={
          showExpandArrow
            ? () => {
                setIsOpenDrawer(true);
              }
            : undefined
        }
        title={t(titleKey)}
        subTitle={t(subTitleKey)}
        isActive={codeType === value}
        icon={icon}
        onClick={(v) => {
          if (forWidgetMode) {
            setIsOpenDrawer(false);
          }
          if (!isEditMode) {
            setValue('codeType', v);
            clearErrors();
          }
        }}
      />
    );
  };

  return (
    <Container className={clsxm('flex h-full flex-col gap-4 xl:rounded-xl', className)}>
      {!forWidgetMode && (
        <div className="flex items-center flex-wrap justify-between gap-4">
          <Typography className="font-bold sm:text-l">{t('qr.stepType.selectYourQRCodeType')}</Typography>
          <CreateBulkButton variant="outlined" size="medium" className="w-full md:w-auto" />
        </div>
      )}
      <div className=".testid-select-qr-code-type-container grid grid-cols-1 gap-3 sm:grid-cols-2">
        {CODE_TYPE_ITEMS_ALL.map((e) =>
          renderTypeItem({
            itemType: e,
            hideOnMobile: forWidgetMode && e.value !== selectedItemType?.value,
            showExpandArrow: true,
          })
        )}
        {/* TODO handle other code types for mobile */}
        <SwipeableDrawer
          anchor={'bottom'}
          open={isOpenDrawer}
          disableSwipeToOpen
          onOpen={() => setIsOpenDrawer(true)}
          onClose={() => setIsOpenDrawer(false)}
          classes={{
            paper: /*tw*/ 'bg-transparent',
          }}
        >
          <div className="rounded-t-2xl flex flex-col gap-4 p-4 py-8 bg-white">
            {CODE_TYPE_ITEMS_ALL.map((e) => renderTypeItem({ itemType: e }))}
          </div>
        </SwipeableDrawer>
      </div>
    </Container>
  );
};

const getTranslationKeyForType = (type: ECodeType) => {
  if (type === ECodeType.WEBSITE) {
    const titleKey: TranslationKey = 'qr.stepType.websiteURL';
    const subTitleKey: TranslationKey = 'qr.stepType.websiteDescription';
    return { titleKey, subTitleKey };
  }
  if (type === ECodeType.MULTILINK) {
    const titleKey: TranslationKey = 'qr.stepType.multiLinks';
    const subTitleKey: TranslationKey = 'qr.stepType.multiLinksDescription';
    return { titleKey, subTitleKey };
  }
  if (type === ECodeType.PDF) {
    const titleKey: TranslationKey = 'qr.stepType.pdf';
    const subTitleKey: TranslationKey = 'qr.stepType.pdfDescription';
    return { titleKey, subTitleKey };
  }
  if (type === ECodeType.V_CARD) {
    const titleKey: TranslationKey = 'qr.stepType.vCard';
    const subTitleKey: TranslationKey = 'qr.stepType.vCardDescription';
    return { titleKey, subTitleKey };
  }
  if (type === ECodeType.GS1) {
    const titleKey: TranslationKey = 'qr.stepType.gs1';
    const subTitleKey: TranslationKey = 'qr.stepType.gs1Description';
    return { titleKey, subTitleKey };
  }
  if (type === ECodeType.WIFI) {
    const titleKey: TranslationKey = 'qr.stepType.wifi';
    const subTitleKey: TranslationKey = 'qr.stepType.wifiDescription';
    return { titleKey, subTitleKey };
  }
  return {};
};
