import { IS_PROD_ENV } from '@app/environment/typed-env';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { AnyObjectSchema, InferType, StringSchema } from 'yup';

const raiseErrorToaster = () => {
  if (IS_PROD_ENV) {
    toast.error('Something went wrong');
  } else {
    toast.error('Server DTO Validation Error (check console for details)');
  }
};

export const validateAndAssert = async <S extends AnyObjectSchema | StringSchema>(
  res: Promise<AxiosResponse<unknown>>,
  schema: S
): Promise<InferType<S>> => {
  const { data, request } = await res;

  const xhr = request instanceof XMLHttpRequest ? request : undefined;

  try {
    const result = await schema.validate(data);
    return result;
  } catch (e) {
    if (xhr) {
      console.warn('error response', xhr.responseURL);
    }
    console.log(
      `%cDTO ERROR: ${get(e, 'errors')}`,
      'display: inline-block ; border: 3px solid red ; border-radius: 7px ; font-size: 20px ; ' +
        'padding: 10px ; margin: 20px ;'
    );
    console.error(data);
    raiseErrorToaster();
    throw e;
  }
};
