import { FiRrAngleSmallRight } from '@app/assets';
import { Container, Typography } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { ECodeType } from '@app/swagger-types';
import { Box } from '@mui/material';
import { QRCodeTypeItemType } from '../../qr-code.types';

export const SelectQRCodeType: React.FC<
  QRCodeTypeItemType & {
    title: string;
    subTitle: string;
    isActive: boolean;
    onClick: ((v: ECodeType) => void) | null;
    className?: string;
    forWidgetMode?: boolean;
    onExpand?: () => void;
  }
> = ({ value, title, subTitle, isActive, icon, onClick, className, forWidgetMode, onExpand, ...props }) => {
  return (
    <Box
      onClick={() => {
        onClick && onClick(value);
      }}
      className={className}
      {...props}
    >
      <Container
        className={clsxm(
          'rounded-lg border border-gray-200 p-[1px]',
          onClick && 'hover:cursor-pointer hover:border-accept-hover',
          isActive && 'border-accept-main'
        )}
      >
        <div className="flex justify-between">
          <div className="flex gap-4">
            <div className={clsxm('h-full rounded-md bg-gray-100 p-5', isActive && 'bg-accept-light text-accept-main')}>
              {icon}
            </div>
            <div className="flex flex-col justify-center gap-2">
              <Typography className="font-semibold">{title}</Typography>
              <Typography className="text-xxs text-gray-500">{subTitle}</Typography>
            </div>
          </div>
          {onExpand && (
            <div
              className={clsxm('px-2 flex items-center', forWidgetMode ? 'flex sm:hidden' : 'hidden')}
              onClick={(e) => {
                if (onExpand) {
                  e.preventDefault();
                  e.stopPropagation();
                  onExpand();
                }
              }}
              aria-hidden
            >
              <FiRrAngleSmallRight className="rotate-90" />
            </div>
          )}
        </div>
      </Container>
    </Box>
  );
};
