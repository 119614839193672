import { apiService, validateAndAssert } from '@app/services/api-service';
import { SubscriptionCancelFeedbackInDto, SubscriptionInDto } from '@app/swagger-types';
import { boolean, object, string } from 'yup';
import { NullablePartial } from '@app/utils/type.utils';

export abstract class SubscriptionApi {
  static async subscribeViaStripeGateway(body: SubscriptionInDto) {
    return validateAndAssert(
      apiService.put('/private/subscription/via-stripe-gateway', body),
      object({
        url: string().nullable(),
        isScheduledDowngrade: boolean().nullable(),
        scheduledId: string().nullable(),
      })
    );
  }

  /**
   * resubscribe to current plan in canceled but not expired yet state
   */
  static async resubscribeCurrentPlan() {
    await apiService.put('/private/subscription/resubscribe-via-stripe-gateway');
  }

  static async unsubscribeCurrentPlan(userChoicesDto: NullablePartial<SubscriptionCancelFeedbackInDto>) {
    await apiService.put('/private/subscription/unsubscribe', userChoicesDto);
  }
  static async cancelScheduledUpdate() {
    await apiService.put('/private/subscription/cancel-scheduled-update');
  }
}
