import { Routes } from '@app/constants/routes';
import { useHandler } from '@app/hooks/useHandler.hook';
import { useTypedNavigate } from '@app/router';
import { oneOfEnum } from '@app/utils/yup.utils';
import { StringParam, useQueryParam } from 'use-query-params';

export enum ECancelPlan {
  CANCEL_PLAN = 'CANCEL_PLAN',
}

export const PLAN_CANCEL_MODE_QUERY_PARAM = 'mode';

export const useCancelSubscriptionPlan = () => {
  const navigate = useTypedNavigate();

  const [rawMode, setMode] = useQueryParam(PLAN_CANCEL_MODE_QUERY_PARAM, StringParam);

  const isValidQueryParam = oneOfEnum(ECancelPlan).isValidSync(rawMode);
  const mode = isValidQueryParam ? rawMode : undefined;

  if (!isValidQueryParam && rawMode) {
    setMode(undefined);
  }

  const handleNavigateToCancelForm = useHandler(() => {
    navigate({
      to: Routes.customer.account.subscription,
      queryParams: {
        [PLAN_CANCEL_MODE_QUERY_PARAM]: ECancelPlan.CANCEL_PLAN,
      },
    });
  });

  return { mode, setMode, handleNavigateToCancelForm };
};
