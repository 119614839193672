import { GoogleIcon } from '@app/assets';
import { IS_LOCALHOST, typedEnv } from '@app/environment/typed-env';
import { PARAM_GOOGLE_CLICK_ID } from '@app/gtag/google.constant';
import { EGTAGRegistrationOrigin, EGTAGRegistrationPopUp } from '@app/gtag/gtag.type';
import { useGoogleClickId } from '@app/gtag/useGoogleClickId';
import { useCurrentLanguage } from '@app/i18n/useCurrentLanguage';
import {
  AUTH_QUERY_PARAM_AUTO_DOWNLOAD_QR_ID,
  AUTH_QUERY_PARAM_AUTO_LINK_QR_ID,
  AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_POPUP,
  AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_SOURCE,
  AUTH_QUERY_PARAM_GOOGLE_MODE,
  AUTH_QUERY_PARAM_LANGUAGE,
  AUTH_QUERY_PARAM_SUCCESS_REDIRECT,
  AUTH_QUERY_VALUE_GOOGLE_MODE_SIGN_IN,
} from '@app/modules/auth/auth.constants';
import { SignUpInDto } from '@app/swagger-types';
import { checkTypeCompliance } from '@app/utils/type.utils';
import { useTranslation } from 'react-i18next';
import { Button } from '../button/Button';
import { Routes } from '@app/constants/routes';

checkTypeCompliance<keyof SignUpInDto, typeof AUTH_QUERY_PARAM_LANGUAGE>();
checkTypeCompliance<keyof SignUpInDto, typeof AUTH_QUERY_PARAM_AUTO_LINK_QR_ID>();

export const GoogleButton: React.FC<{
  autoLinkQRID?: string | null;
  onPreClick?: () => void;
  modeSignIn?: boolean;
  /**
   * null for sign-in case
   */
  origin: EGTAGRegistrationOrigin | null;
  /**
   * provide popup when origin = "generator"
   */
  popup?: EGTAGRegistrationPopUp | null;
}> = ({ autoLinkQRID, onPreClick, modeSignIn, origin, popup }) => {
  const { t } = useTranslation();
  const language = useCurrentLanguage();
  const { googleClickId } = useGoogleClickId();

  return (
    <Button
      onClick={() => {
        onPreClick?.();
        // TODO use env for api host
        const url = new URL(typedEnv.REACT_APP_OAUTH_GOOGLE_URL);
        if (IS_LOCALHOST) {
          url.searchParams.set(
            AUTH_QUERY_PARAM_SUCCESS_REDIRECT,
            encodeURIComponent(new URL(Routes.auth.oauth2_authorization, window.location.origin).toString())
          );
        }
        if (language) {
          url.searchParams.set(AUTH_QUERY_PARAM_LANGUAGE, language);
        }
        if (autoLinkQRID) {
          url.searchParams.set(AUTH_QUERY_PARAM_AUTO_LINK_QR_ID, autoLinkQRID);
          // TODO set success-redirect param when backend API ready to redirect to route like
          // /c/my-qr-codes?autoDownloadQRID=1NYX6311
          url.searchParams.set(AUTH_QUERY_PARAM_AUTO_DOWNLOAD_QR_ID, autoLinkQRID);
        }
        if (googleClickId) {
          url.searchParams.set(PARAM_GOOGLE_CLICK_ID, googleClickId);
        }
        if (modeSignIn) {
          url.searchParams.set(AUTH_QUERY_PARAM_GOOGLE_MODE, AUTH_QUERY_VALUE_GOOGLE_MODE_SIGN_IN);
        }
        if (origin) {
          url.searchParams.set(AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_SOURCE, origin);
        }
        if (popup) {
          url.searchParams.set(AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_POPUP, popup);
        }
        const strURL = url.toString();
        // console.log('oauth2', strURL);
        window.location.href = strURL;
      }}
      variant="outlined"
      className="w-full"
    >
      <GoogleIcon className="mr-2" />
      {t('auth.continueWithGoogle')}
    </Button>
  );
};
