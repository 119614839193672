import { Routes } from '@app/constants/routes';
import { useTypedNavigate } from '@app/router';
import { useQueryParams, withDefault, createEnumParam } from 'use-query-params';

export enum EQRCodeStep {
  TYPE = 'TYPE',
  INPUT = 'INPUT',
  CUSTOMIZE = 'CUSTOMIZE',
  DONE = 'DONE',
}

export const QR_CODE_NAVIGATION_QUERY_PARAM = 'step';

const StepEnumParam = createEnumParam(Object.values(EQRCodeStep));

export const useQRCodeNavigation = () => {
  const navigate = useTypedNavigate();
  const [{ [QR_CODE_NAVIGATION_QUERY_PARAM]: step }, setQueryParams] = useQueryParams({
    [QR_CODE_NAVIGATION_QUERY_PARAM]: withDefault(StepEnumParam, EQRCodeStep.TYPE),
  });

  const handleNavigate = (qrCodeId: string, action: EQRCodeStep) => {
    navigate({
      to: Routes.customer.my_qr_codes.edit,
      queryParams: {
        [QR_CODE_NAVIGATION_QUERY_PARAM]: action,
      },
      params: { id: qrCodeId },
    });
  };

  const getNavigationUrl = (qrCodeId: string, action: EQRCodeStep) => {
    const url = new URL(Routes.customer.my_qr_codes.edit, window.location.origin);
    url.searchParams.set(QR_CODE_NAVIGATION_QUERY_PARAM, action);
    url.pathname = url.pathname.replace(':id', qrCodeId);
    return url.pathname + url.search;
  };

  return { step, setQueryParams, handleNavigate, getNavigationUrl };
};
