import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { GalleryEditor } from '@app/components/gallery-editor/GalleryEditor';
import { useTranslation } from 'react-i18next';
import type { useLibraryEditor } from '../useLibraryEditor';

export const LibraryEditor: React.FC<
  ReturnType<typeof useLibraryEditor> & {
    onLibrarySelect?: (libraryId: string | null) => void;
    disabled?: boolean;
  }
> = ({
  libraryType,
  images,
  libraries,
  selectedImageId,
  createLibrary,
  deleteLibrary,
  publicConcatLibraries,
  publicDeleteLibrary,
  isLoading,
  isCreatingLibrary,
  // isDeletingLibrary,
  onLibrarySelect,
  forWidgetMode,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <GalleryEditor
      forWidgetMode={forWidgetMode}
      disabled={disabled}
      value={images}
      deleteOnServer={false}
      withNoImg
      dropZoneLoading={isLoading || isCreatingLibrary}
      selectedImageId={selectedImageId || NO_LOGO_ID}
      onClickImage={(img) => {
        if (img.id === NO_LOGO_ID) {
          onLibrarySelect?.(null);
          return;
        }
        const file = libraries?.result.find((l) => l.file?.id === img.imageId);
        if (file) {
          onLibrarySelect?.(file.id || null);
        }
      }}
      handleUploadIds={(v) => {
        createLibrary(
          { fileIds: v, libraryType: libraryType },
          {
            onSuccess: (data) => {
              try {
                if (forWidgetMode) {
                  publicConcatLibraries(data);
                }
                const lastUploadLogo = data.result[data.result.length - 1];
                if (lastUploadLogo) {
                  onLibrarySelect?.(lastUploadLogo.id);
                }
              } catch {}
            },
          }
        );
      }}
      handleDeleteId={(id) => {
        const lib = libraries?.result?.find((l) => l?.file?.id === id);
        if (lib && lib?.id) {
          if (forWidgetMode) {
            publicDeleteLibrary(lib.id);
          } else {
            deleteLibrary(lib.id);
          }
        }
      }}
      onChange={(v) => {
        if (!v.find((img) => img.imageId === selectedImageId && selectedImageId !== null)) {
          onLibrarySelect?.(null);
        }
      }}
      noImgText={t('qr.stepInput.noImage')}
    />
  );
};
