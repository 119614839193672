import { Typography } from '@app/components';
import { BackButton } from '@app/components/buttons/back-button/BackButton';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { Routes } from '@app/constants/routes';
import { useGetQRCodeById } from '@app/domain/qr-code/api/qr-code.hooks.api';
import { QRCodeForm } from '@app/domain/qr-code/components/QRCodeForm';
import { DEFAULT_MULTILINK_FORM_VALUES, DEFAULT_VCARD_FORM_VALUES } from '@app/domain/qr-code/constants';
import { DEFAULT_TEMPLATE_FORM_VALUES } from '@app/domain/template/constants';
import { useTypedNavigate, useTypedParams } from '@app/router';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';

export const MyQRCodeEditPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();
  const { id } = useTypedParams(Routes.customer.my_qr_codes.edit);
  const { data: qrCode, isLoading } = useGetQRCodeById(id);

  if (isLoading || !qrCode) {
    return (
      <div className="relative h-full">
        {!qrCode && !isLoading && (
          <div className="mt-2 flex items-center px-6 lg:px-12">
            <BackButton
              onClick={() => {
                navigate({ to: Routes.customer.my_qr_codes.index, preserveQueryParams: true });
              }}
            />
            <Typography variant="xxl" className="font-bold">
              {t('qr.editQRCode')}
            </Typography>
          </div>
        )}
        <div className="absolute top-[30%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
          <ContentLoader isEmpty={!qrCode} loading={isLoading} entity="QR Code" />
        </div>
      </div>
    );
  }

  return (
    <QRCodeForm
      defaultValues={{
        ...DEFAULT_TEMPLATE_FORM_VALUES,
        templateId: qrCode.design?.template?.id,
        websiteUrl: qrCode.website?.url,
        libraryId: qrCode.design?.library?.id,
        ...omit(qrCode.design, 'id'),
        ...qrCode,
        multilink: {
          ...DEFAULT_MULTILINK_FORM_VALUES,
          ...qrCode.multilink,
          libraryId: qrCode.multilink?.library?.id,
        },
        pdf: qrCode.pdf
          ? {
              ...qrCode.pdf,
              fileId: qrCode.pdf.file.id,
            }
          : null,
        vcard: { ...DEFAULT_VCARD_FORM_VALUES, ...qrCode.vcard, libraryId: qrCode.vcard?.library?.id },
      }}
    />
  );
};
