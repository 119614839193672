import { mapValues } from 'lodash';

import { parseNumbersFromString } from '@app/utils/string.utils';

import { PaletteMode, ThemeOptions } from '@mui/material';

import { tailwindTheme } from './tailwind-theme-generated';

type CustomTypographyVariantsKeys = keyof typeof tailwindTheme.fontSize;

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    '2xl': true;
  }

  interface TypographyVariants extends Record<CustomTypographyVariantsKeys, React.CSSProperties> {}

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions extends Record<CustomTypographyVariantsKeys, React.CSSProperties> {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends Record<CustomTypographyVariantsKeys, true> {}
}

const mapFontSizes = () => {
  const mapped = mapValues(tailwindTheme.fontSize, (fontSize: [string, { lineHeight: string }]) => ({
    fontSize: fontSize[0],
    lineHeight: fontSize[1].lineHeight,
  }));

  return mapped as unknown as Record<CustomTypographyVariantsKeys, React.CSSProperties>;
};

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: tailwindTheme.colors.primary,
          },
          text: {
            primary: tailwindTheme.colors.zinc[900],
            secondary: tailwindTheme.colors.zinc[400],
          },
        }
      : {
          // palette values for dark mode

          primary: {
            main: tailwindTheme.colors.primary,
          },
          background: {
            paper: tailwindTheme.colors.zinc[900],
          },
          text: {
            primary: tailwindTheme.colors.white,
            secondary: tailwindTheme.colors.zinc[400],
          },
        }),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: parseNumbersFromString(tailwindTheme.screens.sm),
      md: parseNumbersFromString(tailwindTheme.screens.md),
      lg: parseNumbersFromString(tailwindTheme.screens.lg),
      xl: parseNumbersFromString(tailwindTheme.screens.xl),
      '2xl': parseNumbersFromString(tailwindTheme.screens['2xl']),
    },
  },
  typography: {
    fontFamily: tailwindTheme.fontFamily.sans.join(','),
    ...mapFontSizes(),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: tailwindTheme.colors.gray[800],
        },
      },
      defaultProps: {
        variantMapping: {
          xl: 'h4',
          xxl: 'h3',
          xxxl: 'h2',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        iconSizeLarge: {
          '& > *:first-of-type': {
            fontSize: 19,
          },
        },
        iconSizeMedium: {
          '& > *:first-of-type': {
            fontSize: 19,
          },
        },
        iconSizeSmall: {
          '& > *:first-of-type': {
            fontSize: 21,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '& .MuiTouchRipple-root .MuiTouchRipple-child': {
            borderRadius: 'inherit',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: tailwindTheme.colors.black,
            background: `${tailwindTheme.colors.accept['light']} !important`,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          img: {
            // override reset from wailwindcss
            height: '100% !important',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 7,
          '&.Mui-disabled': {
            opacity: 0.4,
          },
        },
        colorPrimary: {
          color: tailwindTheme.colors.gray[500],
          '&.Mui-checked': {
            color: tailwindTheme.colors.white,
          },
        },
        track: {
          opacity: 0.6,
          borderRadius: 50,
          backgroundColor: tailwindTheme.colors.gray[300],
          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            backgroundColor: tailwindTheme.colors.accept['dark-blue'],
          },
          '.Mui-disabled + &, .Mui-checked.Mui-disabled + &': {
            opacity: 0.5,
          },
        },
        thumb: {
          boxShadow: 'none',
          width: '18px',
          height: '18px',
        },
        switchBase: {
          padding: '10px',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          '&.Mui-active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: '50%',
          fontSize: 16,
          height: 40,
          minWidth: 40,
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: tailwindTheme.colors.accept.main,
            color: tailwindTheme.colors.white,
          },
          '&:hover': {
            backgroundColor: tailwindTheme.colors.accept.light,
          },
        },
        ellipsis: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        previousNext: {
          '&:hover': {
            backgroundColor: tailwindTheme.colors.accept.light,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: () => {
          return {
            textTransform: 'capitalize',
            color: tailwindTheme.colors.gray[500],
            '&.Mui-selected': {
              color: tailwindTheme.colors.gray[700],
              fontWeight: 600,
            },
          };
        },
      },
    },
  },
});
