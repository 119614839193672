import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { AccountEmailPreferences } from '@app/domain/user/components/AccountEmailPreferences';
import { AccountInfo } from '@app/domain/user/components/AccountInfo';
import { AccountQRCodePreferences } from '@app/domain/user/components/AccountQRCodePreferences';
import { AccountSecurity } from '@app/domain/user/components/AccountSecurity';
import { AccountUsage } from '@app/domain/user/components/AccountUsage';
import { clsxm } from '@app/styles/clsxm';

export const AccountSettingsPage: React.FC = () => {
  const { isOldPlanUser } = useAuthorizedUser();
  return (
    <div className="flex flex-col gap-3">
      <AccountInfo />
      <div className={clsxm('grid grid-cols-1 gap-3', !isOldPlanUser && 'md:grid-cols-2')}>
        <AccountUsage />
        <AccountEmailPreferences />
      </div>
      <AccountQRCodePreferences />
      <AccountSecurity />
    </div>
  );
};
