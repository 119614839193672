import { Button } from '@app/components';
import { ButtonProps } from '@app/components/buttons/button/Button';
import { Routes } from '@app/constants/routes';
import { useTranslation } from 'react-i18next';
import { PermissionCrown } from '../permission-crown/PermissionCrown';
import { usePlanLimits } from '../../hooks/usePlanLimits';
import { BulkIcon } from '@app/assets';
import { MouseEvent } from 'react';
import {
  EBulkMode,
  QR_CODE_BULK_MODE_QUERY_PARAM,
} from '@app/domain/plan/components/bulk-button/useBulkQueryParam.hook';
import { useTypedNavigate } from '@app/router';

export const CreateBulkButton: React.FC<Omit<ButtonProps, 'onClick'>> = ({
  variant = 'outlined',
  children,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const { canCreateBulk } = usePlanLimits();
  const navigate = useTypedNavigate();

  const handlerClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate({
      to: Routes.customer.create_qr_code,
      queryParams: {
        [QR_CODE_BULK_MODE_QUERY_PARAM]: EBulkMode.BULK_WEBSITE,
      },
    });
  };

  return (
    <a href={Routes.customer.create_qr_code} onClick={(e) => e.preventDefault()}>
      <Button
        size="small"
        variant={variant}
        startIcon={<BulkIcon />}
        endIcon={<PermissionCrown requireUpgrade={!canCreateBulk} />}
        onClick={handlerClick}
        {...otherProps}
      >
        {children ? children : t('qr.bulk.createBulkButton')}
      </Button>
    </a>
  );
};
