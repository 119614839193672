import React, { cloneElement, useMemo, useState, useCallback, useEffect } from 'react';
import { Modal, Typography } from '@mui/material';
import styles from './ConfirmDialog.module.scss';
import { clsxm } from '@app/styles/clsxm';
import { Button } from '@app/components';
import { ButtonProps } from '@app/components/buttons/button/Button';
import { useTranslation } from 'react-i18next';

interface PropTypes {
  trigger: React.ReactElement;
  onConfirm: () => void;
  headerTitle?: string;
  title?: string;
  text?: string;
  confirmText?: string;
  icon?: React.ReactNode;
  confirmIcon?: React.ReactNode;
  cancelText?: string;
  disabled?: boolean;
  actionsContainerClassName?: string;
  titleClassName?: string;
  textClassName?: string;
  containerClassName?: string;
  children?: React.ReactNode;
  confirmButtonProps?: ButtonProps;
  /**
   * change this key to auto-open confirm dialog
   */
  autoOpenKey?: number | string;
}

export const ConfirmDialog: React.FC<PropTypes> = ({
  trigger,
  onConfirm,
  title,
  text,
  confirmText,
  confirmIcon,
  cancelText,
  disabled,
  containerClassName,
  actionsContainerClassName,
  titleClassName,
  textClassName,
  confirmButtonProps,
  autoOpenKey,
  children,
}) => {
  const { t } = useTranslation();
  if (!title) {
    title = t('dialog.areYouSure');
  }
  if (!confirmText) {
    confirmText = t('dialog.confirm');
  }
  if (!cancelText) {
    cancelText = t('dialog.cancel');
  }
  const [open, setOpen] = useState(false);
  const clickTrigger = useMemo(() => {
    return cloneElement(trigger, {
      onClick: (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
        if (disabled) {
          return;
        }
        setOpen(true);
      },
    });
  }, [trigger, setOpen, disabled]);

  useEffect(() => {
    if (!autoOpenKey) {
      return;
    }
    setOpen(true);
  }, [autoOpenKey]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(() => {
    onConfirm();
    handleClose();
  }, [handleClose, onConfirm]);

  return (
    <>
      {clickTrigger}
      <Modal open={open} onClose={handleClose} onClick={(e) => e.stopPropagation()}>
        <div
          className={clsxm(
            styles.Root,
            'min-w-[350px] max-w-[calc(100%-24px)] rounded-md bg-white p-8',
            containerClassName
          )}
        >
          <div className={styles.Content}>
            <Typography variant="l" className={clsxm('mb-4 text-center font-bold', titleClassName)}>
              {title}
            </Typography>
            {text ? (
              <Typography variant="m" className={clsxm('mb-8 text-gray-500', textClassName)}>
                {text}
              </Typography>
            ) : null}
            {children}
            <div className={clsxm('flex w-full flex-col gap-3', actionsContainerClassName)}>
              <Button
                onClick={handleSubmit}
                className="flex-1"
                variant="contained"
                color="primary"
                startIcon={confirmIcon}
                {...confirmButtonProps}
              >
                {confirmText}
              </Button>
              <Button onClick={handleClose} className="flex-1" variant="outlined" color="secondary">
                {cancelText}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
