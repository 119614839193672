import { IS_LOCALHOST } from '@app/environment/typed-env';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const INITIAL_DELAY_IN_SECONDS = IS_LOCALHOST ? 12 : 60;

export const OTPResendCounter: React.FC<{
  onClickResend?: () => void;
  blockedUntilDate?: Date;
}> = ({ onClickResend, blockedUntilDate }) => {
  const { t } = useTranslation();
  const now = Date.now();

  const [, setRenderTick] = useState(0);
  const [lastSendTime, setLastSendTime] = useState(now);

  const calcSecondsLeft = () => {
    if (blockedUntilDate) {
      const untilTime = blockedUntilDate.getTime();
      if (now < untilTime) {
        const secondsLeft = Math.max(0, Math.ceil((untilTime - now) / 1e3));
        return { secondsLeft };
      }
    }
    const secondsLeft = Math.max(0, INITIAL_DELAY_IN_SECONDS - Math.ceil((now - lastSendTime) / 1e3));
    return { secondsLeft };
  };
  const { secondsLeft } = calcSecondsLeft();
  const isReadyToResend = secondsLeft === 0;

  useEffect(() => {
    if (isReadyToResend) {
      return;
    }
    setLastSendTime(Date.now());

    const timer = setInterval(() => {
      setRenderTick((tick) => tick + 1);
    }, 1e3);
    return () => {
      clearInterval(timer);
    };
  }, [isReadyToResend]);

  return (
    <div>
      <div className="inline-block pr-2">{t('auth.otp.didNotGetCode')}</div>
      {isReadyToResend ? (
        <button
          className="text-accept-main cursor-pointer font-bold"
          type="button"
          onClick={() => {
            setLastSendTime(Date.now());
            onClickResend?.();
          }}
        >
          {t('auth.otp.buttonResendCode')}
        </button>
      ) : (
        <div className="inline-block">{t('auth.otp.resendInSeconds', { seconds: secondsLeft })}</div>
      )}
    </div>
  );
};
