import { Typography } from '@app/components';
import { AccountAPIDocumentation } from '@app/domain/api-key/components/AccountAPIDocumentation';
import { AccountAPIKeysAccess } from '@app/domain/api-key/components/AccountAPIKeysContainer';
import { EPlanLimitType } from '@app/domain/plan/components/plan-limit-dialog/PlanLimitDialog';
import { UpgradeBanner } from '@app/domain/plan/components/UpgradeBanner';
import { usePlanLimits } from '@app/domain/plan/hooks/usePlanLimits';
import { useTranslation } from 'react-i18next';

export const AccountAPIKeysPage: React.FC = () => {
  const { t } = useTranslation();
  const { canUseAPIKeys } = usePlanLimits();

  return (
    <div className="flex flex-col gap-4">
      <Typography className="font-bold text-gray-800 text-xxxl pl-6 lg:pl-0">{t('apikey.title')}</Typography>
      <div className="flex flex-col gap-4">
        {!canUseAPIKeys && <UpgradeBanner planLimitDialogType={EPlanLimitType.API_KEYS} className="lg:rounded-xl" />}
        <div className="flex flex-col-reverse 2xl:grid 2xl:grid-cols-5 gap-4">
          <AccountAPIKeysAccess className="2xl:col-span-3" />
          <AccountAPIDocumentation className="2xl:col-span-2" />
        </div>
      </div>
    </div>
  );
};
