export const translationForPagesKeys = {
  onboarding: {
    buttonMaybeLater: 'Maybe Later',
    segmentStudent: {
      title: 'Student',
      description: `You're here to impress with interactive projects and simplify information sharing.`,
    },
    segmentCharity: {
      title: 'Non-profit or Charity',
      description: `You're here to create a seamless way for supporters to connect and contribute.`,
    },
    segmentTeacher: {
      title: 'Teacher',
      description: `You're here to make learning engaging and resources easy to access for students.`,
    },
    segmentLargeCompany: {
      title: 'Large Company',
      description: `You're here to enhance brand reach and streamline info across teams and clients.`,
    },
    segmentPersonal: {
      title: 'Personal',
      description: `You're here to explore, create, and add a personal touch to your digital life.`,
    },
    segmentSmallBusiness: {
      title: 'Small Business',
      description: `You're here to build brand connections and drive engagement from the ground up.`,
    },
    stepInitial: {
      title: `What will you be using Trueqrcode for?`,
      description: `We'll use this to recommend the best QR types for you.`,
    },
    stepGetDiscount: {
      titleFirstPart: `Get 20% Off Trueqrcode`,
      titleLastPart: `Today`,
      description: `Unlock the benefits of Trueqrcode PRO and elevate your business.
Claim your 20% discount—only available today for new users.`,
      hereWhatYouGet: `Here's what you get with Trueqrcode PRO:`,
      buttonGetDiscount: `Get 20% Off Now`,
      benefit1: {
        highlight: 'Unlimited scans.',
        description: 'Reach as many people as you need without worrying about scan limits.',
      },
      benefit2: {
        highlight: 'Always Editable QR Codes.',
        description:
          'Always the possibility to adjust the links behind the QR, without reprinting. And much more, such as different QR types, QR analytics.',
      },
    },
    stepSelectCycle: {
      titleFirstPart: `Try Trueqrcode`,
      description: `Get 20% off today for new users.`,
      todaysPrice: `Today's Price`,
      month: 'month',
      regularPrice: 'regular price',
      dueToday: `Due Today`,
      pay: 'Pay',
      buttonGetDiscount: `Get 20% Off Now`,
      rightSide: {
        title: 'Today - 20% Off Trueqrcode PRO',
        description: `Unlock unlimited scans, editable QR codes, advanced analytics, and more. Don't miss out on this exclusive discount!`,
        trustedBy: `Trusted by over 5.000 companies worldwide`,
      },
    },
  },
  account: {
    pageTitle: 'Account',
    tabSettings: 'Account Settings',
    tabSubscription: 'Subscription',
    tabBilling: 'Billing',
    sectionInfo: {
      title: 'Account info',
      language: 'Language',
      currency: 'Currency',
      firstName: 'First name',
      placeholderFirstName: 'Enter first name',
      lastName: 'Last name',
      placeholderLastName: 'Enter first name',
      email: 'Email',
      editAccountInfo: 'Edit Account info',
    },
    sectionBilling: {
      title: 'Billing info',
      billingContact: 'Billing contact',
      invoiceDetails: 'Invoice details',
      editBillingInfo: 'Edit billing info',
      firstName: 'First name',
      placeholderFirstName: 'Enter first name',
      lastName: 'Last name',
      placeholderLastName: 'Enter first name',
      billingEmail: 'Billing email',
      companyName: 'Company name',
      placeholderCompanyName: 'Enter your company name',
      country: 'Country',
      placeholderCountry: 'Select country',
      state: 'State',
      placeholderState: 'Enter state',
      city: 'City',
      placeholderCity: 'Enter city',
      zipCode: 'ZIP code',
      streetAddress: 'Street address',
      placeholderStreetAddress: 'Enter your street address',
    },
    sectionUsage: {
      title: 'Account usage',
      amountOfQRsCreated: '{{totalQRCodes}} of {{maxQRCodes}} QR codes created',
      amountOfScans: '{{totalScans}} of {{maxScans}} scans',
    },
    sectionEmailPreferences: {
      title: 'Email preferences',
      accountNotifications: 'Account notifications',
      qrNotifications: 'QR notifications',
      marketing: 'Marketing',
    },
    sectionQRPreferences: {
      title: 'QR code preferences',
      useGPSBasedLocation: 'Use GPS based location for QR codes',
    },
    sectionSecurity: {
      title: 'Security',
    },
    sectionManage: {
      title: 'Manage your account',
      deleteAccount: 'Delete account',
      deleteAccountWarningTitle: 'Are you sure you want to delete you account?',
      deleteAccountWarningDescription: 'Account cannot be restored, all data will be automatically deleted.',
    },
    changePassword: {
      title: 'Change password',
      recommendations: 'Keep your data safe by creating a password that is complex and long enough.',
      newPassword: 'New password',
      createNewPassword: 'Create new password',
      confirmNewPassword: 'Confirm new password',
    },
  },
  createQRCode: {
    pageTitle: 'Create QR Codes',
  },
  dashboard: {
    pageTitle: 'Dashboard',
  },
  myQRCodes: {
    pageTitle: 'My QR Codes',
    createQRCode: 'Create QR Code',
    viewAllQRCodes: 'View All QR Codes',
  },
  templates: {
    pageTitle: 'Templates',
    createTemplate: 'Create Template',
    viewAllTemplates: 'View All Templates',
  },
  qrAnalytics: {
    pageTitle: 'Analytics',
    sectionTitle: 'Overall statistics',
    totalQRCodes: 'Total QR codes',
    totalScans: 'Total scans',
    totalUsers: 'Total users',
    scannedQRCodes: 'Scanned QR codes',
    seeAllAnalytics: 'See All Analytics',
    amountOfScans: 'Amount of scans',
    amountOfUsers: 'Amount of users',
    scansByDaytime: 'Scans by daytime',
    scansByLocation: 'Scans by location',
    countries: 'Countries',
    cities: 'Cities',
    countryName: 'Country name',
    cityName: 'City name',
    scans: 'Scans',
    users: 'Users',
    qrCodeTitle: 'QR code title',
    period: 'Period',
    last7days: 'Last 7 days',
    last30days: 'Last 30 days',
    last6months: 'Last 6 months',
    last12months: 'Last 12 months',
    resetFilters: 'Reset Filters',
    intervalDaily: 'daily',
    intervalWeekly: 'weekly',
    intervalMonthly: 'monthly',
    today: 'today',
    andOthers: 'and others',
  },
} as const;
