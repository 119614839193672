import { Typography } from '@app/components';
import { Routes } from '@app/constants/routes';
import { PermissionCrown } from '@app/domain/plan/components/permission-crown/PermissionCrown';
import { usePlanLimits } from '@app/domain/plan/hooks/usePlanLimits';
import { useCancelSubscriptionPlan } from '@app/domain/subscription/hooks/useCancelSubscriptionPlan';
import { useMatchActiveRoute, useTypedNavigate } from '@app/router';
import { mutable } from '@app/utils/type.utils';
import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const tabRoutes = mutable([
  Routes.customer.account.settings,
  Routes.customer.account.subscription,
  Routes.customer.account.billing,
  Routes.customer.account.api_keys,
] as const);

export const AccountModule: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();
  const value = useMatchActiveRoute(tabRoutes);

  const { canUseAPIKeys } = usePlanLimits();

  const { mode } = useCancelSubscriptionPlan();
  const handleChange = (event: React.ChangeEvent<unknown>, to: typeof tabRoutes[number]) => {
    navigate({ to, preserveQueryParams: true });
  };

  return (
    <div className="flex flex-col gap-3 py-6 h-full">
      {!mode && (
        <>
          <div className="px-6">
            <Typography variant="xl" className="mb-3 font-bold text-gray-800">
              {t('account.pageTitle')}
            </Typography>
          </div>
          <div className="px-0 lg:px-6">
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{ className: 'h-[3px] rounded-t-[3px]' }}
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab label={t('account.tabSettings')} value={tabRoutes[0]} />
              <Tab label={t('account.tabSubscription')} value={tabRoutes[1]} />
              <Tab label={t('account.tabBilling')} value={tabRoutes[2]} />
              <Tab
                label={
                  <span className="flex items-center">
                    {t('account.tabAPIKeys')}
                    <PermissionCrown requireUpgrade={!canUseAPIKeys} className="absolute top-0 right-0" />
                  </span>
                }
                className="relative"
                value={tabRoutes[3]}
              />
            </Tabs>
          </div>
          <div className="h-full lg:px-6">{<Outlet />}</div>
        </>
      )}
      {mode && <div className="h-full lg:px-6">{<Outlet />}</div>}
    </div>
  );
};
