import { Routes } from '@app/constants/routes';

import { ERole } from '@app/swagger-types';
import { MyRouteObject } from '@app/models/router';
import { TypedNavigate } from '@app/router';
import { CustomerModule } from './CustomerModule';
import { CustomerDashboardPage } from './pages/dashboard/CustomerDashboardPage';
import { MyQrCodesPage } from './pages/my-qr-codes/MyQrCodes.page';
import { Templates } from './pages/templates/Templates.page';
import { HelpPage } from './pages/help/HelpPage';
import { CreateTemplatePage } from './pages/templates/CreateTemplate.page';
import { EditTemplatePage } from './pages/templates/EditTemplate.page';
import { CreateQRCodePage } from './pages/create-qr-code/CreateQRCode.page';
import { MyQRCodeEditPage } from './pages/my-qr-codes/MyQRCodeEdit.page';
import { AccountModule } from './pages/account/AccountModule';
import { AccountSettingsPage } from './pages/account/AccountSettings.page';
import { AccountSubscriptionPage } from './pages/account/AccountSubscription.page';
import { AccountSuccessPaymentPage } from './pages/account/AccountSuccessPaymentPage';
import { AccountBillingPage } from './pages/account/AccountBillingPage';
import { AnalyticsPage } from './pages/analytics/AnalyticsPage';
import { AccountAPIKeysPage } from './pages/account/AccountAPIKeys.page';

export const CustomerRoutes: MyRouteObject = {
  path: Routes.customer.index,
  element: <CustomerModule />,
  role: [ERole.ROLE_CUSTOMER],
  children: [
    { path: Routes.customer.dashboard, element: <CustomerDashboardPage /> },
    { path: Routes.customer.create_qr_code, element: <CreateQRCodePage /> },
    { path: Routes.customer.my_qr_codes.index, element: <MyQrCodesPage /> },
    { path: Routes.customer.my_qr_codes.edit, element: <MyQRCodeEditPage /> },
    // TODO after release
    { path: Routes.customer.analytics.index, element: <AnalyticsPage /> },
    { path: Routes.customer.templates.index, element: <Templates /> },
    { path: Routes.customer.templates.create, element: <CreateTemplatePage /> },
    { path: Routes.customer.templates.edit, element: <EditTemplatePage /> },
    { path: Routes.customer.help, element: <HelpPage /> },
    {
      path: Routes.customer.account.index,
      element: <AccountModule />,
      children: [
        { path: Routes.customer.account.settings, element: <AccountSettingsPage /> },
        { path: Routes.customer.account.subscription, element: <AccountSubscriptionPage /> },
        { path: Routes.customer.account.billing, element: <AccountBillingPage /> },
        { path: Routes.customer.account.api_keys, element: <AccountAPIKeysPage /> },
      ],
    },
    { path: Routes.customer.success_payment, element: <AccountSuccessPaymentPage /> },

    { index: true, path: '*', element: <TypedNavigate to={Routes.customer.dashboard} /> },
  ],
};
