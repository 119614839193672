/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum EAuthError {
  AUTH_BAD_CREDENTIALS = 'AUTH_BAD_CREDENTIALS',
  AUTH_INVALID_REFRESH_TOKEN = 'AUTH_INVALID_REFRESH_TOKEN',
  AUTH_INVALID_ACCESS_TOKEN = 'AUTH_INVALID_ACCESS_TOKEN',
  AUTH_NO_ACCESS_RIGHTS = 'AUTH_NO_ACCESS_RIGHTS',
  AUTH_EMAIL_ALREADY_EXISTS = 'AUTH_EMAIL_ALREADY_EXISTS',
  AUTH_RECENT_PASSWORD_CANNOT_BE_REUSED = 'AUTH_RECENT_PASSWORD_CANNOT_BE_REUSED',
  AUTH_INCORRECT_CURRENT_PASSWORD = 'AUTH_INCORRECT_CURRENT_PASSWORD',
  AUTH_USER_SESSION_CANNOT_BE_DELETED = 'AUTH_USER_SESSION_CANNOT_BE_DELETED',
  AUTH_INCORRECT_CONFIRMATION_CODE = 'AUTH_INCORRECT_CONFIRMATION_CODE',
  AUTH_INCORRECT_ACTIVATION_CODE = 'AUTH_INCORRECT_ACTIVATION_CODE',
  AUTH_USER_SESSION_NOT_FOUND = 'AUTH_USER_SESSION_NOT_FOUND',
  AUTH_INVALID_XSRF_TOKEN = 'AUTH_INVALID_XSRF_TOKEN',
}

export enum EFileError {
  FILE_EXPORT_UNAVAILABLE = 'FILE_EXPORT_UNAVAILABLE',
  FILE_CONVERT_TO_FILE_UNAVAILABLE = 'FILE_CONVERT_TO_FILE_UNAVAILABLE',
  FILE_CANNOT_BE_DELETED = 'FILE_CANNOT_BE_DELETED',
  FILE_ID_NOT_FOUND = 'FILE_ID_NOT_FOUND',
}

export enum EInvitationError {
  INVITATION_CANCELED = 'INVITATION_CANCELED',
  INVITATION_REGISTERED_CANNOT_BE_CHANGED = 'INVITATION_REGISTERED_CANNOT_BE_CHANGED',
  INVITATION_INCORRECT_TOKEN = 'INVITATION_INCORRECT_TOKEN',
  INVITATION_NOT_FOUND = 'INVITATION_NOT_FOUND',
}

export enum EOAuth2Error {
  OAUTH2UNSUPPORTED = 'OAUTH2_UNSUPPORTED',
  OAUTH2INCORRECTPROVIDER = 'OAUTH2_INCORRECT_PROVIDER',
  OAUTH2EMAILNOTFOUND = 'OAUTH2_EMAIL_NOT_FOUND',
}

export enum EPaymentError {
  PAYMENT_ERROR = 'PAYMENT_ERROR',
}

export enum EPlanError {
  PLAN_END_OF_SUBSCRIPTION_DATE = 'PLAN_END_OF_SUBSCRIPTION_DATE',
  PLAN_EXPIRED = 'PLAN_EXPIRED',
  PLAN_ID_NOT_FOUND = 'PLAN_ID_NOT_FOUND',
  PLAN_IS_UNAVAILABLE = 'PLAN_IS_UNAVAILABLE',
  PLAN_LIMIT_ID_NOT_FOUND = 'PLAN_LIMIT_ID_NOT_FOUND',
}

export enum EPrice {
  PRICE_ID_NOT_FOUND = 'PRICE_ID_NOT_FOUND',
}

export enum EQrCodeError {
  QR_CODE_TYPE_NOT_AVAILABLE = 'QR_CODE_TYPE_NOT_AVAILABLE',
  QR_CODE_ALREADY_HAS_OWNER = 'QR_CODE_ALREADY_HAS_OWNER',
  QR_CODE_NOT_RELATED = 'QR_CODE_NOT_RELATED',
  QR_CODE_NOT_AVAILABLE = 'QR_CODE_NOT_AVAILABLE',
  QR_CODE_ID_NOT_FOUND = 'QR_CODE_ID_NOT_FOUND',
  QR_CODE_TEMPLATE_NOT_RELATED = 'QR_CODE_TEMPLATE_NOT_RELATED',
  QR_CODE_TEMPLATE_ID_NOT_FOUND = 'QR_CODE_TEMPLATE_ID_NOT_FOUND',
  QR_CODE_DESIGN_ID_NOT_FOUND = 'QR_CODE_DESIGN_ID_NOT_FOUND',
  QR_CODE_VCARD_ID_NOT_FOUND = 'QR_CODE_VCARD_ID_NOT_FOUND',
  QR_CODE_PDF_ID_NOT_FOUND = 'QR_CODE_PDF_ID_NOT_FOUND',
  QR_CODE_LOGO_ID_NOT_FOUND = 'QR_CODE_LOGO_ID_NOT_FOUND',
  QR_CODE_WEBSITE_ID_NOT_FOUND = 'QR_CODE_WEBSITE_ID_NOT_FOUND',
  QR_CODE_MULTILINK_ID_NOT_FOUND = 'QR_CODE_MULTILINK_ID_NOT_FOUND',
  QR_CODE_CANNOT_ASSIGN_TO_ANONYMOUS_USER = 'QR_CODE_CANNOT_ASSIGN_TO_ANONYMOUS_USER',
  QR_CODE_CANNOT_CONVERT_CSV = 'QR_CODE_CANNOT_CONVERT_CSV',
  QR_CODE_CANNOT_BE_CREATED = 'QR_CODE_CANNOT_BE_CREATED',
  QR_CODE_BULK_CREATION_CANNOT_BE_EMPTY = 'QR_CODE_BULK_CREATION_CANNOT_BE_EMPTY',
  QRCODEBULKCREATIONCANNOTBEMORETHEN50ITEMS = 'QR_CODE_BULK_CREATION_CANNOT_BE_MORE_THEN_50_ITEMS',
  QR_CODE_LIMIT_EXCEEDED = 'QR_CODE_LIMIT_EXCEEDED',
  USER_HAS_LIMITED_BULK_QR_CODE_GENERATION = 'USER_HAS_LIMITED_BULK_QR_CODE_GENERATION',
  QR_CODE_HAS_BEEN_DELETED = 'QR_CODE_HAS_BEEN_DELETED',
}

export enum ERestrictionError {
  MAX_LABELS_USED_RESTRICTION = 'MAX_LABELS_USED_RESTRICTION',
  MAX_QR_CODE_USED_RESTRICTION = 'MAX_QR_CODE_USED_RESTRICTION',
  MAX_SCAN_USED_RESTRICTION = 'MAX_SCAN_USED_RESTRICTION',
  PLAN_SUBSCRIPTION_RESTRICTION = 'PLAN_SUBSCRIPTION_RESTRICTION',
  PLAN_CREATION_QR_CODE_RESTRICTION = 'PLAN_CREATION_QR_CODE_RESTRICTION',
  PLAN_TEMPLATE_RESTRICTION = 'PLAN_TEMPLATE_RESTRICTION',
  PLAN_GPS_RESTRICTION = 'PLAN_GPS_RESTRICTION',
  PLAN_SCAN_RESTRICTION = 'PLAN_SCAN_RESTRICTION',
  MAX_BULK_QR_CODE_USED_RESTRICTION = 'MAX_BULK_QR_CODE_USED_RESTRICTION',
  PLAN_RESTRICTION = 'PLAN_RESTRICTION',
}

export enum ESubscriptionError {
  SUBSCRIPTION_ALREADY_CANCELED = 'SUBSCRIPTION_ALREADY_CANCELED',
  SUBSCRIPTION_CAN_NOT_RENEW = 'SUBSCRIPTION_CAN_NOT_RENEW',
  SUBSCRIPTION_DOES_NOT_EXISTED = 'SUBSCRIPTION_DOES_NOT_EXISTED',
  SUBSCRIPTION_NEED_TO_CANCEL_TO_CHANGE_CURRENCY = 'SUBSCRIPTION_NEED_TO_CANCEL_TO_CHANGE_CURRENCY',
  SUBSCRIPTION_CAN_NOT_DOWNGRADED = 'SUBSCRIPTION_CAN_NOT_DOWNGRADED',
  SUBSCRIPTION_STILL_ACTIVE = 'SUBSCRIPTION_STILL_ACTIVE',
  SUBSCRIPTION_HAS_NOT_ANY_SCHEDULED_UPDATE = 'SUBSCRIPTION_HAS_NOT_ANY_SCHEDULED_UPDATE',
  SUBSCRIPTION_CANNOT_UPGRADE_TRIALING = 'SUBSCRIPTION_CANNOT_UPGRADE_TRIALING',
  SUBSCRIPTION_ID_NOT_FOUND = 'SUBSCRIPTION_ID_NOT_FOUND',
}

export enum EUserError {
  USER_INCORRECT_CONFIRMATION_CODES = 'USER_INCORRECT_CONFIRMATION_CODES',
  USER_EMAIL_NOT_FOUND = 'USER_EMAIL_NOT_FOUND',
  USER_ID_NOT_FOUND = 'USER_ID_NOT_FOUND',
  USER_CANNOT_BE_ACTIVATED_OR_BANNED = 'USER_CANNOT_BE_ACTIVATED_OR_BANNED',
  USER_CANNOT_CHANGE_OF_EXPIRATION_DATE = 'USER_CANNOT_CHANGE_OF_EXPIRATION_DATE',
  USER_CANNOT_CHANGE_OF_EXPIRATION_DATE_BEFORE_NOW = 'USER_CANNOT_CHANGE_OF_EXPIRATION_DATE_BEFORE_NOW',
  USER_EMAIL_CONFIRMATION_BLOCKED = 'USER_EMAIL_CONFIRMATION_BLOCKED',
  USER_EMAIL_NOT_VERIFIED = 'USER_EMAIL_NOT_VERIFIED',
  USER_EMAIL_CONFIRMATION_EXPIRED = 'USER_EMAIL_CONFIRMATION_EXPIRED',
  USER_HAS_DISPUTED_PAYMENT = 'USER_HAS_DISPUTED_PAYMENT',
  USER_SETTING_NOT_FOUND = 'USER_SETTING_NOT_FOUND',
}

export interface Error {
  message: string;
  /** Base error code interface */
  errorCode: ErrorCode;
  args?: (object | null)[];
  template?: string | null;
}

/** Base error code interface */
export type ErrorCode = BaseErrorCode &
  (
    | BaseErrorCodeTypeMapping<'EPlanError', any>
    | BaseErrorCodeTypeMapping<'EAuthError', any>
    | BaseErrorCodeTypeMapping<'ERestrictionError', any>
    | BaseErrorCodeTypeMapping<'EQrCodeError', any>
    | BaseErrorCodeTypeMapping<'EOAuth2Error', any>
    | BaseErrorCodeTypeMapping<'EFileError', any>
    | BaseErrorCodeTypeMapping<'EUserError', any>
    | BaseErrorCodeTypeMapping<'ESubscriptionError', any>
    | BaseErrorCodeTypeMapping<'EInvitationError', any>
    | BaseErrorCodeTypeMapping<'EPaymentError', any>
    | BaseErrorCodeTypeMapping<'EPriceError', any>
  );

export interface ErrorResponse {
  /** @format int64 */
  timestamp: number;
  /** @format int32 */
  status: number;
  errors: Error[];
}

export interface ErrorRestrictionResponse {
  /** @format int64 */
  timestamp: number;
  /** @format int32 */
  status: number;
  errors: Error[];
  errorCodes: ErrorCode[];
}

export interface EmailPreferenceInDto {
  token: string;
  accountNotification: boolean;
  qrNotification: boolean;
  marketingNotification: boolean;
}

export interface EmailPreferenceInfoOutDto {
  email: string;
  accountNotification: boolean;
  qrNotification: boolean;
  marketingNotification: boolean;
}

/** @default "EUR" */
export enum ECurrency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
}

/** @default "QUARTERLY" */
export enum EPlanCycle {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
}

export interface SubscriptionInDto {
  /**
   * @format uuid
   * @default "207fd6bd-fc50-404a-96c3-ed47ef3af591"
   */
  planId: string;
  /**
   * @format uuid
   * @default "aeb3d760-a642-497d-ae81-1d67296b1c0d"
   */
  priceId?: string;
  planCycle: EPlanCycle;
  currency: ECurrency;
  /** @default "https://osdb.io" */
  successRedirectURL: string;
  /** @default "https://osdb.io" */
  errorRedirectURL: string;
  /** @default false */
  allowPromotionCodes?: boolean;
}

export interface SubscriptionGatewayOutDto {
  url?: string | null;
  isScheduledDowngrade?: boolean | null;
  isScheduledDowngradeToFree?: boolean | null;
  scheduledId?: string | null;
}

export interface DesignInDto {
  template?: TemplateInDto;
  /** @format uuid */
  libraryId?: string;
  /**
   * @format int32
   * @example 0
   */
  logoSize?: number;
  frameType: EFrameType;
  frameText?: string;
  /**
   * @format int32
   * @example 0
   */
  frameTextSize?: number;
  /** @example "#000000" */
  frameTextColor: string;
  /** @example "#000000" */
  frameBackgroundColor: string;
  /** @example "#FFFFFF" */
  backgroundColor: string;
  patternType: EPatternType;
  cornerType: ECornerType;
  /** @example "#000000" */
  patternColor: string;
  /** @example "#000000" */
  cornerColor: string;
  reusable?: boolean;
}

/** @example "SQUARE" */
export enum ECornerType {
  SQUARE = 'SQUARE',
  ROUNDED = 'ROUNDED',
  FULL_CIRCLE = 'FULL_CIRCLE',
}

/** @example "NONE" */
export enum EFrameType {
  NONE = 'NONE',
  BORDER_LABEL_BOTTOM = 'BORDER_LABEL_BOTTOM',
  BORDER_LABEL_TOP = 'BORDER_LABEL_TOP',
  ARROW_LABEL_TOP = 'ARROW_LABEL_TOP',
  ARROW_LABEL_BOTTOM = 'ARROW_LABEL_BOTTOM',
  BORDER_SPACE_LABEL_BOTTOM = 'BORDER_SPACE_LABEL_BOTTOM',
  BORDER_SPACE_LABEL_TOP = 'BORDER_SPACE_LABEL_TOP',
  BORDER_INTERRUPTION = 'BORDER_INTERRUPTION',
  NO_BORDER = 'NO_BORDER',
  BORDER = 'BORDER',
}

/** @example "SQUARE" */
export enum EPatternType {
  SQUARE = 'SQUARE',
  CLASSY = 'CLASSY',
  CLASSY_ROUNDED = 'CLASSY_ROUNDED',
  DOTS = 'DOTS',
  EXTRA_ROUNDED = 'EXTRA_ROUNDED',
  ROUNDED = 'ROUNDED',
}

export interface TemplateInDto {
  /** @example "Title template" */
  title: string;
}

export interface DesignOutDto {
  /** @format uuid */
  id: string;
  createdBy?: UserBaseOutDto;
  template?: TemplateOutDto;
  library?: LibraryOutDto;
  isReusable: boolean;
  /** @format int32 */
  logoSize: number;
  frameType: EFrameType;
  frameText?: string | null;
  /** @format int32 */
  frameTextSize: number;
  frameTextColor: string;
  frameBackgroundColor: string;
  backgroundColor: string;
  patternType: EPatternType;
  cornerType: ECornerType;
  patternColor: string;
  cornerColor: string;
  /** @format date-time */
  createdDate: string;
  reusable?: boolean;
}

export enum ECustomerGen {
  GEN1 = 'GEN_1',
  GEN2 = 'GEN_2',
  GEN3 = 'GEN_3',
  GEN4 = 'GEN_4',
}

export enum ECustomerSegment {
  STUDENT = 'STUDENT',
  NON_PROFIT_OR_CHARITY = 'NON_PROFIT_OR_CHARITY',
  TEACHER = 'TEACHER',
  LARGE_COMPANY = 'LARGE_COMPANY',
  PERSONAL = 'PERSONAL',
  SMALL_BUSINESS = 'SMALL_BUSINESS',
}

export enum ELanguage {
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  DUTCH = 'DUTCH',
  SPANISH = 'SPANISH',
  ITALIAN = 'ITALIAN',
}

export enum ELibraryType {
  DESIGN_LOGO = 'DESIGN_LOGO',
  MULTILINK_LOGO = 'MULTILINK_LOGO',
  VCARD_LOGO = 'VCARD_LOGO',
  LINK_LOGO = 'LINK_LOGO',
}

export enum ERole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_CUSTOMER = 'ROLE_CUSTOMER',
}

export enum EUserStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
  DELETED = 'DELETED',
}

export interface FileOutDto {
  /** @format uuid */
  id: string;
  fileName: string;
  /** @format date-time */
  createdDate: string;
}

export interface LibraryOutDto {
  /** @format uuid */
  id: string;
  file: FileOutDto;
  libraryType: ELibraryType;
  /** @format date-time */
  createdDate: string;
}

export interface TemplateOutDto {
  /** @format uuid */
  id: string;
  title: string;
  createdBy: UserBaseOutDto;
  /** @format date-time */
  createdDate: string;
}

export interface UserBaseOutDto {
  /** @format uuid */
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  language: ELanguage;
  currency: ECurrency;
  /** @format int64 */
  totalCodes?: number;
  /** @format int64 */
  totalScans?: number;
  /** @format int64 */
  monthlyScans?: number;
  shouldAskForGPSCoordinates?: boolean;
  accountNotification?: boolean;
  qrNotification?: boolean;
  marketingNotification?: boolean;
  phone?: string | null;
  address?: string | null;
  birthDate?: string | null;
  role: ERole;
  /** @format uuid */
  avatarId?: string | null;
  company?: string | null;
  status: EUserStatus;
  customerGen: ECustomerGen;
  customerSegment?: ECustomerSegment;
  /** @format date-time */
  upsellTopBannerHiddenDate?: string | null;
  /** @format date-time */
  createdDate: string;
}

export interface TemplateDeleteInDto {
  ids: string[];
}

export interface QrCodeMultiDeleteInDto {
  qrCodeIds: string[];
}

export interface QRCodeConnectInDto {
  generatedQRCodes?: string[];
}

export enum ECodeType {
  WEBSITE = 'WEBSITE',
  MULTILINK = 'MULTILINK',
  PDF = 'PDF',
  V_CARD = 'V_CARD',
}

export enum ECreatedWithFlow {
  MANUAL = 'MANUAL',
  BULK = 'BULK',
}

export enum ELinkType {
  CUSTOM = 'CUSTOM',
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  TIKTOK = 'TIKTOK',
  TELEGRAM = 'TELEGRAM',
  WHATSAPP = 'WHATSAPP',
  INSTAGRAM = 'INSTAGRAM',
  YOUTUBE = 'YOUTUBE',
  SKYPE = 'SKYPE',
  TWITTER = 'TWITTER',
}

export interface LinkOutDto {
  /** @format uuid */
  id: string;
  library?: LibraryOutDto;
  title: string;
  url: string;
  linkType: ELinkType;
  /** @format date-time */
  createdDate: string;
}

export interface MultilinkOutDto {
  /** @format uuid */
  id: string;
  library?: LibraryOutDto;
  title: string;
  description?: string;
  designBgColor: string;
  designTitleColor: string;
  designDescriptionColor: string;
  buttonBgColor: string;
  buttonTextColor: string;
  buttonHoverColor: string;
  buttonBorderColor: string;
  customLinks: LinkOutDto[];
  socialLinks: LinkOutDto[];
  /** @format date-time */
  createdDate: string;
}

export interface PDFOutDto {
  /** @format uuid */
  id: string;
  file: FileOutDto;
  /** @format date-time */
  createdDate: string;
}

export interface PageResponseQRCodeOutDto {
  result: QRCodeOutDto[];
  /** @format int64 */
  total: number;
}

export interface QRCodeOutDto {
  id: string;
  name: string;
  design?: DesignOutDto;
  createdBy?: UserBaseOutDto;
  shouldAskForGPSCoordinates: boolean;
  codeType: ECodeType;
  /** @format int64 */
  amountOfScans?: number | null;
  /** @format int64 */
  monthlyScans?: number | null;
  publicLogoType?: string | null;
  /** @format date-time */
  expireDate?: string | null;
  /** @format date-time */
  createdDate: string;
  isOverScanLimit?: boolean | null;
  website?: WebsiteOutDto;
  pdf?: PDFOutDto;
  multilink?: MultilinkOutDto;
  vcard?: VCardOutDto;
  /** @format int64 */
  totalDownloads?: number | null;
  /** @format date-time */
  lastDownloadDate?: string | null;
  isCustomized?: boolean | null;
  hasOwnerDisputedPayment?: boolean | null;
  createdWithFlow: ECreatedWithFlow;
}

export interface VCardLinkOutDto {
  /** @format uuid */
  id: string;
  linkType: ELinkType;
  url: string;
  /** @format date-time */
  createdDate: string;
}

export interface VCardOutDto {
  /** @format uuid */
  id: string;
  library?: LibraryOutDto;
  fullName: string;
  phoneNumber: string;
  alternativePhoneNumber?: string | null;
  email: string;
  website: string;
  companyName: string;
  jobPosition: string;
  street: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  designPrimaryColor: string;
  designSecondaryColor: string;
  designNameColor: string;
  designTitleColor: string;
  designTextColor: string;
  designIconColor: string;
  designTextHoverColor: string;
  buttonText: string;
  /** @format int32 */
  buttonSize: number;
  buttonBgColor: string;
  buttonTextColor: string;
  buttonHoverColor: string;
  buttonBorderColor: string;
  links: VCardLinkOutDto[];
  /** @format date-time */
  createdDate: string;
}

export interface WebsiteOutDto {
  /** @format uuid */
  id: string;
  url: string;
  /** @format date-time */
  createdDate: string;
}

export interface CustomerInDto {
  firstName?: string;
  lastName?: string;
  /** @example "12345678912" */
  phone?: string;
  /** @example "Kharkiv, Ukraine" */
  address?: string;
  /** @example "1991-10-17" */
  birthDate?: string;
  /** @format uuid */
  avatarId?: string;
  /** @example "OSDB" */
  company?: string;
  language?: ELanguage;
  currency?: ECurrency;
  customerSegment?: ECustomerSegment;
  shouldAskForGPSCoordinates?: boolean;
  accountNotification?: boolean;
  qrNotification?: boolean;
  marketingNotification?: boolean;
  /** @format date-time */
  endOfSubscriptionDate?: string;
}

export enum EInvoiceStatus {
  PAID = 'PAID',
  FAILED = 'FAILED',
  OPEN = 'OPEN',
  DRAFT = 'DRAFT',
  VOID = 'VOID',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
}

export enum EPlanStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

export enum EPlanType {
  FREE = 'FREE',
  TRIAL = 'TRIAL',
  BASIC = 'BASIC',
  STARTER = 'STARTER',
  PREMIUM = 'PREMIUM',
  PRO = 'PRO',
  BUSINESS = 'BUSINESS',
}

export enum EScanAnalyticUnit {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum ESettingType {
  AUTH2FA = 'AUTH_2FA',
}

export enum EUsageWarning {
  USAGE80PERCENT = 'USAGE_80_PERCENT',
  USAGE100PERCENT = 'USAGE_100_PERCENT',
}

export interface PlanDetailsOutDto {
  planType: EPlanType;
  planStatus: EPlanStatus;
  /** @format date-time */
  endOfCurrentSubscription: string;
  /** @format date-time */
  endOfTrialCurrentSubscription?: string;
  hasDisputedPayment?: boolean | null;
  /** @format int32 */
  amountMaxQrCodes?: number | null;
  /** @format int32 */
  amountMaxScans?: number | null;
  hasTemplates: boolean;
  hasLabels: boolean;
  hasRealTimeDataDashboard: boolean;
  hasTrackScanTimeOfDay: boolean;
  hasTrackScanLocation: boolean;
  hasTrackScanDevice: boolean;
  hasTrackScanGPS: boolean;
  hasQrDesignCustomisations: boolean;
  hasBulkQrCodeGeneration: boolean;
  /** @format int32 */
  amountOfMaxBulkQrCode?: number | null;
  gdprCompliant: boolean;
  ccpaCompliant: boolean;
  knowledgeBase: boolean;
  emailSupport: boolean;
  dedicatedCustomerSuccessManager: boolean;
  hasFileExtensions: boolean;
  fileExtensions: string[];
  /** @format int32 */
  scanAnalyticsQuantity?: number | null;
  scanAnalyticsUnit?: EScanAnalyticUnit;
}

export interface PlanUserOutDto {
  /** @format uuid */
  id: string;
  name: string;
  price?: PriceOutDto;
  scheduledUpdate?: ScheduleUpdateOutDto;
  lastInvoiceId?: string | null;
  lastInvoiceStatus?: EInvoiceStatus;
  canceledReason?: string | null;
  /** @format date-time */
  createdDate: string;
}

export interface PriceOutDto {
  /** @format uuid */
  id?: string | null;
  /** @format double */
  price?: number | null;
  planCycle?: EPlanCycle;
  planCurrency: ECurrency;
  customerGen: ECustomerGen;
  /** @format date-time */
  createdDate?: string | null;
}

export interface ScheduleUpdateOutDto {
  planType: EPlanType;
  planCycle?: EPlanCycle;
  /** @format date-time */
  nextPeriodStart: string;
  scheduleSubscriptionId?: string;
}

export interface UserOutDto {
  /** @format uuid */
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  language: ELanguage;
  currency: ECurrency;
  /** @format int64 */
  totalCodes?: number;
  /** @format int64 */
  totalScans?: number;
  /** @format int64 */
  monthlyScans?: number;
  shouldAskForGPSCoordinates?: boolean;
  accountNotification?: boolean;
  qrNotification?: boolean;
  marketingNotification?: boolean;
  phone?: string | null;
  address?: string | null;
  birthDate?: string | null;
  role: ERole;
  /** @format uuid */
  avatarId?: string | null;
  company?: string | null;
  status: EUserStatus;
  /** @format date-time */
  createdDate: string;
  settings: UserSettingOutDto[];
  plan?: PlanUserOutDto;
  currentPlanDetails?: PlanDetailsOutDto;
  /** @format date-time */
  stripeSyncedDate?: string | null;
  /** @format uuid */
  currentSubscriptionId?: string | null;
  /** @format uuid */
  previousSubscriptionId?: string | null;
  customerId?: string | null;
  chargebeeId?: string | null;
  activeCampaignId?: string | null;
  /** @format date-time */
  lastActiveCampaignSyncedDate?: string | null;
  anyCodeDownloaded: boolean;
  anyCodeCustomized: boolean;
  hasVerifiedEmail?: boolean | null;
  isOldPlanUser: boolean;
  usageWarning?: EUsageWarning;
  /** @format date-time */
  newUsageResetDate?: string | null;
  customerGen: ECustomerGen;
  customerSegment?: ECustomerSegment;
  /** @format date-time */
  upsellTopBannerHiddenDate?: string | null;
}

export interface UserSettingOutDto {
  /** @format uuid */
  id: string;
  type: ESettingType;
  isEnabled: boolean;
}

export interface UserChangeUpsellTopBannerHiddenDateInDto {
  /** @format date-time */
  hiddenDate?: string;
}

export interface AdminInDto {
  firstName: string;
  lastName: string;
  /** @example "12345678912" */
  phone?: string;
  /** @example "Kharkiv, Ukraine" */
  address?: string;
  /** @example "1991-10-17" */
  birthDate?: string;
  /** @format uuid */
  avatarId?: string;
  language?: ELanguage;
}

export interface AdminOutDto {
  /** @format uuid */
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  language: ELanguage;
  phone?: string;
  address?: string;
  birthDate?: string;
  role: ERole;
  /** @format uuid */
  avatarId?: string;
  status: EUserStatus;
  /** @format date-time */
  createdDate: string;
}

export interface CheckEmailConfirmationInDto {
  /** @example "okogutenko+client@osdb.io" */
  email: string;
  confirmationCode: string;
}

export interface CheckEmailConfirmationOutDto {
  hasVerifiedEmail: boolean;
  /** @format int32 */
  countOfFailedConfirmationCode?: number | null;
  /** @format date-time */
  lastConfirmationEmailDate?: string | null;
  /** @format date-time */
  nextConfirmationEmailDate?: string | null;
}

export interface CheckEmailRegisteredInDto {
  /** @example "okogutenko+client@osdb.io" */
  email: string;
}

export interface CheckEmailRegisteredOutDto {
  isEmailRegistered: boolean;
}

export interface GeoDetailsInDto {
  /** @format double */
  lat: number;
  /** @format double */
  lng: number;
  /** @format double */
  accuracy?: number;
}

export interface ScanInDto {
  /** @format uuid */
  deviceId: string;
  gps?: GeoDetailsInDto;
}

export type CodeInDto = object;

export interface LinkInDto {
  /** @format uuid */
  libraryId?: string;
  title: string;
  url: string;
  linkType: ELinkType;
}

export interface MultilinkInDto {
  /** @format uuid */
  libraryId?: string;
  title: string;
  description?: string;
  designBgColor: string;
  designTitleColor: string;
  designDescriptionColor: string;
  buttonBgColor: string;
  buttonTextColor: string;
  buttonHoverColor: string;
  buttonBorderColor: string;
  customLinks: LinkInDto[];
  socialLinks: LinkInDto[];
}

export interface PDFInDto {
  /** @format uuid */
  fileId: string;
}

export interface QRCodeInDto {
  /** @example "Name qr code" */
  name: string;
  design?: DesignInDto;
  /** @format uuid */
  templateId?: string;
  codeType: ECodeType;
  publicLogoType?: string;
  website?: WebsiteInDto;
  pdf?: PDFInDto;
  multilink?: MultilinkInDto;
  vcard?: VCardInDto;
  googleClickId?: string;
  codeDto?: CodeInDto;
}

export interface VCardInDto {
  /** @format uuid */
  libraryId?: string;
  fullName: string;
  phoneNumber: string;
  alternativePhoneNumber?: string;
  email?: string;
  website?: string;
  companyName?: string;
  jobPosition?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  state?: string;
  country?: string;
  designPrimaryColor: string;
  designSecondaryColor: string;
  designNameColor: string;
  designTitleColor: string;
  designTextColor: string;
  designIconColor: string;
  designTextHoverColor: string;
  buttonText: string;
  /** @format int32 */
  buttonSize: number;
  buttonBgColor: string;
  buttonTextColor: string;
  buttonHoverColor: string;
  buttonBorderColor: string;
  links?: VCardLinkInDto[];
}

export interface VCardLinkInDto {
  linkType: ELinkType;
  url: string;
}

export interface WebsiteInDto {
  /** @example "https://osdb.io" */
  url: string;
}

export interface LibraryInDto {
  fileIds?: string[];
  libraryType?: ELibraryType;
}

export interface PageResponseLibraryOutDto {
  result: LibraryOutDto[];
  /** @format int64 */
  total: number;
}

/** @example "COOKIE" */
export enum EAuthType {
  COOKIE = 'COOKIE',
  TOKEN = 'TOKEN',
}

export interface SignUpInDto {
  /** @example "ssmith@osdb.io" */
  email: string;
  language: ELanguage;
  autoLinkQRID?: string;
  googleClickId?: string;
  authType?: EAuthType;
}

export interface AuthOutDto {
  /** @format uuid */
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  language: ELanguage;
  currency: ECurrency;
  /** @format int64 */
  totalCodes?: number;
  /** @format int64 */
  totalScans?: number;
  /** @format int64 */
  monthlyScans?: number;
  shouldAskForGPSCoordinates?: boolean;
  accountNotification?: boolean;
  qrNotification?: boolean;
  marketingNotification?: boolean;
  phone?: string | null;
  address?: string | null;
  birthDate?: string | null;
  role: ERole;
  /** @format uuid */
  avatarId?: string | null;
  company?: string | null;
  status: EUserStatus;
  /** @format date-time */
  createdDate: string;
  settings: UserSettingOutDto[];
  plan?: PlanUserOutDto;
  currentPlanDetails?: PlanDetailsOutDto;
  /** @format date-time */
  stripeSyncedDate?: string | null;
  hasVerifiedEmail?: boolean | null;
  usageWarning?: EUsageWarning;
  /** @format date-time */
  newUsageResetDate?: string | null;
  accessToken?: string | null;
  refreshToken?: string;
}

export interface SignInStepOneInDto {
  /** @example "okogutenko+client@osdb.io" */
  email: string;
  /** @example "okogutenko+client@osdb.io" */
  password: string;
  authType?: EAuthType;
}

export interface SignInStepTwoInDto {
  /** @example "dberchiyan@osdb.io" */
  email: string;
  confirmCode: string;
  authType?: EAuthType;
}

export interface UpdateTokensInDto {
  refreshToken: string;
}

export interface AuthTokensOutDto {
  accessToken: string;
  refreshToken: string;
}

export interface InvitationSignUpInDto {
  /** @format uuid */
  invitationToken: string;
  password: string;
  firstName: string;
  lastName: string;
  /** @example "12345678912" */
  phone?: string;
  /** @example "Kharkiv, Ukraine" */
  address?: string;
  /** @example "1991-10-17" */
  birthDate?: string;
  /** @example "OSDB" */
  company?: string;
  language: ELanguage;
  authType?: EAuthType;
}

export interface ResetPasswordInDto {
  /** @example "dberchiyan@osdb.io" */
  email: string;
  confirmCode: string;
  /** @example "dberchiyan@osdb.io" */
  newPassword: string;
}

export interface ForgotPasswordInDto {
  /** @example "dberchiyan@osdb.io" */
  email: string;
}

export interface InviteInDto {
  email: string;
  /**
   * @pattern ROLE_CUSTOMER
   * @example "ROLE_CUSTOMER"
   */
  role?: string;
}

export interface QrCodeBulkCreationOutDto {
  createdCodes?: QRCodeOutDto[];
}

export interface QRCodeBulkWebsiteInDto {
  createCodes: QRCodeWebsiteInDto[];
}

export interface QRCodeWebsiteInDto {
  name: string;
  /** @pattern ^(http|https)://.*$ */
  websiteUrl: string;
}

export interface CustomerAdminOutDto {
  /** @format uuid */
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  deletedEmail?: string | null;
  language: ELanguage;
  currency: ECurrency;
  /** @format int64 */
  totalCodes?: number;
  /** @format int64 */
  totalScans?: number;
  /** @format int64 */
  monthlyScans?: number;
  shouldAskForGPSCoordinates?: boolean;
  accountNotification?: boolean;
  qrNotification?: boolean;
  marketingNotification?: boolean;
  phone?: string | null;
  address?: string | null;
  birthDate?: string | null;
  role: ERole;
  /** @format uuid */
  avatarId?: string | null;
  company?: string | null;
  subscription: CustomerSubscriptionOutDto;
  status: EUserStatus;
  customerId?: string | null;
  chargebeeId?: string | null;
  /** @format date-time */
  stripeSyncedDate?: string | null;
  /** @format date-time */
  deletedDate?: string | null;
  /** @format date-time */
  createdDate: string;
  activeCampaignId?: string | null;
  /** @format date-time */
  lastActiveCampaignSyncedDate?: string | null;
  activeSessions?: UserAdminSessionOutDto[];
  googleClickId?: string | null;
  /** @format date-time */
  trialUsageDate?: string | null;
  hasVerifiedEmail?: boolean | null;
  hasDisputedPayment: boolean;
  customerGen: ECustomerGen;
  customerSegment?: ECustomerSegment;
  /** @format date-time */
  upsellTopBannerHiddenDate?: string | null;
  usedCoupon?: string | null;
}

export interface CustomerAdminSyncStripeOutDto {
  stripeInformation?: StripeSubscriptionInfoOutDto;
  systemInformation?: SystemSubscriptionInfoOutDto;
  isSuccess?: boolean;
  reasonFailed?: string;
  outAfter?: string;
}

export interface CustomerSubscriptionOutDto {
  planType: EPlanType;
  planStatus: EPlanStatus;
  currency?: ECurrency;
  planCycle?: EPlanCycle;
  /** @format date-time */
  startOfSubscriptionDate?: string | null;
  /** @format date-time */
  endOfSubscriptionDate?: string | null;
  /** @format date-time */
  endOfTrialSubscriptionDate?: string | null;
  scheduledUpdate?: ScheduleUpdateOutDto;
}

export enum ESubscriptionStatus {
  ACTIVE = 'ACTIVE',
  PAST_DUE = 'PAST_DUE',
  UNPAID = 'UNPAID',
  CANCELED = 'CANCELED',
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
  TRIALING = 'TRIALING',
  PAUSED = 'PAUSED',
}

export interface StripeSubscriptionInfoOutDto {
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
  subscriptionStatus?: string;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  cancelAt?: string;
  /** @format date-time */
  canceledAt?: string;
  /** @format date-time */
  endedAt?: string;
  /** @format date-time */
  currentPeriodEnd?: string;
  /** @format date-time */
  currentPeriodStart?: string;
}

export interface SystemSubscriptionInfoOutDto {
  resultCustomer?: CustomerAdminOutDto;
  planType?: EPlanType;
  subscriptionStatus?: ESubscriptionStatus;
  /** @format date-time */
  expirationDate?: string;
  /** @format date-time */
  stripeSyncedDate?: string;
}

export interface UserAdminSessionOutDto {
  /** @format uuid */
  id: string;
  ipAddress: string;
  platform: string;
  /** @format date-time */
  lastUsed: string;
  /** @format date-time */
  createdDate: string;
}

export interface CustomerPortalInDto {
  /** @default "https://osdb.io" */
  returnURL: string;
}

export interface CustomerPortalOutDto {
  url: string;
}

export interface UserStatusInDto {
  /**
   * @pattern ACTIVE|BANNED|DELETED
   * @example "ACTIVE"
   */
  status: string;
}

export interface InvitationStatusInDto {
  /**
   * @pattern PENDING|CANCELED
   * @example "PENDING"
   */
  status: string;
}

export interface QRCodeChangeOwnerStepTwoInDto {
  newOwnerEmail: string;
  verificationCode: string;
}

export interface QRCodeChangeOwnerStepOneInDto {
  recipientEmail: string;
}

export interface BillingInDto {
  firstName?: string;
  lastName?: string;
  billingEmail?: string;
  companyName?: string;
  country?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  streetAddress?: string;
}

export interface BillingInfoOutDto {
  billing?: BillingOutDto;
}

export interface BillingOutDto {
  firstName?: string | null;
  lastName?: string | null;
  billingEmail: string;
  companyName?: string | null;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  zipCode?: string | null;
  streetAddress?: string | null;
}

export interface ChangePasswordInDto {
  /** @example "dberchiyan@osdb.io" */
  newPassword: string;
  /** @example "dberchiyan@osdb.io" */
  confirmNewPassword: string;
}

export interface UserChangeEmailStepTwoInDto {
  newEmailConfirmCode: string;
}

export interface UserChangeEmailStepOneInDto {
  newEmail: string;
}

export interface TwoFAStepTwoInDto {
  confirmCode: string;
  isEnabled: boolean;
}

export interface QrCodeAdminInDto {
  /** @format uuid */
  createdById?: string;
  isDeleted?: boolean;
}

export interface QRCodeAdminOutDto {
  id: string;
  name: string;
  isDeleted: boolean;
  design?: DesignOutDto;
  createdBy?: UserBaseOutDto;
  shouldAskForGPSCoordinates: boolean;
  codeType: ECodeType;
  /** @format int64 */
  amountOfScans?: number | null;
  /** @format int64 */
  monthlyScans?: number | null;
  publicLogoType?: string | null;
  /** @format date-time */
  expireDate?: string | null;
  /** @format date-time */
  createdDate: string;
  website?: WebsiteOutDto;
  pdf?: PDFOutDto;
  multilink?: MultilinkOutDto;
  vcard?: VCardOutDto;
  /** @format date-time */
  deletedDate?: string | null;
  createdWithFlow: ECreatedWithFlow;
}

export interface ResponceBuildVersion {
  version?: string;
  /** @format date-time */
  buildTime?: string;
}

export enum EInvitationStatus {
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  REGISTERED = 'REGISTERED',
}

export interface PageResponseUserInvitationOutDto {
  result: UserInvitationOutDto[];
  /** @format int64 */
  total: number;
}

export interface UserInvitationOutDto {
  /** @format uuid */
  id: string;
  email: string;
  /** @format uuid */
  token: string;
  role: ERole;
  status: EInvitationStatus;
  /** @format date-time */
  createdDate: string;
  /** @format uuid */
  invitedBy: string;
}

export interface PlanSubscriptionOutDto {
  /** @format uuid */
  id: string;
  name: string;
  price: PriceOutDto;
  /** @format date-time */
  createdDate: string;
}

export interface SubscriptionOutDto {
  /** @format uuid */
  id?: string | null;
  subscription: PlanSubscriptionOutDto;
  status: ESubscriptionStatus;
  /** @format date-time */
  createdDate?: string | null;
}

export interface PageResponseScanOutDto {
  result: ScanOutDto[];
  /** @format int64 */
  total: number;
}

export interface ScanOutDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  deviceId: string;
  qrCodeId: string;
  ip: string;
  country?: string | null;
  region?: string | null;
  city?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  providerOrg?: string | null;
  timeZone?: string | null;
  operationSystem?: string | null;
  deviceType?: string | null;
  browserName?: string | null;
  browserType?: string | null;
  browserVersion?: string | null;
  basedOn?: 'IP' | 'GPS' | null;
  serviceError?: string | null;
  /** @format date-time */
  createdDate: string;
}

export interface PageResponseDesignOutDto {
  result: DesignOutDto[];
  /** @format int64 */
  total: number;
}

export interface DisabledReasonsOutDto {
  reasonCode?: ERestrictionError;
}

export interface PageResponsePlanOutDto {
  result: PlanOutDto[];
  /** @format int64 */
  total: number;
}

export interface PlanLimitOutDto {
  planType: EPlanType;
  /** @format int32 */
  amountMaxQrCodes?: number | null;
  /** @format int32 */
  amountMaxScans?: number | null;
  hasTemplates: boolean;
  hasLabels: boolean;
  hasRealTimeDataDashboard: boolean;
  hasTrackScanTimeOfDay: boolean;
  hasTrackScanLocation: boolean;
  hasTrackScanDevice: boolean;
  hasTrackScanGPS: boolean;
  hasQrDesignCustomisations: boolean;
  hasBulkQrCodeGeneration: boolean;
  /** @format int32 */
  amountOfMaxBulkQrCode?: number | null;
  gdprCompliant: boolean;
  ccpaCompliant: boolean;
  knowledgeBase: boolean;
  emailSupport: boolean;
  dedicatedCustomerSuccessManager: boolean;
  /** @format int32 */
  scanAnalyticsQuantity?: number | null;
  scanAnalyticsUnit?: EScanAnalyticUnit | null;
  hasFileExtensions: boolean;
  fileExtensions: string[];
  qrAdjustable?: boolean | null;
}

export interface PlanOutDto {
  /** @format uuid */
  id: string;
  name: string;
  type: EPlanType;
  prices: PriceOutDto[];
  planLimit: PlanLimitOutDto;
  disabledReasons?: DisabledReasonsOutDto[];
  /** @format date-time */
  createdDate: string;
}

export enum EEmailTopic {
  WARNING80PERCENTSCANLIMIT = 'WARNING_80_PERCENT_SCAN_LIMIT',
  EXPIRED_QR_SCANNED = 'EXPIRED_QR_SCANNED',
  SIGN_UP = 'SIGN_UP',
  PLAN_UPGRADE = 'PLAN_UPGRADE',
  PLAN_DOWNGRADE = 'PLAN_DOWNGRADE',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  QR_CODE_CHANGE_OWNER = 'QR_CODE_CHANGE_OWNER',
  ACCOUNT_OVER_SCAN_LIMIT = 'ACCOUNT_OVER_SCAN_LIMIT',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
}

export interface EmailReportOutDto {
  /** @format uuid */
  id: string;
  subject: string;
  topic: EEmailTopic;
  /** @format uuid */
  userId: string;
  recipientEmail: string;
  context: string;
  language: ELanguage;
  /** @format date-time */
  createdDate: string;
}

export interface PageResponseEmailReportOutDto {
  result: EmailReportOutDto[];
  /** @format int64 */
  total: number;
}

export interface DashboardOutDto {
  /** @format int64 */
  totalQRCodes: number;
  /** @format int64 */
  totalScans: number;
  /** @format int64 */
  monthlyScans: number;
  /** @format int64 */
  totalUsers: number;
  /** @format int64 */
  monthlyUsers: number;
}

export enum ESubscriptionAdminStatus {
  ACTIVE = 'ACTIVE',
  CANCELED_NOT_EXPIRED = 'CANCELED_NOT_EXPIRED',
  EXPIRED = 'EXPIRED',
}

export interface PageResponseCustomerAdminOutDto {
  result: CustomerAdminOutDto[];
  /** @format int64 */
  total: number;
}

export interface CardMethodOutDto {
  last4: string;
  /** @format int64 */
  expMonth: number;
  /** @format int64 */
  expYear: number;
  brand: string;
}

export interface PaymentMethodInfoOutDto {
  cardMethod?: CardMethodOutDto;
  sepaMethod?: SepaMethodOutDto;
}

export interface SepaMethodOutDto {
  last4: string;
  bankCode: string;
  branchCode: string;
  country: string;
}

export interface InvoiceOutDto {
  name: string;
  /** @format date-time */
  date: string;
  planType?: EPlanType;
  status: EInvoiceStatus;
  /** @format int64 */
  amount: number;
  currency?: ECurrency;
  urlInvoice?: string | null;
  invoiceId: string;
  failedReason?: string | null;
  openReason?: string | null;
  hasDisputed: boolean;
}

export interface PageResponseInvoiceOutDto {
  result: InvoiceOutDto[];
  /** @format int64 */
  total: number;
}

export interface UserSettingListOutDto {
  settings: UserSettingOutDto[];
}

export interface UserSessionListOutDto {
  sessions: UserSessionOutDto[];
}

export interface UserSessionOutDto {
  /** @format uuid */
  id: string;
  ipAddress: string;
  platform: string;
  isCurrent: boolean;
  /** @format int64 */
  totalRequests: number;
  /** @format date-time */
  createdDate: string;
}

export interface ScansByLocationOutDto {
  /** @format int64 */
  totalCountries: number;
  /** @format int64 */
  totalCities: number;
  withLocation: WithLocationOutDto[];
  unknownLocation: UnknownLocationOutDto[];
}

export interface UnknownLocationOutDto {
  /** @format int64 */
  totalScans: number;
  /** @format int64 */
  totalUsers: number;
}

export interface WithLocationOutDto {
  country: string;
  city: string;
  /** @format int64 */
  totalScans: number;
  /** @format int64 */
  totalUsers: number;
  /** @format double */
  cityLatitude: number;
  /** @format double */
  cityLongitude: number;
}

export interface OverallAnalyticOutDto {
  /** @format int64 */
  totalQRCodes: number;
  /** @format int64 */
  totalScans: number;
  /** @format int64 */
  totalUsers: number;
}

export interface ChartAnalyticOutDto {
  results: ChartResultsOutDto[];
  /** @format int64 */
  total: number;
}

export interface ChartResultsOutDto {
  /** @format int64 */
  total: number;
  /** @format date-time */
  date: string;
}

export interface PageResponseAdminOutDto {
  result: AdminOutDto[];
  /** @format int64 */
  total: number;
}

export interface PageResponseQRCodeAdminOutDto {
  result: QRCodeAdminOutDto[];
  /** @format int64 */
  total: number;
}

/** Base error code interface */
type BaseErrorCode = object;

type BaseErrorCodeTypeMapping<Key, Type> = {
  type: Key;
} & Type;
