import { CopyIcon } from '@app/assets';
import { Button, Container, IconButton, Typography } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import React from 'react';
import { format } from 'date-fns';
import { FORMAT } from '@app/constants/formats';
import { toast } from 'react-toastify';
import { maskMiddleString } from '@app/utils/string.utils';
import { useApiKeys, useCreateApiKey, useDeleteApiKey } from '../api/apiKey.hooks.api';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';

type Props = {
  className: string;
};

export const AccountAPIKeysAccess: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const { data, isLoading, error } = useApiKeys();
  const createMutation = useCreateApiKey();
  const deleteMutation = useDeleteApiKey();

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center col-span-full">
          <ContentLoader loading />
        </div>
      );
    }
    if (error) {
      return (
        <div className="flex justify-center col-span-full">
          <ContentLoader error={error} />
        </div>
      );
    }

    if (!data || !data.total) {
      return (
        <div className="col-span-3 flex flex-col gap-3">
          <p className="text-m text-gray-800 font-semibold">{t('apikey.access.noAPIKey')}</p>
          <p className="font-normal text-gray-500 text-xs">{t('apikey.access.noAPIKeyDescription')}</p>
        </div>
      );
    }

    return data.result.map((e) => {
      const lastUsedFormatedDate = e.lastUsed
        ? format(new Date(e.lastUsed), FORMAT.DATE_YEAR)
        : t('apikey.access.naver');
      const createdFormatedDate = e.createdDate
        ? format(new Date(e.createdDate), FORMAT.DATE_YEAR)
        : t('apikey.access.naver');

      return (
        <React.Fragment key={e.id}>
          <CopyApiKey apiKey={e.keyValue} />

          <div className="col-span-1 lg:col-span-3 border-b border-gray-300" />

          <div className="text-gray-800 font-normal text-s italic whitespace-nowrap">
            {t('apikey.access.created')}: {createdFormatedDate}
          </div>
          <div className="text-gray-800 font-normal text-s italic whitespace-nowrap">
            {t('apikey.access.LastUser')}: {lastUsedFormatedDate}
          </div>
          <div className="hidden lg:block" />
        </React.Fragment>
      );
    });
  };

  const isApiKeyExist = (data && data?.total > 0) || false;
  const apiKeyId = data?.result[0]?.id;
  const handleButtonClick = () => {
    if (createMutation.isLoading || deleteMutation.isLoading) {
      return;
    }

    if (isApiKeyExist && apiKeyId) {
      deleteMutation.mutate(apiKeyId);
      return;
    }
    createMutation.mutate();
  };

  return (
    <Container
      className={clsxm(
        'overflow-x-scroll items-center grid grid-cols-1 lg:grid-cols-[auto_1fr_auto] gap-x-6 gap-y-4 p-6 lg:rounded-xl',
        className
      )}
    >
      <Typography className="text-lg text-gray-800 font-bold">{t('apikey.access.title')}</Typography>
      <div className="hidden lg:block" />
      <div className="flex justify-start w-full lg:justify-end">
        {!isApiKeyExist ? (
          <Button
            color="success"
            className="px-6 text-s font-semibold whitespace-nowrap"
            disabled={createMutation.isLoading}
            loading={createMutation.isLoading}
            onClick={handleButtonClick}
          >
            {t('apikey.access.createKeyButton')}
          </Button>
        ) : (
          <ConfirmDialog
            trigger={
              <Button
                color="error"
                className="px-6 text-s font-semibold whitespace-nowrap"
                disabled={deleteMutation.isLoading}
                loading={deleteMutation.isLoading}
              >
                {t('apikey.access.deleteKeyButton')}
              </Button>
            }
            onConfirm={handleButtonClick}
            containerClassName="max-w-[350px] text-center"
            title={`${t('dialog.deleteQuestionTitle')} ${t('apikey.entityName')}?`}
            text={`${t('dialog.areYouSureToDeleteThis')} ${t('apikey.entityName')}?"`}
            confirmText={t('dialog.yesDelete')}
          />
        )}
      </div>

      <div className="col-span-1 lg:col-span-3 border-b border-gray-300" />
      {renderContent()}
    </Container>
  );
};

export const CopyApiKey = ({ apiKey }: { apiKey: string }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const displayKey = isVisible ? apiKey : maskMiddleString(apiKey);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      toast.success(t('apikey.toastCopyMessageSuccess'));
      setIsVisible(true);
    } catch (err) {
      toast.error(t('apikey.toastCopyMessageError'));
      console.error('Failed to copy:', err);
    }
  };

  return (
    <>
      <div className="block text-m text-gray-800 font-semibold">{t('apikey.access.liveAPIKey')}</div>
      <button
        className="text-gray-800 font-normal cursor-pointer text-xs md:text-m whitespace-nowrap text-left"
        onClick={handleCopy}
      >
        {displayKey}
      </button>

      <IconButton
        className="hidden justify-start lg:ml-auto lg:flex"
        onClick={handleCopy}
        size="large"
        variant="transparent"
      >
        <CopyIcon role="button" className={clsxm('cursor-pointer transition text-gray-800 hover:text-accept-hover')} />
      </IconButton>
    </>
  );
};
