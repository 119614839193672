import { UISectionCurrencySelector } from './subscription/UISectionCurrencySelector';
import { UISectionInvoiceStatus } from './subscription/UISectionInvoiceStatus';
import { UISectionPaymentMethodDetails } from './subscription/UISectionPaymentMethodDetails';
import { UISectionPermissionCrown } from './subscription/UISectionPermissionCrown';
import { UISectionPlanLimitDialog } from './subscription/UISectionPlanLimitDialog';
import { UISectionSubscriptionPrice } from './subscription/UISectionSubscriptionPrice';
import { UISectionSubscriptionStatus } from './subscription/UISectionSubscriptionStatus';

export const UITabSubscription: React.FC = () => {
  return (
    <div className="flex flex-col gap-4">
      <UISectionCurrencySelector />
      <UISectionPlanLimitDialog />
      <UISectionPermissionCrown />
      <UISectionSubscriptionStatus />
      <UISectionSubscriptionPrice />
      <UISectionInvoiceStatus />
      <UISectionPaymentMethodDetails />
    </div>
  );
};
