import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ApiKeyApi } from './apiKey.api';

const QUERY_KEY_API_KEY = 'API_KEYS';
export const useApiKeys = () => {
  return useQuery({
    queryKey: [QUERY_KEY_API_KEY],
    queryFn: async () => ApiKeyApi.getAll(),
  });
};

export const useCreateApiKey = () => {
  const queryClient = useQueryClient();

  return useMutation(() => ApiKeyApi.create(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEY_API_KEY]);
    },
    onSettled: async () => {
      await queryClient.refetchQueries([QUERY_KEY_API_KEY]);
    },
  });
};

export const useDeleteApiKey = () => {
  const queryClient = useQueryClient();

  return useMutation((id: string) => ApiKeyApi.delete({ id }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEY_API_KEY]);
    },
    onSettled: async () => {
      await queryClient.refetchQueries([QUERY_KEY_API_KEY]);
    },
  });
};
