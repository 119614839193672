import { ECodeType } from '@app/swagger-types';
import { useFormContext } from 'react-hook-form';
import { QRCodeFormSchema } from '../../api/qr-code.form.schema.api';
import { QRCodeMultilink } from './multilink/QRCodeMultilink';
import { QRCodePdf } from './pdf/QRCodePdf';
import { QRCodeVCard } from './vcard/QRCodeVCard';
import { QRCodeWebsiteUrl } from './website/QRCodeWebsiteUrl';
import { useTranslation } from 'react-i18next';
import { usePlanLimits } from '@app/domain/plan/hooks/usePlanLimits';
import { ManagePlanButton } from '@app/domain/plan/components/ManagePlanButton';
import { PermissionCrown } from '@app/domain/plan/components/permission-crown/PermissionCrown';

export const QRCodeInputContainer: React.FC<{
  forWidgetMode?: boolean;
  isEditMode?: boolean;
}> = ({ forWidgetMode, isEditMode }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<QRCodeFormSchema>();
  const codeType = watch('codeType');
  const { canEditQrInput } = usePlanLimits();

  const getBody = () => {
    const disabledEditInput = isEditMode && !canEditQrInput && !forWidgetMode;
    if (codeType === ECodeType.WEBSITE) {
      if (forWidgetMode) {
        return <QRCodeWebsiteUrl className="h-auto" hint={t('qr.websiteInput.yourCodeGeneratedAutomatically')} />;
      }
      return <QRCodeWebsiteUrl disabledEditInput={disabledEditInput} />;
    }

    if (codeType === ECodeType.MULTILINK) {
      return <QRCodeMultilink forWidgetMode={forWidgetMode} disabledEditInput={disabledEditInput} />;
    }

    if (codeType === ECodeType.PDF) {
      return <QRCodePdf forWidgetMode={forWidgetMode} disabledEditInput={disabledEditInput} />;
    }

    if (codeType === ECodeType.V_CARD) {
      return <QRCodeVCard forWidgetMode={forWidgetMode} disabledEditInput={disabledEditInput} />;
    }

    return (
      <div className="flex-1 bg-white px-5 py-6 xl:rounded-xl">
        {codeType} section
        <div>In progress</div>
      </div>
    );
  };

  if (forWidgetMode || canEditQrInput || !isEditMode) {
    return getBody();
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex-1 bg-white px-5 py-6 xl:rounded-xl">
        <div className="flex gap-4 border-gray-300 border bg-gray-100 rounded-lg p-4">
          <PermissionCrown className="shrink-0" />
          <div className="flex flex-col gap-4">
            <b className="text-lg">{t('limit.upgradeToUnlockThisFeature')}</b>
            {t('limit.keepYourCampaignsWithoutReprinting')}
            <div>
              <ManagePlanButton />
            </div>
          </div>
        </div>
      </div>
      {getBody()}
    </div>
  );
};
