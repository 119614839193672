import { Container } from '@app/components';
import { TranslationKey } from '@app/locales/defaultTranslation';
import { clsxm } from '@app/styles/clsxm';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

type QRCodeStepperProps = {
  steps: TranslationKey[];
  activeStep: number;
  setActiveStep: (step: number) => void;
};

export const QRCodeStepper: React.FC<QRCodeStepperProps> = ({ steps, activeStep, setActiveStep }) => {
  const { t } = useTranslation();

  return (
    <Container className="flex gap-3 sm:gap-4 xl:rounded-xl">
      {steps.map((step, index) => (
        <Box
          key={step}
          className={clsxm(
            'flex items-center justify-center flex-1 rounded-lg border border-gray-200 py-2 px-[2px] text-center text-xs font-semibold text-slate-500 transition-all',
            'hover:cursor-pointer hover:border-accept-hover sm:px-4',
            activeStep === index && 'border-accept-main bg-accept-light text-accept-text'
          )}
          onClick={() => setActiveStep(index)}
        >
          {t(step)}
        </Box>
      ))}
    </Container>
  );
};
