import { IS_PROD_ENV } from '@app/environment/typed-env';
import { EGTAGRegistrationOrigin, EGTAGRegistrationPopUp, IEventDownloadQRCode } from './gtag.type';
import { ECustomerSegment } from '@app/swagger-types';

type GtagFn = (event: string, payload: object) => void;

const EMPTY_GTAG: GtagFn = (event, payload, prefix = 'LOCAL_') => {
  console.log(`gtag('${prefix}${event}', ${JSON.stringify(payload, null, 4)})`);
};

const getGtag = (): { gtag: GtagFn; dataLayer: any[] } => {
  // use gtag from window on PROD if available
  const dataLayer: any[] = (window as any).dataLayer || [];
  try {
    const gtag: GtagFn = (event, payload) => {
      // structure of dataLayer item depends on Google Tag Manager version
      dataLayer.push({ event, ...payload });
      if (!IS_PROD_ENV) {
        console.log(`gtag('${event}', ${JSON.stringify(payload, null, 4)})`);
      }
    };
    return { gtag, dataLayer };
  } catch {
    console.log('dataLayer not available');
    return { gtag: EMPTY_GTAG, dataLayer };
  }
};

const { gtag: _gtag, dataLayer } = getGtag();

const buildEventDispatcher =
  <T extends object>(event: string) =>
  (payload: T) =>
    _gtag(event, payload);

let lastUserId: string | undefined;

/**
 * Google Tag Analytics manager to dispatch typed events
 */
export const gtag = {
  mapper: {
    // sample
    // normalizeEntity: (entity: EntityInDto) => ({
    //     pax: entity.prop,
    //     pets: entity.otherProp.map(e => e.details).join(', ') || undefined,
    // }),
  },

  // general
  // let GA know which user is here
  initialSessionStart: ({ user_id }: { user_id: string }) => {
    if (lastUserId === user_id) {
      return;
    }
    lastUserId = user_id;
    dataLayer.push({ user_id });
    if (!IS_PROD_ENV) {
      console.log(`session gtag(${JSON.stringify({ user_id }, null, 4)})`);
    }
  },
  changeLanguage: buildEventDispatcher<{ language: string }>('change_language'),
  changeCurrency: buildEventDispatcher<{ currency: string }>('change_currency'),

  // qr widget specific
  expandFramesAccordion: buildEventDispatcher<{ value: string }>('expand_frames_accordion'),
  downloadQRCode: buildEventDispatcher<IEventDownloadQRCode>('download_qr_code'),
  attemptSignUp: buildEventDispatcher<{
    authentication_method: 'google' | 'email';
    user_email?: string;
    origin: EGTAGRegistrationOrigin.GENERATOR;
    popup: EGTAGRegistrationPopUp;
  }>('attempt_sign_up'),

  // auth on user app
  signUp: buildEventDispatcher<{
    authentication_method: 'google' | 'email';
    user_email: string;
    user_id: string;
    origin: EGTAGRegistrationOrigin;
    /**
     * provide popup when origin = "generator"
     */
    popup?: EGTAGRegistrationPopUp;
  }>('registration'),
  segmentSelected: buildEventDispatcher<{
    segment: ECustomerSegment;
  }>('segment_selected'),
  purchase: buildEventDispatcher<{
    user_email: string;
    user_id: string;
    ecommerce: {
      invoice_id?: string | null;
      // price
      value: number;
      // e.g. EUR
      currency: string;
      items: [
        {
          // Plan_ID
          item_id: string;
          // e.g. Basic_Monthly_EUR
          item_name: string;
          price: number;
          quantity: 1;
        }
      ];
    };
  }>('purchase'),
};
