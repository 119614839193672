import styles from './Button.module.scss';
import { get } from 'lodash';

import { clsxm } from '@app/styles/clsxm';

import { Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress } from '@mui/material';

import { ButtonColor } from '../ButtonColors';
import React from 'react';
import { withQA } from '@app/debug/withQA';

export type ButtonProps = { color?: ButtonColor; loading?: boolean } & Omit<MuiButtonProps, 'color'>;

export const Button = withQA(
  {
    name: 'Button',
    debugProps: ({ asEnum, asBoolean }) => ({
      fullWidth: asBoolean(false),
      variant: asEnum(['text', 'outlined', 'contained']),
      color: asEnum([
        'inherit',
        'primary',
        'secondary',
        'error',
        'success',
        'warning',
        'default',
        'info',
        'black',
        'gray',
        'white',
      ]),
    }),
  },
  React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ children, color = 'primary', className, variant = 'contained', loading, ...props }, ref) => {
      return (
        <MuiButton
          type="button"
          {...props}
          ref={ref}
          variant={variant}
          className={clsxm(
            styles.button,
            get(styles, `${variant}-${color}`),
            get(styles, `${props.size}`),
            get(styles, clsxm({ loading: loading })),
            className
          )}
        >
          {loading && (
            <CircularProgress
              className="visible absolute left-[50%-8px]"
              size={16}
              variant="indeterminate"
              data-loading
            />
          )}
          {children}
        </MuiButton>
      );
    }
  )
);
