// import { FAQSection } from '@app/domain/user/components/subscription/FAQ.section';
import { SubscriptionCancelPlanForm } from '@app/domain/subscription/components/SubscriptionCancelPlanForm';
import { useCancelSubscriptionPlan } from '@app/domain/subscription/hooks/useCancelSubscriptionPlan';
import { SubscriptionPlans } from '@app/domain/user/components/subscription/SubscriptionPlans';

export const AccountSubscriptionPage: React.FC = () => {
  const { mode } = useCancelSubscriptionPlan();
  return (
    <div className="flex flex-col gap-6 h-full">
      {!mode && <SubscriptionPlans />}
      {mode && <SubscriptionCancelPlanForm />}
      {/* hide this section as per request from doc https://docs.google.com/document/d/1LVSnfr3N5WSJSc7MrAWCvQj0D_ezrCNU8VS4xVm_JPI/edit */}
      {/* <FAQSection /> */}
    </div>
  );
};
