import { clsxm } from '@app/styles/clsxm';
import { Typography } from '@mui/material';
import { MyQRCodesDashboard } from './components/MyQRCodesDashboard';
import { MyTemplatesDashboard } from './components/MyTemplatesDashboard';
import { useTranslation } from 'react-i18next';
import { QRAnalyticsOverall } from '../analytics/components/QRAnalyticsOverall';
import { CreateBulkButton } from '@app/domain/plan/components/bulk-button/CreateBulkButton';
import { CreateQRCodeButton } from '@app/domain/plan/components/CreateQRCodeButton';

export const CustomerDashboardPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="relative flex h-full flex-col gap-4 pb-32 lg:p-6">
      <div className="flex items-center justify-between gap-4">
        <Typography variant="xl" className="px-6 pt-6 font-bold lg:p-0 lg:text-xxl">
          {t('dashboard.pageTitle')}
        </Typography>
        <div
          className={clsxm(
            'fixed bottom-0 left-0 right-0 z-10 flex gap-4 flex-wrap items-end justify-center bg-white pb-10 pt-2 md:ml-[33%]',
            'lg:relative lg:h-auto lg:bg-inherit lg:pb-0 lg:pt-0 lg:ml-0'
          )}
        >
          <CreateQRCodeButton />
          <CreateBulkButton variant="outlined" size="medium" className="w-full md:w-auto" />
        </div>
      </div>
      <QRAnalyticsOverall fromDashboardPage />
      <MyQRCodesDashboard />
      <MyTemplatesDashboard />
    </div>
  );
};
