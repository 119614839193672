import { useTranslation } from 'react-i18next';
import { PermissionCrown } from './permission-crown/PermissionCrown';
import { ManagePlanButton } from './ManagePlanButton';
import { useEffect } from 'react';
import { EPlanLimitType, usePlanLimitDialog } from './plan-limit-dialog/PlanLimitDialog';
import { clsxm } from '@app/styles/clsxm';

export const UpgradeBanner: React.FC<{ planLimitDialogType?: EPlanLimitType; className?: string }> = ({
  planLimitDialogType,
  className,
}) => {
  const { t } = useTranslation();

  const { openLimitDialog, removeLimitDialog } = usePlanLimitDialog(planLimitDialogType || null);

  useEffect(() => {
    openLimitDialog();
    return () => removeLimitDialog();
  }, [planLimitDialogType, openLimitDialog, removeLimitDialog]);

  const getDescription = () => {
    if (planLimitDialogType === EPlanLimitType.CREATE_QR_CODE) {
      return t('limit.unlockMoreQrCodePossibilities');
    }
    if (planLimitDialogType === EPlanLimitType.CREATE_BULK) {
      return t('limit.streamlineWorkflowByCreatingMultipleCodes');
    }
    if (planLimitDialogType === EPlanLimitType.API_KEYS) {
      return t('limit.unlimitedQrCodeManagementForBusinessUsers');
    }

    return t('limit.keepYourCampaignsWithoutReprinting');
  };

  return (
    <div className={clsxm('bg-white px-5 py-6 xl:rounded-xl', className)}>
      <div className="flex gap-4 border-gray-300 border bg-gray-100 rounded-lg p-4">
        <PermissionCrown className="shrink-0" />
        <div className="flex flex-col gap-4">
          <b className="text-lg">{t('limit.upgradeToUnlockThisFeature')}</b>
          {getDescription()}
          <div>
            <ManagePlanButton />
          </div>
        </div>
      </div>
    </div>
  );
};
