import { FiRrInfo } from '@app/assets';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { useTranslation } from 'react-i18next';

export const PlanChangeReminderBanner: React.FC = () => {
  const { t } = useTranslation();
  const { friendlyExpireDate } = useActivePlan();

  if (!friendlyExpireDate) {
    return null;
  }

  return (
    <div className="flex gap-3 items-start rounded-lg bg-gray-100 border border-gray-300 px-3 py-2">
      <FiRrInfo className="shrink-0 text-gray-500 mt-1" />
      <div className="flex flex-col gap-3">
        <p className="flex gap-1 flex-wrap text-gray-700">
          {t('globalWarning.scheduledPlanCancel.currentPlanActiveUntil')}
          <b className="text-gray-800">{friendlyExpireDate}</b>
        </p>
        <p className="flex gap-1 flex-wrap text-gray-700">
          {t('globalWarning.scheduledPlanCancel.yourLimitOfScansWillBeDowngraded')}
          <b className="text-gray-800">{friendlyExpireDate}</b>
        </p>
      </div>
    </div>
  );
};
