import { Typography } from '@app/components';
import { DeleteMyAccountButton } from '@app/domain/user/components/DeleteMyAccountButton';
import { SubscriptionPlans } from '@app/domain/user/components/subscription/SubscriptionPlans';
import { useTranslation } from 'react-i18next';
import { HELPCENTER_LINK_EXTERNAL } from '../help/help.constants';

/**
 * @deprecated new freemium model has no trial flow
 * Remove after full migration to freemium
 */
export const TrialExpiredPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="relative flex h-full flex-col justify-between gap-4 p-6">
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between gap-4">
          <Typography variant="xl" className="font-bold lg:text-xxl">
            {t('trial.yourTrialPeriodIsOver')}
          </Typography>
        </div>
        <SubscriptionPlans />
      </div>
      <div className="flex justify-between pt-16">
        <div className="flex gap-4">
          {t('support.haveAQuestion')}
          <a className="font-bold text-accept-main" href={HELPCENTER_LINK_EXTERNAL} target="_blank" rel="noreferrer">
            {t('support.contactSupport')}
          </a>
        </div>
        <DeleteMyAccountButton textVariant />
      </div>
    </div>
  );
};
