import { useForm, SubmitHandler } from 'react-hook-form';

import { Routes } from '@app/constants/routes';

import { SignInFormValidationSchema } from '@app/auth/api/auth.form';

import { useStore } from '@app/store/useStore.hook';

import { Typography } from '@mui/material';

import { AuthApi } from '@app/auth/api/auth.api';
import { TypedLink } from '@app/router';
import { Input, Button, Form } from '@app/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { DividerWithText } from '@app/components/divider/DividerWithText';
import { useHandler } from '@app/hooks/useHandler.hook';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { GoogleButton } from '@app/components/buttons/google-button/GoogleButton';
import {
  AUTH_QUERY_PARAM_FOR_PREFILL_EMAIL,
  AUTH_QUERY_PARAM_SIGN_IN_EMAIL,
  AUTH_QUERY_PARAM_SIGN_IN_ERROR,
  AUTH_QUERY_VALUE_GOOGLE_EMAIL_NOT_REGISTERED,
} from '../auth.constants';
import { useQueryParam, StringParam } from 'use-query-params';
import { useEffect } from 'react';
import { AuthLayout } from '../AuthLayout';
import { clsxm } from '@app/styles/clsxm';

export const SignInPage = () => {
  return (
    <AuthLayout>
      <Body />
    </AuthLayout>
  );
};

const Body = () => {
  const { t } = useTranslation();
  const setCurrentUser = useStore().setCurrentUser;

  const { control, formState, handleSubmit, register, watch, clearErrors, setValue } =
    useForm<SignInFormValidationSchema>({
      resolver: yupResolver(SignInFormValidationSchema),
    });

  const [signInError] = useQueryParam(AUTH_QUERY_PARAM_SIGN_IN_ERROR, StringParam);
  const [signInEmail = ''] = useQueryParam(AUTH_QUERY_PARAM_SIGN_IN_EMAIL, StringParam);
  const [prefillEmail = ''] = useQueryParam(AUTH_QUERY_PARAM_FOR_PREFILL_EMAIL, StringParam);

  useEffect(() => {
    if (prefillEmail) {
      setValue('email', prefillEmail);
    }
  }, [prefillEmail, setValue]);

  const isGoogleEmailNotRegistered = signInError === AUTH_QUERY_VALUE_GOOGLE_EMAIL_NOT_REGISTERED;

  const {
    mutate: login,
    isLoading,
    error,
  } = useMutation(
    async (formData: SignInFormValidationSchema) => {
      const { data } = await AuthApi.signIn({ ...formData });
      return data;
    },
    {
      onSuccess: (res) => {
        setCurrentUser(res);
      },
    }
  );

  const onSubmit: SubmitHandler<SignInFormValidationSchema> = useHandler((formData) => {
    clearErrors();
    login(formData);
  });

  const { email, password } = watch();

  const disabledSubmitBtn = !email || !password;

  const maybeGoogleError = isGoogleEmailNotRegistered
    ? new Error(`${signInEmail} ${t('auth.error.emailNotRegistered')}`)
    : undefined;

  return (
    <div className={clsxm('flex h-full flex-col gap-y-4', 'md:outline outline-1 outline-gray-100 md:rounded md:p-8')}>
      <GoogleButton modeSignIn origin={null} />
      {maybeRenderError(maybeGoogleError)}
      <DividerWithText text={t('auth.or')} />
      <Form
        control={control}
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-1 flex-col gap-y-4 sm:flex-initial"
      >
        <Input
          {...register('email')}
          label={t('auth.email')}
          withBG
          placeholder={t('auth.enterYourEmail')}
          id="email"
          data-cy="sign-in-email"
          errorMessage={formState.errors.email?.message || ''}
        />
        <Input
          {...register('password')}
          label={t('auth.password')}
          placeholder={t('auth.enterYourPassword')}
          withBG
          type="password"
          id="password"
          data-cy="sign-in-password"
          errorMessage={formState.errors.password?.message || ''}
        />

        <div className="flex items-center justify-center">
          <TypedLink
            to={Routes.auth.forgot_password}
            className="font-semibold text-accept-dark-blue hover:text-accept-dark-blue/50"
          >
            {t('auth.forgotPassword')}
          </TypedLink>
        </div>

        <div className="mt-auto space-y-4 sm:mt-0">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className="!mt-6"
            loading={isLoading}
            disabled={disabledSubmitBtn}
            data-cy="sign-in-submit"
          >
            {t('auth.signIn')}
          </Button>
          {maybeRenderError(error as Error)}
        </div>
      </Form>

      <Typography align="center" className="text-gray-700 dark:text-white">
        {t('auth.dontHaveAnAccount')}{' '}
        <TypedLink to={Routes.auth.sign_up} className="font-semibold text-accept-main hover:text-accept-hover">
          {t('auth.signUp')}
        </TypedLink>
      </Typography>
    </div>
  );
};
