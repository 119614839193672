import { clsxm } from '@app/styles/clsxm';
import { LinearProgress } from '@mui/material';

export const SmartProgressBar: React.FC<{
  value?: number | null;
  max?: number | null;
}> = ({ value = 0, max = 0 }) => {
  const progress = max ? (100 * (value || 0)) / max : 0;

  const isLimitReached = max === null ? false : (value || 0) >= max;

  return (
    <div>
      <LinearProgress
        className={clsxm(isLimitReached && 'text-red-500')}
        classes={{ root: clsxm('rounded h-[6px] bg-accept-menu-item'), bar: clsxm('rounded') }}
        variant="determinate"
        color={isLimitReached ? 'inherit' : 'primary'}
        value={progress}
      />
    </div>
  );
};
