import { IS_PROD_ENV } from '@app/environment/typed-env';
import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { useActivePlan } from '../plan/hooks/useActivePlan';
import { ONE_DAY_IN_MS } from './discount/first-day-discount.constant';

const DEV_SHORT_DELAY_MS = 5e3;

export const useShouldShowSubscriptionBanner = () => {
  const { currentUser } = useAuthorizedUser();
  const { isFreePlan } = useActivePlan();

  if (!isFreePlan) {
    return false;
  }

  if (currentUser?.upsellTopBannerHiddenDate) {
    try {
      const hiddenDate = new Date(currentUser.upsellTopBannerHiddenDate);
      const delta = Date.now() - hiddenDate.getTime();
      if (delta < (IS_PROD_ENV ? ONE_DAY_IN_MS : DEV_SHORT_DELAY_MS)) {
        return false;
      }
    } catch {}
  }

  return true;
};
