import { useMemo } from 'react';

import { IconButton, TableColumn } from '@app/components';

import { Typography } from '@mui/material';
import { ECreatedWithFlow, QRCodeAdminOutDto } from '@app/swagger-types';
import { NestedKeyOf } from '@app/utils/type.utils';
import { Visibility } from '@mui/icons-material';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { QRid } from './QRid';
import { QRstatus } from './QRstatus';
import { format } from 'date-fns';
import { FORMAT } from '@app/constants/formats';
import { clsxm } from '@app/styles/clsxm';

const getEntityField = (v: NestedKeyOf<QRCodeAdminOutDto>) => v;

export const useTableColumns = () => {
  const navigate = useTypedNavigate();
  const tableColumns = useMemo<TableColumn<QRCodeAdminOutDto>[]>(() => {
    return [
      {
        headerName: 'ID',
        field: getEntityField('id'),
        align: 'left',
        firstClickSort: 'asc',
        render: ({ id }) => (
          <div className="flex items-center gap-2">
            <QRid id={id} />
          </div>
        ),
      },
      {
        headerName: 'QR name',
        field: getEntityField('name'),
        align: 'left',
        firstClickSort: 'asc',
        render: ({ name }) => (
          <Typography variant="xs" noWrap>
            {name}
          </Typography>
        ),
      },
      {
        headerName: 'Type',
        field: getEntityField('codeType'),
        align: 'left',
        firstClickSort: 'asc',
        render: ({ codeType }) => (
          <Typography variant="xs" noWrap>
            {codeType}
          </Typography>
        ),
      },
      {
        headerName: 'Owner',
        field: getEntityField('createdBy'),
        align: 'left',
        firstClickSort: 'asc',
        render: ({ createdBy }) => <Typography variant="xs">{createdBy?.email}</Typography>,
      },
      {
        headerName: 'Created date',
        field: getEntityField('createdDate'),
        align: 'center',
        render: ({ createdDate, createdWithFlow }) => {
          return (
            <Typography variant="xs">
              {format(new Date(createdDate), FORMAT.FULL_TIME)}
              <br />
              <span
                className={clsxm(
                  'text-xs rounded px-1 text-white',
                  createdWithFlow === ECreatedWithFlow.MANUAL ? 'bg-slate-600' : 'bg-sky-600'
                )}
              >
                {createdWithFlow}
              </span>
            </Typography>
          );
        },
      },
      {
        headerName: 'Scans',
        field: getEntityField('amountOfScans'),
        align: 'center',
        disableSort: true,
        render: ({ amountOfScans }) => {
          return <Typography variant="xs">{amountOfScans ?? '-'}</Typography>;
        },
      },
      {
        headerName: 'Status',
        field: getEntityField('isDeleted'),
        align: 'center',
        render: ({ isDeleted, createdBy }) => {
          return (
            <Typography variant="xs">
              <QRstatus isDeleted={isDeleted} createdBy={createdBy} />
            </Typography>
          );
        },
      },
      {
        headerName: 'Actions',
        // "actions" used as key, not for entity field
        field: 'actions',
        disableSort: true,
        align: 'right',
        render: ({ id }) => (
          <IconButton
            onClick={() => {
              navigate({ to: Routes.admin.qr.edit, params: { id } });
            }}
          >
            <Visibility />
          </IconButton>
        ),
      },
    ];
  }, [navigate]);

  return tableColumns;
};
