import { Container, Input, Typography } from '@app/components';
import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { GalleryEditor } from '@app/components/gallery-editor/GalleryEditor';
import { useCreateLibrary, useDeleteLibrary, useGetLibrary } from '@app/domain/library/api/library.hooks.api';
import { QRCodeFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { clsxm } from '@app/styles/clsxm';
import { ELibraryType } from '@app/swagger-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePersistedPublicImages } from '../usePersistedPublicImages';

// TODO translate
// TODO refactor to reuse most of logic inside via shared utils/components for both MultilinkHeaderEditor and QRCodePersonalInfo
// TODO refator to use useLibraryEditor
export const QRCodePersonalInfo: React.FC<{
  forWidgetMode?: boolean;
}> = ({ forWidgetMode }) => {
  const { t } = useTranslation();
  const { control, watch, trigger, setValue } = useFormContext<QRCodeFormSchema>();
  const libraryId = watch('vcard.libraryId');

  const libraryType = ELibraryType.VCARD_LOGO;
  const { data: logos, isLoading } = useGetLibrary({ libraryType }, { forWidgetMode });

  const { mutate: createLogos, isLoading: creatingLogos } = useCreateLibrary({ forWidgetMode });
  const { mutate: deleteLogo, isLoading: deletingLogo } = useDeleteLibrary({ forWidgetMode });

  const { libraries: publicLibs, setLibraries: setPublicLibs } = usePersistedPublicImages('shared');

  const workLibs = forWidgetMode ? publicLibs : logos;
  const images = workLibs?.result.map((r) => ({ imageId: r?.file?.id, libraryId: r.id, id: r.id })) || [];
  const selectedImageId = workLibs?.result.find((l) => l.id === libraryId)?.file?.id;

  return (
    <Container className="flex flex-col gap-4 xl:rounded-xl">
      <Typography className="font-bold sm:text-l">{t('qr.vCardInput.personalInformation')}</Typography>
      <Controller
        name="vcard.fullName"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Input
            {...field}
            value={value || ''}
            label="Your name"
            onChange={(e) => {
              if (fieldState?.error?.message) {
                trigger('vcard.fullName');
              }
              onChange(e);
            }}
            withBG
            placeholder="Enter full name"
            errorMessage={fieldState?.error?.message || ''}
          />
        )}
      />
      <div className="flex flex-col gap-3">
        <Typography variant="m" className="font-bold">
          {t('qr.stepInput.imageOptional')}
        </Typography>
        <Typography variant="xxs" className="text-gray-500">
          {t('qr.stepInput.addImageDescription')}
        </Typography>

        <div className={clsxm(deletingLogo && 'pointer-events-none opacity-70')}>
          {/* TODO refactor: reuse editor with hooks into single component and single hook */}
          <GalleryEditor
            forWidgetMode={forWidgetMode}
            value={images}
            deleteOnServer={false}
            withNoImg
            dropZoneLoading={isLoading || creatingLogos}
            selectedImageId={selectedImageId || NO_LOGO_ID}
            onClickImage={(img) => {
              if (img.id === NO_LOGO_ID) {
                setValue('vcard.libraryId', NO_LOGO_ID);
                return;
              }
              const logo = workLibs?.result.find((l) => l.file?.id === img.imageId);
              if (logo) {
                setValue('vcard.libraryId', logo.id || NO_LOGO_ID);
              }
            }}
            handleUploadIds={(v) => {
              createLogos(
                { fileIds: v, libraryType },
                {
                  onSuccess: (data) => {
                    try {
                      if (forWidgetMode) {
                        setPublicLibs((prev) => {
                          if (!prev) {
                            return data;
                          }
                          const newArr = prev.result.concat(data.result);
                          return {
                            result: newArr,
                            total: newArr.length,
                          };
                        });
                      }
                      const lastUploadLogo = data.result[data.result.length - 1];
                      if (lastUploadLogo) {
                        setValue('vcard.libraryId', lastUploadLogo.id);
                      }
                    } catch {}
                  },
                }
              );
            }}
            handleDeleteId={(id) => {
              const logo = workLibs?.result?.find((l) => l?.file?.id === id);
              if (logo && logo?.id) {
                if (forWidgetMode) {
                  setPublicLibs((data) =>
                    data
                      ? {
                          ...data,
                          result: data?.result.filter((img) => img.id !== logo.id),
                        }
                      : data
                  );
                } else {
                  deleteLogo(logo.id);
                }
              }
            }}
            onChange={(v) => {
              if (!v.find((img) => img.imageId === selectedImageId && selectedImageId !== NO_LOGO_ID)) {
                setValue('vcard.libraryId', NO_LOGO_ID);
              }
            }}
            noImgText={t('qr.stepInput.noImage')}
          />
        </div>
      </div>
    </Container>
  );
};
