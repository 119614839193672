import { useTranslation } from 'react-i18next';
import { DiscountSVG } from './icons';
import { LogoLoreal, LogoPuma, LogoSamsung } from '@app/modules/auth/assets';

export const OnboardingMediaRightSide: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 pt-16 p-8 flex flex-col justify-between">
      {/* header */}
      <div className="flex flex-col gap-4">
        <DiscountSVG />
        <div className="font-bold text-xl">{t('onboarding.stepSelectCycle.rightSide.title')}</div>
        <div>{t('onboarding.stepSelectCycle.rightSide.description')}</div>
      </div>
      {/* footer */}
      <div className="flex flex-col gap-4">
        <div>{t('onboarding.stepSelectCycle.rightSide.trustedBy')}</div>
        <div className="grid grid-cols-3 gap-4">
          <div className="bg-white p-2 rounded-lg text-black flex items-center justify-center">
            <LogoLoreal className="w-16" />
          </div>
          <div className="bg-white p-2 rounded-lg text-black flex items-center justify-center">
            <LogoPuma className="w-16" />
          </div>
          <div className="bg-white p-2 rounded-lg text-black flex items-center justify-center">
            <LogoSamsung className="w-16" />
          </div>
        </div>
      </div>
    </div>
  );
};
