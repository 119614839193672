import { toNumber } from 'lodash';

export const parseNumbersFromString = (s: string) => toNumber(s.replace(/[^\d.-]/g, ''));

export const capitalizeWords = (input: string) => {
  const words = input.split(' ');
  const capitalizedWords = words.map((word) => {
    if (word.length === 0) {
      return '';
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return capitalizedWords.join(' ');
};

export const maskMiddleString = (input: string, maskChar = '*', maskStart = 4, maskEnd = 4): string => {
  if (input.length <= maskStart + maskEnd) {
    return input;
  }

  const start = input.slice(0, maskStart);
  const end = input.slice(-maskEnd);
  const middle = input.slice(maskStart, -maskEnd).replace(/./g, maskChar);

  return start + middle + end;
};
