/* eslint-disable prefer-template */
/* eslint-disable curly */
/* eslint-disable no-var */
/* eslint-disable brace-style */
/* eslint-disable prettier/prettier */
export const initGtmScript_DIRTY = () => {
    !(function () {
        'use strict';
        function l(e) {
            for (var t = e, r = 0, n = document.cookie.split(';'); r < n.length; r++) {
                var o = n[r].split('=');
                if (o[0].trim() === t) return o[1];
            }
        }
        function s(e) {
            return localStorage.getItem(e);
        }
        function u(e) {
            return window[e];
        }
        function A(e, t) {
            e = document.querySelector(e);
            return t ? (null == e ? void 0 : e.getAttribute(t)) : null == e ? void 0 : e.textContent;
        }
        var e = window,
            t = document,
            r = 'script',
            n = 'dataLayer',
            o = 'https://sst.trueqrcode.com',
            a = 'https://load.sst.trueqrcode.com',
            i = '9gqxkqfnd',
            c = 'zt=aWQ9R1RNLU0zNjhDVEo%3D&page=2',
            g = 'stapeUserId',
            v = '',
            E = '',
            d = !1;
        try {
            var d =
                !!g &&
                ((m = navigator.userAgent), !!(m = new RegExp('Version/([0-9._]+)(.*Mobile)?.*Safari.*').exec(m))) &&
                16.4 <= parseFloat(m[1]),
                f = 'stapeUserId' === g,
                I =
                    d && !f
                        ? (function (e, t, r) {
                            void 0 === t && (t = '');
                            var n = { cookie: l, localStorage: s, jsVariable: u, cssSelector: A },
                                t = Array.isArray(t) ? t : [t];
                            if (e && n[e])
                                for (var o = n[e], a = 0, i = t; a < i.length; a++) {
                                    var c = i[a],
                                        c = r ? o(c, r) : o(c);
                                    if (c) return c;
                                }
                            else console.warn('invalid uid source', e);
                        })(g, v, E)
                        : void 0;
            d = d && (!!I || f);
        } catch (e) {
            console.error(e);
        }
        var m = e,
            g =
                ((m[n] = m[n] || []),
                    m[n].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }),
                    t.getElementsByTagName(r)[0]),
            v = I ? '&bi=' + encodeURIComponent(I) : '',
            E = t.createElement(r),
            f = (d && (i = 8 < i.length ? i.replace(/([a-z]{8}$)/, 'kp$1') : 'kp' + i), !d && a ? a : o);
        (E.async = !0), (E.src = f + '/' + i + '.js?' + c + v), null != (e = g.parentNode) && e.insertBefore(E, g);
    })();
};
