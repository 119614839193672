import { Button, Typography } from '@app/components';
import { useHandler } from '@app/hooks/useHandler.hook';
import { clsxm } from '@app/styles/clsxm';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QRDownloadHandlerFn } from '../qr-code-internal.types';
import { QRCodeRenderer, QRRendererProps } from '../QRCodeRenderer';

export const DownloadQRCodeModal: React.FC<
  NiceModalHocProps & { rendererProps: QRRendererProps; onClose?: () => void }
> = NiceModal.create(({ rendererProps, onClose }) => {
  const { t } = useTranslation();
  const modal = useModal();

  const [downloadHandler, setDownloadHandler] = useState<QRDownloadHandlerFn | undefined>(undefined);

  const onDownloadHandler = useHandler((downloadFn?: QRDownloadHandlerFn) => {
    // setter has to be a function to avoid downloadFn call
    setDownloadHandler(() => downloadFn);
  });

  const closeDialog = () => {
    modal.remove();
    onClose?.();
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoading || !downloadHandler) {
      return;
    }
    let mounted = true;
    downloadHandler({ delay: 100 }).finally(() => {
      if (!mounted) {
        return;
      }
      setIsLoading(false);
    });
    return () => {
      mounted = false;
    };
  }, [downloadHandler, isLoading]);

  return (
    <Modal open={modal.visible} onClose={closeDialog} onClick={(e) => e.stopPropagation()}>
      <div
        className={clsxm(
          'absolute top-[50%] left-[50%] w-full max-w-[calc(100%-24px)] -translate-x-[50%] -translate-y-[50%] overflow-hidden rounded-3xl bg-white p-8',
          'sm:max-w-auto sm:w-auto sm:min-w-[450px] sm:p-12'
        )}
      >
        <div className="space-y-6">
          <Typography variant="xl" className="font-bold">
            {t('dialog.download')}
          </Typography>
          <div className="mx-auto w-[320px] max-w-full">
            <QRCodeRenderer {...rendererProps} onDownloadHandler={onDownloadHandler} />
          </div>
          <div className="flex justify-between gap-4">
            <Button onClick={closeDialog} disabled={isLoading} className="flex-1" variant="outlined">
              {t('dialog.cancel')}
            </Button>
            <Button
              loading={isLoading}
              className="flex-1"
              type="submit"
              disabled={!downloadHandler}
              onClick={() => {
                setIsLoading(true);
              }}
            >
              {t('dialog.download')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
});
