import { EScanAnalyticUnit, PlanLimitOutDto } from '@app/swagger-types';
import { TFunction } from 'i18next';
import { PlanLimitOutDtoKey } from '../..';
import { IncludeItem } from './IncludeItem';

/**
 * keys for special handling (e.g. with dependent fields)
 */
type SpecialPlanLimitKey =
  | 'scanAnalyticsQuantity'
  | 'scanAnalyticsUnit'
  | 'fileExtensions'
  | 'hasFileExtensions'
  | 'hasRealTimeDataDashboard'
  | 'qrTypes'
  | 'ccpaCompliant'
  | 'gdprCompliant';
type SimplePlanLimitKey = Exclude<PlanLimitOutDtoKey, SpecialPlanLimitKey>;

export const buildSubscriptionFeatureRenderer = ({
  planToCheck,
  forceIsAvailable,
  t18n: t,
}: {
  planToCheck: PlanLimitOutDto;
  forceIsAvailable?: boolean;
  t18n: TFunction<'translation', undefined>;
}) => {
  const variants: Record<SimplePlanLimitKey, string> = {
    ['dedicatedCustomerSuccessManager']: t('subscription.feature.dedicatedCustomerSuccessManager'),
    ['emailSupport']: t('subscription.feature.emailSupport'),
    ['knowledgeBase']: t('subscription.feature.knowledgeBase'),
    ['hasBulkQrCodeGeneration']: t('subscription.feature.bulkQRCodeGeneration'),
    ['hasQrDesignCustomisations']: t('subscription.feature.qrDesignCustomisations'),
    ['hasTrackScanGPS']: t('subscription.feature.tracksScansGPSCoordinates'),
    ['hasTrackScanDevice']: t('subscription.feature.tracksScansDeviceType'),
    ['hasTrackScanLocation']: t('subscription.feature.tracksScansLocations'),
    ['hasTrackScanTimeOfDay']: t('subscription.feature.tracksScansTimeOfDay'),
    ['hasLabels']: t('subscription.feature.labels'),
    ['hasTemplates']: t('subscription.feature.templates'),
    qrAdjustable: t('subscription.feature.qrAdjustable'),
  };

  // TODO remove any
  const anyRecord: Record<string, string> = planToCheck as any;

  const checkLimitKey = (
    key: PlanLimitOutDtoKey
  ):
    | false
    | {
        isAvailable: boolean;
        title: React.ReactNode;
        value: React.ReactNode;
      } => {
    if (key === 'ccpaCompliant' || key === 'gdprCompliant') {
      return false;
    }
    // TODO rename to hasQrAdjustableInput when API erady
    if (key === 'hasQrDesignCustomisations') {
      return false;
    }
    if (key === 'scanAnalyticsQuantity') {
      return false;
    }
    if (key === 'scanAnalyticsUnit') {
      return false;
    }
    if (key === 'fileExtensions') {
      return false;
    }
    if (key === 'hasRealTimeDataDashboard') {
      const getValue = () => {
        if (!planToCheck.scanAnalyticsUnit) {
          return t('subscription.feature.full');
        }
        if (planToCheck.scanAnalyticsQuantity === 1 && planToCheck.scanAnalyticsUnit === EScanAnalyticUnit.YEAR) {
          return t('subscription.feature.oneYear');
        }
        if (planToCheck.scanAnalyticsQuantity === 6 && planToCheck.scanAnalyticsUnit === EScanAnalyticUnit.MONTH) {
          return t('subscription.feature.sixMonths');
        }
        if (planToCheck.scanAnalyticsQuantity === 7 && planToCheck.scanAnalyticsUnit === EScanAnalyticUnit.DAY) {
          return t('subscription.feature.sevenDays');
        }
        return `${planToCheck.scanAnalyticsQuantity} ${planToCheck.scanAnalyticsUnit}`;
      };
      return {
        isAvailable: true,
        title: t('subscription.feature.scanAnalytics'),
        value: getValue(),
      };
    }
    if (key === 'qrTypes') {
      return {
        isAvailable: true,
        title: t('subscription.feature.qrTypes'),
        value: t('subscription.feature.all'),
      };
    }
    if (key === 'hasFileExtensions') {
      return {
        isAvailable: true,
        title: t('subscription.feature.fileExtensions'),
        value: planToCheck.fileExtensions?.join(', '),
      };
    }
    const isAvailable = Boolean(anyRecord[key]);
    const title = variants[key];
    // TODO i18n
    const value = isAvailable ? t('subscription.feature.yes') : t('subscription.feature.no');
    return {
      isAvailable,
      title,
      value,
    };
  };
  const render = (key: PlanLimitOutDtoKey) => {
    const feature = checkLimitKey(key);
    if (!feature) {
      return null;
    }
    return (
      <IncludeItem
        key={key}
        isAvailable={forceIsAvailable ?? feature.isAvailable}
        title={feature.title}
        value={feature.value}
      />
    );
  };
  return render;
};
