import { DeleteIcon } from '@app/assets';
import { Button, Checkbox, PlaceholderBox, Typography } from '@app/components';
import { SearchInput } from '@app/components/fields/search-input/SearchInput';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { Routes } from '@app/constants/routes';
import { PlanLimitTooltip } from '@app/domain/plan/components/PlanLimitTooltip';
import { usePlanLimits } from '@app/domain/plan/hooks/usePlanLimits';
import {
  useDeleteTemplate,
  useGetTemplates,
  useMultiDeleteTemplates,
} from '@app/domain/template/api/template.hooks.api';
import { TemplateContainer } from '@app/domain/template/components/TemplateContainer';
import { TEMPLATES_PAGE_SIZE } from '@app/domain/template/constants';
import { useTemplatesQueryParams } from '@app/domain/template/template.hooks';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';
import { useTypedNavigate } from '@app/router';
import { clsxm } from '@app/styles/clsxm';
import { DesignOutDto } from '@app/swagger-types';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const Templates: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useTypedNavigate();
  const [selectedDesigns, setSelectedDesigns] = useState<DesignOutDto[]>([]);

  const [{ term }, setParams] = useTemplatesQueryParams();
  const { data: paginatedTemplates, isFetching, fetchNextPage } = useGetTemplates({ term });
  const { mutate: deleteTemplate, isLoading: deleteLoading } = useDeleteTemplate();
  const { mutate: multiDeleteTemplates, isLoading: multiDeleteLoading } = useMultiDeleteTemplates();

  const handleDelete = useCallback(
    (design: DesignOutDto) => {
      if (design?.template?.id) {
        deleteTemplate(design?.template?.id, {
          onSuccess: () => {
            setSelectedDesigns((prev) => prev.filter((p) => p.id !== design.id));
            toast.success(t('toaster.templateDeleted'));
          },
        });
      }
    },
    [deleteTemplate, t]
  );

  const handleNavigate = useCallback(
    (id: string) => {
      navigate({ to: Routes.customer.templates.edit, preserveQueryParams: true, params: { id } });
    },
    [navigate]
  );

  const getNavigationUrl = (id: string) => {
    return Routes.customer.templates.edit.replace(':id', id);
  };

  const handleSelectDesign = useCallback((design: DesignOutDto, selected: boolean) => {
    if (selected) {
      setSelectedDesigns((prev) => [...prev, design]);
    } else {
      setSelectedDesigns((prev) => prev.filter((p) => p.id !== design.id));
    }
  }, []);

  const allTemplatesInOnePage = useMemo(() => {
    return (
      paginatedTemplates?.pages.reduce((prev: DesignOutDto[], cur) => {
        return [...prev, ...cur.result];
      }, []) || []
    );
  }, [paginatedTemplates?.pages]);

  const selectedAllTemplates = useMemo(() => {
    return (
      !!allTemplatesInOnePage.length &&
      allTemplatesInOnePage.every((temp) => !!selectedDesigns.find((sd) => sd.id === temp.id))
    );
  }, [allTemplatesInOnePage, selectedDesigns]);

  const { canCreateTemplate } = usePlanLimits();

  const totalTemplates = (() => {
    return allTemplatesInOnePage?.length || 0;
  })();

  const hideLoadMoreButton = (() => {
    return (paginatedTemplates?.pages[0]?.total || 0) <= (paginatedTemplates?.pages.length || 0) * TEMPLATES_PAGE_SIZE;
  })();

  return (
    <div className="relative flex h-full flex-col gap-4 p-6">
      <div className="flex items-center justify-between gap-4">
        <Typography variant="xl" className="font-bold lg:text-xxl">
          {t('templates.pageTitle')}
        </Typography>
        <PlanLimitTooltip condition={!canCreateTemplate}>
          <div
            className={clsxm(
              'fixed bottom-0 left-0 z-10 flex w-full justify-center bg-white px-6 pt-6 pb-10',
              'lg:relative lg:block lg:w-auto lg:bg-inherit lg:p-0'
            )}
          >
            <Button
              onClick={() => {
                navigate({ to: Routes.customer.templates.create, preserveQueryParams: true });
              }}
              disabled={!canCreateTemplate}
              className="lg:w-[240px]"
              startIcon={<AddIcon />}
            >
              {t('templates.createTemplate')}
            </Button>
          </div>
        </PlanLimitTooltip>
      </div>
      <div>
        <SearchInput
          className="max-w-[350px]"
          placeholder={t('search.searchByTitle')}
          value={term}
          onChange={(text) => {
            setParams({ term: text });
          }}
        />
        <div className="mt-2 grid grid-cols-2 items-center gap-x-4 gap-y-2 pl-1 sm:grid-cols-[1fr_auto_auto]">
          <Checkbox
            label={
              <span className="text-gray-700">
                {selectedAllTemplates ? t('manageResults.deselectAll') : t('manageResults.selectAll')}
              </span>
            }
            checked={selectedAllTemplates}
            onChange={() => {
              if (selectedAllTemplates) {
                setSelectedDesigns([]);
              } else {
                setSelectedDesigns(allTemplatesInOnePage);
              }
            }}
          />
          <div className="flex items-center justify-end gap-1 text-xs text-gray-700">
            <span
              className={clsxm(
                'min-w-[20px] rounded-xl bg-gray-500 px-[6px] py-[2px] text-center font-medium text-white transition-all',
                selectedDesigns.length && 'bg-primary'
              )}
            >
              {selectedDesigns.length}
            </span>
            <span>{t('manageResults.selected')}</span>
          </div>
          {!!selectedDesigns.length && (
            <div>
              <ConfirmDialog
                trigger={
                  <Button size="small" variant="text" color="secondary" startIcon={<DeleteIcon />}>
                    {t('manageResults.delete')}
                  </Button>
                }
                onConfirm={() => {
                  const ids = selectedDesigns.map(({ template }) => template?.id || '').filter((id) => id);
                  multiDeleteTemplates(
                    { ids },
                    {
                      onSuccess: () => {
                        toast.success(t('toaster.templatesDeleted'));
                        setSelectedDesigns([]);
                      },
                    }
                  );
                }}
                title={t('manageResults.deleteSelectedTemplates', { count: selectedDesigns.length })}
                confirmText={t('manageResults.yesDelete')}
              />
            </div>
          )}
        </div>
      </div>
      {!!totalTemplates && (
        <div
          className={clsxm(
            'mb-4 grid grid-cols-2 gap-4 pb-[100px]',
            'sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 lg:pb-4 xl:grid-cols-4 2xl:grid-cols-5',
            isFetching && 'pointer-events-none'
          )}
        >
          {allTemplatesInOnePage?.map((template) => (
            <TemplateContainer
              key={template.id}
              template={template}
              deleteTemplate={handleDelete}
              handleNavigate={handleNavigate}
              getNavigationUrl={getNavigationUrl}
              handleSelectDesign={handleSelectDesign}
              selected={!!selectedDesigns.find((sd) => sd?.id === template?.id)}
              disalbed={deleteLoading || multiDeleteLoading}
            />
          ))}
        </div>
      )}
      <div className="flex-1">
        {!totalTemplates && !isFetching ? (
          <PlaceholderBox variant="primary" title={t('placeholder.noTemplatesCreated')} />
        ) : null}

        {isFetching ? (
          <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
            <ContentLoader isEmpty={false} loading entity="Templates" />
          </div>
        ) : null}

        {!hideLoadMoreButton && (
          <div className="flex justify-center">
            <Button
              onClick={() => {
                fetchNextPage();
              }}
              className="w-[200px]"
              loading={isFetching}
              variant="text"
            >
              showMore
              {t('search.showMore')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
