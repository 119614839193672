import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { IS_LOCALHOST, IS_NOT_PROD_ENV, IS_WORDPRESS_WIDGET } from '../environment/typed-env';
import Backend, { LocizeBackendOptions } from 'i18next-locize-backend';
import { defaultTranslation } from '../locales/defaultTranslation';
import { LANGUAGE_CODES } from './i18.utils';

const backend: LocizeBackendOptions = {
  // TODO get from env
  projectId: 'dda672be-c4ca-4020-bb54-09a237b3f205',
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    partialBundledLanguages: true,
    backend,
    detection: {
      order: IS_WORDPRESS_WIDGET
        ? ['htmlTag', 'querystring', 'cookie', 'sessionStorage', 'localStorage', 'navigator']
        : undefined,
      // make "en-US" as just "en"
      // convertDetectedLanguage: (lng) => lng.replace(/-\w+/gi, ''),
    },
    debug: IS_NOT_PROD_ENV && false,
    interpolation: {
      // not needed because react escapes values already
      escapeValue: false,
    },
    fallbackLng: [LANGUAGE_CODES.ENGLISH],
    defaultNS: 'translation',
    returnNull: false,
    resources: IS_LOCALHOST
      ? {
          'en-US': {
            translation: defaultTranslation,
          },
        }
      : undefined,
  });
