import { QrCodeIcon, ScanIcon } from '@app/assets';
import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { Button, Container, Typography } from '@app/components';
import { SmartProgressBar } from '@app/components/progress/SmartProgressBar';
import { Routes } from '@app/constants/routes';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { useTypedNavigate } from '@app/router';
import { clsxm } from '@app/styles/clsxm';
import { capitalizeWords } from '@app/utils/string.utils';
import { useTranslation } from 'react-i18next';

export const AccountUsage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();
  const { planType } = useActivePlan();
  const { currentUser: user } = useAuthorizedUser();

  const totalScans = user?.monthlyScans || 0;
  const amountMaxScans = user?.currentPlanDetails?.amountMaxScans || 0;
  const totalQRCodes = user?.totalCodes || 0;
  const amountMaxQrCodes = user?.currentPlanDetails?.amountMaxQrCodes;

  return (
    <Container className="flex flex-col gap-6 p-6 lg:rounded-xl">
      <div className="flex items-start gap-1 lg:items-center justify-between flex-col lg:flex-row">
        <Typography variant="m" className="font-bold">
          {t('account.sectionUsage.title')}
        </Typography>
        <span className="flex text-xs flex-wrap gap-1 items-baseline">
          <p className="font-medium">{t('subscription.currentPlan')}:</p>
          <p className="font-bold">{capitalizeWords(planType || '')}</p>
          <p className="font-bold">
            {t('subscription.planInfo', {
              maxQRCodes: amountMaxQrCodes || t('subscription.unlimited'),
              maxScans: amountMaxScans || t('subscription.unlimited'),
            })}
          </p>
        </span>
      </div>
      <div className={clsxm('grid grid-cols-1 gap-x-12 lg:grid-cols-2 gap-y-4')}>
        <div className="flex flex-col gap-6 lg:flex-row lg:gap-10">
          <div className="flex flex-1 flex-col gap-4">
            <div className="flex items-center gap-4 text-gray-700">
              <QrCodeIcon className="shrink-0" />
              <div>
                {t('account.sectionUsage.amountOfQRsCreated', {
                  totalQRCodes,
                  maxQRCodes: amountMaxQrCodes || `∞`,
                })}
              </div>
            </div>
            <SmartProgressBar value={!amountMaxQrCodes ? -1 : totalQRCodes} max={amountMaxQrCodes || 100} />
          </div>
        </div>
        <div className="flex flex-col gap-6 lg:flex-row lg:gap-10">
          <div className="flex flex-1 flex-col gap-4">
            <div className="flex items-center gap-4 text-gray-700">
              <ScanIcon className="shrink-0" />
              <div>
                {t('account.sectionUsage.amountOfScans', {
                  totalScans: totalScans,
                  maxScans: amountMaxScans || `∞`,
                })}
              </div>
            </div>
            <SmartProgressBar
              value={!amountMaxScans ? -1 : amountMaxScans > totalScans ? totalScans : amountMaxScans}
              max={amountMaxScans || 100}
            />
          </div>
        </div>
      </div>

      <div>
        <Button
          onClick={() => {
            navigate({ to: Routes.customer.account.subscription });
          }}
          color="secondary"
          variant="outlined"
          className="w-[220px]"
        >
          {t('subscription.buttonUpgradePlan')}
        </Button>
      </div>
    </Container>
  );
};
