import { PaginatedResponse } from '@app/api/api.type';
import { CustomerOutSchema } from '@app/domain/user';
import type { UserOutSchema } from '@app/auth/api/auth.schema';
import { buildSortParam } from '@app/utils/api.util';
import { identityQueryConfig } from '@app/utils/react-query.utils';
import {
  DesignOutDto,
  EmailReportOutDto,
  InvoiceOutDto,
  LibraryOutDto,
  PlanOutDto,
  QRCodeAdminOutDto,
  QRCodeOutDto,
} from '@app/swagger-types';

// TODO refactor to custom hooks
export const GET_USERS_QUERY = identityQueryConfig({
  name: 'get-users',
  variant: 'table',
  defaultSort: buildSortParam<UserOutSchema>('createdDate', 'desc'),
  searchParam: 'u_search',
  customerGenParam: 'u_customer_gen',
  planTypeParam: 'u_plan_type',
  planStatusParam: 'u_plan_status',
  tablePrefix: 'gut',
  config: {
    staleTime: 60000,
  },
  // schema: CustomerOutSchema,
} as const);
export type GET_USERS_QUERY = typeof GET_USERS_QUERY & { schema: PaginatedResponse<CustomerOutSchema> };

export const GET_ADMIN_QRS_QUERY = identityQueryConfig({
  name: 'get-admin-qrs',
  variant: 'table',
  defaultSort: buildSortParam<QRCodeAdminOutDto>('createdDate', 'desc'),
  searchParam: 'qr_search',
  typeParam: 'qr_type',
  afterDateParam: 'qr_after_date',
  tablePrefix: 'gqr',
  config: {
    staleTime: 60000,
  },
  // schema: CustomerOutSchema,
} as const);
export type GET_ADMIN_QRS_QUERY = typeof GET_ADMIN_QRS_QUERY & { schema: PaginatedResponse<CustomerOutSchema> };

export const GET_ADMIN_EMAIL_REPORTS_QUERY = identityQueryConfig({
  name: 'get-admin-email-reports',
  variant: 'table',
  defaultSort: buildSortParam<EmailReportOutDto>('createdDate', 'desc'),
  recipientParam: 'em_recipient',
  topicParam: 'em_topic',
  languageParam: 'em_language',
  tablePrefix: 'gem',
  config: {
    staleTime: 10000,
  },
} as const);
export type GET_ADMIN_EMAIL_REPORTS_QUERY = typeof GET_ADMIN_EMAIL_REPORTS_QUERY;

export const CUSTOMER_BILLING_GET_INVOICES = identityQueryConfig({
  name: 'CUSTOMER_BILLING_GET_INVOICES',
  variant: 'table',
  defaultSort: buildSortParam<InvoiceOutDto>('date', 'desc'),
  recipientParam: 'in_recipient',
  topicParam: 'in_topic',
  languageParam: 'in_language',
  tablePrefix: 'gin',
  config: {
    staleTime: 60000,
  },
} as const);
export type CUSTOMER_BILLING_GET_INVOICES = typeof CUSTOMER_BILLING_GET_INVOICES;

export const GET_LIBRARY_QUERY = identityQueryConfig({
  name: 'get-library',
  variant: 'base',
  config: {
    staleTime: 60000,
  },
} as const);
export type GET_LIBRARY_QUERY = typeof GET_LIBRARY_QUERY & {
  schema: { result: LibraryOutDto[] };
};

export const GET_LIBRARY_BY_ID_QUERY = identityQueryConfig({
  name: 'get-library-by-id',
  variant: 'base',
  config: {
    staleTime: 60000,
  },
} as const);
export type GET_LIBRARY_BY_ID_QUERY = typeof GET_LIBRARY_BY_ID_QUERY & {
  schema: { result: LibraryOutDto };
};

export const GET_TEMPLATES_QUERY = identityQueryConfig({
  name: 'get-templates',
  variant: 'base',
  config: {
    staleTime: 60000,
  },
} as const);
export type GET_TEMPLATES_QUERY = typeof GET_TEMPLATES_QUERY & {
  schema: { result: DesignOutDto[] };
};

export const GET_TEMPLATE_QUERY = identityQueryConfig({
  name: 'get-template',
  variant: 'base',
  config: {
    staleTime: 60000,
  },
} as const);
export type GET_TEMPLATE_QUERY = typeof GET_TEMPLATE_QUERY & {
  schema: { result: DesignOutDto };
};

export const GET_MY_QR_CODES_QUERY = identityQueryConfig({
  name: 'get-my-qr-codes',
  variant: 'base',
  config: {
    staleTime: 60000,
  },
} as const);
export type GET_MY_QR_CODES_QUERY = typeof GET_MY_QR_CODES_QUERY & {
  schema: { result: QRCodeOutDto[] };
};

export const GET_MY_QR_CODE_BY_ID_QUERY = identityQueryConfig({
  name: 'get-my-qr-code-by-id',
  variant: 'base',
  config: {
    staleTime: 60000,
  },
} as const);
export type GET_MY_QR_CODE_BY_ID_QUERY = typeof GET_MY_QR_CODE_BY_ID_QUERY & {
  schema: { result: QRCodeOutDto };
};

export const GET_SCANNED_QR_CODE_BY_ID_QUERY = identityQueryConfig({
  name: 'get-scanned-qr-code-by-id',
  variant: 'base',
  config: {
    staleTime: 60000,
  },
} as const);
export type GET_SCANNED_QR_CODE_BY_ID_QUERY = typeof GET_SCANNED_QR_CODE_BY_ID_QUERY & {
  schema: { result: QRCodeOutDto };
};

export const GET_PLANS_QUERY = identityQueryConfig({
  name: 'get-plans',
  variant: 'base',
  config: {
    staleTime: 60000,
  },
} as const);
export type GET_PLANS_QUERY = typeof GET_PLANS_QUERY & {
  schema: { result: PlanOutDto[] };
};

export const GET_CURRENT_PLAN_CONFIG_QUERY = identityQueryConfig({
  name: 'get-current-plan-config',
  variant: 'base',
  config: {
    staleTime: 60000,
  },
} as const);
export type GET_CURRENT_PLAN_CONFIG_QUERY = typeof GET_CURRENT_PLAN_CONFIG_QUERY & {
  schema: { result: PlanOutDto };
};

export const GET_DASHBOARD_MY_STATS_QUERY = identityQueryConfig({
  name: 'GET_DASHBOARD_MY_STATS_QUERY',
  variant: 'base',
  config: {
    staleTime: 60000,
  },
});
