import { QRCodeFormStep } from './QRCodeFormStep';

export const QR_CODE_STEPPER_STEPS = ['Type', 'Input', 'Customize', 'Done'] as const;
export type QR_CODE_STEPPER_STEPS_TYPE = typeof QR_CODE_STEPPER_STEPS[number];

export const QrCodeFormStepper: React.FC<{
  activeStep: QR_CODE_STEPPER_STEPS_TYPE;
  completedSteps: QR_CODE_STEPPER_STEPS_TYPE[];
  onChange?: (step: QR_CODE_STEPPER_STEPS_TYPE) => void;
  disabled?: boolean;
  /**
   * @deprecated no needed for widget?
   */
  forWidgetMode?: boolean;
}> = ({ activeStep, completedSteps, onChange, disabled, forWidgetMode }) => {
  return (
    <div className="mr-[2px] flex sm:mr-0">
      {QR_CODE_STEPPER_STEPS.map((step, idx) => (
        <QRCodeFormStep
          key={step}
          step={step}
          index={idx + 1}
          isCompleted={!!completedSteps.find((s) => step === s)}
          isActive={activeStep === step}
          onChange={disabled ? undefined : onChange}
          forWidgetMode={forWidgetMode}
        />
      ))}
    </div>
  );
};
