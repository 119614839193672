import { UIStyledSection } from '../components/UIStyledSection';
import { Autocomplete, Checkbox, OptionItem, Select } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { FormControlLabel, MenuItem, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';

export const UITabSelects: React.FC = () => {
  const [values, setValues] = useState<{ value: string; label: string }[]>([]);

  return (
    <div className="flex flex-col gap-4">
      <UIStyledSection title="UISectionSelects">
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-3 items-end gap-4 pb-6">
            <Select
              multiple
              defaultValue={[]}
              inputProps={{
                label: 'Multiple select with label',
              }}
            >
              <OptionItem value={1}>Option 1</OptionItem>
              <OptionItem value={2}>Option 2</OptionItem>
              <OptionItem value={3}>Option 3</OptionItem>
            </Select>
            <Select
              size="large"
              defaultValue={1}
              inputProps={{
                label: 'Large select with label',
              }}
            >
              <OptionItem value={1}>Option 1</OptionItem>
              <OptionItem value={2}>Option 2</OptionItem>
              <OptionItem value={3}>Option 3</OptionItem>
            </Select>
            <Select size="small">
              <OptionItem value={1}>Option 1</OptionItem>
              <OptionItem value={2}>Option 2</OptionItem>
              <OptionItem value={3}>Option 3</OptionItem>
            </Select>
          </div>
          <div className="grid grid-cols-3 items-end gap-4 pb-6">
            <Autocomplete
              className="my-1"
              browserAutocompleteOff
              disableCloseOnSelect
              InputProps={{
                placeholder: 'placeholder...',
                label: 'Autocomplete',
              }}
              isOptionEqualToValue={({ value: optionValue }, { value }) => optionValue === value}
              getOptions={async () => [
                { label: '1', value: '1' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
              ]}
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props} selected={selected}>
                  {option.label}
                </MenuItem>
              )}
            />
            <Autocomplete
              multiple
              browserAutocompleteOff
              disableCloseOnSelect
              value={values}
              InputProps={{
                placeholder: 'Placeholder...',
                label: 'multiple autocomplete',
                classes: { root: 'flex-wrap' },
              }}
              isOptionEqualToValue={({ value: optionValue }, { value }) => optionValue === value}
              onChange={(_, value) => {
                setValues(value || []);
              }}
              getOptions={async () => [
                { label: '1', value: '1' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
              ]}
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props} selected={selected}>
                  <Checkbox label={option.label} checked={selected} disableRipple className="scale-75 py-0" />
                </MenuItem>
              )}
            />
          </div>
        </div>
      </UIStyledSection>
      <UISectionRadioGroup />
    </div>
  );
};

enum ERadioValue {
  OPTION_ONE = 'OPTION_ONE',
  OPTION_TWO = 'OPTION_TWO',
  OPTION_THREE = 'OPTION_THREE',
}

const UISectionRadioGroup: React.FC = () => {
  /**
   * @note string type for value used for simplification of demo.
   * make sure to valide value before setting to state where non-arbitrary string expected.
   */
  const [value, setValue] = useState<string>(ERadioValue.OPTION_ONE);

  return (
    <UIStyledSection title="Radio group">
      <RadioGroup
        value={value}
        onChange={(e) => {
          setValue(e.currentTarget.value);
        }}
      >
        <FormControlLabel
          classes={{ label: clsxm('text-s text-gray-500', value === ERadioValue.OPTION_ONE && 'text-gray-800') }}
          value={ERadioValue.OPTION_ONE}
          control={<Radio />}
          label={ERadioValue.OPTION_ONE}
        />
        <FormControlLabel
          classes={{ label: clsxm('text-s text-gray-500', value === ERadioValue.OPTION_TWO && 'text-gray-800') }}
          value={ERadioValue.OPTION_TWO}
          control={<Radio />}
          label={ERadioValue.OPTION_TWO}
        />
        <FormControlLabel
          classes={{
            label: clsxm('text-s text-gray-500', value === ERadioValue.OPTION_THREE && 'text-gray-800'),
          }}
          value={ERadioValue.OPTION_THREE}
          control={<Radio />}
          label={ERadioValue.OPTION_THREE}
        />
      </RadioGroup>
    </UIStyledSection>
  );
};
