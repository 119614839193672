import styles from './CustomerModule.module.scss';
import { Outlet } from 'react-router-dom';

import { clsxm } from '@app/styles/clsxm';

import { Header } from '@app/layout/header/Header';
import { CustomerSidebarMenu } from '@app/layout/customer-sidebar-menu/CustomerSidebarMenu';
import { useStore } from '@app/store/useStore.hook';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { TrialExpiredPage } from './pages/trial/TrialExpiredPage';
import { BlackFridayBanner } from './components/BlackFridayBanner';
import { GlobalWarningBanner } from './components/GlobalWarningBanner';
import { MaybeAutoClaim } from './components/MaybeAutoClaim';
import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { OnboardingDialog } from './components/onboarding/OnboardingDialog';
import { useEffect, useState } from 'react';
import { BuySubscriptionBanner } from '@app/domain/subscription/BuySubscriptionBanner';
import { useIsMobile } from '@app/hooks/useIsMobile.hook';
import { useShouldShowSubscriptionBanner } from '@app/domain/subscription/useShouldShowSubscriptionBanner';
import { BUY_SUBSCRIPTION_BANNER_HEIGHT } from '@app/domain/subscription/BuySubscriptionBanner.constants';

export const CustomerModule: React.FC = () => {
  const { isDesktop } = useStore.useCurrentBreakpointDevice();
  const { isTrial, isExpired } = useActivePlan();
  const isMobile = useIsMobile();
  const isShowSubscriptionBanner = useShouldShowSubscriptionBanner();

  const isExpiredTrial = isTrial && isExpired;

  const outlet = isExpiredTrial ? <TrialExpiredPage /> : <Outlet />;

  return (
    <div className={clsxm('relative flex w-full flex-1 bg-accept-light-bg', styles.root)}>
      <Header />
      <div className={styles['app-content']}>
        <CustomerSidebarMenu />
        <main className={clsxm('w-full pb-20', isDesktop && 'pl-[260px]')}>
          <div
            style={{ paddingTop: !isMobile && isShowSubscriptionBanner ? `${BUY_SUBSCRIPTION_BANNER_HEIGHT}px` : 0 }}
            className="flex flex-col gap-4 h-full"
          >
            <MaybeAutoClaim />
            {isMobile && isShowSubscriptionBanner && <BuySubscriptionBanner />}
            <BlackFridayBanner />
            <GlobalWarningBanner />
            <MaybeOnboarding />
            {outlet}
          </div>
        </main>
      </div>
    </div>
  );
};

const MaybeOnboarding: React.FC = () => {
  const { shouldShowOnboarding } = useAuthorizedUser();
  const [isOpen, setIsOpen] = useState(shouldShowOnboarding);
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (shouldShowOnboarding) {
      setResetKey((i) => i + 1);
      setIsOpen(true);
    }
  }, [shouldShowOnboarding]);

  return (
    <OnboardingDialog
      key={resetKey}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    />
  );
};
