import { mixed, MixedSchema, AnyObject, string, lazy, object, array, number, AnyObjectSchema } from 'yup';

export const oneOfEnum = <T>(enumObject: { [s: string]: T } | ArrayLike<T>): MixedSchema<T, AnyObject, undefined, ''> =>
  mixed<T>().oneOf(Object.values(enumObject)).required();
// TODO make it a single enum with oneOfEnum
// but need to handle optional after required applied.
// oneOfEnum(ERole).optional() not working - still requires a value
// oneOfEnum(ERole).notRequired() not working because type is "null | T" which is not assignable to undefined
export const oneOfEnumOptional = <T>(
  enumObject: { [s: string]: T } | ArrayLike<T>
): MixedSchema<T | undefined, AnyObject, undefined, ''> => mixed<T>().oneOf(Object.values(enumObject));
export const oneOfEnumOptionalNullable = <T>(
  enumObject: { [s: string]: T } | ArrayLike<T>
): MixedSchema<T | undefined | null, AnyObject, undefined, ''> =>
  mixed<T>()
    .nullable()
    .oneOf([null, ...Object.values(enumObject)]);

export const password = () => string().required('Required Field').min(6, 'Min 6 characters');
export const email = () => string().email('Invalid email address').required('Required Field');

const lazyAny = lazy(() => mixed());

export type AnyLazyType = typeof lazyAny;

export const pageableData = <T extends AnyObjectSchema | AnyLazyType, V extends T['__outputType']>(data: T) => {
  return object({
    result: array().of<V>(data).required(),
    total: number().required(),
  });
};
