import { Button } from '@app/components';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { ButtonProps } from '@app/components/buttons/button/Button';

export const CreateQRCodeButton: React.FC<Omit<ButtonProps, 'onClick'>> = ({ ...otherProps }) => {
  const { t } = useTranslation();

  const navigate = useTypedNavigate();

  const handleCerateQRCodeClick = () => {
    navigate({ to: Routes.customer.create_qr_code });
  };

  return (
    <a href={Routes.customer.create_qr_code} onClick={(e) => e.preventDefault()}>
      <Button onClick={handleCerateQRCodeClick} startIcon={<AddIcon />} {...otherProps}>
        {t('myQRCodes.createQRCode')}
      </Button>
    </a>
  );
};
