import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { ConfirmPasswordForm } from '@app/modules/auth/components/ConfirmPasswordForm';
import { useConfirmPassword } from '@app/auth/api/auth.api.hook';

export const ResetPassword = () => {
  const navigate = useTypedNavigate();

  const {
    mutate: handleConfirmPassword,
    isLoading,
    error,
  } = useConfirmPassword({
    onSuccess: () => {
      navigate({ to: Routes.auth.sign_in });
    },
  });

  return (
    <ConfirmPasswordForm
      onConfirmPassword={handleConfirmPassword}
      loading={isLoading}
      error={error}
      hideSupportSection
    />
  );
};
