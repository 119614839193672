import { useIsMobile } from '@app/hooks/useIsMobile.hook';
import { UIStyledSection } from '../components/UIStyledSection';
import { clsxm } from '@app/styles/clsxm';

export const UITabLayout: React.FC = () => {
  return (
    <div className="flex flex-col gap-4">
      <MobileCheckSection />
    </div>
  );
};

const MobileCheckSection: React.FC = () => {
  const isMobile = useIsMobile();
  return (
    <UIStyledSection title="Mobile responsive check">
      <div>
        <div className="font-bold">
          Detect mobile using <b>TailwindCSS</b>
        </div>
        <div className="bg-green-200 md:bg-transparent">I active on mobile</div>
        <div className="md:bg-green-200 bg-transparent">I active on desktop</div>
      </div>
      <div>
        <div className="font-bold">
          Detect mobile using <b>useIsMobile</b>
        </div>
        <div className={clsxm('bg-transparent', isMobile && 'bg-green-200')}>I active on mobile</div>
        <div className={clsxm('bg-green-200', isMobile && 'bg-transparent')}>I active on desktop</div>
      </div>
    </UIStyledSection>
  );
};
