import { PlanChangeReminderBanner } from './PlanChangeReminderBanner';
import { PlanDowngradeInfo } from './PlanDowngradeInfo';

export const SubscriptionCancelGeneralContainer: React.FC = () => {
  return (
    <div className="gap-4 flex flex-col">
      <PlanChangeReminderBanner />
      <PlanDowngradeInfo />
    </div>
  );
};
