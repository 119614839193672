import styles from './CustomerModule.module.scss';
import { Outlet } from 'react-router-dom';

import { clsxm } from '@app/styles/clsxm';

import { Header } from '@app/layout/header/Header';
import { CustomerSidebarMenu } from '@app/layout/customer-sidebar-menu/CustomerSidebarMenu';
import { useStore } from '@app/store/useStore.hook';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { TrialExpiredPage } from './pages/trial/TrialExpiredPage';
import { BlackFridayBanner } from './components/BlackFridayBanner';
import { GlobalWarningBanner } from './components/GlobalWarningBanner';
import { MaybeAutoClaim } from './components/MaybeAutoClaim';

export const CustomerModule: React.FC = () => {
  const { isDesktop } = useStore.useCurrentBreakpointDevice();
  const { isTrial, isExpired } = useActivePlan();

  const isExpiredTrial = isTrial && isExpired;

  return (
    <div className={clsxm('relative flex w-full flex-1 bg-accept-light-bg', styles.root)}>
      <Header />
      <div className={styles['app-content']}>
        <CustomerSidebarMenu />
        <main className={clsxm('w-full pb-20', isDesktop && 'pl-[260px]')}>
          <div>
            <MaybeAutoClaim />
            <BlackFridayBanner />
            <GlobalWarningBanner />
            {isExpiredTrial ? <TrialExpiredPage /> : <Outlet />}
          </div>
        </main>
      </div>
    </div>
  );
};
