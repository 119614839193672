import React, { useState, useCallback } from 'react';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';

import { useUncontrolled } from '@app/hooks/useUncontrolled.hook';
import { FileApi } from '@app/api/file/file.api';
import { ImageItemType } from './components/image-item/ImageItem';
import { ImageDropZone } from './components/image-drop-zone/ImageDropZone';
import { ImageCollection } from './components/image-collection/ImageCollection';
import styles from './GalleryEditor.module.scss';
import { clsxm } from '@app/styles/clsxm';
import { uploadImage } from '@app/utils/file.utils';
import { useTranslation } from 'react-i18next';

export type GalleryItemType = Partial<ImageItemType>;
export interface GalleryEditorProps {
  value?: GalleryItemType[];
  onChange?: (value: GalleryItemType[]) => void;
  onUploaded?: (value: GalleryItemType[]) => void;
  handleUploadIds?: (imageIds: string[]) => void;
  handleDeleteId?: (imageId: string) => void;
  onClickImage?: (item: ImageItemType) => void;
  selectedImageId?: string | null;
  disabled?: boolean;
  dropZoneLoading?: boolean;
  uploadOnServer?: boolean;
  deleteOnServer?: boolean;
  withNoImg?: boolean;
  withAppLogoImg?: boolean;
  noImgText?: string;
  forWidgetMode?: boolean;
}

/**
 * OPTIMIZATION: provide `value[number].item.id` to avoid re-mount of item on every render
 */
export const GalleryEditor = React.memo<GalleryEditorProps>(function GalleryEditor({
  value,
  onChange,
  handleUploadIds,
  handleDeleteId,
  disabled,
  onClickImage,
  selectedImageId,
  withNoImg,
  withAppLogoImg,
  noImgText,
  dropZoneLoading,
  uploadOnServer = true,
  deleteOnServer = true,
  forWidgetMode = false,
}) {
  const { t } = useTranslation();
  const [_value, handleChange] = useUncontrolled<ImageItemType[]>({
    value: value?.map((img) => {
      return {
        id: img.id || nanoid(),
        ...img,
      };
    }),
    defaultValue: [],
    rule: (val) => !!val,
    onChange: (value) => {
      onChange && onChange(value || []);
    },
  });
  const [loading, setLoading] = useState(false);
  const [progressState, setProgressState] = useState<Record<string, number>>({});

  const onUpload = useCallback(
    async (files: File[]) => {
      setLoading(true);
      const _progressState = { ...progressState };
      const images = files.map<ImageItemType>((file) => {
        const previewUrl = URL.createObjectURL(file);
        _progressState[previewUrl] = 0;
        return {
          id: nanoid(),
          previewUrl,
          file,
        };
      });

      handleChange(((prev = []) => [...images, ...prev]) as any);
      setProgressState(_progressState);
      const updateProgress = (previewUrl: string, progress: number) => {
        setProgressState((prev) => {
          const _prev = { ...prev };
          _prev[previewUrl] = progress;
          return _prev;
        });
      };
      try {
        const uploadedFiles = await Promise.all(
          uploadOnServer
            ? images.map((img) =>
                uploadImage(
                  img,
                  (e) => img.previewUrl && updateProgress(img.previewUrl, Math.round((100 * e.loaded) / e.total)),
                  { forWidgetMode }
                )
              )
            : images
        );

        handleChange((prev = []) => {
          return prev.map((image: ImageItemType) => {
            const uploadedImage = uploadedFiles.find((img) => img.id === image.id);
            if (uploadedImage) {
              return uploadedImage;
            }
            return image;
          });
        });

        handleUploadIds && handleUploadIds(uploadedFiles.map(({ imageId }) => imageId || '').filter((file) => !!file));

        setProgressState((prev) => {
          const _prev = { ...prev };
          uploadedFiles.forEach((img) => {
            if (img.previewUrl) {
              delete _prev[img.previewUrl];
            }
          });
          return _prev;
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [handleChange, progressState, uploadOnServer, handleUploadIds, forWidgetMode]
  );

  const deleteImageItem = useCallback(
    async (img: ImageItemType) => {
      if (img.imageId) {
        if (deleteOnServer) {
          await FileApi.deleteFile(img.imageId);
        }
        handleChange(((prev: ImageItemType[]) => prev?.filter((image) => image.imageId !== img.imageId)) as any);
        handleDeleteId && handleDeleteId(img.imageId);
      }
    },
    [deleteOnServer, handleChange, handleDeleteId]
  );

  const handleDragEnd = useCallback(
    async (items: ImageItemType[]) => {
      handleChange(items);
    },
    [handleChange]
  );

  return (
    <div className={clsxm(styles.Wrapper, 'flex w-full', disabled && 'cursor-not-allowed')}>
      <ImageCollection
        progressState={progressState}
        loading={loading}
        items={_value || []}
        onDragEnd={handleDragEnd}
        onDeleteEnd={deleteImageItem}
        disabled={disabled}
        onClickImage={onClickImage}
        selectedImageId={selectedImageId}
        withNoImg={withNoImg}
        withAppLogoImg={withAppLogoImg}
        noImgText={noImgText}
        forWidgetMode={forWidgetMode}
      >
        <ImageDropZone
          onNotAllowed={(file) => toast.error(t('error.fileNotSupported', { fileName: file?.name }))}
          allowedTypes={['image/png', 'image/jpeg', 'image/jpg']}
          onMultipleChange={onUpload}
          loading={dropZoneLoading || loading}
          multiple={forWidgetMode ? false : undefined}
        />
      </ImageCollection>
    </div>
  );
});
