import { clsxm } from '@app/styles/clsxm';

export const FormStepBare = ({
  content,
  isActive = false,
  isCompleted,
  isFirstStep,
  isLastStep,
}: {
  content: React.ReactNode;
  isActive: boolean;
  isCompleted: boolean;
  isFirstStep?: boolean;
  isMiddleStep?: boolean;
  isLastStep?: boolean;
}) => {
  const commonStyles = clsxm(
    'py-1 border-y-[1.3px]',
    isActive ? 'border-accept-main bg-accept-light text-accept-main' : 'border-gray-300 bg-white'
  );

  const completedBg = isCompleted ? 'bg-gray-100' : '';
  const fillClassName = clsxm('text-white', isActive && 'text-accept-light', isCompleted && 'text-gray-100');
  const strokeClassName = clsxm('text-gray-300', isCompleted && 'text-gray-300', isActive && 'text-accept-main');

  return (
    <div className="flex flex-1 h-11">
      {isFirstStep ? (
        <StepEdge
          className={clsxm('border-l-[1.3px] border-t border-b rounded-tl rounded-bl px-1', commonStyles, completedBg)}
        />
      ) : (
        <div>
          <StepSVG isFirstPart fillClassName={fillClassName} strokeClassName={strokeClassName} />
        </div>
      )}

      <StepContent
        className={clsxm(
          'flex grow flex-1 border-t border-b items-center px-1 justify-center sm:justify-start',
          commonStyles,
          completedBg
        )}
      >
        {content}
      </StepContent>

      {isLastStep ? (
        <StepEdge
          className={clsxm('border-r-[1.3px] border-t border-b rounded-tr rounded-br px-1', commonStyles, completedBg)}
        />
      ) : (
        <div>
          <StepSVG isLastPart fillClassName={fillClassName} strokeClassName={strokeClassName} />
        </div>
      )}
    </div>
  );
};

const StepEdge = ({ className }: { className: string }) => <div className={className}></div>;

const StepContent = ({ className, children }: { className: string; children: React.ReactNode }) => (
  <div className={className}>{children}</div>
);

const StepSVG: React.FC<{
  isFirstPart?: boolean;
  isLastPart?: boolean;
  className?: string;
  fillClassName?: string;
  strokeClassName?: string;
}> = ({ fillClassName, strokeClassName, isFirstPart, isLastPart, className }) => (
  <svg
    width="100%"
    preserveAspectRatio="none"
    height="100%"
    viewBox={isLastPart ? '152 0 19 46' : isFirstPart ? '0 0 19 46' : '0 0 174 46'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsxm('xs:min-w-6 min-w-3', className)}
  >
    <mask id="path-1-inside-1_6210_49546" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.194 1.71574C155.446 0.640859 154.219 0 152.91 0H4.65537C1.42461 0 -0.473223 3.63211 1.37175 6.28426L11.411 20.7157C12.3662 22.0888 12.3662 23.9112 11.411 25.2843L1.37175 39.7157C-0.473225 42.3679 1.42461 46 4.65537 46H152.91C154.219 46 155.446 45.3591 156.194 44.2843L169.411 25.2843C170.366 23.9112 170.366 22.0888 169.411 20.7157L156.194 1.71574Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M156.194 1.71574C155.446 0.640859 154.219 0 152.91 0H4.65537C1.42461 0 -0.473223 3.63211 1.37175 6.28426L11.411 20.7157C12.3662 22.0888 12.3662 23.9112 11.411 25.2843L1.37175 39.7157C-0.473225 42.3679 1.42461 46 4.65537 46H152.91C154.219 46 155.446 45.3591 156.194 44.2843L169.411 25.2843C170.366 23.9112 170.366 22.0888 169.411 20.7157L156.194 1.71574Z"
      fill={'currentColor'}
      className={clsxm(fillClassName)}
    />
    <path
      d="M11.411 20.7157L10.5901 21.2868H10.5901L11.411 20.7157ZM11.411 25.2843L12.2319 25.8553H12.2319L11.411 25.2843ZM169.411 25.2843L170.232 25.8553L169.411 25.2843ZM169.411 20.7157L170.232 20.1447L169.411 20.7157ZM1.37175 39.7157L0.550842 39.1447L1.37175 39.7157ZM156.194 1.71574L157.015 1.14468L156.194 1.71574ZM156.194 44.2843L157.015 44.8553L156.194 44.2843ZM4.65537 1H152.91V-1H4.65537V1ZM12.2319 20.1447L2.19265 5.7132L0.550844 6.85533L10.5901 21.2868L12.2319 20.1447ZM12.2319 25.8553C13.4259 24.139 13.4259 21.861 12.2319 20.1447L10.5901 21.2868C11.3065 22.3166 11.3065 23.6834 10.5901 24.7132L12.2319 25.8553ZM2.19265 40.2868L12.2319 25.8553L10.5901 24.7132L0.550842 39.1447L2.19265 40.2868ZM152.91 45H4.65537V47H152.91V45ZM168.59 24.7132L155.373 43.7132L157.015 44.8553L170.232 25.8553L168.59 24.7132ZM168.59 21.2868C169.307 22.3166 169.307 23.6834 168.59 24.7132L170.232 25.8553C171.426 24.139 171.426 21.861 170.232 20.1447L168.59 21.2868ZM155.373 2.28681L168.59 21.2868L170.232 20.1447L157.015 1.14468L155.373 2.28681ZM0.550842 39.1447C-1.75537 42.4599 0.616917 47 4.65537 47V45C2.2323 45 0.808923 42.2759 2.19265 40.2868L0.550842 39.1447ZM152.91 1C153.892 1 154.812 1.48064 155.373 2.28681L157.015 1.14468C156.08 -0.198927 154.547 -1 152.91 -1V1ZM152.91 47C154.547 47 156.08 46.1989 157.015 44.8553L155.373 43.7132C154.812 44.5194 153.892 45 152.91 45V47ZM4.65537 -1C0.616917 -1 -1.75537 3.54014 0.550844 6.85533L2.19265 5.7132C0.808924 3.72408 2.2323 1 4.65537 1V-1Z"
      fill={'currentColor'}
      className={clsxm(strokeClassName)}
      mask="url(#path-1-inside-1_6210_49546)"
    />
  </svg>
);
