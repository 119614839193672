import { useHandler } from '@app/hooks/useHandler.hook';
import { useQuery, useQueryClient } from 'react-query';

class OnboardingState {
  isOpen = false;
}

let globalState: OnboardingState = new OnboardingState();

/**
 * Hook to track state of onboarding dialog e.g. isOpen
 * to properly render other components on page
 * e.g. to hide other dialogs until onboarding completed
 */
export const useOnboardingDialogState = () => {
  const queryClient = useQueryClient();
  const { data: state } = useQuery({
    queryKey: [useOnboardingDialogState.queryKey],
    queryFn: () => globalState,
  });
  const setState = useHandler((newState: OnboardingState) => {
    globalState = newState;
    queryClient.setQueryData(useOnboardingDialogState.queryKey, globalState);
  });

  return { state, setState };
};
useOnboardingDialogState.queryKey = 'useOnboardingDialogState';
Object.freeze(useOnboardingDialogState);
