/**
 * IMPORT ONLY for user app, not widget.
 * DO NOT import this module anywhere for a widget app.
 * Widget should rely on WordPress existing GTM setup.
 */

import { IS_LOCALHOST } from './environment/typed-env';
import { Routes } from './constants/routes';
import { initGtmScript_DIRTY } from './index-gtm-source.mjs';

// TODO remove package, not needed for GTM server-side tracking
// import TagManager from 'react-gtm-module';
// const tagManagerArgs = {
//   gtmId: typedEnv.REACT_APP_GOOGLE_TAG_MANAGER_ID,
// };

const IS_SERVER_RENDER = window.location.pathname.startsWith(`${Routes.render.index}/`);

if (!IS_LOCALHOST && !IS_SERVER_RENDER) {
  // TagManager.initialize(tagManagerArgs);
  // --------------
  initGtmScript_DIRTY();
}
