import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { FORMAT } from '@app/constants/formats';
import { EPlanStatus, EPlanType } from '@app/swagger-types';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

// TODO return permissions and limits
export const useActivePlan = () => {
  const { currentUser } = useAuthorizedUser();
  const [, setRenderTrigger] = useState(0);

  const endOfSubscriptionDate = currentUser?.currentPlanDetails?.endOfCurrentSubscription;
  const scheduledUpdate = currentUser?.plan?.scheduledUpdate;

  useEffect(() => {
    if (!endOfSubscriptionDate) {
      return;
    }
    const expireDate = new Date(endOfSubscriptionDate);
    const delta = expireDate.getTime() - Date.now();
    const timeTillExpiration = delta < 0 ? 0 : delta;
    const timer = setTimeout(() => {
      setRenderTrigger((i) => i + 1);
    }, timeTillExpiration);
    return () => {
      clearTimeout(timer);
    };
  }, [endOfSubscriptionDate]);

  if (!currentUser) {
    return { isLoading: true };
  }

  const planDetails = currentUser.currentPlanDetails;
  const { plan } = currentUser;
  const { planType, planStatus } = planDetails || {};
  const isFreePlan = planType === EPlanType.FREE;
  const isTrial = planType === EPlanType.TRIAL;
  const planCycle = plan?.price?.planCycle;
  const isPlanCanceled = planStatus === EPlanStatus.CANCELED;
  if (!endOfSubscriptionDate) {
    return {
      isLoading: true,
      isTrial,
      isFreePlan,
      planType,
      planStatus,
      planCycle,
      planDetails,
      isPlanCanceled,
      endOfSubscriptionDate,
      scheduledUpdate,
    };
  }

  const expireDate = new Date(endOfSubscriptionDate);
  const isExpired = new Date() >= expireDate;
  const isPlanCanceledStillActive = isPlanCanceled && !isExpired;
  const friendlyExpireDate = expireDate ? format(expireDate, FORMAT.DATE_YEAR) : undefined;
  const friendlyScheduledDate = scheduledUpdate?.nextPeriodStart
    ? format(new Date(scheduledUpdate.nextPeriodStart), FORMAT.DATE_YEAR)
    : undefined;
  return {
    isExpired,
    isTrial,
    isFreePlan,
    isPlanCanceled,
    plan,
    planType,
    planStatus,
    planDetails,
    expireDate,
    friendlyExpireDate,
    isPlanCanceledStillActive,
    planCycle,
    endOfSubscriptionDate,
    scheduledUpdate,
    friendlyScheduledDate,
  };
};
