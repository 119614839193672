export const isString = (value: any): value is string => {
  return typeof value === 'string';
};

/**
 * @usage getValidEnumOrDefault(['red', 'blue'] as const, 'red', 'actual-nonsafe-value')
 * @usage getValidEnumOrDefault(EStep, EStep.INITIAL, 'actual-nonsafe-value')
 * TODO refactor D to NoInfer when TypeScript upgraded to 5.4+
 */
export const getValidEnumOrDefault = <T, D extends T>(
  enumObject: { [s: string]: T } | ArrayLike<T>,
  defaultValue: D,
  value: unknown
): T => {
  // return (oneOfEnum(enumObject).isValidSync(value) ? value : defaultValue);
  if (Array.isArray(enumObject)) {
    if (enumObject.includes(value)) {
      return value as T;
    }
  }
  if (Object.values<unknown>(enumObject).includes(value)) {
    return value as T;
  }
  return defaultValue;
};

export const enumValues = <T extends Record<string, string | number>>(enumObj: T) =>
  Object.values(enumObj) as unknown as T[keyof T][];
