import { PdfBigPreviewIcon, WebsiteUrlBigPreviewIcon } from '@app/assets';
import { VCardPreview } from '@app/domain/scan/components/vcard/VCardPreview';
import { clsxm } from '@app/styles/clsxm';
import { ECodeType } from '@app/swagger-types';
import { SvgScopeWrap } from '@app/utils/SvgScopeWrap';
import { useFormContext } from 'react-hook-form';
import { QRCodeFormSchema } from '../api/qr-code.form.schema.api';
import { extractStylePropsFromQRForm } from '../qr-code.utils';
import { MultilinkPreview } from './input/multilink/MultilinkPreview';
import { QRCodeRendererSVG } from './QRCodeRendererSVG';
import { EQRCodeStep } from '../hooks/useQRCodeNavigation';
import { GS1Preview } from './input/gs1/GS1Preview';

// TODO after release
// import { useGetTemplateById } from '@app/domain/template/api/template.hooks.api';

export const QRCodePreviewSection: React.FC<{
  activeStep: EQRCodeStep;
  qrId?: string;
}> = ({ activeStep, qrId }) => {
  const { watch } = useFormContext<QRCodeFormSchema>();

  const formLatestValue = watch();
  const { websiteUrl, codeType, multilink, pdf, vcard, gs1, wifi } = formLatestValue;

  // TODO after release
  // const { templateId } = formLatestValue;
  // const { data: template } = useGetTemplateById(templateId);

  const shouldBlurQRCode = (() => {
    if (codeType === ECodeType.WEBSITE) {
      return !websiteUrl;
    }
    if (codeType === ECodeType.PDF) {
      return !pdf?.fileId;
    }
    if (codeType === ECodeType.WIFI) {
      return !(wifi?.encryption && wifi?.ssid);
    }
  })();

  if (codeType === ECodeType.WEBSITE && activeStep === EQRCodeStep.TYPE) {
    return (
      <SvgScopeWrap>
        <WebsiteUrlBigPreviewIcon />
      </SvgScopeWrap>
    );
  }

  if (codeType === ECodeType.MULTILINK && (activeStep === EQRCodeStep.TYPE || activeStep === EQRCodeStep.INPUT)) {
    return <MultilinkPreview multilinkFromForm={multilink} isRenderFromForm />;
  }

  if (codeType === ECodeType.PDF) {
    if (activeStep === EQRCodeStep.TYPE) {
      return (
        <SvgScopeWrap>
          <PdfBigPreviewIcon />
        </SvgScopeWrap>
      );
    }
  }

  if (codeType === ECodeType.V_CARD && (activeStep === EQRCodeStep.TYPE || activeStep === EQRCodeStep.INPUT)) {
    return <VCardPreview vcardFromForm={vcard} isRenderFromForm />;
  }

  if (codeType === ECodeType.GS1 && (activeStep === EQRCodeStep.TYPE || activeStep === EQRCodeStep.INPUT)) {
    return <GS1Preview gs1FromForm={gs1} isRenderFromForm />;
  }

  if (codeType === ECodeType.WIFI && (activeStep === EQRCodeStep.TYPE || activeStep === EQRCodeStep.INPUT)) {
    return (
      <div className={clsxm(shouldBlurQRCode && 'blur-sm')}>
        <QRCodeRendererSVG
          asPreview
          qrId={qrId}
          {...extractStylePropsFromQRForm(formLatestValue)}
          frameClassName="min-w-[250px] max-w-[300px] flex-1 bg-transparent p-0"
        />
      </div>
    );
  }

  return (
    <div className={clsxm(shouldBlurQRCode && 'blur-sm')}>
      <QRCodeRendererSVG
        asPreview
        qrId={qrId}
        gtin={gs1?.gtin}
        // TODO template not needed?
        // {...extractStylePropsFromQRForm(formLatestValue, template)}
        {...extractStylePropsFromQRForm(formLatestValue)}
        frameClassName="min-w-[250px] max-w-[300px] flex-1 bg-transparent p-0"
      />
    </div>
  );
};
