import { CheckCircleIcon, NoCheckCircleIcon } from '@app/assets';

export const IncludeItem: React.FC<{ isAvailable?: string | boolean | null; text: React.ReactNode }> = ({
  text,
  isAvailable,
}) => {
  return (
    <div className="flex items-center gap-2 text-gray-800">
      <span className="h-5 w-5">{isAvailable ? <CheckCircleIcon /> : <NoCheckCircleIcon />}</span>
      <span>{text}</span>
    </div>
  );
};
