import { AUTH_QUERY_PARAM_AUTO_LINK_QR_ID } from '@app/modules/auth/auth.constants';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';

const LOCALSTORAGE_AUTO_LINK_KEY = 'autoLinkQRID';

// use for root route so all authorized and unauthorized routes trigger persistance
export const usePersistAutoLink = () => {
  const [autoLinkQRID] = useQueryParam(AUTH_QUERY_PARAM_AUTO_LINK_QR_ID, withDefault(StringParam, null));

  if (autoLinkQRID) {
    // console.log('persist autoLinkQRID', autoLinkQRID);
    localStorage.setItem(LOCALSTORAGE_AUTO_LINK_KEY, autoLinkQRID);
  } else {
    // console.log('no autoLinkQRID to persist', window.location.href);
  }
};

export const useGetPersistedAutoLink = () => {
  const autoLinkQRID = localStorage.getItem(LOCALSTORAGE_AUTO_LINK_KEY);

  // console.log('get persisted autoLinkQRID', autoLinkQRID);
  return { autoLinkQRID };
};

export const useControlPersistedAutoLink = () => {
  const reset = () => {
    // console.log('remove persisted autoLinkQRID');
    localStorage.removeItem(LOCALSTORAGE_AUTO_LINK_KEY);
  };

  return { reset };
};
