import { PlanLimitOutDtoKey } from './user.type';

/**
 * @deprecated
 */
export const SUBSCRIPTION_PLAN_LIMIT_KEYS: PlanLimitOutDtoKey[] = [
  'hasTemplates',
  // 'hasLabels',
  'hasRealTimeDataDashboard',
  'hasTrackScanTimeOfDay',
  'hasTrackScanLocation',
  'hasTrackScanDevice',
  'hasTrackScanGPS',
  'hasQrDesignCustomisations',
  // 'hasBulkQrCodeGeneration',
  'gdprCompliant',
  'ccpaCompliant',
  'knowledgeBase',
  'emailSupport',
  'dedicatedCustomerSuccessManager',
];

export const NEW_SUBSCRIPTION_PLAN_LIMIT_KEYS: PlanLimitOutDtoKey[] = [
  'qrAdjustable',
  'hasFileExtensions',
  'hasRealTimeDataDashboard',
  'hasBulkQrCodeGeneration',
  'hasTrackScanGPS',
  'hasTemplates',
  'qrTypes',
];
