import { useFormContext, Controller } from 'react-hook-form';
import { FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Typography } from '@app/components';
import { TranslationKey } from '@app/locales/defaultTranslation';
import { ValuesKeys } from './SubscriptionCancelPlanForm';
import type { TypeCancellationFormSchema } from '../api/subscription.schema';

type radioField = {
  id: string;
  label: TranslationKey;
  value: TranslationKey;
  isSpecify?: boolean;
};

type textareafield = {
  id: string;
  label: TranslationKey;
  placeholder: TranslationKey;
};

type FormSchemaType = {
  id: ValuesKeys;
  label: TranslationKey;
} & (
  | { type: 'radio'; choices: radioField[] }
  | {
      type: 'textarea';
      choices: textareafield[];
    }
);

const FormSchema: FormSchemaType[] = [
  {
    id: 'primaryReason',
    label: 'subscription.cancellation.stepFeedback.questions.primaryReason.title',
    type: 'radio',
    choices: [
      {
        id: '1',
        value: 'subscription.cancellation.stepFeedback.questions.primaryReason.choiceNoLongerNeeded',
        label: 'subscription.cancellation.stepFeedback.questions.primaryReason.choiceNoLongerNeeded',
      },
      {
        id: '2',
        value: 'subscription.cancellation.stepFeedback.questions.primaryReason.choiceBetterAlternative',
        label: 'subscription.cancellation.stepFeedback.questions.primaryReason.choiceBetterAlternative',
      },
      {
        id: '3',
        value: 'subscription.cancellation.stepFeedback.questions.primaryReason.choiceTooExpensive',
        label: 'subscription.cancellation.stepFeedback.questions.primaryReason.choiceTooExpensive',
      },
      {
        id: '4',
        value: 'subscription.cancellation.stepFeedback.questions.primaryReason.choiceTechnicalIssues',
        label: 'subscription.cancellation.stepFeedback.questions.primaryReason.choiceTechnicalIssues',
      },

      {
        id: '5',
        value: 'subscription.cancellation.stepFeedback.questions.primaryReason.choiceLackOfFeatures',
        label: 'subscription.cancellation.stepFeedback.questions.primaryReason.choiceLackOfFeatures',
      },

      {
        id: '6',
        value: 'subscription.cancellation.stepFeedback.questions.primaryReason.choicePoorSupport',
        label: 'subscription.cancellation.stepFeedback.questions.primaryReason.choicePoorSupport',
      },

      {
        id: '7',
        value: 'subscription.cancellation.stepFeedback.questions.primaryReason.choiceOther',
        label: 'subscription.cancellation.stepFeedback.questions.primaryReason.choiceOther',
        isSpecify: true,
      },
    ],
  },
  {
    id: 'featuresMissingOrImprove',
    label: 'subscription.cancellation.stepFeedback.questions.featuresMissingOrImprove.title',
    type: 'radio',
    choices: [
      {
        id: '1',
        value: 'subscription.cancellation.stepFeedback.questions.featuresMissingOrImprove.choiceYes',
        label: 'subscription.cancellation.stepFeedback.questions.featuresMissingOrImprove.choiceYes',
        isSpecify: true,
      },
      {
        id: '2',
        value: 'subscription.cancellation.stepFeedback.questions.featuresMissingOrImprove.choiceNo',
        label: 'subscription.cancellation.stepFeedback.questions.featuresMissingOrImprove.choiceNo',
      },
    ],
  },
  {
    id: 'howToKeepYouAsCustomer',
    label: 'subscription.cancellation.stepFeedback.questions.howToKeepYouAsCustomer.title',
    type: 'radio',
    choices: [
      {
        id: '1',
        value: 'subscription.cancellation.stepFeedback.questions.howToKeepYouAsCustomer.choiceYes',
        label: 'subscription.cancellation.stepFeedback.questions.howToKeepYouAsCustomer.choiceYes',
        isSpecify: true,
      },
      {
        id: '2',
        value: 'subscription.cancellation.stepFeedback.questions.howToKeepYouAsCustomer.choiceNo',
        label: 'subscription.cancellation.stepFeedback.questions.howToKeepYouAsCustomer.choiceNo',
      },
    ],
  },
  {
    id: 'commentsOrSuggestions',
    type: 'textarea',
    label: 'subscription.cancellation.stepFeedback.questions.commentsOrSuggestions.title',
    choices: [
      {
        id: '1',
        placeholder: 'subscription.cancellation.stepFeedback.fields.textareaPlaceholder',
        label: 'subscription.cancellation.stepFeedback.fields.textareaLabel',
      },
    ],
  },
];

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export const SubscriptionCancelFeedbackContainer = ({
  setSpecifyValues,
  specifyValues,
}: {
  specifyValues: TypeCancellationFormSchema;
  setSpecifyValues: SetState<TypeCancellationFormSchema>;
}) => {
  const { t } = useTranslation();
  const { control, watch, register } = useFormContext();

  return (
    <div className="flex flex-col p-8 gap-6 rounded-lg border border-gray-200">
      <Typography variant="xxl" className="font-bold text-xl">
        {t('subscription.cancellation.stepFeedback.questions.title')}
      </Typography>
      {FormSchema.map((section) => {
        const selectedValue = watch(section.id);
        return (
          <div key={section.id} className="flex flex-col gap-4">
            <Typography variant="xl" className="font-bold">
              {t(section.label)}
            </Typography>
            <Controller
              name={section.id}
              control={control}
              render={({ field }) => (
                <>
                  {section.type === 'radio' ? (
                    <RadioGroup
                      {...field}
                      className="grid grid-cols-1 lg:grid-cols-2 gap-4"
                      onChange={(e) => {
                        field.onChange(e);
                        const selected: string = e.target.value;

                        if (section.choices.find((choice) => choice?.isSpecify && choice.value !== selected)) {
                          setSpecifyValues((prev) => ({ ...prev, [section.id]: '' }));
                        }
                      }}
                    >
                      {section.choices.map((choice) => (
                        <div key={choice.id} className="gap-4 flex flex-col">
                          <FormControlLabel value={t(choice.value)} control={<Radio />} label={t(choice.label)} />
                          {choice.isSpecify && selectedValue === t(choice.value) && (
                            <div className="gap-2 flex flex-col">
                              <h4 className="text-m">
                                {t('subscription.cancellation.stepFeedback.fields.textareaLabel')}
                              </h4>
                              <TextareaAutosize
                                className="placeholder:color-gray-500 color-gray-800 px-4 py-3 resize-none rounded-xl border border-gray-200 bg-gray-100 outline-none focus:border-gray-800"
                                value={specifyValues[section.id] || ''}
                                minRows={3}
                                placeholder={t('subscription.cancellation.stepFeedback.fields.textareaPlaceholder')}
                                onChange={(e) =>
                                  setSpecifyValues((prev) => ({ ...prev, [section.id]: e.target.value }))
                                }
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </RadioGroup>
                  ) : (
                    section.choices.map((choice) => (
                      <div className="gap-2 flex flex-col" key={choice.id}>
                        <h4 className="text-m">{t('subscription.cancellation.stepFeedback.fields.textareaLabel')}</h4>
                        <TextareaAutosize
                          className="placeholder:color-gray-500 color-gray-800 px-4 py-3 resize-none rounded-xl border border-gray-200 bg-gray-100 outline-none focus:border-gray-800"
                          {...register(section.id)}
                          placeholder={t(choice.placeholder)}
                          minRows={3}
                        />
                      </div>
                    ))
                  )}
                </>
              )}
            />
          </div>
        );
      })}
    </div>
  );
};
