import { Button } from '@app/components';
import { useStore } from '@app/store/useStore.hook';
import { useTranslation } from 'react-i18next';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { FiRrInfo } from '@app/assets';
import { clsxm } from '@app/styles/clsxm';

/**
 * Historical commit for expired page (before freemium migration)
 * See - 28001add94542369e4a7d6524c7074d9d1d4504e
 */
export const ExpiredQRPage = () => {
  const { t } = useTranslation();
  const user = useStore.useCurrentUser();
  const navigate = useTypedNavigate();

  const renderBody = () => {
    return (
      <div className="flex h-full w-full flex-col items-center gap-4 justify-center md:justify-start md:pb-8">
        <div className="flex flex-col gap-4 text-center">
          <div className="font-bold text-xl">{t('qr.expired.ownerNotified')}</div>
          <div>{t('qr.expired.thankYou')}</div>
        </div>
        <Button
          onClick={() => {
            navigate({ to: user ? Routes.customer.account.subscription : Routes.index });
          }}
          className="w-[220px]"
        >
          {t('qr.expired.buttonIAmOwner')}
        </Button>
      </div>
    );
  };

  const notification = (
    <div>
      <div
        className={clsxm(
          'flex gap-4 items-start flex-wrap',
          'p-4 outline outline-1 outline-gray-300 bg-gray-100',
          'lg:rounded-lg'
        )}
      >
        <div className="flex items-center">
          <FiRrInfo className="mt-1 text-error" />
        </div>
        <div className="flex flex-col gap-2 flex-1">
          <b>{t('qr.expired.notification')}</b>
          <div>{t('qr.expired.qrCodeNoLongerWorking')}</div>
          <div>{t('qr.expired.yourScannerWorksWell')}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex h-full flex-col items-start gap-4 bg-white p-4 rounded-lg">
      <div className="flex flex-col gap-4 w-full">
        <div className="text-xxl font-bold">{t('qr.expired.thanksForScanning')}</div>
        {notification}
        <div className="font-bold text-xl">{t('qr.expired.whatNow')}</div>
        <div className="flex flex-col gap-8 md:pb-16">
          <div className="flex flex-col gap-4">
            <div className="font-bold">{t('qr.expired.haveYouScannedCode')}</div>
            <div>{t('qr.expired.informTheOwner')}</div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="font-bold">{t('qr.expired.areYouOwner')}</div>
            <div>{t('qr.expired.clickOnIAmOwner')}</div>
          </div>
        </div>
      </div>
      {renderBody()}
    </div>
  );
};
