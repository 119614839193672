import { useFirstDayDiscountAvailable } from '@app/domain/subscription/hooks/useFirstDayDiscountAvailable';
import { clsxm } from '@app/styles/clsxm';
import { EPlanCycle, PriceOutDto } from '@app/swagger-types';
import { renderCurrencyAsSymbol } from '@app/utils/currency.util';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

export const SubscriptionPriceContainer: React.FC<{
  planPrice?: PriceOutDto | null;
  compareToPlanPrice?: PriceOutDto;
  compareToPlanCycle?: EPlanCycle;
  className?: string;
  comparisonPriceFactor?: number;
}> = ({ planPrice, compareToPlanCycle, compareToPlanPrice, className, comparisonPriceFactor = 1 }) => {
  const { t } = useTranslation();
  const { isAvailable: shouldShowDiscount } = useFirstDayDiscountAvailable();

  if (!planPrice) {
    return null;
  }

  const shouldShowStrikethroughPrice = compareToPlanCycle
    ? compareToPlanCycle === EPlanCycle.YEARLY
    : planPrice?.planCycle === EPlanCycle.YEARLY;
  const basePrice = planPrice?.price ?? 1;
  const isQuerterly = planPrice?.planCycle === EPlanCycle.QUARTERLY;
  const priceToShow = isQuerterly ? basePrice / 3 : shouldShowStrikethroughPrice ? basePrice / 12 : basePrice;
  const strikethroughPrice =
    shouldShowStrikethroughPrice && compareToPlanPrice ? (compareToPlanPrice.price || 0) * comparisonPriceFactor : null;

  const cycleText = isQuerterly
    ? t('subscription.perMonthQuarterly')
    : shouldShowStrikethroughPrice
    ? t('subscription.perMonthYearly')
    : t('subscription.perMonth');

  return (
    <div className={clsxm('flex flex-col items-center gap-4', className)}>
      <div className="flex items-center justify-center gap-1">
        {typeof priceToShow === 'number' && (
          <NumberFormat
            thousandSeparator
            fixedDecimalScale
            decimalScale={2}
            className="text-xxl font-bold text-gray-800"
            value={Math.abs(priceToShow)}
            displayType="text"
            prefix={renderCurrencyAsSymbol(planPrice.planCurrency)}
          />
        )}
        {typeof strikethroughPrice === 'number' && (
          <NumberFormat
            thousandSeparator
            fixedDecimalScale
            decimalScale={2}
            className="text-m font-bold text-gray-500 line-through"
            value={Math.abs(strikethroughPrice)}
            displayType="text"
            prefix={renderCurrencyAsSymbol(planPrice.planCurrency)}
          />
        )}
        <span className="lowercase text-gray-500">/ {t('subscription.month')}</span>
      </div>
      {!shouldShowDiscount && <span className="text-gray-500">({cycleText})</span>}
    </div>
  );
};
