import { Button, Typography } from '@app/components';
import { PlanLimitOutDto } from '@app/swagger-types';
import { Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NEW_SUBSCRIPTION_PLAN_LIMIT_KEYS, SUBSCRIPTION_PLAN_LIMIT_KEYS } from '../../user.const';
import { IncludeItem } from './IncludeItem';
import { buildSubscriptionFeatureRenderer } from './subscription.utils';

export const SubscriptionPlanIncludes: React.FC<{
  isOldPlanUser: boolean;
  isShowAllFeatures: boolean;
  planLimit: PlanLimitOutDto;
  setIsShowAllFeatures: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isOldPlanUser, planLimit, isShowAllFeatures, setIsShowAllFeatures }) => {
  const { t } = useTranslation();

  const renderFeatureForPlan = buildSubscriptionFeatureRenderer({
    t18n: t,
    planToCheck: planLimit,
  });

  if (isOldPlanUser) {
    return (
      <>
        <div className="mx-5 flex flex-col gap-3">
          <Typography variant="m" className="font-semibold text-gray-800">
            {t('subscription.includes')}:
          </Typography>
          <IncludeItem isAvailable title={t('subscription.feature.maxScans')} value={planLimit.amountMaxScans || '∞'} />

          {SUBSCRIPTION_PLAN_LIMIT_KEYS.slice(0, 2).map(renderFeatureForPlan)}
          <Collapse in={isShowAllFeatures}>
            <div className="flex flex-col gap-3">{SUBSCRIPTION_PLAN_LIMIT_KEYS.slice(2).map(renderFeatureForPlan)}</div>
          </Collapse>
        </div>
        <Button
          className="mx-[2px] mb-[2px] rounded-[11px] rounded-t-none border-gray-100 bg-gray-100"
          color="secondary"
          size="large"
          variant="outlined"
          onClick={() => {
            setIsShowAllFeatures((prev) => !prev);
          }}
        >
          {!isShowAllFeatures ? t('subscription.seeAllFeatures') : t('subscription.seeLessFeatures')}
        </Button>
      </>
    );
  }

  return (
    <>
      <div className="mx-5 flex flex-col gap-3">
        <Typography variant="m" className="font-semibold text-gray-800">
          {t('subscription.includes')}:
        </Typography>
        {NEW_SUBSCRIPTION_PLAN_LIMIT_KEYS.map(renderFeatureForPlan)}
      </div>
    </>
  );
};
