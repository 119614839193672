import { CurrencySelector } from '@app/domain/subscription/components/CurrencySelector';
import { UIStyledSection } from '../../components/UIStyledSection';
import { ECurrency } from '@app/swagger-types';
import { useState } from 'react';

export const UISectionCurrencySelector: React.FC = () => {
  const [currency, setCurrency] = useState(ECurrency.EUR);

  return (
    <UIStyledSection title="CurrencySelector">
      <div className="inline-flex gap-4">
        <CurrencySelector value={currency} onChange={(v) => setCurrency(v)} />
        <CurrencySelector value={currency} onChange={(v) => setCurrency(v)} short />
      </div>
    </UIStyledSection>
  );
};
