import { PdfFilledIcon, UploadIcon } from '@app/assets';
import { Container, IconButton, Typography } from '@app/components';
import { FileDropZoneWrapper } from '@app/components/file-drop-zone-wrapper/FileDropZoneWrapper';
import { clsxm } from '@app/styles/clsxm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { friendlyByteSize, uploadFile } from '@app/utils/file.utils';
import { toast } from 'react-toastify';
import { OPENABLE_EXTENSION_TO_MIME } from '@app/constants/file-uploads';
import { useMutation } from 'react-query';
import { CircularProgress } from '@mui/material';
import { QRCodeFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { useFormContext } from 'react-hook-form';

const ACCEPT = OPENABLE_EXTENSION_TO_MIME.pdf;
const MAX_FILE_SIZE_MB = 15 * 1024 * 1024;

export const QRCodePdf: React.FC<{
  forWidgetMode?: boolean;
}> = ({ forWidgetMode }) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<QRCodeFormSchema>();
  const fileId = watch('pdf.fileId');
  const fileDto = watch('pdf.file');

  console.log(errors.pdf);
  const hasNoPdfError = errors.pdf && !fileId;
  const [file, setFile] = useState<File | null>(null);

  const { isLoading, mutate } = useMutation({
    mutationFn: async (f: File) => uploadFile({ file: f, isPublic: true, unauthorized: forWidgetMode }),
    onSuccess: (data) => {
      setValue('pdf.fileId', data.id);
      setValue('pdf.file', data);
    },
  });

  const renderContent = () => {
    if (!fileId && !isLoading) {
      return (
        <FileDropZoneWrapper
          accept={ACCEPT}
          className={clsxm(
            'flex cursor-pointer items-center justify-center gap-1 rounded-xl border border-gray-200 bg-gray-100 p-2 py-6 text-gray-500',
            'hover:bg-gray-100',
            hasNoPdfError && 'ring-1 ring-red-400'
          )}
          onChange={(f) => {
            if (f.size > MAX_FILE_SIZE_MB) {
              toast.warn(t('error.maxFileSize'));
              return;
            }
            if (f.type !== ACCEPT) {
              toast.warn(t('error.onlyPdfFile'));
              return;
            }
            setFile(f);
            mutate(f);
          }}
        >
          <IconButton variant="transparent">
            <UploadIcon />
          </IconButton>
          {t('qr.pdfInput.dragPdfOr')}
          <span className="font-bold text-accept-main">{t('qr.pdfInput.browse')}</span>
        </FileDropZoneWrapper>
      );
    }

    return (
      <div
        className={clsxm(
          'flex items-center justify-between gap-1 rounded-xl border border-gray-200 bg-gray-100 p-2 py-4 text-gray-500'
        )}
      >
        <div className="flex gap-1">
          <PdfFilledIcon />
          {fileDto && (
            <div className="flex flex-col">
              <div className="text-xs text-gray-800">{fileDto.fileName}</div>
              {/* TODO show size when API ready */}
              {/* <div className="text-xs">{friendlyByteSize(fileDto.size)}</div> */}
              {file && file.name === fileDto.fileName && <div className="text-xs">{friendlyByteSize(file.size)}</div>}
            </div>
          )}
          {(!fileDto || isLoading) && <CircularProgress />}
        </div>
        <IconButton
          variant="transparent"
          onClick={() => {
            setFile(null);
            setValue('pdf.fileId', '');
            setValue('pdf.file', null);
          }}
        >
          <Close />
        </IconButton>
      </div>
    );
  };

  return (
    <Container className="flex flex-col gap-3 sm:gap-4 xl:rounded-xl">
      <Typography className="font-bold sm:text-l">{t('qr.pdfInput.content')}</Typography>
      {renderContent()}
      {hasNoPdfError && (
        <div className="rounded-xl bg-red-100 p-2 text-center text-xs text-red-500">{t('error.pdfFileRequired')}</div>
      )}
      <div className="rounded-xl bg-gray-100 p-2 text-center text-xs text-gray-500">{t('qr.pdfInput.hint')}</div>
    </Container>
  );
};
