import { Routes } from '@app/constants/routes';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { TypedLink } from '@app/router';
import { FIRST_DAY_DISCOUNT_AVAILABLE_FOR_MS, ONE_DAY_IN_MS } from './discount/first-day-discount.constant';
import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { PermissionCrown } from '../plan/components/permission-crown/PermissionCrown';
import { useTranslation } from 'react-i18next';
import { IS_NOT_PROD_ENV, IS_PROD_ENV } from '@app/environment/typed-env';
import { FiRrCrossSmall } from '@app/assets';
import { useUpdateCustomer } from '../user/api/user.hooks.api';

// TODO remove after testing or make part of dev kit config
const DEV_SHOW_MOCK_FIRST_DAY_DISCOUNT = IS_NOT_PROD_ENV && true;
const DEV_SHORT_DELAY_MS = 5e3;

export const BuySubscriptionBanner: React.FC = () => {
  const { t } = useTranslation();
  const isFirstDayDiscountAvailable = useIsFirstDayDiscountAvailable();
  const { currentUser } = useAuthorizedUser();
  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();
  const { isFreePlan } = useActivePlan();

  if (!isFreePlan) {
    return null;
  }

  if (currentUser?.upsellTopBannerHiddenDate) {
    try {
      const hiddenDate = new Date(currentUser.upsellTopBannerHiddenDate);
      const delta = Date.now() - hiddenDate.getTime();
      if (delta < (IS_PROD_ENV ? ONE_DAY_IN_MS : DEV_SHORT_DELAY_MS)) {
        return null;
      }
    } catch {}
  }

  return (
    <div className="flex items-start justify-between bg-[#DFE6ED] text-zinc-700 px-2 py-4 md:gap-4 md:px-6 text-s mb-4">
      <div />
      <div className="">
        <PermissionCrown className="inline-block w-5 h-5 align-top" />{' '}
        <span>
          {isFirstDayDiscountAvailable
            ? t('subscription.globalBanner.discountDescription')
            : t('subscription.globalBanner.regularDescription')}
        </span>{' '}
        <TypedLink to={Routes.customer.account.subscription} className="underline font-bold text-nowrap">
          {t('subscription.globalBanner.buttonGetStarted')}
        </TypedLink>
      </div>
      <button
        disabled={isLoading}
        className="cursor-pointer disabled:cursor-not-allowed hover:bg-white rounded"
        onClick={() => {
          updateCustomer({
            upsellTopBannerHiddenDate: new Date().toJSON(),
          });
        }}
      >
        <FiRrCrossSmall />
      </button>
    </div>
  );
};

export const useIsFirstDayDiscountAvailable = () => {
  const { currentUser } = useAuthorizedUser();
  const { isFreePlan } = useActivePlan();
  if (DEV_SHOW_MOCK_FIRST_DAY_DISCOUNT) {
    return true;
  }
  if (!isFreePlan) {
    return false;
  }
  const checkIsTooLate = () => {
    if (!currentUser) {
      return true;
    }
    try {
      const date = new Date(currentUser.createdDate);
      return Date.now() > date.getTime() + FIRST_DAY_DISCOUNT_AVAILABLE_FOR_MS;
    } catch (error) {
      console.error(error);
    }
    return true;
  };
  const isTooLate = checkIsTooLate();
  if (isTooLate) {
    return false;
  }
  return true;
};
