import './i18n/i18n-side-effect';
import './index-gtm-setup';

import { camelCase } from 'lodash';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/utils';

import App from './App';
import '@app/styles/index.scss';
import { dirtyInjectGlobalStylesWorkaround } from './styles/workaround/dirtyInjectGlobalStylesWorkaround';

ClassNameGenerator.configure((componentName) => camelCase(componentName.replace('Mui', '')));

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={Fallback}>
      <Suspense fallback={<div className="h-full text-center">loading...</div>}>
        <App />
      </Suspense>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

dirtyInjectGlobalStylesWorkaround();

function Fallback({ error }: FallbackProps) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Oops, something went wrong:</p>
      <pre style={{ color: 'red' }}>{error?.message}</pre>
    </div>
  );
}
