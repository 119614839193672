import { useTranslation } from 'react-i18next';
import { Form, Typography } from '@app/components';
import { FormStepper } from '@app/components/stepper/FormStepper';
import { enumValues } from '@app/utils/assertion.utils';
import { TranslationKey } from '@app/locales/defaultTranslation';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { CancellationFormSchema, TypeCancellationFormSchema } from '../api/subscription.schema';
import { Button } from '@app/components';
import { useHandler } from '@app/hooks/useHandler.hook';
import { BackButton } from '@app/components/buttons/back-button/BackButton';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { Routes } from '@app/constants/routes';
import { useTypedNavigate } from '@app/router';
import { SubscriptionCancelGeneralContainer } from './SubscriptionCancelGeneralContainer';
import { SubscriptionCancelFeedbackContainer } from './SubscriptionCancelFeedbackContainer';
import { clsxm } from '@app/styles/clsxm';
import { useMutation } from 'react-query';
import { SubscriptionApi } from '../api/subscription.api';
import { SubscriptionCancelFeedbackInDto } from '@app/swagger-types';
import { NullablePartial } from '@app/utils/type.utils';

enum ECancelPlanFormStep {
  GENERAL = 'GENERAL',
  FEEDBACK = 'FEEDBACK',
}

const defaultValues: TypeCancellationFormSchema = {
  primaryReason: '',
  featuresMissingOrImprove: null,
  howToKeepYouAsCustomer: null,
  commentsOrSuggestions: null,
};

export type ValuesKeys = keyof typeof defaultValues;

export const SubscriptionCancelPlanForm: React.FC = () => {
  const { t } = useTranslation();
  const CancelPlanFormSteps = enumValues(ECancelPlanFormStep);
  const [completedSteps, setCompletedSteps] = useState<ECancelPlanFormStep[]>([]);
  const [activeStep, setActiveStep] = useState<ECancelPlanFormStep>(() => CancelPlanFormSteps[0]);
  const canSaveForm = true;
  const [specifyValues, setSpecifyValues] = useState<TypeCancellationFormSchema>(defaultValues);

  const { plan } = useActivePlan();
  const navigate = useTypedNavigate();

  const cancelPlanMutation = useMutation(
    async (choices: NullablePartial<SubscriptionCancelFeedbackInDto>) => {
      return await SubscriptionApi.unsubscribeCurrentPlan(choices);
    },
    {
      onSuccess: () => {
        window.location.href = Routes.customer.account.subscription;
      },
    }
  );

  const formMethods = useForm<TypeCancellationFormSchema>({
    resolver: yupResolver(CancellationFormSchema),
    defaultValues,
  });

  const { control, handleSubmit, getValues } = formMethods;

  const getStepTitleKey = (step: ECancelPlanFormStep): TranslationKey => {
    const stepTitles: Record<ECancelPlanFormStep, TranslationKey> = {
      [ECancelPlanFormStep.GENERAL]: 'subscription.cancellation.stepGeneral.stepTitle',
      [ECancelPlanFormStep.FEEDBACK]: 'subscription.cancellation.stepFeedback.stepTitle',
    };

    if (stepTitles[step]) {
      return stepTitles[step];
    }

    throw new Error(`Unknown step type: ${step}`);
  };

  const renderActiveStep = useMemo(() => {
    switch (activeStep) {
      case ECancelPlanFormStep.GENERAL:
        return <SubscriptionCancelGeneralContainer />;
      case ECancelPlanFormStep.FEEDBACK:
        return (
          <SubscriptionCancelFeedbackContainer specifyValues={specifyValues} setSpecifyValues={setSpecifyValues} />
        );
      default:
        return null;
    }
  }, [activeStep, specifyValues]);

  const handlePrevStep = () => {
    const currentStepIndex = CancelPlanFormSteps.findIndex((s) => s === activeStep);

    if (currentStepIndex > 0) {
      setActiveStep(CancelPlanFormSteps[currentStepIndex - 1]);
    }
  };
  const handleNextStep = () => {
    const currentStepIndex = CancelPlanFormSteps.findIndex((s) => s === activeStep);

    if (currentStepIndex < CancelPlanFormSteps.length - 1) {
      setActiveStep(CancelPlanFormSteps[currentStepIndex + 1]);
    }
  };

  const onChangeActiveStep = useHandler(async (step: ECancelPlanFormStep) => {
    if (step === activeStep) {
      return;
    }

    const currentStepIndex = CancelPlanFormSteps.findIndex((s) => s === activeStep);

    setCompletedSteps((prev) => [...new Set([...prev, CancelPlanFormSteps[currentStepIndex]])]);

    setActiveStep(step);
  });

  const prepareFormData = (): TypeCancellationFormSchema => {
    const values = getValues();
    const finalValues: TypeCancellationFormSchema = { ...values };

    if (specifyValues) {
      const keys: ValuesKeys[] = Object.keys(specifyValues).filter((k) => k !== 'cancelImmediately') as ValuesKeys[];
      keys.forEach((key) => {
        if (key && values[key] && specifyValues[key]) {
          finalValues[key] = specifyValues[key] ?? values[key];
        }
      });
    }

    return finalValues;
  };

  const onSubmit: SubmitHandler<TypeCancellationFormSchema> = useHandler(() => {
    cancelPlanMutation.mutate({ cancelImmediately: false, ...prepareFormData() });
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeStep]);

  return (
    <div className="flex-1 bg-white px-5 py-6 xl:rounded-xl">
      <div className="flex items-center justify-between mb-6 gap-4 ">
        <div className="flex items-center justify-start ">
          <BackButton
            className="text-gray-800"
            onClick={() => {
              navigate({ to: Routes.customer.account.subscription });
            }}
          />
          <Typography variant="xl" className="font-bold lg:text-xxl">
            {t('subscription.cancellation.title', { plan: plan?.name })}
          </Typography>
        </div>
      </div>
      <FormProvider {...formMethods}>
        <Form control={control} className={clsxm('flex flex-col gap-4 ')} onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-1 flex-col gap-4">
            <FormStepper<ECancelPlanFormStep>
              steps={CancelPlanFormSteps}
              onChange={onChangeActiveStep}
              completedSteps={completedSteps}
              activeStep={activeStep}
              disabled={!canSaveForm}
              getStepTitleKey={getStepTitleKey}
            />
            <div className="flex flex-1 flex-col gap-4 xl:flex-initial xl:rounded-xl mt-6">{renderActiveStep}</div>

            <div className="flex w-full gap-4 mt-10 mb-8">
              {activeStep !== ECancelPlanFormStep.GENERAL && (
                <Button size="medium" variant="outlined" className="w-full max-w-56" onClick={handlePrevStep}>
                  {t('subscription.cancellation.buttonBack')}
                </Button>
              )}

              {activeStep === ECancelPlanFormStep.FEEDBACK && (
                <Button
                  size="medium"
                  color="primary"
                  className="w-full max-w-56 ml-auto"
                  type="submit"
                  loading={cancelPlanMutation.isLoading}
                >
                  {t('subscription.cancellation.buttonConfirm')}
                </Button>
              )}

              {activeStep !== ECancelPlanFormStep.FEEDBACK && (
                <Button size="medium" color="primary" className="w-full max-w-56 ml-auto" onClick={handleNextStep}>
                  {t('subscription.cancellation.buttonContinue')}
                </Button>
              )}
            </div>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};
