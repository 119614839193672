import { clsxm } from '@app/styles/clsxm';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  MeasuringStrategy,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { PropsWithChildren, useCallback } from 'react';
import { ImageItem, ImageItemType, NO_LOGO_ID } from '../image-item/ImageItem';
import { WIDGET_LOGO_OPTION_APP_LOGO } from '@app/qr-widget/widget.constants';
import { AppLogo } from '@app/assets';

interface ImageCollectionProps {
  items: ImageItemType[];
  loading?: boolean;
  progressState: Record<string, number>;
  onDragEnd?: (items: ImageItemType[]) => void;
  onDeleteEnd: (imageItem: ImageItemType) => Promise<void>;
  onClickImage?: (image: ImageItemType) => void;
  disabled?: boolean;
  selectedImageId?: string | null;
  withNoImg?: boolean;
  withAppLogoImg?: boolean;
  noImgText?: string;
  forWidgetMode?: boolean;
}

export const ImageCollection = ({
  items,
  progressState,
  loading,
  onDragEnd,
  onDeleteEnd,
  children,
  disabled,
  onClickImage,
  selectedImageId,
  withNoImg,
  withAppLogoImg,
  noImgText,
  forWidgetMode,
}: PropsWithChildren<ImageCollectionProps>) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    })
  );

  const handleDragEnd = useCallback(
    (e) => {
      const { active, over } = e;
      if (active.id !== over.id) {
        const oldIndex = items.findIndex((item) => active.id === item.id);
        const newIndex = items.findIndex((item) => over.id === item.id);
        onDragEnd && onDragEnd(arrayMove(items, oldIndex, newIndex));
      }
    },
    [items, onDragEnd]
  );

  return (
    <DndContext
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <div className="w-full @container">
          <div
            className={clsxm(
              'grid grid-cols-3 gap-4',
              '@sm:grid-cols-4 @lg:grid-cols-5',
              disabled && 'pointer-events-none'
            )}
          >
            {withNoImg && (
              <ImageItem
                disabled={loading}
                onClick={onClickImage}
                img={{ id: NO_LOGO_ID }}
                selected={selectedImageId === NO_LOGO_ID}
                onDeleteEnd={onDeleteEnd}
                noImgText={noImgText}
              />
            )}
            {withAppLogoImg && (
              <div
                className={clsxm(
                  'flex cursor-pointer items-center justify-center rounded-lg bg-gray-100 p-2',
                  'border-[1.5px] border-transparent',
                  selectedImageId === WIDGET_LOGO_OPTION_APP_LOGO && 'border-accept-main'
                )}
                onClick={() => {
                  onClickImage?.({ id: WIDGET_LOGO_OPTION_APP_LOGO });
                }}
                aria-hidden
              >
                <AppLogo />
              </div>
            )}
            {items.map((item) => {
              return (
                <ImageItem
                  key={item.id}
                  disabled={loading}
                  onClick={onClickImage}
                  img={item}
                  selected={selectedImageId === item.imageId}
                  progress={item.previewUrl ? progressState[item.previewUrl] : undefined}
                  onDeleteEnd={onDeleteEnd}
                  isPublic={forWidgetMode}
                />
              );
            })}
            {children}
          </div>
        </div>
      </SortableContext>
    </DndContext>
  );
};
