import { clsxm } from '@app/styles/clsxm';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { IS_LOCALHOST, IS_PROD_ENV } from '@app/environment/typed-env';
import { Button, IconButton } from '@app/components';
import { DarkMode, LightMode } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useUpdateCustomer } from '@app/domain/user/api/user.hooks.api';
import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { EPlanType, ERole } from '@app/swagger-types';
import { BUY_SUBSCRIPTION_BANNER_HEIGHT } from '@app/domain/subscription/BuySubscriptionBanner.constants';
import { useIsMobile } from '@app/hooks/useIsMobile.hook';
import { useShouldShowSubscriptionBanner } from '@app/domain/subscription/useShouldShowSubscriptionBanner';
import { SubscriptionApi } from '@app/domain/subscription/api/subscription.api';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';

export const DevMenu: React.FC<{
  handleChangeTheme: () => void;
  isDarkTheme: boolean;
}> = ({ handleChangeTheme, isDarkTheme }) => {
  const [visible, setVisible] = useState(true);
  const { planType } = useActivePlan();
  const [unsubscrideButtonVisible, setUnsubscrideButtonVisible] = useState(planType !== EPlanType.FREE);
  const isMobile = useIsMobile();
  const isShowSubscriptionBanner = useShouldShowSubscriptionBanner();

  useEffect(() => {
    setUnsubscrideButtonVisible(planType !== EPlanType.FREE);
  }, [planType, setUnsubscrideButtonVisible]);

  if (!IS_LOCALHOST && IS_PROD_ENV) {
    return null;
  }

  if (!visible) {
    return null;
  }

  const downloadTranslation: () => void | undefined = (window as any).downloadTranslation;

  const unsubscribeCurrentPlan = async () => {
    await SubscriptionApi.unsubscribeCurrentPlan({ cancelImmediately: true });
    setUnsubscrideButtonVisible(false);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    window.location.reload();
  };

  const ExtraOffsetStyles = {
    '--extra-offset': `calc(0.75rem + ${BUY_SUBSCRIPTION_BANNER_HEIGHT}px)`,
  };

  return (
    <div
      className={clsxm(
        'group absolute left-1/2 z-[2000]',
        !isMobile && isShowSubscriptionBanner ? 'top-[var(--extra-offset)]' : 'top-3'
      )}
      style={ExtraOffsetStyles}
    >
      {' '}
      {IS_LOCALHOST && IS_PROD_ENV && (
        <div className="fixed left-0 top-0 w-full rounded-b bg-orange-400 p-2 px-8 text-center font-bold text-orange-900 md:left-80 md:w-auto">
          ⚠️ PROD ENVIRONMENT ⚠️
        </div>
      )}
      <div
        className={clsxm(
          'flex-col gap-4',
          'group-hover:flex',
          'absolute -right-2 -top-2 hidden rounded bg-zinc-600/80 px-6 pt-16 pb-4'
        )}
      >
        <TypedLink
          to={Routes.uiKit.index}
          className="block rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary"
        >
          UI Kit
        </TypedLink>
        <TypedLink
          to={Routes.devKit.index}
          className="block rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary"
        >
          Dev Kit
        </TypedLink>
        <TypedLink to={Routes.index} className="block rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary">
          Home
        </TypedLink>
        <TypedLink to={Routes.widget} className="block rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary">
          Widget
        </TypedLink>
        {unsubscrideButtonVisible && (
          <Button variant="outlined" size="small" onClick={unsubscribeCurrentPlan}>
            Cancel plan immediately
          </Button>
        )}
        <Button variant="outlined" size="small" onClick={() => setVisible(false)}>
          Hide This
        </Button>
        {downloadTranslation && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              downloadTranslation();
            }}
          >
            Download Translation
          </Button>
        )}
        <ResetOnboardingButton />
      </div>
      <IconButton
        onClick={handleChangeTheme}
        className={clsxm(isDarkTheme ? 'bg-zinc-700' : 'bg-white', 'rounded-full')}
      >
        {isDarkTheme ? <DarkMode /> : <LightMode />}
      </IconButton>
    </div>
  );
};

const ResetOnboardingButton: React.FC = () => {
  const { currentUser } = useAuthorizedUser();
  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();

  if (currentUser?.role !== ERole.ROLE_CUSTOMER) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={() => {
        updateCustomer({
          customerSegment: null,
        });
      }}
      loading={isLoading}
    >
      Reset Onboarding
    </Button>
  );
};
