import { FiRrArrowSmallRight } from '@app/assets';
import { Button, Container, Typography } from '@app/components';
import { Routes } from '@app/constants/routes';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { SubscriptionApi } from '@app/domain/subscription/api/subscription.api';
import { useIsBlackFridayAvailable } from '@app/modules/customer/components/BlackFridayBanner';
import { clsxm } from '@app/styles/clsxm';
import {
  ECurrency,
  EPlanCycle,
  EPlanStatus,
  EPlanType,
  ECustomerGen,
  PlanOutDto,
  PriceOutDto,
} from '@app/swagger-types';
import { Divider } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SubscriptionPlanIncludes } from './SubscriptionPlanIncludes';
import { SubscriptionPriceContainer } from './SubscriptionPriceContainer';
import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { useFirstDayDiscountAvailable } from '@app/domain/subscription/hooks/useFirstDayDiscountAvailable';
import NumberFormat from 'react-number-format';
import { renderCurrencyAsSymbol } from '@app/utils/currency.util';
import { DISCOUNTED_MULTIPLIER } from '@app/domain/subscription/constants';
import { SubscriptionPlanHeaderLimits } from './SubscriptionPlanHeaderLimits';

export const SubscriptionPlanContainer: React.FC<{
  plan: PlanOutDto;
  selectedPlanCycle: EPlanCycle;
  selectedCurrency: ECurrency;
  isShowAllFeatures: boolean;
  setIsShowAllFeatures: React.Dispatch<React.SetStateAction<boolean>>;
  askToDowngradeToFreePlan: () => void;
  shouldAllowDowngradeToFreePlan: boolean;
}> = ({
  plan,
  selectedPlanCycle,
  selectedCurrency,
  isShowAllFeatures,
  setIsShowAllFeatures,
  askToDowngradeToFreePlan,
  shouldAllowDowngradeToFreePlan,
}) => {
  const { t } = useTranslation();
  const {
    plan: activePlan,
    planDetails,
    planType: activePlanType,
    isExpired,
    planStatus,
    scheduledUpdate,
    isPlanCanceledStillActive,
  } = useActivePlan();
  const { isOldPlanUser, currentUser } = useAuthorizedUser();
  const monthlyPlanPrice = plan.prices.find((p) => p.planCycle === EPlanCycle.MONTHLY);
  const quarterlyPlanPrice = plan.prices.find((p) => p.planCycle === EPlanCycle.QUARTERLY);
  const planPrice = plan.prices.find((p) => p.planCycle === selectedPlanCycle);
  const isFreePlan = plan.type === EPlanType.FREE;
  const isProPlan = plan.type === EPlanType.PRO;
  const isBusinessPlan = plan.type === EPlanType.BUSINESS;

  const isBlackFridayAvailable = useIsBlackFridayAvailable();
  const subscribeMutation = useMutation({
    mutationFn: async () => {
      if (!planPrice) {
        return;
      }
      const res = await SubscriptionApi.subscribeViaStripeGateway({
        planId: plan.id,
        planCycle: selectedPlanCycle,
        currency: planPrice.planCurrency,
        successRedirectURL: `${window.location.origin}${Routes.customer.success_payment}`,
        // TODO dedicated page for error from stripe
        errorRedirectURL: window.location.href,
        allowPromotionCodes: isBlackFridayAvailable,
      });
      return res;
    },
  });
  const resubscribeMutation = useMutation(
    async () => {
      return await SubscriptionApi.resubscribeCurrentPlan();
    },
    {
      onSuccess: () => {
        window.location.reload();
      },
    }
  );

  // console.log('plan', activePlan, plan);
  const isCurrentPlanAndFree = !isOldPlanUser && !activePlan && plan.type === EPlanType.FREE;
  const checkIsCurrentPlan = () => {
    if (isOldPlanUser) {
      return plan.id === activePlan?.id && planDetails?.planType !== EPlanType.TRIAL;
    }
    return isCurrentPlanAndFree || plan.id === activePlan?.id;
  };
  const isCurrentPlan = checkIsCurrentPlan();

  // TODO after release
  // const disabledReason = '';
  let isButtonDisabled =
    Boolean(scheduledUpdate) ||
    (isFreePlan ? !shouldAllowDowngradeToFreePlan : !planPrice) ||
    subscribeMutation.isLoading ||
    resubscribeMutation.isLoading ||
    // disable button except "renew plan" while having canceled still active plan to avoid potential bugs
    (isPlanCanceledStillActive && !isCurrentPlan);
  const gatewayData = subscribeMutation.data;
  if (gatewayData) {
    isButtonDisabled = true;
    if (gatewayData.url) {
      window.location.href = gatewayData.url;
    } else {
      // case for scheduled downgrade
      window.location.reload();
    }
  }

  const activeCycle = activePlan?.price?.planCycle;
  const isCurrentCycle = planPrice ? planPrice.planCycle === activeCycle : undefined;
  const isCurrentPlanCanceledStillActive = isCurrentPlan && !isExpired && planStatus === EPlanStatus.CANCELED;
  const isCurrentPlanCanceledExpired = isCurrentPlan && isExpired && planStatus === EPlanStatus.CANCELED;
  const isPlanForUpgrade = isCurrentPlan
    ? activeCycle === EPlanCycle.MONTHLY || activeCycle === EPlanCycle.QUARTERLY
    : checkIsPlanForUpgrade({
        activePlan: {
          planType: activePlanType,
          planCycle: activeCycle,
        },
        targetPlan: {
          planType: plan.type,
          planCycle: planPrice?.planCycle,
        },
      });

  const renderButton = () => {
    const upgradeButton = (
      <Button className="mx-5" disabled={isButtonDisabled} onClick={() => subscribeMutation.mutate()}>
        {t('subscription.upgradeTo')} {plan.name}
      </Button>
    );
    if (isExpired) {
      return upgradeButton;
    }
    if (isCurrentPlan) {
      if (isCurrentPlanAndFree) {
        return (
          <Button className="mx-5" disabled>
            {t('subscription.currentPlan')}
          </Button>
        );
      }
      if (isCurrentPlanCanceledExpired) {
        return (
          <Button className="mx-5" disabled={isButtonDisabled} onClick={() => subscribeMutation.mutate()}>
            {t('subscription.upgradeTo')} {plan.name}
          </Button>
        );
      }
      if (isCurrentCycle) {
        return isCurrentPlanCanceledStillActive ? (
          <Button className="mx-5" disabled={isButtonDisabled} onClick={() => resubscribeMutation.mutate()}>
            {t('subscription.renewPlan')}
          </Button>
        ) : (
          <Button className="mx-5" disabled>
            {t('subscription.currentPlan')}
          </Button>
        );
      }
      if (isPlanForUpgrade) {
        return (
          <Button className="mx-5" disabled={isButtonDisabled} onClick={() => subscribeMutation.mutate()}>
            {t('subscription.changeToYearly')}
          </Button>
        );
      }
      const isTargetCycleQuarterly = planPrice?.planCycle === EPlanCycle.QUARTERLY;
      if (isTargetCycleQuarterly) {
        return (
          <Button className="mx-5" disabled={isButtonDisabled} onClick={() => subscribeMutation.mutate()}>
            {t('subscription.changeToQuarterly')}
          </Button>
        );
      }
      return (
        <Button className="mx-5" disabled={isButtonDisabled} onClick={() => subscribeMutation.mutate()}>
          {t('subscription.changeToMonthly')}
        </Button>
      );
    }
    if (isPlanForUpgrade) {
      return upgradeButton;
    }
    return (
      <Button
        className="mx-5"
        disabled={isButtonDisabled}
        onClick={() => {
          if (isFreePlan) {
            askToDowngradeToFreePlan();
            return;
          }
          subscribeMutation.mutate();
        }}
      >
        {t('subscription.downgradeTo')} {plan.name}
      </Button>
    );
  };

  const freePlanPrice: PriceOutDto = {
    price: 0,
    planCycle: selectedPlanCycle,
    planCurrency: selectedCurrency,
    customerGen: currentUser?.customerGen || ECustomerGen.GEN3,
  };

  /**
   * TODO refactor to fix type inference
   * currently, click on prop like `currentUser?.hasVerifiedEmail` does not navigate to UserOutSchema
   */

  const { timeLeft, isAvailable } = useFirstDayDiscountAvailable();

  const renderPlanDiscountInfo = () => {
    const diacount = ((1 - DISCOUNTED_MULTIPLIER) * 100).toFixed(0);
    const prettieredDiscount = `-${diacount}%`;
    const shouldShowStrikethroughPrice = activeCycle
      ? activeCycle === EPlanCycle.YEARLY
      : planPrice?.planCycle === EPlanCycle.YEARLY;
    const basePrice = planPrice?.price ?? 1;
    const isQuerterly = planPrice?.planCycle === EPlanCycle.QUARTERLY;
    const priceToShow = isQuerterly ? basePrice / 3 : shouldShowStrikethroughPrice ? basePrice / 12 : basePrice;
    const priceWithDiscount = +(DISCOUNTED_MULTIPLIER * priceToShow || priceToShow).toFixed(2);

    if (isProPlan || isBusinessPlan) {
      return (
        <>
          <span className="flex gap-1 text-accept-main font-semibold text-s text-center">
            {prettieredDiscount} {t('subscription.discount.common.inFirstDay')}
          </span>
          <span className="text-gray-700 font-normal text-s">{t('subscription.discount.common.priceToday')}</span>
          <span className="flex items-center gap-2 -mt-1 flex-wrap text-center justify-center">
            {typeof priceToShow === 'number' && (
              <NumberFormat
                thousandSeparator
                fixedDecimalScale
                decimalScale={2}
                className="text-gray-700 font-normal text-m"
                value={Math.abs(priceToShow)}
                displayType="text"
                prefix={renderCurrencyAsSymbol(selectedCurrency)}
              />
            )}
            <b className="text-gray-700 font-semibold text-m">{prettieredDiscount}</b>
            <FiRrArrowSmallRight />
            {typeof priceWithDiscount === 'number' && (
              <NumberFormat
                thousandSeparator
                fixedDecimalScale
                decimalScale={2}
                className="text-xxl font-bold text-accept-main"
                value={Math.abs(priceWithDiscount)}
                displayType="text"
                prefix={renderCurrencyAsSymbol(selectedCurrency)}
              />
            )}
          </span>
        </>
      );
    }

    return (
      <p className="text-center sm:mx-8">
        <Trans i18nKey={'subscription.discount.freePlan'}>
          <b />
        </Trans>
      </p>
    );
  };

  return (
    <Container
      rounded
      className={clsxm('w-full p-0 pt-6', isCurrentPlan && !isExpired && 'ring-1 ring-accept-menu-item-bg')}
    >
      <Typography className="mb-5 text-xxl px-6 text-center font-bold text-gray-800">{plan.name}</Typography>
      <Divider />
      <div className="mt-5 flex flex-col gap-5 mb-8">
        <SubscriptionPriceContainer
          planPrice={planPrice || freePlanPrice}
          compareToPlanPrice={monthlyPlanPrice || quarterlyPlanPrice}
          compareToPlanCycle={selectedPlanCycle}
          comparisonPriceFactor={isOldPlanUser ? 1 : 1 / 3}
        />

        {isAvailable && (
          <div
            className={clsxm(
              'flex flex-col items-center px-5 py-2 justify-center gap-2 rounded-xl min-h-28 bg-gray-100 mx-8',
              !isFreePlan && 'bg-accept-light'
            )}
          >
            {renderPlanDiscountInfo()}
          </div>
        )}
        <Divider />
        <SubscriptionPlanHeaderLimits planLimit={plan.planLimit} fullWidth className="px-4" />

        <SubscriptionPlanIncludes
          isOldPlanUser={isOldPlanUser}
          planLimit={plan.planLimit}
          isShowAllFeatures={isShowAllFeatures}
          setIsShowAllFeatures={setIsShowAllFeatures}
        />
        <div className="mt-1" />
        {renderButton()}
        {!isFreePlan && isAvailable && timeLeft && (
          <span className="text-center px-2 text-xs text-accept-text">
            {t('subscription.offerEndsIn')}: {timeLeft}
          </span>
        )}
      </div>
    </Container>
  );
};

const checkIsPlanForUpgrade = ({
  activePlan,
  targetPlan,
}: {
  activePlan: {
    planType?: EPlanType;
    planCycle?: EPlanCycle;
  };
  targetPlan: {
    planType?: EPlanType;
    planCycle?: EPlanCycle;
  };
}): boolean => {
  if (activePlan.planType === EPlanType.TRIAL) {
    return true;
  }
  if (activePlan.planType === EPlanType.FREE) {
    return true;
  }
  if (!activePlan.planType || !activePlan.planCycle) {
    return false;
  }
  if (!targetPlan.planType || !targetPlan.planCycle) {
    return false;
  }
  if (activePlan.planCycle === EPlanCycle.YEARLY && targetPlan.planCycle === EPlanCycle.MONTHLY) {
    return false;
  }
  if (activePlan.planCycle === EPlanCycle.YEARLY && targetPlan.planCycle === EPlanCycle.QUARTERLY) {
    return false;
  }
  const { isTargetHigher } = checkPlanTypeHierarchy(activePlan.planType, targetPlan.planType);
  return isTargetHigher;
};

const checkPlanTypeHierarchy = (
  activeType: EPlanType,
  targetType: EPlanType
): {
  areEqual: boolean;
  isTargetHigher: boolean;
  isTargetLower: boolean;
} => {
  if (activeType === EPlanType.TRIAL || targetType === EPlanType.TRIAL) {
    throw new Error('trial type is out of hierarchy');
  }
  const indexActive = PLAN_TYPE_HIERARCHY_FROM_LOW_TO_HIGH.indexOf(activeType);
  const indexTarget = PLAN_TYPE_HIERARCHY_FROM_LOW_TO_HIGH.indexOf(targetType);
  return {
    areEqual: indexActive === indexTarget,
    isTargetHigher: indexTarget > indexActive,
    isTargetLower: indexTarget < indexActive,
  };
};

const PLAN_TYPE_HIERARCHY_FROM_LOW_TO_HIGH = [
  EPlanType.BASIC,
  EPlanType.STARTER,
  EPlanType.PREMIUM,
  // ---
  EPlanType.FREE,
  EPlanType.PRO,
  EPlanType.BUSINESS,
];
