import { PaginatedResponse } from '@app/api/api.type';
import { GET_CURRENT_PLAN_CONFIG_QUERY, GET_PLANS_QUERY } from '@app/constants/query-api-configs';
import { APIQueryParams } from '@app/models/api';
import { ECurrency, PlanOutDto } from '@app/swagger-types';
import { useQuery, UseQueryOptions } from 'react-query';
import { PlanApi } from './plan.api';

export const useGetPlans = (
  params: { currency: ECurrency } & APIQueryParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<PlanOutDto>,
    (typeof GET_PLANS_QUERY.name | ({ currency: ECurrency } & APIQueryParams))[]
  >
) => {
  return useQuery(
    [GET_PLANS_QUERY.name, params],
    async () => {
      return await PlanApi.getAll(params);
    },
    {
      staleTime: GET_PLANS_QUERY.config.staleTime,
      keepPreviousData: true,
      ...options,
    }
  );
};

/**
 * TODO remove in favor of single source of plans - auth DTO
 * @deprecated use useActivePlan
 */
export const useGetCurrentPlanConfig = (
  options?: UseQueryOptions<unknown, Error, PaginatedResponse<PlanOutDto>, typeof GET_CURRENT_PLAN_CONFIG_QUERY.name[]>
) => {
  return useQuery(
    [GET_CURRENT_PLAN_CONFIG_QUERY.name],
    async () => {
      return await PlanApi.getCurrentPlan();
    },
    {
      staleTime: GET_CURRENT_PLAN_CONFIG_QUERY.config.staleTime,
      keepPreviousData: true,
      ...options,
    }
  );
};
