import { OptionItem } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { ECurrency } from '@app/swagger-types';
import { oneOfEnum } from '@app/utils/yup.utils';
import { Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const CurrencySelector: React.FC<{
  value: ECurrency;
  onChange: (newCurrency: ECurrency) => void;
  short?: boolean;
}> = ({ value, onChange, short }) => {
  const { t } = useTranslation();

  return (
    <Select
      value={value}
      displayEmpty
      onChange={(e) => {
        const rawValue = e.target.value;
        if (oneOfEnum(ECurrency).isValidSync(rawValue)) {
          onChange(rawValue);
        }
      }}
      size="small"
      inputProps={{
        className: clsxm('truncate'),
      }}
      renderValue={(value) => {
        if (!value) {
          return <span className="text-gray-500 opacity-40">{t('subscription.selectCurrency')}</span>;
        }
        if (short) {
          return <span className="font-semibold text-gray-700">{value}</span>;
        }
        return (
          <span className="text-gray-500">
            {t('subscription.currency')}: <span className="font-semibold text-gray-700">{value}</span>
          </span>
        );
      }}
    >
      {ORDERED_CURRENCIES.map((key) => {
        return (
          <OptionItem value={ECurrency[key]} key={key} className="min-h-fit">
            {ECurrency[key]}
          </OptionItem>
        );
      })}
    </Select>
  );
};

const ORDERED_CURRENCIES = [ECurrency.EUR, ECurrency.USD, ECurrency.GBP];
