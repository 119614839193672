import { QRCodeFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { QRCodeAddress } from './QRCodeAddress';
import { QRCodeCompanyDetails } from './QRCodeCompanyDetails';
import { QRCodeContactDetails } from './QRCodeContactDetails';
import { QRCodeDesign } from './QRCodeDesign';
import { QRCodePersonalInfo } from './QRCodePersonalInfo';
import { QRCodeSocialNetworks } from '../../QRCodeSocialNetworks';
import { VCardStepper } from './VCardStepper';

export const QRCodeVCard: React.FC<{
  forWidgetMode?: boolean;
}> = ({ forWidgetMode }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { trigger } = useFormContext<QRCodeFormSchema>();

  return (
    <div className="flex flex-col gap-4">
      <VCardStepper
        activeStep={activeStep}
        setActiveStep={async (nextStep) => {
          const isCanSetNextStep = await trigger('vcard', { shouldFocus: true });
          if (isCanSetNextStep) {
            setActiveStep(() => nextStep);
          }
        }}
      />

      {!activeStep ? (
        <>
          <QRCodePersonalInfo forWidgetMode={forWidgetMode} />
          <QRCodeContactDetails />
          <QRCodeCompanyDetails />
          <QRCodeAddress />
          <QRCodeSocialNetworks formPath="vcard.links" />
        </>
      ) : (
        <QRCodeDesign />
      )}
    </div>
  );
};
