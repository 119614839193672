import { Button } from '@app/components';
import { Routes } from '@app/constants/routes';
import { useTypedNavigate } from '@app/router';
import { useTranslation } from 'react-i18next';

export const ManagePlanButton: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();

  return (
    <a href={Routes.customer.account.subscription}>
      <Button
        size="small"
        variant="outlined"
        onClick={(e) => {
          e.preventDefault();
          navigate({ to: Routes.customer.account.subscription });
        }}
      >
        {t('subscription.buttonManagePlan')}
      </Button>
    </a>
  );
};
