import { QrDtoMapper } from '@app/domain/qr-code/api/qr-code.mapper';
import { QRCodePublicOutDtoSchema } from '@app/domain/qr-code/api/qr-code.schema.api';
import { apiService } from '@app/services/api-service';
import { ScanInDto } from '@app/swagger-types';

export abstract class ScanApi {
  static async scanById(qrCodeId: string, body: ScanInDto) {
    // const res = await validateAndAssert(
    //   apiService.post(`/public/scans/${qrCodeId.toUpperCase()}`, body),
    //   QRCodePublicOutDtoSchema
    // );

    interface QRCodeResponse {
      qrCode: QRCodePublicOutDtoSchema;
    }
    const ress = await apiService.post<QRCodeResponse>(`/public/scans/${qrCodeId.toUpperCase()}`, body).then((a) => a);
    const res = ress.data.qrCode;
    QrDtoMapper.mutateResponseForForm(res);
    return res;
  }
}
