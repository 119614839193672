import { Outlet, useLocation } from 'react-router-dom';
import { clsxm } from '@app/styles/clsxm';
import { LanguageSwitcher } from '@app/components';
import { HomeButton } from '@app/components/buttons/home-button/HomeButton';
import { Routes } from '@app/constants/routes';

export const AuthModule = () => {
  const r = useLocation();

  const isExpiredPage = r.pathname.startsWith(Routes.authSpecial.expired_qr);
  const isClaimPage = r.pathname.startsWith(Routes.authSpecial.claim_qr);
  const isSignUpOrSignInPage = r.pathname.startsWith(Routes.auth.sign_in) || r.pathname.startsWith(Routes.auth.sign_up);

  return (
    <div
      className={clsxm(
        'flex min-h-screen flex-col px-6 py-10 sm:px-[75px] sm:py-12',
        isExpiredPage ? 'bg-gray-50' : 'bg-white'
      )}
    >
      <div className="z-20 flex justify-between gap-4">
        <HomeButton />
        <LanguageSwitcher />
      </div>
      <div className={clsxm('z-10 flex flex-1 basis-full justify-center py-16', 'sm:items-center md:basis-[500px]')}>
        <div
          className={clsxm(
            'w-full',
            isSignUpOrSignInPage ? 'max-w-4xl' : isExpiredPage ? 'max-w-4xl' : isClaimPage ? 'max-w-lg' : 'max-w-sm'
          )}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
