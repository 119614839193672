export const translationForWidgetKeys = {
  widget: {
    scanTracking: 'Scan Tracking',
    createBulk: 'Create Bulk',
    design: {
      frame: 'Frame',
      shapeAndColor: 'Shape & Color',
      logo: 'Logo',
    },
    signUpDialog: {
      // TODO remove after release
      createAccountToContinue: 'Create an Account to continue',
      buttonCreateAccount: 'Create an Account',
      buttonFreeSignUp: 'Sign up for free',
      buttonContinueWithEmail: 'Continue with Email',
      emailFlow: {
        titleContinueWithEmail: 'Continue with Email',
        description: `We'll check if you have an account and help create one if you don't`,
        emailLabel: 'Email (personal or work)',
      },
      // TODO remove after release
      multilink: {
        header: 'Multilink',
        description: 'Create multiple links on one page',
        benefit1: 'Always adjustable',
        benefit2: 'Scan tracking',
        benefit3: 'Design & Customize',
      },
      // TODO remove after release
      pdf: {
        header: 'PDF',
        description: 'Open PDF document after code scanning',
        benefit1: 'Always adjustable',
        benefit2: 'Scan tracking',
        benefit3: 'Design & Customize',
      },
      // TODO remove after release
      vCard: {
        header: 'The Online Business Card',
        description: 'Make your online business card a success with a vCard Plus QR',
        benefit1: 'Always adjustable',
        benefit2: 'Scan tracking',
        benefit3: 'Design & Customize',
      },
      frames: {
        header: 'Attract more scans with <1>frames</1>',
        description: 'By adding our call-to-action frames, you can urge your audience to take an action such as:',
        benefit1: 'Scan Me',
        benefit2: 'View PDF',
        benefit3: 'Rate Us',
      },
      shapes: {
        header: 'Stay fresh with different <1>shapes & colors</1>',
        description: 'You can modify the shapes of the QR Code to make it unique by using:',
        benefit1: 'Increase QR scans',
        benefit2: 'Unique QR code',
        benefit3: 'Design & Customize',
      },
      // TODO remove after release
      logos: {
        header: 'Spotlight for your <1>logo</1>',
        description:
          'Make your brand instantly recognizable as your company logo becomes the center of attention on your QR Code',
        benefit1: 'Increase QR scans',
        benefit2: 'Unique QR code',
        benefit3: 'Design & Customize',
      },
      scans: {
        header: 'Track your QR code scans',
        description: 'Gain insights into which QR codes are successful',
        benefit1: 'Up to date',
        benefit2: 'Advanced filters',
        benefit3: 'Clear',
      },
      bulk: {
        header: 'Create bulk',
        description: 'Create multiple QR codes at once',
        benefit1: 'Download CSV sample',
        benefit2: 'Edit and upload new CSV',
        benefit3: 'All your QR codes in one place',
      },
    },
    downloadDialog: {
      pleaseDoNotRefreshOrExit: 'Please do not refresh or exit',
      clickHere: 'Click here',
      ifDownloadNotStartedAutomatically: 'if download not started automatically',
      downloadingReadyInSeconds_one: 'Downloading.. Ready in {{count}} second.',
      downloadingReadyInSeconds_other: 'Downloading.. Ready in {{count}} seconds.',
      title: `Don't lose your QR code and save it`,
      benefit1: 'QR remains yours',
      benefit2: 'Always adaptable',
      benefit3: 'All your QR codes in one place',
      benefit4: 'See who scans your QR',
      benefit5: 'Add your logo in QR codes',
      // TODO remove after release
      saveForFree: 'Save For Free',
    },
    download: 'Download',
    buttonNext: 'Next',
    buttonBack: 'Back',
    buttonContinue: 'Continue',
  },
} as const;
