import { IS_NOT_PROD_ENV } from '@app/environment/typed-env';
import type { ParseKeys } from 'i18next';
import { translationForCommonKeys } from './common.translation';
import { translationForDomainKeys } from './domain.translation';
import { translationForPagesKeys } from './pages.translation';
import { translationForWidgetKeys } from './widget.translation';
import { devKitTranslationGetPrefix } from '@app/modules/dev-kit/pages/section/TranslationDevKitSection';

/**
 * useful docs links:
 * plurals - https://www.i18next.com/translation-function/plurals
 */

export const defaultTranslation = {
  ...translationForCommonKeys,
  ...translationForPagesKeys,
  ...translationForWidgetKeys,
  ...translationForDomainKeys,
} as const;

export type TranslationKey = ParseKeys;

// validate keys defined uniquely
if (
  [translationForCommonKeys, translationForPagesKeys, translationForWidgetKeys, translationForDomainKeys].flatMap((e) =>
    Object.keys(e)
  ).length !== Object.keys(defaultTranslation).length
) {
  console.error('Translation keys conflicts. Some keys duplicated.');
  console.error('Fix translation keys conflicts to avoid invalid translations.');
}

// for better DX
const LOCAL_PREFIX = devKitTranslationGetPrefix();

const calcTotalKeys = (obj: object) => {
  const keys = Object.keys(obj);
  let sum = 0;
  keys.forEach((k) => {
    const val: unknown = obj[k as keyof typeof obj];
    if (val === null) {
      console.error('invalid translation value found:', k, obj, val);
      return;
    }
    if (typeof val === 'object') {
      sum += calcTotalKeys(val);
    } else if (typeof val === 'string') {
      if (IS_NOT_PROD_ENV) {
        if (!val.startsWith(LOCAL_PREFIX)) {
          (obj[k as keyof typeof obj] as any) = `${LOCAL_PREFIX}${val}`;
        }
      }
      sum++;
    } else {
      console.error('invalid translation value found:', k, obj, val);
    }
  });
  return sum;
};

if (IS_NOT_PROD_ENV && 'structuredClone' in window) {
  // convenienve for translations update (import) to locize
  const originalTranslation = structuredClone(defaultTranslation);
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(originalTranslation))}`;
  const download = () => {
    const link = document.createElement('a');
    link.download = 'translation.json';
    link.href = dataStr;
    link.click();
  };
  Object.assign(window, { translation: originalTranslation, downloadTranslation: download });
  const NEED_LOGS = false;
  if (NEED_LOGS) {
    console.log(originalTranslation, 'total translation keys:', calcTotalKeys(defaultTranslation));
  }
  // console.log('import latest translation to locize via next commands:');
  // console.log('copy(JSON.stringify(window.translation, null, 2));');
  // console.log('OR');
  // console.log('window.downloadTranslation();');
}
