import { TranslationKey } from '@app/locales/defaultTranslation';
import { FormStep } from './FormStep';

export type StepType = string;

type FormStepperProps<T> = {
  steps: T[];
  activeStep: T;
  completedSteps: T[];
  onChange?: (step: T) => void;
  disabled?: boolean;
  getStepTitleKey: (step: T) => TranslationKey;
  /**
   * @deprecated no needed for widget?
   */
  forWidgetMode?: boolean;
};

export const FormStepper = <T extends StepType>({
  steps,
  activeStep,
  completedSteps,
  onChange,
  disabled,
  getStepTitleKey,
  forWidgetMode,
}: FormStepperProps<T>) => {
  return (
    <div className="mr-[2px] flex sm:mr-0">
      {steps.map((step, idx) => (
        <FormStep
          key={step}
          step={step}
          steps={steps}
          index={idx + 1}
          isCompleted={completedSteps.includes(step)}
          isActive={activeStep === step}
          onChange={disabled ? undefined : onChange}
          forWidgetMode={forWidgetMode}
          getStepTitleKey={getStepTitleKey}
        />
      ))}
    </div>
  );
};
