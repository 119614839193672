import { LibraryImage } from '@app/domain/library/components/LibraryImage';
import { SOCIAL_NETWORKS } from '@app/domain/qr-code/components/QRCodeSocialNetworks';
import { clsxm } from '@app/styles/clsxm';
import { ELinkType } from '@app/swagger-types';
import styled from '@emotion/styled';
import { renderFriendlyLinkType } from '../../scan/scan.utils';
import { regexUrlWithProtocol } from '../api/qr-code.form.schema.api';

export type AnyLink = {
  url?: string;
  linkType: ELinkType;
  title?: string;
  libraryId?: string;
  library?: {
    id?: string;
  } | null;
};

export const QRCodeSocialLinks: React.FC<{
  links?: AnyLink[] | null;
  showDemoLinks?: boolean;
  className?: string;
  demoLinks?: AnyLink[];
  style?: {
    buttonBgColor: string;
    buttonTextColor: string;
    buttonHoverColor: string;
    buttonBorderColor: string;
  };
}> = ({ links, showDemoLinks, demoLinks, className, style }) => {
  const visibleLinks = showDemoLinks ? (demoLinks ? demoLinks : DEMO_LINKS) : links;
  return (
    <div
      className={clsxm('mb-6 flex max-h-full flex-col gap-2 overflow-auto', className, showDemoLinks && 'grayscale')}
    >
      {visibleLinks?.map((link, index) => {
        const socialIcon = SOCIAL_NETWORKS.find((st) => st.linkType === link.linkType)?.icon;
        const url = link.url;
        const isMock = !url;
        const protocoledUrl = url && regexUrlWithProtocol.test(url) ? url : `https://${url}`;
        return (
          <HoverableLink
            href={protocoledUrl}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              if (showDemoLinks || isMock) {
                return;
              }
              window.open(protocoledUrl, '_blank');
            }}
            key={`${link.linkType}_${index}`}
            className={clsxm(
              'flex items-center gap-3 rounded-xl border border-gray-200 bg-white p-1',
              !showDemoLinks && 'hover:cursor-pointer',
              !style?.buttonHoverColor && 'hover:bg-accept-light'
            )}
            hoverColor={style?.buttonHoverColor}
            style={
              style
                ? {
                    background: style.buttonBgColor,
                    borderColor: style.buttonBorderColor,
                  }
                : undefined
            }
            aria-hidden
          >
            <div
              className={clsxm(
                'relative h-[34px] w-[34px] min-w-[34px] overflow-hidden rounded-[10px] child:h-full child:w-full'
              )}
            >
              {socialIcon || <LibraryImage libraryId={link.libraryId} />}
            </div>

            <div
              className="text-xs font-medium text-accept-dark-blue"
              style={
                style
                  ? {
                      color: style.buttonTextColor,
                    }
                  : undefined
              }
            >
              {showDemoLinks && !demoLinks ? `Link ${index + 1}` : renderFriendlyLinkType(link.linkType, link.title)}
            </div>
          </HoverableLink>
        );
      })}
    </div>
  );
};

const DEMO_LINKS: AnyLink[] = [
  { linkType: ELinkType.CUSTOM },
  { linkType: ELinkType.CUSTOM },
  { linkType: ELinkType.INSTAGRAM },
  { linkType: ELinkType.YOUTUBE },
];

const HoverableLink = styled.a<{
  hoverColor?: string;
}>`
  &:hover {
    background: ${(p) => p.hoverColor || 'transparent'} !important;
  }
`;
