import { Button } from '@app/components';
import { ButtonProps } from '@app/components/buttons/button/Button';
import { Routes } from '@app/constants/routes';
import { useTypedNavigate } from '@app/router';
import { useTranslation } from 'react-i18next';

export const ManagePlanButton: React.FC<Omit<ButtonProps, 'onClick'> & { onCloseModal?: () => void }> = ({
  variant = 'outlined',
  onCloseModal,
  children,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();

  return (
    <a href={Routes.customer.account.subscription}>
      <Button
        size="small"
        variant={variant}
        onClick={(e) => {
          e.preventDefault();
          onCloseModal?.();
          navigate({ to: Routes.customer.account.subscription });
        }}
        {...otherProps}
      >
        {!children ? t('subscription.buttonManagePlan') : children}
      </Button>
    </a>
  );
};
