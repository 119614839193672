import { ECodeType } from '@app/swagger-types';
import { useFormContext } from 'react-hook-form';
import { QRCodeFormSchema } from '../../api/qr-code.form.schema.api';
import { QRCodeMultilink } from './multilink/QRCodeMultilink';
import { QRCodePdf } from './pdf/QRCodePdf';
import { QRCodeVCard } from './vcard/QRCodeVCard';
import { QRCodeWebsiteUrl } from './website/QRCodeWebsiteUrl';
import { useTranslation } from 'react-i18next';

export const QRCodeInputContainer: React.FC<{
  forWidgetMode?: boolean;
}> = ({ forWidgetMode }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<QRCodeFormSchema>();
  const codeType = watch('codeType');

  if (codeType === ECodeType.WEBSITE) {
    if (forWidgetMode) {
      return <QRCodeWebsiteUrl className="h-auto" hint={t('qr.websiteInput.yourCodeGeneratedAutomatically')} />;
    }
    return <QRCodeWebsiteUrl />;
  }

  if (codeType === ECodeType.MULTILINK) {
    return <QRCodeMultilink forWidgetMode={forWidgetMode} />;
  }

  if (codeType === ECodeType.PDF) {
    return <QRCodePdf forWidgetMode={forWidgetMode} />;
  }

  if (codeType === ECodeType.V_CARD) {
    return <QRCodeVCard forWidgetMode={forWidgetMode} />;
  }

  return (
    <div className="flex-1 bg-white px-5 py-6 xl:rounded-xl">
      {codeType} section
      <div>In progress</div>
    </div>
  );
};
