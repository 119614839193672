import { useStore } from '@app/store/useStore.hook';
import { UserOutSchema } from './api/auth.schema';
import { AuthStatus } from './api/auth.type';

/**
 * TODO refactor to fix type inference
 * currently, click on prop like `currentUser?.hasVerifiedEmail` does not navigate to UserOutSchema
 * NOTE: types explicitly declared for return type for easy click to inspect while inference not working
 */
export const useAuthorizedUser = (): {
  currentUser: UserOutSchema | null;
  authStatus: AuthStatus;
  isOldPlanUser: boolean;
} => {
  const currentUser = useStore.useCurrentUser();
  const authStatus = useStore.useAuthStatus();
  const isOldPlanUser =
    // unauthorized users should see new plan
    currentUser?.isOldPlanUser || false;
  return { currentUser, authStatus, isOldPlanUser };
};
