import { SvgScopeWrap } from '@app/utils/SvgScopeWrap';
import { ReactComponent as CrownIcon } from './crown.svg';

export const PermissionCrown: React.FC<{
  requireUpgrade?: boolean;
  className?: string;
}> = ({ requireUpgrade = true, className }) => {
  return (
    <SvgScopeWrap>
      <CrownIcon color={requireUpgrade ? '#FB3' : '#D1D5DB'} className={className} />
    </SvgScopeWrap>
  );
};
