import { Typography } from '@mui/material';

import { Button } from '@app/components';
import { useStore } from '@app/store/useStore.hook';
import { useTranslation } from 'react-i18next';
import { GoogleButton } from '@app/components/buttons/google-button/GoogleButton';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { AUTH_QUERY_PARAM_AUTO_LINK_QR_ID } from '../auth.constants';
import { EGTAGRegistrationOrigin } from '@app/gtag/gtag.type';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';

export const ClaimQRPage = () => {
  const { t } = useTranslation();
  const user = useStore.useCurrentUser();
  const navigate = useTypedNavigate();

  const [autoLinkQRID] = useQueryParam(AUTH_QUERY_PARAM_AUTO_LINK_QR_ID, withDefault(StringParam, null));

  const renderBody = () => {
    if (!user) {
      return (
        <div className="flex h-full w-full max-w-sm flex-col gap-y-4">
          <GoogleButton autoLinkQRID={autoLinkQRID} origin={EGTAGRegistrationOrigin.CLAIM} />
        </div>
      );
    }
    return (
      <div className="flex h-full w-full max-w-sm flex-col items-center gap-y-4">
        <Button
          onClick={() => {
            navigate({ to: Routes.customer.account.subscription });
          }}
          className="w-[220px]"
        >
          {t('subscription.buttonUpgradePlan')}
        </Button>
      </div>
    );
  };

  return (
    <div className="flex h-full flex-col items-center gap-y-4">
      <div className="mb-4 flex flex-col gap-2">
        <Typography variant="4xl" className="text-center font-bold">
          {t('qr.expired.qrCodeNoLongerValid')}
        </Typography>
        <Typography variant="s" className="text-center text-gray-500">
          {t('qr.expired.activateYourQr')}
        </Typography>
      </div>
      {renderBody()}
    </div>
  );
};
