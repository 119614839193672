import styles from './Header.module.scss';
import clsx from 'clsx';
import { useMemo, useCallback } from 'react';

import { useStore } from '@app/store/useStore.hook';

import { AppBar, Toolbar } from '@mui/material';
import { UserHeaderInfo } from './user-header-info/UserHeaderInfo';
import { IconButton } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { MenuIcon } from '@app/assets';
import { useShouldShowSubscriptionBanner } from '@app/domain/subscription/useShouldShowSubscriptionBanner';
import { BUY_SUBSCRIPTION_BANNER_HEIGHT } from '@app/domain/subscription/BuySubscriptionBanner.constants';
import { useIsMobile } from '@app/hooks/useIsMobile.hook';

type Props = {
  className?: string;
};

export const Header: React.FC<Props> = ({ className, children }) => {
  const {
    currentUser: user,
    currentBreakpointDevice: { isDesktop },
    setNavMobile,
  } = useStore(({ currentUser, currentBreakpointDevice, navMobileOpen, setNavMobile }) => ({
    currentUser,
    currentBreakpointDevice,
    navMobileOpen,
    setNavMobile,
  }));
  const isShowSubscriptionBanner = useShouldShowSubscriptionBanner();
  const isMobile = useIsMobile();

  const handleNavMobileClick = useCallback(
    (open: boolean) => () => {
      setNavMobile(open);
    },
    [setNavMobile]
  );

  const desktopHeaderContent = useMemo(() => {
    return (
      <>
        <div className={styles['left-group']}></div>
        <div className={styles['right-group']}>
          {user && (
            <div className={clsxm(styles['user-info'], 'p-2')}>
              <UserHeaderInfo user={user} />
            </div>
          )}
        </div>
      </>
    );
  }, [user]);

  const mobileHeaderContent = useMemo(() => {
    return (
      <>
        <div className={styles['left-group']}>
          <IconButton variant="transparent" size="medium" className="text-white" onClick={handleNavMobileClick(true)}>
            <MenuIcon />
          </IconButton>
        </div>
        {user && (
          <div className={clsxm(styles['user-info'])}>
            <UserHeaderInfo user={user} isMobileLayout />
          </div>
        )}
      </>
    );
  }, [handleNavMobileClick, user]);

  return (
    <AppBar
      position="fixed"
      className={clsx(styles.header, isDesktop ? styles.desktop : styles.mobile, className)}
      elevation={0}
    >
      <Toolbar
        style={{ marginTop: !isMobile && isShowSubscriptionBanner ? `${BUY_SUBSCRIPTION_BANNER_HEIGHT}px` : 0 }}
        className={styles.toolbar}
      >
        {children ? children : isDesktop ? desktopHeaderContent : mobileHeaderContent}
      </Toolbar>
    </AppBar>
  );
};
