import { useStore } from '@app/store/useStore.hook';
import { EPlanType } from '@app/swagger-types';

export const usePlanLimits = () => {
  const currentUser = useStore.useCurrentUser();
  const planDetails = currentUser?.currentPlanDetails;

  const noDispute = !planDetails?.hasDisputedPayment;
  const isFreeUser = planDetails?.planType === EPlanType.FREE;
  const isBusinessOrOldUser = planDetails?.planType === EPlanType.BUSINESS || currentUser?.isOldPlanUser;
  return {
    allLimits: planDetails,
    currentUser,
    canCreateQRCode:
      noDispute &&
      (planDetails?.amountMaxQrCodes === null ||
        checkIsFirstNumberLess(currentUser?.totalCodes, planDetails?.amountMaxQrCodes)),
    canCreateTemplate: noDispute && planDetails?.hasTemplates,
    canDownloadSVG: planDetails?.fileExtensions?.includes('SVG') || false,
    canEditQrInput: !isFreeUser,
    canSeeAnalyticsUpTo6Months: !isFreeUser,
    canSeeAnalyticsForYear: isBusinessOrOldUser,
    canCreateBulk: !isFreeUser,
    canTrackGPS: isBusinessOrOldUser,
    canUseAPIKeys: isBusinessOrOldUser,
    // TODO when API ready
    // canEditQrInput: planDetails?.hasQrAdjustableInput || false,
  };
};

const checkIsFirstNumberLess = (first?: number, second?: number) => {
  if (typeof first !== 'number' || typeof second !== 'number') {
    return;
  }
  return first < second;
};
