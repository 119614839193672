import { useState } from 'react';
import { DevKitSection } from '../DevKitSection';
import { Button, Input } from '@app/components';
import { IS_LOCALHOST } from '@app/environment/typed-env';

export const TranslationDevKitSection: React.FC = () => {
  const [prefix, setPrefix] = useState(devKitTranslationGetPrefix);

  const changePrefix = (prefix: string) => {
    const newPrefix = prefix.trim();
    setPrefix(newPrefix);
    persistPrefix(newPrefix);
  };
  return (
    <DevKitSection title="Translation">
      <div className="flex flex-col gap-4 items-start">
        <div>Reload app after changing prefix to see changes.</div>
        <Input
          label="Translation prefix (auto persisted)"
          type="text"
          value={prefix}
          placeholder={prefix ? '' : 'empty prefix'}
          onChange={(e) => {
            changePrefix(e.target.value);
          }}
        />
        <div className="flex gap-4">
          <Button
            onClick={() => {
              changePrefix('');
              window.location.reload();
            }}
          >
            Remove prefix
          </Button>
          <Button
            onClick={() => {
              changePrefix(DEFAULT_LOCAL_PREFIX);
              window.location.reload();
            }}
          >
            Reset prefix
          </Button>
        </div>
      </div>
    </DevKitSection>
  );
};

const LOCALSTORAGE_KEY_FOR_PREFIX = 'translation-prefix';
const DEFAULT_LOCAL_PREFIX = IS_LOCALHOST ? 'en_' : '';

export const devKitTranslationGetPrefix = () => {
  const prefix = localStorage.getItem(LOCALSTORAGE_KEY_FOR_PREFIX);
  if (prefix === null) {
    return DEFAULT_LOCAL_PREFIX;
  }
  return prefix || '';
};
const persistPrefix = (prefix: string) => localStorage.setItem(LOCALSTORAGE_KEY_FOR_PREFIX, prefix);
