import { Button, Container, IconButton, Input, Typography } from '@app/components';
import { QRCodeFormSchema, regexUrlWithoutProtocol } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { clsxm } from '@app/styles/clsxm';
import { ELibraryType, ELinkType } from '@app/swagger-types';
import { Divider } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { LibraryEditor } from '@app/domain/library/components/LibraryEditor';
import { useLibraryEditor } from '@app/domain/library/useLibraryEditor';
import AddIcon from '@mui/icons-material/Add';
import { DeleteIcon } from '@app/assets';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';

const MAX_LINKS = 10;

const LINKS_FORM_PATH = 'multilink.customLinks';
export const MultilinkLinksEditor: React.FC<{
  forWidgetMode?: boolean;
  disabledEditInput?: boolean;
}> = ({ forWidgetMode, disabledEditInput }) => {
  const { t } = useTranslation();
  const { control } = useFormContext<QRCodeFormSchema>();
  const { fields, remove, append } = useFieldArray({
    control,
    name: LINKS_FORM_PATH,
  });

  return (
    <Container className="grid grid-cols-1 gap-2 xl:rounded-xl">
      <Typography className="font-bold md:text-l">{t('qr.multilinkInput.linksSectionTitle')}</Typography>
      <Divider className="my-4" />
      {fields.map((f, idx) => {
        return (
          <div key={f.id} className="flex flex-col gap-2">
            <div className="flex justify-between gap-2">
              <div className="font-bold">
                {t('qr.multilinkInput.link').toUpperCase()} {idx + 1}
              </div>
              <ConfirmDialog
                trigger={
                  <IconButton size="small" variant="transparent" disabled={disabledEditInput}>
                    <DeleteIcon />
                  </IconButton>
                }
                onConfirm={() => {
                  remove(idx);
                }}
                containerClassName="max-w-[350px] text-center"
                title={`${t('dialog.deleteQuestionTitle')} ${t('qr.multilinkInput.link')}?`}
                text={`${t('dialog.areYouSureToDeleteThis')} ${t('qr.multilinkInput.link')}?"`}
                confirmText={t('dialog.yesDelete')}
              />
            </div>
            <SingleLinkEditor index={idx} forWidgetMode={forWidgetMode} disabledEditInput={disabledEditInput} />
          </div>
        );
      })}
      <div>
        <Button
          onClick={() => append({ title: '', url: '', linkType: ELinkType.CUSTOM })}
          variant="outlined"
          startIcon={<AddIcon />}
          disabled={fields.length >= MAX_LINKS || disabledEditInput}
        >
          {t('qr.multilinkInput.buttonAddLink')}
        </Button>
      </div>
    </Container>
  );
};

const SingleLinkEditor: React.FC<{
  index: number;
  forWidgetMode?: boolean;
  disabledEditInput?: boolean;
}> = ({ index, forWidgetMode, disabledEditInput }) => {
  const { t } = useTranslation();
  const { control, watch, trigger, setValue } = useFormContext<QRCodeFormSchema>();
  const field = watch(`${LINKS_FORM_PATH}.${index}`);
  const libraryId = field?.libraryId;
  const hookProps = useLibraryEditor({
    libraryType: ELibraryType.LINK_LOGO,
    selectedLibraryId: libraryId,
    forWidgetMode,
  });
  console.log('render', hookProps, hookProps.selectedImageId, field.library);

  return (
    <div className="flex flex-col gap-3">
      <Controller
        name={`${LINKS_FORM_PATH}.${index}.title`}
        control={control}
        render={({ field: { value, ...field }, fieldState }) => {
          return (
            <Input
              {...field}
              label={t('qr.multilinkInput.linkTitleLabel')}
              withBG
              value={value || ''}
              placeholder={t('qr.multilinkInput.linkTitlePlaceholder')}
              errorMessage={fieldState?.error?.message || ''}
              endAdornment={
                <IconButton
                  size="small"
                  onClick={() => {
                    field.onChange('');
                  }}
                  className={clsxm('mr-1 bg-white', !value && 'hidden')}
                  variant="transparent"
                >
                  <CloseIcon />
                </IconButton>
              }
              disabled={disabledEditInput}
            />
          );
        }}
      />
      <Controller
        name={`${LINKS_FORM_PATH}.${index}.url`}
        control={control}
        render={({ field: { value, ...field }, fieldState }) => {
          return (
            <Input
              {...field}
              label={t('qr.multilinkInput.linkURLLabel')}
              withBG
              value={value || ''}
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(value.replaceAll(/\s+/g, ''));
              }}
              onBlur={(e) => {
                const url = e.target.value;
                const protocoledUrl = regexUrlWithoutProtocol.test(url) ? `https://${url}` : url;
                field.onChange(protocoledUrl);
                field.onBlur();
                trigger(field.name);
              }}
              placeholder={'https://website.com/page'}
              errorMessage={fieldState?.error?.message || ''}
              endAdornment={
                <IconButton
                  size="small"
                  onClick={() => {
                    field.onChange('');
                  }}
                  className={clsxm('mr-1 bg-white', !value && 'hidden')}
                  variant="transparent"
                >
                  <CloseIcon />
                </IconButton>
              }
              disabled={disabledEditInput}
            />
          );
        }}
      />
      <div className="flex flex-col gap-3">
        <Typography variant="m" className="font-bold">
          {t('qr.stepInput.imageOptional')}
        </Typography>
        <Typography variant="xxs" className="text-gray-500">
          {t('qr.stepInput.addImageDescription')}
        </Typography>
        <LibraryEditor
          {...hookProps}
          onLibrarySelect={(newLibId) => {
            console.log('on select', newLibId);
            setValue(`${LINKS_FORM_PATH}.${index}.libraryId`, newLibId || undefined);
          }}
          disabled={disabledEditInput}
        />
      </div>
      <Divider className="my-4" />
    </div>
  );
};
