import { Button, Typography } from '@app/components';
import { Countdown } from '@app/components/countdown/Countdown';
import { FORMAT } from '@app/constants/formats';
import { GET_USERS_QUERY } from '@app/constants/query-api-configs';
import { SubscriptionStatusBadge } from '@app/domain/subscription/SubscriptionStatusBadge';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';
import { clsxm } from '@app/styles/clsxm';
import { EPlanType, ECustomerGen } from '@app/swagger-types';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { CustomerAdminOutSchema } from '..';
import { UserApi } from '../user.api';

export const CustomerSubscriptionCell: React.FC<{
  /** system user id, not stripe id */
  customerId: string;
  chargebeeId?: string | null;
  subscription: Pick<CustomerAdminOutSchema, 'subscription'>['subscription'];
  stripeSyncedDate?: string | null;
  customerGen: ECustomerGen;
}> = ({ customerId, chargebeeId, subscription, stripeSyncedDate, customerGen }) => {
  const queryClient = useQueryClient();
  const changeExpirationMutation = useMutation({
    mutationFn: async ({
      customerId,
      endOfSubscriptionDate,
    }: {
      customerId: string;
      endOfSubscriptionDate: string;
    }) => {
      return await UserApi.adminPatchCustomer(customerId, { endOfSubscriptionDate });
    },
    onSuccess: (data, customerId) => {
      queryClient.invalidateQueries(GET_USERS_QUERY.name);
      console.log('set expidation date result', customerId, data);
      setIsEditMode(false);
    },
  });

  const forceStripeSyncMutation = useMutation({
    mutationFn: async (customerId: string) => {
      return await UserApi.adminForceStripeSync(customerId);
    },
    onSuccess: (data, customerId) => {
      queryClient.invalidateQueries(GET_USERS_QUERY.name);
      console.log('stripe sync result', customerId, data);
    },
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [newEndOfSubscription, setNewEndOfSubscription] = useState<Date | null>(null);

  const expireDate = subscription.endOfSubscriptionDate ? new Date(subscription.endOfSubscriptionDate) : undefined;
  const isTrial = subscription.planType === EPlanType.TRIAL;

  const { safeValue, isNewDateInFuture } = useMemo(() => {
    let safeValue = '';
    if (!isEditMode) {
      return { safeValue };
    }
    try {
      if (!newEndOfSubscription) {
        return { safeValue };
      }
      const isNewDateInFuture = newEndOfSubscription.getTime() > Date.now();
      safeValue = transformDateToLocalTimeString(newEndOfSubscription);
      return { safeValue, isNewDateInFuture };
    } catch {
      return { safeValue };
    }
  }, [isEditMode, newEndOfSubscription]);

  const now = Date.now();
  const daysLeft = expireDate ? Math.floor((expireDate.getTime() - now) / DAY_IN_MS) : 0;

  return (
    <Typography variant="xs" className="flex flex-wrap items-center gap-2">
      <span>
        <b>{subscription.planType}</b> <i>{subscription.planCycle}</i> {subscription.currency} <b>{customerGen}</b>
      </span>
      {isEditMode ? (
        <div className="flex flex-col gap-4">
          <div>
            {newEndOfSubscription && (
              <div className={clsxm('rounded px-1', isNewDateInFuture ? 'bg-slate-200' : 'bg-red-200')}>
                {isNewDateInFuture ? (
                  <>
                    expire in <Countdown expireDate={newEndOfSubscription} />
                  </>
                ) : (
                  'not future date'
                )}
              </div>
            )}
          </div>
          <div>
            <input
              value={safeValue}
              onChange={(e) => {
                try {
                  const str = e.target.value;
                  const date = transformLocalTimeStringToDate(str);
                  setNewEndOfSubscription(date);
                } catch {
                  // skip
                }
              }}
              type="datetime-local"
            />
          </div>
          <div className="flex gap-4">
            <ConfirmDialog
              onConfirm={() => {
                if (!newEndOfSubscription) {
                  return;
                }
                changeExpirationMutation.mutate({ customerId, endOfSubscriptionDate: newEndOfSubscription.toJSON() });
              }}
              trigger={
                <Button disabled={!safeValue || !isNewDateInFuture} loading={changeExpirationMutation.isLoading}>
                  Save
                </Button>
              }
            />
            <Button
              variant="outlined"
              disabled={changeExpirationMutation.isLoading}
              onClick={() => {
                setIsEditMode(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <>
          {isTrial && daysLeft > -1 && (
            <div className="inline-block rounded bg-sky-200 px-1 text-sky-900">
              <b>{daysLeft}d</b> left
            </div>
          )}
          <SubscriptionStatusBadge
            endOfSubscriptionDate={subscription.endOfSubscriptionDate}
            planType={subscription.planType}
            planStatus={subscription.planStatus}
            onClick={
              isTrial
                ? () => {
                    if (!expireDate) {
                      return;
                    }
                    setIsEditMode(true);
                    setNewEndOfSubscription(expireDate);
                  }
                : undefined
            }
          />
        </>
      )}
      {!isEditMode && (
        <>
          {chargebeeId ? (
            <div className="text-xs italic">stripe sync disabled for chargebee</div>
          ) : (
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              loading={forceStripeSyncMutation.isLoading}
              disabled={Boolean(chargebeeId)}
              onClick={() => forceStripeSyncMutation.mutate(customerId)}
            >
              stripe sync
            </Button>
          )}
          {stripeSyncedDate ? (
            <div className="text-green-600">synced: {format(new Date(stripeSyncedDate), FORMAT.FULL_TIME)}</div>
          ) : (
            <div className="text-gray-400">no sync</div>
          )}
        </>
      )}
    </Typography>
  );
};

const transformDateToLocalTimeString = (date?: Date) => {
  const newDate = date ? new Date(date) : new Date();
  return new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString().slice(0, -1);
};

const transformLocalTimeStringToDate = (dateTimeLocalValue: string) => {
  const fakeUtcTime = new Date(`${dateTimeLocalValue}Z`);
  const d = new Date(fakeUtcTime.getTime() + fakeUtcTime.getTimezoneOffset() * 60000);
  return d;
};

const DAY_IN_MS = 1e3 * 60 * 60 * 24;
