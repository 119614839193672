import { clsxm } from '@app/styles/clsxm';
import { ReactComponent as DownloadIcon } from '@app/assets/icons/actions/download-icon.svg';
import { ReactComponent as UploadIcon } from '@app/assets/icons/actions/upload-icon.svg';
import { Button } from '@app/components';

type Props = {
  className?: string;
};

export const DecorativeButtonsCard = ({ className }: Props) => {
  return (
    <div
      className={clsxm(
        'pointer-events-none w-44 p-3 flex flex-col gap-2 rounded-md bg-white/65 backdrop-blur-sm',
        className
      )}
    >
      <Button
        variant="text"
        startIcon={<UploadIcon className="w-3 ml-3 shrink-0" />}
        className="text-[9px] flex gap-[2px] justify-start p-1 h-auto text-gray-700 bg-white drop-shadow-lg rounded-md"
      >
        Drag PDF here or <span className="text-accept-main">Browse</span>
      </Button>
      <Button
        variant="text"
        startIcon={<DownloadIcon className="w-3 ml-3 shrink-0" />}
        className="text-[9px] justify-start p-1 h-auto text-gray-700 bg-white drop-shadow-lg rounded-md"
      >
        Download CSV
      </Button>
    </div>
  );
};
