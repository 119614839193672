import { object, string, number, InferType } from 'yup';

export const ApiKeyOutDtoSchema = object({
  id: string().uuid().required(),
  keyValue: string().required(),
  totalRequests: number().integer().min(0).required(),
  lastUsed: string().required(),
  createdDate: string().required(),
});
export type ApiKeyOutDto = InferType<typeof ApiKeyOutDtoSchema>;
