import { Button, Container, Typography } from '@app/components';
import { useStore } from '@app/store/useStore.hook';
import { FormControlLabel, Switch } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUpdateCustomer } from '../api/user.hooks.api';

export const AccountEmailPreferences: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser: user } = useStore(({ currentUser }) => ({
    currentUser,
  }));
  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();

  const [emailPreferences, setEmailPreferences] = useState({
    accountNotification: user?.accountNotification || false,
    qrNotification: user?.qrNotification || false,
    marketingNotification: user?.marketingNotification || false,
  });

  const handleEmailPreferences = (key: keyof typeof emailPreferences, value: boolean) => {
    setEmailPreferences((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const disableSubmitButton =
    emailPreferences.accountNotification === user?.accountNotification &&
    emailPreferences.qrNotification === user?.qrNotification &&
    emailPreferences.marketingNotification === user?.marketingNotification;

  return (
    <Container className="flex flex-col gap-6 p-6 lg:rounded-xl">
      <Typography variant="m" className="font-bold">
        {t('account.sectionEmailPreferences.title')}
      </Typography>
      <div className="flex flex-col gap-4 flex-wrap lg:flex-row lg:gap-x-12">
        <div>
          <FormControlLabel
            onChange={() => {
              handleEmailPreferences('accountNotification', !emailPreferences.accountNotification);
            }}
            checked={emailPreferences.accountNotification}
            control={<Switch />}
            label={t('account.sectionEmailPreferences.accountNotifications')}
          />
        </div>
        <div>
          <FormControlLabel
            onChange={() => {
              handleEmailPreferences('qrNotification', !emailPreferences.qrNotification);
            }}
            checked={emailPreferences.qrNotification}
            control={<Switch />}
            label={t('account.sectionEmailPreferences.qrNotifications')}
          />
        </div>
        <div>
          <FormControlLabel
            onChange={() => {
              handleEmailPreferences('marketingNotification', !emailPreferences.marketingNotification);
            }}
            checked={emailPreferences.marketingNotification}
            control={<Switch />}
            label={t('account.sectionEmailPreferences.marketing')}
          />
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            if (user) {
              updateCustomer(
                { ...emailPreferences },
                {
                  onSuccess: () => {
                    toast.success(t('toaster.changesSaved'));
                  },
                }
              );
            }
          }}
          color="secondary"
          variant="outlined"
          className="w-[220px]"
          loading={isLoading}
          disabled={disableSubmitButton}
        >
          {t('dialog.saveChanges')}
        </Button>
      </div>
    </Container>
  );
};
