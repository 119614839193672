import { Trans, useTranslation } from 'react-i18next';
import { EPlanLimitType } from './PlanLimitDialog';
import { LogoLoreal, LogoPuma, LogoSamsung } from '@app/modules/auth/assets';
import { PermissionCrown } from '../permission-crown/PermissionCrown';
import { CheckCircleIcon } from '@app/assets';
import DeviceMacbookSrc from '../assets/device-macbook.png';
import DownloadSVGMacbookSrc from '../assets/download-svg-macbook.png';
import CreateBulkMacbookSrc from '../assets/create-bulk-macbook.png';
import QRCodeSampleSrc from '@app/qr-widget/dialog/components/assets/qr-code-sample.png';
import { ReactComponent as ColorPickerSVG } from '../assets/color-picker.svg';
import { ReactComponent as PatternColorSVG } from '../assets/pattern-color.svg';
import { ReactComponent as ActionsWidgetSVG } from '../assets/actions-widget.svg';
import { ReactComponent as FileSizeWidgetSVG } from '../assets/file-size-widget.svg';
import { clsxm } from '@app/styles/clsxm';
import { QRCodeWidgetCard } from '../assets/QRCodeWidgetCard';
import { DecorativeButtonsCard } from '../assets/DecorativeButtonsCard';

export const usePlanLimitGetDialogContent = (
  planLimitType: EPlanLimitType | null
): {
  header: JSX.Element;
  description: JSX.Element;
  decorativeContent: JSX.Element;
} => {
  const { t } = useTranslation();

  let header = <>{t('limit.dialog.common.upgradeToUnlock')}</>;
  let description = <></>;
  let decorativeContent = <></>;

  const decorativeContentComponent = (
    <>
      <div className="flex flex-col gap-4">
        <div className="font-medium text-xs text-gray-700">{t('limit.dialog.common.testimonial')}</div>
        <div className="flex flex-col gap-1">
          <div className="text-xs italic text-gray-700 font-semibold">{t('limit.dialog.common.author')}</div>
          <div className="text-gray-500 text-xs italic">{t('limit.dialog.common.authorPosition')}</div>
        </div>
      </div>
      <div className="flex flex-col gap-4 mb-4 lg:mb-0">
        <div className="text-xs font-medium text-gray-700">{t('onboarding.stepSelectCycle.rightSide.trustedBy')}</div>
        <div className="grid grid-cols-3 gap-4">
          <div className="bg-white p-2 rounded-lg text-black flex items-center justify-center">
            <LogoLoreal className="w-16" />
          </div>
          <div className="bg-white p-2 rounded-lg text-black flex items-center justify-center">
            <LogoPuma className="w-16" />
          </div>
          <div className="bg-white p-2 rounded-lg text-black flex items-center justify-center">
            <LogoSamsung className="w-16" />
          </div>
        </div>
      </div>
    </>
  );

  switch (planLimitType) {
    case EPlanLimitType.CREATE_QR_CODE:
      header = (
        <div className="flex flex-col gap-4">
          <div className="text-grey-700 inline-flex gap-2 font-semibold text-xs">
            <PermissionCrown className="shrink-0" /> {t('limit.dialog.createQRCode.upgradeToCreateMore')}
          </div>
          <div className="text-gray-800 text-xl font-bold">{t('limit.dialog.createQRCode.title')}</div>
          <div className="font-normal text-xs text-gray-700">{t('limit.dialog.createQRCode.description')}</div>
        </div>
      );
      description = (
        <>
          <div className="mt-5 mb-4 font-semibold text-gray-800 text-xs">
            {t('limit.dialog.createQRCode.availablePlans')}:
          </div>
          <ul className="flex flex-col gap-4 mb-auto">
            <li className="flex gap-2 items-center font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block" />
              <span>
                <b>Free:</b> {t('limit.dialog.createQRCode.limitForFree')}
              </span>
            </li>
            <li className="flex gap-2 items-center font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block" />
              <span>
                <b>PRO:</b> {t('limit.dialog.createQRCode.limitForPro')}
              </span>
            </li>
            <li className="flex gap-2 items-start font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <b>BUSINESS:</b> {t('limit.dialog.createQRCode.limitForBusiness')}
              </span>
            </li>
          </ul>
        </>
      );
      decorativeContent = decorativeContentComponent;
      break;
    case EPlanLimitType.EDIT_QR_CODE:
      header = (
        <div className="flex flex-col gap-4">
          <div className="text-grey-700 inline-flex gap-2 font-semibold text-xs">
            <PermissionCrown className="shrink-0" /> {t('limit.dialog.common.upgradeToUnlock')}
          </div>
          <div className="text-gray-700 text-xl font-bold">{t('limit.dialog.editQRCode.title')}</div>
          <div className="font-normal text-xs text-gray-700">
            <Trans i18nKey={'limit.dialog.editQRCode.description'}>
              <b />
            </Trans>
          </div>
        </div>
      );
      description = (
        <>
          <div className="mt-5 mb-4 font-semibold text-gray-800 text-xs">
            {t('limit.dialog.editQRCode.includeInPro')}:
          </div>
          <ul className="flex flex-col gap-4 mb-auto">
            <li className="flex gap-2 items-start font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.editQRCode.featureUnlimitedScans'}>
                  <b />
                </Trans>
              </span>
            </li>
            <li className="flex gap-2 items-start font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.editQRCode.featureStayFlexible'}>
                  <b />
                </Trans>
              </span>
            </li>
            <li className="flex gap-2 items-start font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.editQRCode.featureSaveTime'}>
                  <b />
                </Trans>
              </span>
            </li>
          </ul>
          <div className="mt-6 mb-8 text-gray-800 text-xs">
            <Trans i18nKey={'limit.dialog.common.upgradeMessage'}>
              <b />
            </Trans>
          </div>
        </>
      );
      decorativeContent = (
        <>
          <div
            className={clsxm(
              'absolute inset-14 left-16 top-36 bottom-32 border border-dashed border-[#B3C1D5] rounded-lg'
            )}
          />
          <img src={DeviceMacbookSrc} alt="macbook" className="bottom-40 absolute right-0 w-4/5 max-w-xs" />
          <div className="h-w-14 top-24 absolute left-1/2 -translate-x-1/2 w-16 p-2 bg-[#F1F3F7] rounded drop-shadow-xl">
            <img src={QRCodeSampleSrc} alt="qr-code" className="w-full h-full" />
          </div>
          <ColorPickerSVG className="bottom-80 absolute left-7 drop-shadow-xl backdrop-blur-sm rounded-lg" />
          <PatternColorSVG className="bottom-40 absolute left-7 drop-shadow-xl backdrop-blur-sm rounded-lg" />
        </>
      );
      break;
    case EPlanLimitType.DOWNLOAD_SVG:
      header = (
        <div className="flex flex-col gap-4">
          <div className="text-grey-700 inline-flex gap-2 font-semibold text-xs">
            <PermissionCrown className="shrink-0" /> {t('limit.dialog.common.upgradeToUnlock')}
          </div>
          <div className="text-gray-700 text-xl font-bold">{t('limit.dialog.downloadSVG.title')}</div>
          <div className="font-normal text-xs text-gray-700">
            <Trans i18nKey={'limit.dialog.downloadSVG.description'}>
              <b />
            </Trans>
          </div>
        </div>
      );
      description = (
        <>
          <div className="mt-5 mb-4 font-semibold text-gray-800 text-xs">
            {t('limit.dialog.downloadSVG.includeInPro')}:
          </div>
          <ul className="flex flex-col gap-4 mb-auto">
            <li className="flex gap-2 items-start font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.downloadSVG.featureUnlimitedScans'}>
                  <b />
                </Trans>
              </span>
            </li>
            <li className="flex gap-2 items-start font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.downloadSVG.featureAlwaysEditableQR'}>
                  <b />
                </Trans>
              </span>
            </li>
          </ul>
          <div className="mt-6 mb-8 text-gray-800 text-xs">
            <Trans i18nKey={'limit.dialog.common.upgradeMessage'}>
              <b />
            </Trans>
          </div>
        </>
      );
      decorativeContent = (
        <>
          <div
            className={clsxm('absolute inset-14 top-28 bottom-32 border border-dashed border-[#B3C1D5] rounded-lg')}
          />
          <img src={DownloadSVGMacbookSrc} alt="macbook" className="bottom-40 right-0 absolute w-4/5 max-w-xs" />
          <div className="h-w-14 top-16 absolute left-1/2 -translate-x-1/2 w-16 p-2 bg-[#F1F3F7] rounded drop-shadow-xl">
            <img src={QRCodeSampleSrc} alt="qr-code" className="w-full h-full" />
          </div>
          <ActionsWidgetSVG className="bottom-80 absolute left-5 drop-shadow-xl backdrop-blur-sm rounded-lg" />
          <FileSizeWidgetSVG className="bottom-40 absolute left-7 drop-shadow-xl backdrop-blur-sm rounded-lg" />
        </>
      );
      break;
    case EPlanLimitType.GPS_SCAN:
      header = (
        <div className="flex flex-col gap-4">
          <div className="text-grey-700 inline-flex gap-2 font-semibold text-xs">
            <PermissionCrown className="shrink-0" /> {t('limit.dialog.common.upgradeToUnlock')}
          </div>
          <div className="text-gray-800 text-xl font-bold">{t('limit.dialog.GPSScan.title')}</div>
          <div className="font-normal text-xs text-gray-700">{t('limit.dialog.GPSScan.description')}</div>
        </div>
      );
      description = (
        <>
          <div className="mt-5 mb-4 font-semibold text-gray-800 text-xs">
            {t('limit.dialog.GPSScan.includeInBusiness')}:
          </div>
          <ul className="flex flex-col gap-4 mb-auto">
            <li className="flex gap-2 items-start font-normal text-xs">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.GPSScan.featureUnlimitedScans'}>
                  <b />
                </Trans>
              </span>
            </li>
            <li className="flex gap-2 items-start font-normal text-xs">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.GPSScan.featureUnlimitedQR'}>
                  <b />
                </Trans>
              </span>
            </li>
            <li className="flex gap-2 items-start font-normal text-xs">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.GPSScan.featureSaveTime'}>
                  <b />
                </Trans>
              </span>
            </li>
          </ul>
        </>
      );
      decorativeContent = decorativeContentComponent;
      break;
    case EPlanLimitType.UNLOCK_ANALYTICS_DURATION:
      header = (
        <div className="flex flex-col gap-4">
          <div className="text-grey-700 inline-flex gap-2 font-semibold text-xs">
            <PermissionCrown className="shrink-0" /> {t('limit.dialog.common.upgradeToUnlock')}
          </div>
          <div className="text-gray-800 text-xl font-bold">{t('limit.dialog.unlockAnalyticsDuration.title')}</div>
          <div className="font-normal text-xs text-gray-700">
            {t('limit.dialog.unlockAnalyticsDuration.description')}
          </div>
        </div>
      );
      description = (
        <>
          <div className="mt-5 mb-4 font-semibold text-gray-800 text-xs">
            {t('limit.dialog.unlockAnalyticsDuration.availablePlans')}:
          </div>
          <ul className="flex flex-col gap-4 mb-auto">
            <li className="flex gap-2 items-center font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block" />
              <span>
                <b>Free:</b> {t('limit.dialog.unlockAnalyticsDuration.limitForFree')}
              </span>
            </li>
            <li className="flex gap-2 items-center font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block" />
              <span>
                <b>PRO:</b> {t('limit.dialog.unlockAnalyticsDuration.limitForPro')}
              </span>
            </li>
            <li className="flex gap-2 items-start font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <b>BUSINESS:</b> {t('limit.dialog.unlockAnalyticsDuration.limitForBusiness')}
              </span>
            </li>
          </ul>
        </>
      );
      decorativeContent = decorativeContentComponent;
      break;
    case EPlanLimitType.CREATE_BULK:
      header = (
        <div className="flex flex-col gap-4">
          <div className="text-grey-700 inline-flex gap-2 font-semibold text-xs">
            <PermissionCrown className="shrink-0" /> {t('limit.dialog.common.upgradeToUnlock')}
          </div>
          <div className="text-gray-700 text-xl font-bold">{t('limit.dialog.createBulk.title')}</div>
          <div className="font-normal text-xs text-gray-700">
            <Trans i18nKey={'limit.dialog.createBulk.description'}>
              <b />
            </Trans>
          </div>
        </div>
      );
      description = (
        <>
          <div className="mt-5 mb-4 font-semibold text-gray-800 text-xs">
            {t('limit.dialog.createBulk.includeInPro')}:
          </div>
          <ul className="flex flex-col gap-4 mb-auto">
            <li className="flex gap-2 items-start font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.createBulk.featureUnlimitedScans'}>
                  <b />
                </Trans>
              </span>
            </li>
            <li className="flex gap-2 items-start font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.createBulk.featureSeveTime'}>
                  <b />
                </Trans>
              </span>
            </li>
            <li className="flex gap-2 items-start font-normal text-xs text-gray-700">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.createBulk.featureIncreaseEfficiency'}>
                  <b />
                </Trans>
              </span>
            </li>
          </ul>
          <div className="mt-6 mb-8 text-gray-800 text-xs">
            <Trans i18nKey={'limit.dialog.common.upgradeMessage'}>
              <b />
            </Trans>
          </div>
        </>
      );
      decorativeContent = (
        <>
          <div
            className={clsxm('absolute inset-14 top-32 bottom-32 border border-dashed border-[#B3C1D5] rounded-lg')}
          />
          <img src={CreateBulkMacbookSrc} alt="macbook" className="bottom-40 right-0 absolute w-4/5 max-w-xs" />
          <div className="h-w-14 top-24 absolute scale-75 left-1/2 -translate-x-1/2 w-16 p-2 bg-[#F1F3F7] rounded drop-shadow-xl">
            <img src={QRCodeSampleSrc} alt="qr-code" className="w-full h-full" />
          </div>
          <QRCodeWidgetCard className="bottom-80 absolute left-5 drop-shadow-xl" />
          <QRCodeWidgetCard
            className="bottom-64 absolute left-5 drop-shadow-xl"
            title="Website"
            description="QR CODE 2"
          />
          <DecorativeButtonsCard className="scale-90 bottom-28 absolute left-4 drop-shadow-xl backdrop-blur-sm rounded-lg" />
        </>
      );
      break;

    case EPlanLimitType.API_KEYS:
      header = (
        <div className="flex flex-col gap-4">
          <div className="text-grey-700 inline-flex gap-2 font-semibold text-xs">
            <PermissionCrown className="shrink-0" /> {t('limit.dialog.apiKeys.upgradeToBusiness')}
          </div>
          <div className="text-gray-800 text-xl font-bold">{t('limit.dialog.apiKeys.title')}</div>
          <div className="font-normal text-xs text-gray-700">{t('limit.dialog.apiKeys.description')}</div>
        </div>
      );
      description = (
        <>
          <div className="mt-5 mb-4 font-semibold text-gray-800 text-xs">
            {t('limit.dialog.apiKeys.includeInBusiness')}:
          </div>
          <ul className="flex flex-col gap-4 mb-auto">
            <li className="flex gap-2 items-start font-normal text-xs">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.apiKeys.featureUnlimitedScans'}>
                  <b />
                </Trans>
              </span>
            </li>
            <li className="flex gap-2 items-start font-normal text-xs">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.apiKeys.featureUnlimitedQRCodes'}>
                  <b />
                </Trans>
              </span>
            </li>
            <li className="flex gap-2 items-start font-normal text-xs">
              <CheckCircleIcon className="inline-block shrink-0 -mt-0.5" />
              <span>
                <Trans i18nKey={'limit.dialog.apiKeys.featureEditable'}>
                  <b />
                </Trans>
              </span>
            </li>
          </ul>
        </>
      );
      decorativeContent = decorativeContentComponent;
      break;
    default:
      header = <>{t('limit.dialog.common.upgradeToUnlock')}</>;
      description = <></>;
      decorativeContent = <></>;
      break;
  }

  return {
    header,
    description,
    decorativeContent,
  };
};
