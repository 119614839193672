import { CustomerSubscriptionOutDto, ECurrency, EPlanCycle, EPlanStatus, EPlanType } from '@app/swagger-types';
import { checkTypeCompliance } from '@app/utils/type.utils';
import { oneOfEnum, oneOfEnumOptional } from '@app/utils/yup.utils';
import { InferType, object, string } from 'yup';

export const CustomerSubscriptionOutDtoSchema = object({
  planType: oneOfEnum(EPlanType),
  planStatus: oneOfEnum(EPlanStatus),
  currency: oneOfEnumOptional(ECurrency),
  planCycle: oneOfEnumOptional(EPlanCycle),
  startOfSubscriptionDate: string().nullable(),
  endOfSubscriptionDate: string().nullable(),
  endOfTrialSubscriptionDate: string().nullable(),
});
export type CustomerSubscriptionOutDtoSchema = InferType<typeof CustomerSubscriptionOutDtoSchema>;
checkTypeCompliance<CustomerSubscriptionOutDto, CustomerSubscriptionOutDtoSchema>();
checkTypeCompliance<CustomerSubscriptionOutDtoSchema, CustomerSubscriptionOutDto>();

export const CancellationFormSchema = object({
  primaryReason: string().nullable(),
  featuresMissingOrImprove: string().nullable(),
  howToKeepYouAsCustomer: string().nullable(),
  commentsOrSuggestions: string().nullable(),
});

export type TypeCancellationFormSchema = InferType<typeof CancellationFormSchema>;
