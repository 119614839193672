import { useEffect, useState, useMemo } from 'react';
import { QRCodePatched } from './QRCodePatched';
import { clsxm } from '@app/styles/clsxm';
import { getLinkForCode, getLinkForGS1, getLinkForQrCodeTemplate, getQRfileNameForDownload } from '../qr-code.utils';
import { useGetLibraryById } from '@app/domain/library/api/library.hooks.api';
import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { ECornerType, EFrameType, EPatternType } from '@app/swagger-types';
import { useBackgroundImageDownload } from '@app/hooks/useBackgroundImageDownload.hook';
import html2canvas from 'html2canvas';
import { EQRDownloadType, QRDownloadHandlerFn } from './qr-code-internal.types';
import { WIDGET_LOGO_OPTION_APP_LOGO } from '@app/qr-widget/widget.constants';
import { getRawImageUrl } from '@app/qr-widget/getRawImageUrl.util';
import { IS_LOCALHOST, typedEnv } from '@app/environment/typed-env';
import { Options } from 'qr-code-styling';
import { QRCodeApi } from '../api/qr-code.api';
import { Routes } from '@app/constants/routes';
import { generatePath } from 'react-router-dom';
import { ExtractParams } from '@app/router';
import { FrameContainerSVG } from './frame/FrameContainerSVG';

const allTypes = ['dots', 'rounded', 'classy', 'classy-rounded', 'square', 'extra-rounded'] as const;
const allCornerSquareTypes = ['dot', 'square', 'extra-rounded'] as const;
const allCornerDotTypes = ['dot', 'square'] as const;

const MOCK_VALUE_FOR_PREVIEW = typedEnv.REACT_APP_HOST_URL;

const BASE_SIZE = 240;
const DOWNLOAD_SIZE = 1024;

const normalizePatternType = (apiType: EPatternType): typeof allTypes[number] => {
  switch (apiType) {
    case EPatternType.SQUARE:
      return 'square';
    case EPatternType.DOTS:
      return 'dots';
    case EPatternType.CLASSY:
      return 'classy';
    case EPatternType.CLASSY_ROUNDED:
      return 'classy-rounded';
    case EPatternType.ROUNDED:
      return 'rounded';
    case EPatternType.EXTRA_ROUNDED:
      return 'extra-rounded';
  }
  return 'square';
};

const normalizeCornerSquareType = (apiType: ECornerType): typeof allCornerSquareTypes[number] => {
  switch (apiType) {
    case ECornerType.SQUARE:
      return 'square';
  }
  switch (apiType) {
    case ECornerType.ROUNDED:
      return 'extra-rounded';
  }
  switch (apiType) {
    case ECornerType.FULL_CIRCLE:
      return 'dot';
  }
  return 'square';
};

const normalizeCornerDotType = (apiType: ECornerType): typeof allCornerDotTypes[number] => {
  switch (apiType) {
    case ECornerType.ROUNDED:
      return 'dot';
    case ECornerType.FULL_CIRCLE:
      return 'dot';
  }
  return 'square';
};

export type QRRendererProps = {
  value?: string;
  publicLogoType?: typeof WIDGET_LOGO_OPTION_APP_LOGO | string | null;
  /**
   * in preview mode value is mock
   */
  asPreview?: boolean;
  backgroundColor?: string;
  patternColor?: string;
  cornerColor?: string;
  patternType?: EPatternType;
  cornerType?: ECornerType;
  // frame props start
  frameType?: EFrameType;
  frameText?: string | null;
  frameTextSize?: number;
  frameTextColor?: string;
  frameBackgroundColor?: string;
  frameClassName?: string;
  // frame props end
  className?: string;
  qrId?: string;
  qrTemplateId?: string;
  libraryId?: string;
  gtin?: string;
  isOption?: boolean;
  onQRCode?: (qr: QRCodePatched) => void;
  onDownloadHandler?: (downloadHandler?: QRDownloadHandlerFn) => void;
  fileName?: string;
};

export const QRCodeRendererSVG: React.FC<QRRendererProps> = ({
  value,
  publicLogoType,
  asPreview,
  backgroundColor,
  patternColor,
  cornerColor,
  patternType = EPatternType.SQUARE,
  cornerType = ECornerType.SQUARE,
  frameType = EFrameType.NONE,
  frameText,
  frameTextSize,
  frameTextColor,
  frameBackgroundColor,
  frameClassName,
  className,
  qrId,
  gtin,
  qrTemplateId,
  libraryId,
  isOption,
  onQRCode,
  onDownloadHandler,
  fileName = '',
}) => {
  if (!fileName) {
    fileName = getQRfileNameForDownload(qrId);
  }

  if (!value) {
    if (qrTemplateId) {
      value = getLinkForQrCodeTemplate(qrTemplateId);
    } else if (qrId && gtin) {
      value = getLinkForGS1(qrId, gtin);
    } else if (qrId) {
      value = getLinkForCode(qrId);
    } else if (asPreview) {
      value = MOCK_VALUE_FOR_PREVIEW;
    }
  }

  const [frameRef, setFrameRef] = useState<SVGElement | null>(null);
  const [contentRef, setContentRef] = useState<SVGElement | null>(null);
  const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);

  const { data: library } = useGetLibraryById(libraryId !== NO_LOGO_ID ? libraryId : undefined);

  // TODO use util to get file src
  // TODO fetch small size
  // const logoSrc = library ? `${typedEnv.REACT_APP_API_BASE_URL}/public/files/${library.file.id}` : undefined;
  // TODO handle loading
  const [blobSrc, imgLoading] = useBackgroundImageDownload(library?.file.id, true);

  const finalUrl = publicLogoType === WIDGET_LOGO_OPTION_APP_LOGO ? getRawImageUrl.rawAppLogoUrl : blobSrc;

  const { qrCode } = useMemo(() => {
    const qrCode = new QRCodePatched(BASE_QR_RENDER_OPTIONS);

    // const container = document.createElement('div');
    // container.style.position = 'absolute';
    // qrCode.append(container);

    // TODO
    // TODO should include frame into download
    // probably need to render frame as SVG here - inside QRCodeRenderer component
    // container.onclick = () => {
    //   qrCode.download({
    //     extension: 'svg',
    //   });
    // };

    return { qrCode };
  }, []);

  const allRenderOptions: Options = useMemo(() => {
    return {
      ...BASE_QR_RENDER_OPTIONS,
      data: value,
      backgroundOptions: {
        color: backgroundColor,
      },
      dotsOptions: {
        type: normalizePatternType(patternType),
        color: patternColor,
      },
      cornersSquareOptions: {
        type: normalizeCornerSquareType(cornerType),
        color: cornerColor,
      },
      cornersDotOptions: {
        type: normalizeCornerDotType(cornerType),
        color: cornerColor,
      },
      image: finalUrl,
      imageOptions: {
        crossOrigin: 'anonymous',
        hideBackgroundDots: true,
        // margin: calcImageMargin(BASE_QR_RENDER_OPTIONS.width),
      },
    } as const;
  }, [value, backgroundColor, patternType, patternColor, cornerType, cornerColor, finalUrl]);

  useEffect(() => {
    qrCode.update(allRenderOptions);
  }, [qrCode, allRenderOptions]);

  useEffect(() => {
    onQRCode?.(qrCode);
  }, [qrCode, onQRCode]);

  const [qrScale, setQrScale] = useState(1);
  const [latestRenderedOptions, setLatestRenderedOptions] = useState<object>({});
  // important: compare via ref only
  const isReadyForDownload = latestRenderedOptions === allRenderOptions;
  // console.log(isReadyForDownload, latestRenderedOptions, allRenderOptions);

  useEffect(() => {
    // TODO refactor this callback hell setup
    if (
      !onDownloadHandler ||
      !rootRef ||
      !isReadyForDownload ||
      // ref needed to get outerHTML
      !frameRef
    ) {
      onDownloadHandler?.(undefined);
      return;
    }
    const snapshotOptions = allRenderOptions;
    const snapshotQrId = qrId;
    const downloadHandler: QRDownloadHandlerFn = async ({
      skipAutoDownload,
      delay,
      expectedQrId,
      onlyServerSideRendering,
      fileType,
    } = {}) => {
      if (!rootRef) {
        throw new Error('no root ref yet');
      }

      if (typeof delay === 'number') {
        await new Promise((res) => setTimeout(res, 1e3));
      }

      const isSVGFileType = fileType === EQRDownloadType.SVG;
      let href = '';
      if (isSVGFileType) {
        href = prepareDownloadHrefFromSVG(frameRef).href;
      } else if (frameType === EFrameType.NONE) {
        const finalOptions: Options = {
          ...snapshotOptions,
          type: 'canvas',
          width: DOWNLOAD_SIZE,
          height: DOWNLOAD_SIZE,
          imageOptions: {
            ...snapshotOptions.imageOptions,
            margin: calcImageMargin(DOWNLOAD_SIZE),
          },
        } as const;
        if (IS_LOCALHOST) {
          console.log('download options', finalOptions);
        }
        const fastQr = await generateFastAndDownloadHandler(finalOptions);
        const canvas = fastQr._canvas;
        if (!canvas) {
          throw new Error('qr canvas not defined');
        }
        href = canvas.toDataURL('image/png');
      } else {
        if (snapshotQrId || onlyServerSideRendering) {
          console.log('start qr SSR', { snapshotQrId, onlyServerSideRendering });
          if (!snapshotQrId) {
            throw new Error('qr id missing');
          }
          if (expectedQrId && expectedQrId !== snapshotQrId) {
            throw new Error('expected qr id mismatch');
          }
          const params: ExtractParams<typeof Routes.render.qr> = { id: snapshotQrId };
          // TODO remove hardcoded servie when QR code generated on device
          const prerenderHref = `https://render-trueqrcode.osdb.io/load-as-png?apiKey=trueqrcode-token-1715242095311&wait_for_selector=.qr-ready&filename=qr-code.png&url=${
            typedEnv.REACT_APP_HOST_URL
          }${generatePath(Routes.render.qr, params)}`;
          const response = await fetch(prerenderHref);
          if (response.status !== 200) {
            throw new Error('qr rendering failed');
          }
          console.log('qr SSR received', response);
          const blob = await response.blob();
          const link = URL.createObjectURL(blob);
          console.log('qr SSR blob', blob, link);
          href = link;
        } else {
          /**
           * @deprecated flow - remove html2canvas rendering because it does not work well on weak devices
           * and not working on Windows, mobile devices etc.
           */

          /*
           * fix text vertical alignment for frame when image rendered
           * as per https://github.com/niklasvh/html2canvas/issues/2775#issuecomment-1316356991
           */
          const style = document.createElement('style');
          document.head.appendChild(style);
          style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');
          /** end of fix */

          const rect = rootRef.getBoundingClientRect();
          const max = Math.max(rect.width, rect.height);
          // TODO handle dynamic size
          const scale = DOWNLOAD_SIZE / max;
          const canvas = await html2canvas(rootRef, {
            // see options at https://html2canvas.hertzen.com/configuration
            // TODO scale for proper size
            scale: 1,
            backgroundColor: null,
            onclone: (doc, el) => {
              el.style.transform = `scale(${scale})`;
              // console.log('clone', doc, el, el.parentElement);
            },
            allowTaint: true,
            useCORS: true,
            // width: 1024,
            // height: 1024,
          });
          style.remove();
          href = canvas.toDataURL('image/png');
        }
      }

      if (!href) {
        throw new Error('qr href not defined');
      }

      // const href = await htmlToImage.toPng(rootRef);
      if (!skipAutoDownload) {
        const link = document.createElement('a');
        // TODO handle filename for different types
        const getFileNameWithExtension = () => {
          if (!isSVGFileType) {
            return fileName;
          }
          return `${fileName.replace(/\.\w+$/, '')}.svg`;
        };
        link.download = getFileNameWithExtension();
        link.href = href;
        link.click();
        if (snapshotQrId) {
          QRCodeApi.registerDownload(snapshotQrId);
        }
      }
      return {
        // TODO need better name because not canvas if server rendered QR?
        canvasDataURL: href,
      };
    };
    const debugDownloadHandler: QRDownloadHandlerFn = async (...args) => {
      const loadId = Math.round(Math.random() * 1e3);
      const start = Date.now();
      console.log('start qr generation', `[#${loadId}]`);
      const res = await downloadHandler(...args);
      console.log('qr generation speed', Date.now() - start, `[#${loadId}]`);
      return res;
    };
    onDownloadHandler(debugDownloadHandler);
  }, [rootRef, onDownloadHandler, fileName, isReadyForDownload, frameType, allRenderOptions, qrId, frameRef]);

  useEffect(() => {
    const p = qrCode._svgDrawingPromise;
    if (!p) {
      return;
    }
    let mounted = true;
    const snapshotOptions = allRenderOptions;
    p.then(() => {
      if (!mounted) {
        return;
      }
      // console.log('complete render');
      setLatestRenderedOptions(snapshotOptions);
    });
    return () => {
      mounted = false;
    };
  }, [qrCode, allRenderOptions]);

  useEffect(() => {
    if (!contentRef) {
      return;
    }
    // ref.append(container);
    contentRef.innerHTML = '';
    qrCode.append(contentRef as any);

    const resize = () => {
      const parent = contentRef.parentElement;
      if (!parent) {
        // console.log('skip resize');
        return;
      }
      const rect = parent.getBoundingClientRect();
      const min = Math.min(rect.width, rect.height);
      const scale = min / BASE_SIZE;
      // TODO remove transform
      // ref.firstChild instanceof SVGElement && (ref.firstChild.style.transform = `scale(${scale})`);
      const svg = contentRef.firstChild instanceof SVGElement ? contentRef.firstChild : null;
      if (svg) {
        // const width = Number(svg.getAttribute('width')) || BASE_SIZE;
        // svg.setAttribute('viewBox', `0 0 ${width / scale} ${width / scale}`);
        svg.setAttribute('viewBox', `0 0 ${BASE_SIZE} ${BASE_SIZE}`);
      }
      setQrScale(scale);
      // console.log('resize', parent, rect, ref, min);
    };

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === contentRef) {
          resize();
        }
      }
    });
    resize();
    resizeObserver.observe(contentRef);
    return () => {
      resizeObserver.disconnect();
    };
  }, [frameRef, contentRef, qrCode]);

  return (
    <div ref={setRootRef} className={clsxm((imgLoading || !isReadyForDownload) && 'opacity-50')} data-qr-id={qrId}>
      <FrameContainerSVG
        className={clsxm('flex items-center justify-center overflow-hidden border-0', frameClassName)}
        isOption={isOption}
        type={frameType}
        text={frameText}
        fontSize={frameTextSize}
        color={frameTextColor}
        backgroundColor={frameBackgroundColor}
        qrBackgroundColor={backgroundColor}
        scale={qrScale}
        /* key is important for proper setRef */
        key="frame-qr-code"
        setFrameRef={setFrameRef}
        setContentRef={setContentRef}
        containerClassName={clsxm('pointer-events-none aspect-square w-full origin-top-left leading-zero', className)}
      />
    </div>
  );
};

const BASE_QR_RENDER_OPTIONS = {
  width: BASE_SIZE,
  height: BASE_SIZE,
  type: 'svg',
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 5,
  },
} as const;

const generateFastAndDownloadHandler = async (options: Options) => {
  const qr = new QRCodePatched(options);
  await qr._canvasDrawingPromise;
  return qr;
};

const calcImageMargin = (qrWidth: number) => (qrWidth * 5) / BASE_SIZE;

const prepareDownloadHrefFromSVG = (currSvg: SVGElement) => {
  const blob = new Blob([currSvg.outerHTML], {
    type: 'image/svg+xml;charset=utf-8',
  });
  const href = URL.createObjectURL(blob);
  setTimeout(() => {
    URL.revokeObjectURL(href);
  }, 30e3);

  return { href };
};
