// TODO after release
// import { LabelIcon, UploadIcon } from '@app/assets';
import { DeleteIcon } from '@app/assets';
import { Button, Checkbox, Pagination, PlaceholderBox, Typography } from '@app/components';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { Routes } from '@app/constants/routes';
import { PlanLimitTooltip } from '@app/domain/plan/components/PlanLimitTooltip';
import { usePlanLimits } from '@app/domain/plan/hooks/usePlanLimits';
import { useDeleteQRCode, useGetQRCodes, useMultiDeleteQRCodes } from '@app/domain/qr-code/api/qr-code.hooks.api';
import { QRCodeContainer } from '@app/domain/qr-code/components/QRCodeContainer';
import { useQRCodeQueryParams } from '@app/domain/qr-code/qr-code.hooks';
import { QRCodeOutDtoOverridden } from '@app/domain/qr-code/qr-code.types';
import { QRCodeSearch } from '@app/domain/qr-code/QRCodeSearch';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';
import { useBasicQueryControls } from '@app/hooks/useBasicQueryControls.hook';
import { useTypedNavigate } from '@app/router';
import { clsxm } from '@app/styles/clsxm';
import AddIcon from '@mui/icons-material/Add';
import pluralize from 'pluralize';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { MaybeDownloadQrCodeDialog } from './MaybeDownloadQrCodeDialog';

export const MyQrCodesPage: React.FC = () => {
  const { t } = useTranslation();
  const [selectedQRCodes, setSelectedQRCodes] = useState<QRCodeOutDtoOverridden[]>([]);

  const navigate = useTypedNavigate();
  const [{ term, sort, type }] = useQRCodeQueryParams();
  const {
    values: { page, size = 10 },
    handlers: { handlePageChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      size: 10,
    },
  });

  const { data: qrCodes, isFetching } = useGetQRCodes({
    term,
    sort,
    type,
    page: Math.ceil((page || 1) - 1),
    size,
  });
  const { mutate: deleteQRCode, isLoading } = useDeleteQRCode();
  const { mutate: multiDeleteQRCode, isLoading: multiDeleteLoading } = useMultiDeleteQRCodes();

  const handleDeleteQRCode = useCallback(
    (id: string) => {
      deleteQRCode(id, {
        onSuccess: () => {
          setSelectedQRCodes((prev) => prev.filter((q) => q.id !== id));
          toast.success(t('toaster.qrDeleted'));
        },
      });
    },
    [deleteQRCode, t]
  );

  const handleSelectQRCode = useCallback((qrCode: QRCodeOutDtoOverridden, selected: boolean) => {
    if (selected) {
      setSelectedQRCodes((prev) => [...prev, qrCode]);
    } else {
      setSelectedQRCodes((prev) => prev.filter((p) => p.id !== qrCode.id));
    }
  }, []);

  const handleEditNavigate = useCallback(
    (qrCodeId: string) => {
      navigate({ to: Routes.customer.my_qr_codes.edit, params: { id: qrCodeId }, preserveQueryParams: true });
    },
    [navigate]
  );

  const pagesCount = useMemo(() => {
    return Math.ceil((qrCodes?.total || 0) / size);
  }, [size, qrCodes?.total]);

  const numberOfItemsDisplayed = useMemo(() => {
    return page === pagesCount ? (qrCodes?.total || 0) - size * (page - 1) : size;
  }, [page, pagesCount, qrCodes?.total, size]);

  const selectedAllTemplates = useMemo(() => {
    return (
      !!qrCodes?.result.length && qrCodes.result.every((temp) => !!selectedQRCodes.find((sd) => sd.id === temp.id))
    );
  }, [qrCodes, selectedQRCodes]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  const { canCreateQRCode } = usePlanLimits();

  const showLoader = isFetching || isLoading || multiDeleteLoading;

  return (
    <div className="relative flex h-full flex-col gap-4 p-6">
      <div className="flex items-center justify-between gap-4">
        <MaybeDownloadQrCodeDialog />
        <Typography variant="xl" className="font-bold lg:text-xxl">
          {t('myQRCodes.pageTitle')}
        </Typography>
        <PlanLimitTooltip condition={!canCreateQRCode}>
          <div
            className={clsxm(
              'fixed bottom-0 left-0 right-0 z-10 flex h-[100px] items-end justify-center bg-white pb-10',
              'lg:relative lg:h-auto lg:bg-inherit lg:pb-0'
            )}
          >
            <Button
              onClick={() => {
                navigate({ to: Routes.customer.create_qr_code });
              }}
              startIcon={<AddIcon />}
              disabled={!canCreateQRCode}
            >
              {t('myQRCodes.createQRCode')}
            </Button>
          </div>
        </PlanLimitTooltip>
      </div>
      <QRCodeSearch />

      <div className="grid grid-cols-2 items-center justify-between gap-x-4 gap-y-2  sm:grid-cols-[1fr_auto_auto]">
        <Checkbox
          label={
            <span className="text-gray-700">
              {selectedAllTemplates ? t('manageResults.deselectAll') : t('manageResults.selectAll')}
            </span>
          }
          checked={selectedAllTemplates}
          onChange={() => {
            if (selectedAllTemplates) {
              setSelectedQRCodes([]);
            } else {
              if (qrCodes?.result) {
                const uniqueQRCodes = qrCodes.result.reduce((acc: QRCodeOutDtoOverridden[], qrCode) => {
                  if (!selectedQRCodes.some((obj) => obj.id === qrCode.id)) {
                    return [...acc, qrCode];
                  }

                  return acc;
                }, []);
                setSelectedQRCodes((prev) => [...prev, ...uniqueQRCodes]);
                return;
              }
              setSelectedQRCodes([]);
            }
          }}
        />
        <div className="flex items-center justify-end gap-1 text-xs text-gray-700">
          <span
            className={clsxm(
              'min-w-[20px] rounded-xl bg-gray-500 px-[6px] py-[2px] text-center font-medium text-white transition-all',
              selectedQRCodes.length && 'bg-primary'
            )}
          >
            {selectedQRCodes.length}
          </span>
          <span>{t('manageResults.selected')}</span>
        </div>
        {!!selectedQRCodes.length && (
          <div className="col-span-2 flex items-center justify-between gap-2 sm:col-span-1">
            {/* TODO after release */}
            {/* <Button disabled className="px-3" size="small" variant="text" color="secondary" startIcon={<LabelIcon />}>
              Label
            </Button> */}
            <ConfirmDialog
              trigger={
                <Button className="px-3" size="small" variant="text" color="secondary" startIcon={<DeleteIcon />}>
                  {t('dialog.delete')}
                </Button>
              }
              onConfirm={() => {
                const qrCodeIds = selectedQRCodes.map(({ id }) => id);
                multiDeleteQRCode(
                  { qrCodeIds },
                  {
                    onSuccess: () => {
                      toast.success(qrCodeIds.length === 1 ? t('toaster.qrsDeleted') : t('toaster.qrDeleted'));
                      setSelectedQRCodes([]);
                    },
                  }
                );
              }}
              containerClassName="max-w-[350px] text-center"
              // TODO i18n
              text={`Are you sure you want to delete  ${pluralize('these', selectedQRCodes.length)} QR ${pluralize(
                'codes',
                selectedQRCodes.length
              )}?`}
              title={`Delete selected QR ${pluralize('codes', selectedQRCodes.length)}?`}
              confirmText="Yes, Delete"
            />
            {/* TODO after release */}
            {/* <Button disabled className="px-3" size="small" variant="text" color="secondary" startIcon={<UploadIcon />}>
              {t('qr.buttonDownload')}
            </Button> */}
          </div>
        )}
      </div>

      <div className="relative flex flex-1 flex-col gap-4">
        {!qrCodes?.total && !isFetching ? (
          <PlaceholderBox variant="primary" title={t('placeholder.noQRsCreated')} />
        ) : null}

        <div className={clsxm('flex flex-col gap-4', showLoader && 'pointer-events-none opacity-80')}>
          {qrCodes?.result.map((qrCode) => (
            <QRCodeContainer
              key={qrCode.id}
              qrCode={qrCode}
              selected={!!selectedQRCodes.find((sqr) => sqr?.id === qrCode?.id)}
              handleDeleteQRCode={handleDeleteQRCode}
              handleSelectQRCode={handleSelectQRCode}
              handleNavigate={handleEditNavigate}
            />
          ))}
        </div>
        {showLoader ? (
          <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
            <ContentLoader isEmpty={false} loading entity="QR Codes" />
          </div>
        ) : null}
      </div>

      {!!page && !!qrCodes?.total && (
        <div className="flex flex-col items-center justify-between gap-4 pb-[100px] sm:mb-4 sm:flex-row lg:pb-0">
          <div className="text-gray-700">
            {t('search.showingAmountOfTotalCodes', { amount: numberOfItemsDisplayed, total: qrCodes.total })}
          </div>
          <Pagination currentPage={page} pageSize={size} total={qrCodes.total} onPagination={handlePageChange} />
        </div>
      )}
    </div>
  );
};
