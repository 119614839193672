import { Container, Typography } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { useTranslation } from 'react-i18next';

type Props = {
  className: string;
};

export const AccountAPIDocumentation: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Container className={clsxm('flex flex-col gap-6 p-6 lg:rounded-xl', className)}>
      <Typography variant="xxl" className="text-lg text-gray-800 font-bold">
        {t('apikey.documentation.title')}
      </Typography>
      <p className="text-gray-800 text-s font-normal">{t('apikey.documentation.description')}:</p>
      <a
        href="https://docs.trueqrcode.com/"
        target="_blank"
        className="text-accept-main font-bold underline underline-offset-2"
        rel="noreferrer"
      >
        https://docs.trueqrcode.com/
      </a>
    </Container>
  );
};
