import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { GalleryEditor } from '@app/components/gallery-editor/GalleryEditor';
import { useCreateLibrary, useDeleteLibrary } from '@app/domain/library/api/library.hooks.api';
import { usePersistedPublicImages } from '@app/domain/qr-code/components/input/usePersistedPublicImages';
import { clsxm } from '@app/styles/clsxm';
import { ELibraryType } from '@app/swagger-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WIDGET_LOGO_OPTION_APP_LOGO } from '../widget.constants';

/**
 * Gallery adapted from src/domain/qr-code/components/input/vcard/QRCodePersonalInfo.tsx
 */
export const LogosPublicGallery: React.FC<{
  publicLogoType?: typeof WIDGET_LOGO_OPTION_APP_LOGO;
  libraryId?: string;
  onLibSelect: (libId?: string) => void;
}> = ({ publicLogoType, libraryId, onLibSelect }) => {
  const forWidgetMode = true;
  const { t } = useTranslation();

  const { mutate: createLogos, isLoading: creatingLogos } = useCreateLibrary({ forWidgetMode });
  const { mutate: deleteLogo, isLoading: deletingLogo } = useDeleteLibrary({ forWidgetMode });

  const libraryType = ELibraryType.DESIGN_LOGO;
  const { libraries: publicLibs, setLibraries: setPublicLibs } = usePersistedPublicImages(libraryType);

  const workLibs = publicLibs;
  const { images, selectedImageId } = useMemo(() => {
    // console.log('changed libs', workLibs);
    const images = workLibs?.result.map((r) => ({ imageId: r?.file?.id, libraryId: r.id, id: r.id })) || [];
    const selectedImageId =
      workLibs?.result.find((l) => l.id === libraryId)?.file?.id ||
      (publicLogoType === WIDGET_LOGO_OPTION_APP_LOGO ? WIDGET_LOGO_OPTION_APP_LOGO : NO_LOGO_ID);
    return { images, selectedImageId };
  }, [workLibs, libraryId, publicLogoType]);

  return (
    <div className={clsxm('w-full', deletingLogo && 'pointer-events-none opacity-70')}>
      {/* TODO refactor: reuse editor with hooks into single component and single hook */}
      <GalleryEditor
        forWidgetMode={forWidgetMode}
        value={images}
        deleteOnServer={false}
        withNoImg
        withAppLogoImg
        dropZoneLoading={creatingLogos}
        selectedImageId={selectedImageId}
        onClickImage={(img) => {
          if (img.id === NO_LOGO_ID) {
            onLibSelect(NO_LOGO_ID);
            return;
          }
          if (img.id === WIDGET_LOGO_OPTION_APP_LOGO) {
            onLibSelect(WIDGET_LOGO_OPTION_APP_LOGO);
            return;
          }
          const logo = workLibs?.result.find((l) => l.file?.id === img.imageId);
          if (logo) {
            onLibSelect(logo.id || NO_LOGO_ID);
          }
        }}
        handleUploadIds={(v) => {
          createLogos(
            { fileIds: v, libraryType },
            {
              onSuccess: (data) => {
                try {
                  if (forWidgetMode) {
                    setPublicLibs((prev) => {
                      if (!prev) {
                        return data;
                      }
                      const newArr = prev.result.concat(data.result);
                      return {
                        result: newArr,
                        total: newArr.length,
                      };
                    });
                  }
                  const lastUploadLogo = data.result[data.result.length - 1];
                  if (lastUploadLogo) {
                    onLibSelect(lastUploadLogo.id);
                  }
                } catch {}
              },
            }
          );
        }}
        handleDeleteId={(id) => {
          const logo = workLibs?.result?.find((l) => l?.file?.id === id);
          if (logo && logo?.id) {
            if (forWidgetMode) {
              setPublicLibs((data) =>
                data
                  ? {
                      ...data,
                      result: data?.result.filter((img) => img.id !== logo.id),
                    }
                  : data
              );
            } else {
              deleteLogo(logo.id);
            }
          }
        }}
        onChange={(v) => {
          if (!v.find((img) => img.imageId === selectedImageId && selectedImageId !== NO_LOGO_ID)) {
            onLibSelect(NO_LOGO_ID);
          }
        }}
        noImgText={t('qr.stepInput.noImage')}
      />
    </div>
  );
};
