import { EmptyPlaceholderIcon } from '@app/assets/icons/vcard';
import { Avatar, Typography } from '@app/components';
import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { useGetLibraryById } from '@app/domain/library/api/library.hooks.api';
import { GS1FormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { AnyLink, QRCodeSocialLinks } from '@app/domain/qr-code/components/QRCodeSocialLinks';
import { clsxm } from '@app/styles/clsxm';
import { ELinkType, GS1OutDto } from '@app/swagger-types';
import { useTranslation } from 'react-i18next';
import { QRCodePreviewWrap } from '../../QRCodePreviewWrap';

export const GS1Preview: React.FC<{
  gs1?: GS1OutDto | null;
  gs1FromForm?: GS1FormSchema | null;
  isRenderFromForm?: boolean;
}> = ({ gs1, gs1FromForm, isRenderFromForm }) => {
  const { t } = useTranslation();
  const currentGS1 = isRenderFromForm ? gs1FromForm : gs1;

  const { data: library } = useGetLibraryById(
    gs1FromForm?.libraryId !== NO_LOGO_ID ? gs1FromForm?.libraryId : undefined,
    {
      enabled: Boolean(isRenderFromForm),
    }
  );

  const fileId = isRenderFromForm ? library?.file?.id : gs1?.library?.file?.id;

  const mockDescription = !currentGS1?.title && t('qr.gs1Input.descriptionPlaceholder');
  const visibleDescription = currentGS1?.description || mockDescription;
  const showDemoLinks = !currentGS1?.title && !currentGS1?.socialLinks?.length && !currentGS1?.customLinks?.length;

  const linkStyle = currentGS1
    ? {
        buttonBgColor: currentGS1.buttonBgColor,
        buttonTextColor: currentGS1.buttonTextColor,
        buttonHoverColor: currentGS1.buttonHoverColor,
        buttonBorderColor: currentGS1.buttonBorderColor,
      }
    : undefined;

  const DEMO_LINKS: AnyLink[] = [
    { linkType: ELinkType.CUSTOM, title: t('qr.gs1Input.demoLinkProduct') },
    { linkType: ELinkType.CUSTOM, title: t('qr.gs1Input.demoLinkAllergens') },
    { linkType: ELinkType.CUSTOM, title: t('qr.gs1Input.demoLinkRecipes') },
    { linkType: ELinkType.CUSTOM, title: t('qr.gs1Input.demoLinkCompany') },
  ];

  return (
    <QRCodePreviewWrap
      isRenderFromForm={isRenderFromForm}
      style={{
        backgroundColor: currentGS1?.designBgColor,
      }}
    >
      <div className="flex items-center justify-center">
        <div
          style={{
            backgroundColor: currentGS1?.designBgColor,
          }}
          className={clsxm('mt-[50px] mb-6 h-[70px] w-[70px] overflow-hidden rounded-[10px]')}
        >
          <Avatar
            isPublic
            imageId={fileId}
            className="rounded-none"
            skeletonVariant="rectangular"
            height="100%"
            width="100%"
          >
            <EmptyPlaceholderIcon />
          </Avatar>
        </div>
      </div>
      <div className="flex flex-col gap-2 mx-4">
        <Typography
          variant="l"
          className="block text-center font-bold text-white"
          style={{ wordBreak: 'break-word', color: currentGS1?.designTitleColor }}
        >
          {currentGS1?.title || t('qr.gs1Input.title')}
        </Typography>
        {visibleDescription && (
          <Typography
            variant="xs"
            className="text-gray-500 block font-normal text-xxs text-center"
            style={{ wordBreak: 'break-word', color: currentGS1?.designDescriptionColor }}
          >
            {visibleDescription}
          </Typography>
        )}
      </div>
      <div className="px-4 pt-8">
        {!showDemoLinks && <QRCodeSocialLinks links={currentGS1?.customLinks} className="mb-2" style={linkStyle} />}
        <QRCodeSocialLinks
          links={currentGS1?.socialLinks}
          demoLinks={DEMO_LINKS}
          showDemoLinks={showDemoLinks}
          style={linkStyle}
        />
      </div>
    </QRCodePreviewWrap>
  );
};
