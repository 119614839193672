import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TelegramIcon,
  TikTokIcon,
  TwitterIcon,
  WhatsAppIcon,
  YoutubeIcon,
} from '@app/assets/icons/social-networks';
import { Container, IconButton, Input, Typography } from '@app/components';
import { QRCodeFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { clsxm } from '@app/styles/clsxm';
import { ELinkType } from '@app/swagger-types';
import { Box, Divider } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { SvgScopeWrap } from '@app/utils/SvgScopeWrap';

// type BrandLink = typeof SOCIAL_NETWORKS[number];

export const SOCIAL_NETWORKS = [
  {
    label: 'Facebook URL',
    icon: <FacebookIcon />,
    linkType: ELinkType.FACEBOOK,
    placeholder: 'https://facebook.com/profile-id',
  },
  {
    label: 'LinkedIn URL',
    icon: <LinkedInIcon />,
    linkType: ELinkType.LINKEDIN,
    placeholder: 'https://linkedin.com/profile-id',
  },
  {
    label: 'TikTok URL',
    icon: <TikTokIcon />,
    linkType: ELinkType.TIKTOK,
    placeholder: 'https://tiktok.com/profile-id',
  },
  {
    label: 'Telegram URL',
    icon: <TelegramIcon />,
    linkType: ELinkType.TELEGRAM,
    placeholder: 'https://t.me/profile-id',
  },
  {
    label: 'WhatsApp URL',
    icon: <WhatsAppIcon />,
    linkType: ELinkType.WHATSAPP,
    placeholder: 'https://chat.whatsapp.com/chat-id',
  },
  {
    label: 'Instagram URL',
    icon: <InstagramIcon />,
    linkType: ELinkType.INSTAGRAM,
    placeholder: 'https://instagram.com/profile-id',
  },
  {
    label: 'Youtube URL',
    icon: <YoutubeIcon />,
    linkType: ELinkType.YOUTUBE,
    placeholder: 'https://youtube.com/profile-id',
  },
  {
    label: 'Twitter URL',
    icon: <TwitterIcon />,
    linkType: ELinkType.TWITTER,
    placeholder: 'https://x.com/profile-id',
  },
];
SOCIAL_NETWORKS.forEach((e) => {
  e.icon = <SvgScopeWrap>{e.icon}</SvgScopeWrap>;
});

export const QRCodeSocialNetworks: React.FC<{
  formPath: 'vcard.links' | 'multilink.socialLinks';
  disabledEditInput?: boolean;
}> = ({ formPath, disabledEditInput }) => {
  const { t } = useTranslation();
  // const [selectSocialNetwork, setSelectSocialNetwork] = useState<BrandLink | null>(null);
  const { control, watch } = useFormContext<QRCodeFormSchema>();
  const { fields, remove, append } = useFieldArray({
    control,
    name: formPath,
  });

  const updatedFields = watch(formPath);

  return (
    <Container className="grid grid-cols-1 gap-2 xl:rounded-xl">
      <Typography className="font-bold md:text-l">{t('qr.socialNetworks')}</Typography>
      <Typography variant="xxs" className="text-gray-500">
        {t('qr.selectNetwork')}
      </Typography>
      <div className="mt-4 grid grid-cols-4 gap-2 md:grid-cols-8">
        {SOCIAL_NETWORKS.map((sn, idx) => {
          const already = updatedFields?.find((f) => f.linkType === sn.linkType);
          if (already) {
            return null;
          }
          return (
            <Box
              onClick={() => {
                if (disabledEditInput) {
                  return;
                }
                // setSelectSocialNetwork(() => sn);
                if (!already) {
                  append({ linkType: sn.linkType, url: '' });
                }
              }}
              key={idx}
              className={clsxm('flex justify-center')}
            >
              <div
                className={clsxm(
                  'child:h-full child:w-full',
                  'height-w-[56px] relative max-w-[56px] rounded-[10px] border border-transparent bg-gray-100 p-[6px]',
                  !disabledEditInput && 'cursor-pointer'
                  // selectSocialNetwork?.label === sn?.label && 'border-primary bg-white'
                )}
              >
                {sn.icon}
              </div>
            </Box>
          );
        })}
      </div>
      <Divider className="my-4" />
      {fields.map((f, idx) => {
        const brand = SOCIAL_NETWORKS.find((e) => f.linkType === e.linkType);
        if (!brand) {
          return null;
        }
        return (
          <Controller
            key={f.id}
            name={`${formPath}.${idx}.url`}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => {
              return (
                <Input
                  {...field}
                  label={brand.label}
                  withBG
                  value={value || ''}
                  placeholder={brand.placeholder}
                  startAdornment={
                    <IconButton disabled variant="transparent">
                      {brand.icon}
                    </IconButton>
                  }
                  errorMessage={fieldState?.error?.message || ''}
                  endAdornment={
                    <IconButton
                      size="small"
                      onClick={() => {
                        field.onChange('');
                        remove(idx);
                      }}
                      className={clsxm('mr-1 bg-white')}
                      variant="transparent"
                      disabled={disabledEditInput}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                  disabled={disabledEditInput}
                />
              );
            }}
          />
        );
      })}
    </Container>
  );
};
