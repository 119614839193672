import { APIQueryParams } from '@app/models/api';
import { apiService, validateAndAssert } from '@app/services/api-service';
import { ECurrency } from '@app/swagger-types';
import { pageableData } from '@app/utils/yup.utils';
import { PlanOutDtoSchema } from './plan.schema.api';

export abstract class PlanApi {
  static async getAll(params: { currency: ECurrency } & APIQueryParams) {
    const res = await validateAndAssert(apiService.get('/private/plans', { params }), pageableData(PlanOutDtoSchema));
    res.result.sort((a, b) =>
      ORDER_OF_PLANS.indexOf(a.name.toLowerCase()) > ORDER_OF_PLANS.indexOf(b.name.toLowerCase()) ? 1 : -1
    );
    return res;
  }
  static async getCurrentPlan() {
    return validateAndAssert(apiService.get('/private/plans/current-plan-config'), PlanOutDtoSchema);
  }
}

const ORDER_OF_PLANS = [
  'basic',
  'starter',
  'premium',
  // new plans
  'free',
  'pro',
  'business',
];
