import styles from './Table.module.scss';
import clsx from 'clsx';

import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  LinearProgress,
  Fade,
} from '@mui/material';

import { TableColumn } from './TableTypes';
import { NotFound } from './components/not-found/NotFound';
import { TableLoader } from './components/table-loader/TableLoader';
import { Checkbox } from '@app/components';
import { TableCard } from '@app/components/table-card/TableCard';

interface TableProps<T> {
  id?: string;
  idKey?: keyof T;
  notFoundMessage?: string;
  cols: TableColumn<T>[];
  tableData: T[];
  loading?: boolean;
  total?: number;
  currentPage?: number;
  pageSize?: number;
  selectedItems?: T[];
  selectableCells?: boolean;
  showSelectAll?: boolean;
  handleAllSelect?: (selectedItems: T[]) => void;
  handleCellSelect?: (selectedItem: T, isChecked: boolean) => void;
  rightHeaderContent?: React.ReactNode;
  leftHeaderContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  headerContent?: React.ReactNode;
  onPagination?: (page: number) => void;
  currentSort?: string;
  onSortChanged?: (newSort: string) => void;
  currentItem?: T;
  onItemSelect?: (item: T) => void;
  onSizeChange?: (newSize: number) => void;
  headerNameClassName?: string;
  tableRowClassName?: string;
  tableCellClassName?: string;
  wrapperClassName?: string;
  cardHeaderClassName?: string;
  tableHeadCellClassName?: string;
  hasMore?: boolean;
  onHasMoreClick?: () => void;
  hidePageSize?: boolean;
  customRowClassDynamic?: (rowData: T) => string;
}

export function Table<T extends { [key: string]: any }>({
  id,
  idKey = 'id',
  notFoundMessage,
  cols,
  tableData = [],
  loading,
  leftHeaderContent,
  rightHeaderContent,
  footerContent,
  currentPage,
  total = 0,
  selectedItems,
  selectableCells,
  showSelectAll,
  handleAllSelect,
  handleCellSelect,
  pageSize = 10,
  onPagination,
  currentSort,
  onSortChanged,
  currentItem,
  onItemSelect,
  hasMore,
  onHasMoreClick,
  onSizeChange,
  headerContent,
  headerNameClassName,
  tableHeadCellClassName,
  wrapperClassName,
  cardHeaderClassName,
  tableCellClassName,
  tableRowClassName,
  hidePageSize,
  customRowClassDynamic,
}: React.PropsWithChildren<TableProps<T>>) {
  return (
    <TableCard
      total={total}
      wrapperClassName={wrapperClassName}
      cardHeaderClassName={cardHeaderClassName}
      pageSize={pageSize}
      currentPage={currentPage}
      leftHeaderContent={leftHeaderContent}
      rightHeaderContent={rightHeaderContent}
      footerContent={footerContent}
      headerContent={headerContent}
      onPagination={onPagination}
      hasMore={hasMore}
      onHasMoreClick={onHasMoreClick}
      onSizeChange={onSizeChange}
      hidePageSize={hidePageSize}
    >
      <div
        className={clsx(
          styles.TableContent,
          !headerContent && !leftHeaderContent && !rightHeaderContent && styles.NoHeaderContent,
          loading ? styles.Loading : null
        )}
      >
        <TableContainer className={styles.TableContainer}>
          {!!loading && !hasMore && <TableLoader />}

          <MuiTable id={id}>
            <TableHead>
              <MuiTableRow>
                {cols.map((col) => {
                  const [sortField, sortDirection] = currentSort ? currentSort.split(',') : [];
                  return (
                    <TableCell
                      key={col.headerName}
                      align={col.align}
                      width={col.width}
                      className={clsx(
                        styles.TableCell,
                        styles.TableHeadCell,
                        tableCellClassName,
                        {
                          [styles.TableHeadDisabledSort]: col.disableSort,
                          [styles.StickyCell]: col.sticky,
                          [styles.StickyCellRight]: col.sticky === 'right',
                          [styles.StickyCellLeft]: col.sticky === 'left',
                        },
                        col.headCellClassName,
                        tableHeadCellClassName
                      )}
                      onClick={() => {
                        if (onSortChanged && !col.disableSort) {
                          let newDirection = col.firstClickSort || DEFAULT_SORT;

                          if (sortDirection && sortField === col.field) {
                            if (sortDirection === 'asc') {
                              newDirection = 'desc';
                            }
                            if (sortDirection === 'desc') {
                              newDirection = 'asc';
                            }
                          }
                          onSortChanged(`${col.field},${newDirection}`);
                        }
                      }}
                    >
                      <Typography variant="body2" className={clsx(styles.HeaderHame, headerNameClassName)}>
                        {col.headerName}
                        {sortField && sortField === col.field ? renderSortIcon(sortDirection) : null}
                      </Typography>
                    </TableCell>
                  );
                })}
              </MuiTableRow>
            </TableHead>
            <TableBody>
              {showSelectAll && tableData.length > 0 && (
                <MuiTableRow className={clsx(styles.TableBodyRow)}>
                  {cols.map((col, colIndex) => (
                    <TableCell
                      className={clsx(
                        styles.TableCell,
                        {
                          [styles.StickyCell]: col.sticky,
                          [styles.StickyCellRight]: col.sticky === 'right',
                          [styles.StickyCellLeft]: col.sticky === 'left',
                        },
                        tableCellClassName
                      )}
                      key={col.headerName}
                    >
                      {colIndex === 0 && handleAllSelect && (
                        <div className={styles.SelectAllWrapper}>
                          <Checkbox
                            checked={!!tableData.length && selectedItems?.length === tableData.length}
                            className={styles.Checkbox}
                            color="primary"
                            disableRipple
                            onClick={() => handleAllSelect(tableData)}
                          />
                          <Typography className={styles.SelectAllText} variant="subtitle2">
                            Select All
                          </Typography>
                        </div>
                      )}
                    </TableCell>
                  ))}
                </MuiTableRow>
              )}

              {tableData.map((data, rowIndex) => (
                <MuiTableRow
                  key={
                    data[idKey] ||
                    (() => {
                      console.error(`undefined idKey for table: "${String(idKey)}", rowIndex: ${rowIndex}`, data);
                      throw new Error(`undefined idKey for table: "${String(idKey)}", rowIndex: ${rowIndex}`);
                    })()
                  }
                  className={clsx(
                    styles.TableBodyRow,
                    tableRowClassName,

                    {
                      [styles.TableBodyRowSelectable]: onItemSelect,
                      [styles.TableBodyRowSelected]: currentItem === data[idKey],
                    },
                    customRowClassDynamic ? customRowClassDynamic(data) : false
                  )}
                  onClick={() => {
                    if (onItemSelect) {
                      onItemSelect(data);
                    }
                  }}
                >
                  {cols.map((col, colIndex) => {
                    // FOR SELECTIONS ONLY  START

                    const makeCheck = selectableCells && colIndex === 0;
                    let checked = false;

                    if (makeCheck) {
                      const found = selectedItems?.findIndex((v) => v[idKey] === data[idKey]) ?? -1;
                      if (found > -1) {
                        checked = true;
                      }
                    }

                    // FOR SELECTIONS ONLY END

                    return (
                      <TableCell
                        key={col.headerName}
                        align={col.align}
                        onClick={col.disableClick ? (e) => e.stopPropagation() : undefined}
                        className={clsx(
                          styles.TableCell,
                          styles.TableBodyCell,
                          {
                            [styles.DisableClick]: col.disableClick,

                            [styles.StickyCell]: col.sticky,
                            [styles.StickyCellRight]: col.sticky === 'right',
                            [styles.StickyCellLeft]: col.sticky === 'left',
                          },
                          col.bodyCellClassName,
                          tableCellClassName
                        )}
                      >
                        {selectableCells && colIndex === 0 ? (
                          <div className={styles.SelectAllWrapper}>
                            <Checkbox
                              checked={checked}
                              className={styles.Checkbox}
                              color="primary"
                              disableRipple
                              onClick={() => handleCellSelect && handleCellSelect(data, checked)}
                            />
                            {renderCell(col, data)}
                          </div>
                        ) : (
                          renderCell(col, data)
                        )}
                      </TableCell>
                    );
                  })}
                </MuiTableRow>
              ))}
            </TableBody>
          </MuiTable>

          {!loading && !tableData.length && <NotFound notFoundMessage={notFoundMessage} />}
          <Fade in={loading && hasMore} unmountOnExit>
            <LinearProgress className={styles.LinearLoader} />
          </Fade>
        </TableContainer>
      </div>
    </TableCard>
  );
}

const renderCell = <T extends Record<string, any>>({ render, field }: TableColumn<T>, data: T) => {
  if (render) {
    return render(data);
  }

  if (data && data[field]) {
    return <Typography variant="h1">{data[field]}</Typography>;
  }

  return null;
};

const renderSortIcon = (sortDirection?: string) => {
  if (!sortDirection) {
    return null;
  }
  return (
    <ChevronDownIcon
      className={clsx(styles.SortIcon, {
        [styles.SortIconDown]: sortDirection === DEFAULT_SORT,
      })}
    />
  );
};

const DEFAULT_SORT = 'desc';
