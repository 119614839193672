import { CheckCircleIcon, FiRrArrowSmallRight } from '@app/assets';
import { Dialog } from '@app/hoc';
import { useIsMobile } from '@app/hooks/useIsMobile.hook';
import { QRWidgetProductPreview } from '@app/qr-widget/dialog/components/QRWidgetProductPreview';
import { clsxm } from '@app/styles/clsxm';
import { ECurrency, ECustomerSegment, EPlanCycle, PlanOutDto } from '@app/swagger-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerSegmentTile } from './CustomerSegmentTile';
import { Button } from '@app/components';
import { useUpdateCustomer } from '@app/domain/user/api/user.hooks.api';
import { BackButton } from '@app/components/buttons/back-button/BackButton';
import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { FIRST_DAY_DISCOUNT_AVAILABLE_FOR_MS } from '@app/domain/subscription/discount/first-day-discount.constant';
import { IS_PROD_ENV } from '@app/environment/typed-env';
import { useGetPlans } from '@app/domain/plan/api/plan.hooks.api';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { useMutation } from 'react-query';
import { SubscriptionApi } from '@app/domain/subscription/api/subscription.api';
import { Routes } from '@app/constants/routes';
import { FormControlLabel, Radio } from '@mui/material';
import { OnboardingMediaRightSide } from './OnboardingMediaRightSide';
import { useOnboardingDialogState } from './hooks/useOnboardingDialogState';
import { CurrencySelector } from '@app/domain/subscription/components/CurrencySelector';
import { renderCurrencyAsSymbol } from '@app/utils/currency.util';
import { gtag } from '@app/gtag/gtag';
import { marketingSelectors } from '@app/gtag/marketing.constant';
import { DISCOUNTED_MULTIPLIER } from '@app/domain/subscription/constants';

enum EStep {
  INITIAL,
  GET_DISCOUNT_TODAY,
  SELECT_CYCLE,
}

// TODO remove
const TODO_REMOVE_MOLTIPLIER_AFTER_TESTING = IS_PROD_ENV ? 1 : 90;
const TODO_REMOVE_INITIAL_STEP_ON_DEV = EStep.INITIAL;

/**
 * Press escape to hide until page reload
 */
export const OnboardingDialog: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  // TODO remove non-initial step after testing
  const [step, setStep] = useState(IS_PROD_ENV ? EStep.INITIAL : TODO_REMOVE_INITIAL_STEP_ON_DEV);
  const { currentUser } = useAuthorizedUser();
  const { isFreePlan } = useActivePlan();
  const { setState } = useOnboardingDialogState();

  useEffect(() => {
    setState({
      isOpen: open,
    });
  }, [open, setState]);

  useEffect(() => {
    if (step === EStep.INITIAL) {
      return;
    }
    if (isFreePlan) {
      if (currentUser?.createdDate) {
        try {
          const date = new Date(currentUser.createdDate);
          const delta = Date.now() - date.getTime();
          if (delta < FIRST_DAY_DISCOUNT_AVAILABLE_FOR_MS * TODO_REMOVE_MOLTIPLIER_AFTER_TESTING) {
            return;
          }
          console.log('first day discount expired', delta);
        } catch (error) {
          console.error(error);
        }
      }
    }
    onClose();
  }, [isFreePlan, step, onClose, currentUser]);

  const renderStep = () => {
    const onSetStep = (newStep: EStep) => setStep(newStep);
    if (step === EStep.INITIAL) {
      return <StepInitial onSetStep={onSetStep} />;
    }
    if (step === EStep.GET_DISCOUNT_TODAY) {
      return <StepGetDiscountToday onSetStep={onSetStep} />;
    }
    return <StepSelectCycle onSetStep={onSetStep} />;
  };

  const isCloseAvailable = step !== EStep.INITIAL;
  const isRightSideAvailable = step !== EStep.INITIAL;

  const renderRightSide = () => {
    if (isMobile || !isRightSideAvailable) {
      return null;
    }
    if (step === EStep.GET_DISCOUNT_TODAY) {
      return <QRWidgetProductPreview triggerPopup={null} hideFreeBadge />;
    }
    return <OnboardingMediaRightSide />;
  };

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        onClose();
      }}
      hideHeader
      paperClassName="text-accept-dark-blue shadow-none bg-transparent rounded-none"
      maxWidth="md"
      fullScreen={isMobile}
    >
      <div className="flex flex-col gap-4 pt-4 md:pt-0">
        <div
          className={clsxm(
            'rounded-2xl bg-white shadow overflow-hidden',
            // extra space needed on mobile to avoid overlap with support chat FAB on prod
            'pb-20 md:pb-0'
          )}
        >
          {isCloseAvailable && (
            <div
              className={clsxm(
                'flex w-full justify-end pr-4 pt-4',
                !isMobile && 'absolute top-0 right-0 z-10 pointer-events-none'
              )}
            >
              <Button
                variant="outlined"
                className="bg-transparent pointer-events-auto hover:bg-white"
                onClick={() => {
                  onClose();
                }}
              >
                {t('onboarding.buttonMaybeLater')}
              </Button>
            </div>
          )}
          <div className={clsxm('grid', isRightSideAvailable && 'md:grid-cols-2')}>
            {/* left side */}
            <div className="bg-white p-8 md:min-h-[520px] md:min-w-96">{renderStep()}</div>
            {/* right side */}
            {renderRightSide()}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

interface CommonProps {
  onSetStep: (newStep: EStep) => void;
}

const StepInitial: React.FC<CommonProps> = ({ onSetStep }) => {
  const { t } = useTranslation();
  const { currentUser } = useAuthorizedUser();
  const [selectedSegment, setSelectedSegment] = useState<null | ECustomerSegment>(currentUser?.customerSegment || null);
  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();

  return (
    <div className="flex flex-col gap-4 h-full justify-between">
      <div className="flex flex-col gap-4 md:px-4">
        <div className="text-xl font-bold">{t('onboarding.stepInitial.title')}</div>
        {t('onboarding.stepInitial.description')}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {ORDERED_SEGMENT_TILES.map((e) => (
          <CustomerSegmentTile
            segment={e}
            key={e}
            isSelected={selectedSegment === e}
            onSelect={() => {
              if (isLoading) {
                return;
              }
              setSelectedSegment(e);
            }}
          />
        ))}
      </div>
      <div className="self-end">
        <Button
          variant="contained"
          className="w-full"
          onClick={() => {
            if (!selectedSegment) {
              return;
            }
            updateCustomer(
              {
                customerSegment: selectedSegment,
              },
              {
                onSuccess: () => {
                  gtag.segmentSelected({
                    segment: selectedSegment,
                  });
                  onSetStep(EStep.GET_DISCOUNT_TODAY);
                },
              }
            );
          }}
          disabled={!selectedSegment}
          loading={isLoading}
        >
          {t('dialog.next')}
          <FiRrArrowSmallRight />
        </Button>
      </div>
    </div>
  );
};

const ORDERED_SEGMENT_TILES = [
  ECustomerSegment.STUDENT,
  ECustomerSegment.NON_PROFIT_OR_CHARITY,
  ECustomerSegment.TEACHER,
  ECustomerSegment.LARGE_COMPANY,
  ECustomerSegment.PERSONAL,
  ECustomerSegment.SMALL_BUSINESS,
];

const StepGetDiscountToday: React.FC<CommonProps> = ({ onSetStep }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 h-full justify-between">
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 text-xl font-bold items-center">
          <BackButton
            size="small"
            onClick={() => {
              onSetStep(EStep.INITIAL);
            }}
          />
          <div className="text-xl font-bold">
            {t('onboarding.stepGetDiscount.titleFirstPart')} <span className="font-bold text-primary">PRO</span>{' '}
            {t('onboarding.stepGetDiscount.titleLastPart')}
          </div>
        </div>
        <div className="text-xs">{t('onboarding.stepGetDiscount.description')}</div>
      </div>
      <div className="flex flex-col gap-4 text-xs">
        <div className="font-bold">{t('onboarding.stepGetDiscount.hereWhatYouGet')}</div>
        <div className="flex flex-col gap-2">
          <div className="">
            <CheckCircleIcon className="inline-block" /> <b>{t('onboarding.stepGetDiscount.benefit1.highlight')}</b>{' '}
            {t('onboarding.stepGetDiscount.benefit1.description')}
          </div>
          <div className="">
            <CheckCircleIcon className="inline-block" /> <b>{t('onboarding.stepGetDiscount.benefit2.highlight')}</b>{' '}
            {t('onboarding.stepGetDiscount.benefit2.description')}
          </div>
        </div>
      </div>
      <div>
        <Button
          variant="contained"
          className="w-full"
          onClick={() => {
            onSetStep(EStep.SELECT_CYCLE);
          }}
        >
          {t('onboarding.stepGetDiscount.buttonGetDiscount')}
          <FiRrArrowSmallRight />
        </Button>
      </div>
    </div>
  );
};

const PRO_PLAN_NAME = 'Pro';
const DEFAULT_CURRENCY = ECurrency.EUR;

const StepSelectCycle: React.FC<CommonProps> = ({ onSetStep }) => {
  const { t } = useTranslation();
  const [selectedCurrency, setSelectedCurrency] = useState(DEFAULT_CURRENCY);
  const { data: plans, isFetching } = useGetPlans({ currency: selectedCurrency });
  const [selectedCycle, setSelectedCycle] = useState<EPlanCycle>(EPlanCycle.QUARTERLY);

  const subscribeMutation = useMutation({
    mutationFn: async () => {
      if (!proPlan) {
        return;
      }
      const res = await SubscriptionApi.subscribeViaStripeGateway({
        planId: proPlan.id,
        planCycle: selectedCycle,
        currency: selectedCurrency,
        successRedirectURL: `${window.location.origin}${Routes.customer.success_payment}`,
        // TODO dedicated page for error from stripe
        errorRedirectURL: window.location.href,
      });
      return res;
    },
    onSuccess: (gatewayData) => {
      if (gatewayData?.url) {
        window.location.href = gatewayData.url;
      }
    },
  });

  const proPlan = plans?.result.find((p) => p.name === PRO_PLAN_NAME);

  const friendlyCycleName = (cycle: EPlanCycle): string => {
    return cycle === EPlanCycle.YEARLY
      ? t('subscription.yearly')
      : cycle === EPlanCycle.QUARTERLY
      ? t('subscription.quarterly')
      : cycle;
  };

  const renderBody = () => {
    if (isFetching) {
      return <ContentLoader loading />;
    }
    if (!proPlan) {
      return <ContentLoader isEmpty />;
    }
    const selectedTotalPrice = +(
      DISCOUNTED_MULTIPLIER *
      (proPlan.prices.find((p) => p.planCurrency === selectedCurrency && p.planCycle === selectedCycle)?.price || 0)
    ).toFixed(2);

    return (
      <div className="flex flex-col gap-4">
        <PlanVariant
          plan={proPlan}
          cycle={EPlanCycle.QUARTERLY}
          currency={selectedCurrency}
          selectedCycle={selectedCycle}
          onSelect={(cycle) => {
            setSelectedCycle(cycle);
          }}
        />
        <div className="border-t border-dashed border-gray-200"></div>
        <PlanVariant
          plan={proPlan}
          cycle={EPlanCycle.YEARLY}
          currency={selectedCurrency}
          selectedCycle={selectedCycle}
          onSelect={(cycle) => {
            setSelectedCycle(cycle);
          }}
        />
        <div className="border-t border-dashed border-gray-200"></div>
        <div className="flex flex-wrap justify-between">
          <div>{t('onboarding.stepSelectCycle.dueToday')}</div>
          <b>
            {renderCurrencyAsSymbol(selectedCurrency)}
            {selectedTotalPrice} ({t('onboarding.stepSelectCycle.pay')} {friendlyCycleName(selectedCycle)})
          </b>
        </div>
      </div>
    );
  };

  const isButtonDisabled = isFetching || !proPlan;

  return (
    <div className="flex flex-col gap-4 h-full justify-between">
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 text-xl font-bold items-center">
          <BackButton
            size="small"
            onClick={() => {
              onSetStep(EStep.GET_DISCOUNT_TODAY);
            }}
          />
          <div className="text-xl font-bold">
            {t('onboarding.stepSelectCycle.titleFirstPart')} <span className="font-bold text-primary">PRO</span>{' '}
          </div>
        </div>
        <div className="text-xs inline-flex gap-4 items-center">
          {t('onboarding.stepSelectCycle.description')}
          <CurrencySelector value={selectedCurrency} onChange={(v) => setSelectedCurrency(v)} short />
        </div>
      </div>
      <div className="flex flex-col gap-4 text-xs">{renderBody()}</div>
      <div>
        <Button
          variant="contained"
          className="w-full"
          disabled={isButtonDisabled}
          loading={isFetching || subscribeMutation.isLoading}
          onClick={() => {
            subscribeMutation.mutate();
          }}
          id={marketingSelectors.onboardingDialog.discountButtonId}
        >
          {t('dialog.next')}
          <FiRrArrowSmallRight />
        </Button>
      </div>
    </div>
  );
};

const PlanVariant: React.FC<{
  plan: PlanOutDto;
  cycle: EPlanCycle;
  currency: ECurrency;
  selectedCycle: EPlanCycle;
  onSelect: (cycle: EPlanCycle) => void;
}> = ({ plan, cycle, currency, selectedCycle, onSelect }) => {
  const { t } = useTranslation();
  const price = plan.prices.find((p) => p.planCurrency === currency && p.planCycle === cycle);

  const totalRegularPrice = price?.price;
  if (!totalRegularPrice) {
    return <ContentLoader loading />;
  }

  const divideFactor = cycle === EPlanCycle.YEARLY ? 12 : cycle === EPlanCycle.QUARTERLY ? 3 : 1;
  const regularMonthlyPrice = +(totalRegularPrice / divideFactor).toFixed(2);
  const discountedMonthlyPrice = +(regularMonthlyPrice * DISCOUNTED_MULTIPLIER).toFixed(2);
  const savePrice = +(totalRegularPrice - totalRegularPrice * DISCOUNTED_MULTIPLIER).toFixed(2);

  const friendlyCycleName = (cycle: EPlanCycle): string => {
    return cycle === EPlanCycle.YEARLY
      ? t('subscription.yearly')
      : cycle === EPlanCycle.QUARTERLY
      ? t('subscription.quarterly')
      : cycle;
  };

  return (
    <FormControlLabel
      classes={{ label: clsxm(/*tw*/ 'text-s text-gray-500', 1 && 'text-gray-800'), root: /*tw*/ 'items-start' }}
      checked={cycle === selectedCycle}
      onClick={() => {
        onSelect(cycle);
      }}
      control={<Radio />}
      label={
        <div className="pt-2.5 flex flex-col gap-4">
          <div>
            <b className="capitalize">{friendlyCycleName(cycle)}</b>{' '}
            <span className="text-accept-text bg-accept-light rounded-full p-0.5 px-2 text-xs font-medium">
              {t('onboarding.stepSelectCycle.save')} {renderCurrencyAsSymbol(currency)}
              {savePrice}
            </span>
          </div>
          <div>
            <span className="text-gray-500 text-s">{t('onboarding.stepSelectCycle.todaysPrice')}: </span>
            <b className="text-xxl">
              {renderCurrencyAsSymbol(currency)}
              {discountedMonthlyPrice}
            </b>{' '}
            <span className="text-s">/ {t('onboarding.stepSelectCycle.month')}</span>
          </div>
          <div className="text-s">
            (
            <b>
              {renderCurrencyAsSymbol(currency)}
              {regularMonthlyPrice}
            </b>{' '}
            / {t('onboarding.stepSelectCycle.month')} {t('onboarding.stepSelectCycle.regularPrice')})
          </div>
        </div>
      }
    />
  );
};
