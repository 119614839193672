import { useMemo, useEffect, useCallback } from 'react';

import { useStore } from '@app/store/useStore.hook';

import { createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { getDesignTokens } from '@app/styles/material-theme';
import { DevMenu } from './DevMenu';

export const ThemeWrapper: React.FC = ({ children }) => {
  const { theme, setTheme } = useStore(({ theme, setTheme }) => ({ theme, setTheme }));
  const hasHydrated = useStore.use_hasHydrated();

  const muiTheme = useMemo(() => createTheme(getDesignTokens(theme)), [theme]);

  const isDarkTheme = theme === 'dark';

  // TAILWIND RELATED
  useEffect(() => {
    if (isDarkTheme) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkTheme]);

  const handleChangeTheme = useCallback(() => {
    setTheme(isDarkTheme ? 'light' : 'dark');
  }, [setTheme, isDarkTheme]);

  if (!hasHydrated) {
    return null;
  }

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline enableColorScheme />
      <ThemeProvider theme={muiTheme}>
        <DevMenu handleChangeTheme={handleChangeTheme} isDarkTheme={isDarkTheme} />
        <div className={isDarkTheme ? 'dark' : ''}>{children}</div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
