import { CheckCircleIcon, NoCheckCircleIcon } from '@app/assets';

export const IncludeItem: React.FC<{
  isAvailable?: string | boolean | null;
  title: React.ReactNode;
  value: React.ReactNode;
}> = ({ isAvailable, title, value }) => {
  return (
    <div className="flex items-center gap-2 text-gray-800">
      <span className="h-5 w-5">
        {isAvailable ? <CheckCircleIcon /> : <NoCheckCircleIcon className="fill-red-600" />}
      </span>
      <span>{title}:</span> <b>{value}</b>
    </div>
  );
};
