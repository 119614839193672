import { useGetQRCodeById } from '@app/domain/qr-code/api/qr-code.hooks.api';
import { AUTH_QUERY_PARAM_AUTO_DOWNLOAD_QR_ID } from '@app/modules/auth/auth.constants';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { QRRendererProps } from '@app/domain/qr-code/components/QRCodeRenderer';
import { extractStylePropsFromQRDesign, getQRfileNameForDownload } from '@app/domain/qr-code/qr-code.utils';
import { QRCodeOutDtoOverridden } from '@app/domain/qr-code/qr-code.types';
import NiceModal from '@ebay/nice-modal-react';
import { DownloadQRCodeModal } from '@app/domain/qr-code/components/modals/DownloadQRCodeModal';
import { useEffect } from 'react';
import { useOnboardingDialogState } from '../../components/onboarding/hooks/useOnboardingDialogState';

export const MaybeDownloadQrCodeDialog: React.FC = () => {
  const [autoDownloadQRID] = useQueryParam(AUTH_QUERY_PARAM_AUTO_DOWNLOAD_QR_ID, withDefault(StringParam, null));
  const { state } = useOnboardingDialogState();

  if (!autoDownloadQRID || state?.isOpen) {
    return null;
  }

  return <PreBody autoDownloadQRID={autoDownloadQRID} />;
};

const PreBody: React.FC<{
  autoDownloadQRID: string;
}> = ({ autoDownloadQRID }) => {
  const { data: qrCode } = useGetQRCodeById(autoDownloadQRID);

  if (!qrCode) {
    return null;
  }

  return <Body qrCode={qrCode} />;
};

const Body: React.FC<{
  qrCode: QRCodeOutDtoOverridden;
}> = ({ qrCode }) => {
  const [, setParam] = useQueryParam(AUTH_QUERY_PARAM_AUTO_DOWNLOAD_QR_ID, withDefault(StringParam, null));

  useEffect(() => {
    const rendererProps: QRRendererProps = {
      qrId: qrCode.id,
      ...(qrCode.design ? extractStylePropsFromQRDesign(qrCode.design) : undefined),
      publicLogoType: qrCode.publicLogoType,
      fileName: getQRfileNameForDownload(qrCode.id, qrCode.name),
    };

    console.log('show', rendererProps);
    NiceModal.show(DownloadQRCodeModal, {
      rendererProps,
      onClose: () => {
        setParam(null);
      },
    }).then(() => {
      document.body.blur();
    });
  }, [qrCode, setParam]);

  return null;
};
