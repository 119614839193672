import { DownloadIcon, PdfFilledIcon, UploadIcon } from '@app/assets';
import { Button, Container, IconButton, Typography } from '@app/components';
import { FileDropZoneWrapper } from '@app/components/file-drop-zone-wrapper/FileDropZoneWrapper';
import { clsxm } from '@app/styles/clsxm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { friendlyByteSize } from '@app/utils/file.utils';
import { toast } from 'react-toastify';
import { OPENABLE_EXTENSION_TO_MIME } from '@app/constants/file-uploads';
import { useMutation, useQueryClient } from 'react-query';
import { CircularProgress } from '@mui/material';
import { QRCodeApi } from '@app/domain/qr-code/api/qr-code.api';
import { IS_NOT_PROD_ENV } from '@app/environment/typed-env';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { useStore } from '@app/store/useStore.hook';
import { GET_DASHBOARD_MY_STATS_QUERY, GET_MY_QR_CODES_QUERY } from '@app/constants/query-api-configs';
import { UpgradeBanner } from '@app/domain/plan/components/UpgradeBanner';
import { EPlanLimitType } from '@app/domain/plan/components/plan-limit-dialog/PlanLimitDialog';
import { usePlanLimits } from '@app/domain/plan/hooks/usePlanLimits';

const ACCEPT = OPENABLE_EXTENSION_TO_MIME.csv;
const MAX_FILE_SIZE_MB = 15 * 1024 * 1024;

export const QRCodeBulk: React.FC<{
  onCancel: () => void;
}> = ({ onCancel }) => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();
  const { canCreateBulk } = usePlanLimits();

  const [file, setFile] = useState<File | null>(null);

  const fileDto = file ? { fileName: file.name } : null;
  const fileId = file?.name;

  const queryClient = useQueryClient();
  const getMe = useStore.useGetMe();

  const { isLoading, mutate } = useMutation({
    mutationFn: async (f: File) => QRCodeApi.createBulk({ csv: f }),
    onSuccess: async (data) => {
      IS_NOT_PROD_ENV && console.log(data);
      toast.success(t('toaster.bulkQrsCreated'));
      navigate({
        to: Routes.customer.my_qr_codes.index,
      });
      await Promise.all([
        getMe(),
        queryClient.invalidateQueries(GET_MY_QR_CODES_QUERY.name),
        queryClient.invalidateQueries(GET_DASHBOARD_MY_STATS_QUERY.name),
      ]);
    },
  });

  const renderContent = () => {
    if (!fileId && !isLoading) {
      return (
        <FileDropZoneWrapper
          accept={ACCEPT}
          className={clsxm(
            'flex cursor-pointer items-center justify-center gap-1 rounded-xl border border-gray-200 bg-gray-100 p-2 py-6 text-gray-500',
            'hover:bg-gray-100'
          )}
          onChange={(f) => {
            if (f.size > MAX_FILE_SIZE_MB) {
              toast.warn(t('error.maxFileSize'));
              return;
            }
            if (f.type !== ACCEPT) {
              toast.warn(t('error.onlyPdfFile'));
              return;
            }
            setFile(f);
            // mutate(f);
          }}
        >
          <IconButton variant="transparent">
            <UploadIcon />
          </IconButton>
          {t('qr.bulk.dragCSVOr')}
          <span className="font-bold text-accept-main">{t('qr.bulk.browse')}</span>
        </FileDropZoneWrapper>
      );
    }

    return (
      <div
        className={clsxm(
          'flex items-center justify-between gap-1 rounded-xl border border-gray-200 bg-gray-100 p-2 py-4 text-gray-500'
        )}
      >
        <div className="flex gap-1">
          <PdfFilledIcon />
          {fileDto && (
            <div className="flex flex-col">
              <div className="text-xs text-gray-800">{fileDto.fileName}</div>
              {/* TODO show size when API ready */}
              {/* <div className="text-xs">{friendlyByteSize(fileDto.size)}</div> */}
              {file && file.name === fileDto.fileName && <div className="text-xs">{friendlyByteSize(file.size)}</div>}
            </div>
          )}
          {(!fileDto || isLoading) && <CircularProgress />}
        </div>
        <IconButton
          variant="transparent"
          onClick={() => {
            setFile(null);
          }}
        >
          <Close />
        </IconButton>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 p-0 xl:px-6">
      {!canCreateBulk && <UpgradeBanner planLimitDialogType={EPlanLimitType.CREATE_BULK} />}
      <Container className="flex flex-col gap-3 sm:gap-4 xl:rounded-xl">
        <div>
          <Button
            onClick={() => {
              onCancel();
            }}
            variant="outlined"
            disabled={isLoading}
          >
            {t('qr.buttonBack')}
          </Button>
        </div>
        <Typography className="font-bold sm:text-l">{t('qr.bulk.downloadExampleTitle')}</Typography>
        <Typography className="text-gray-500">{t('qr.bulk.downloadExampleSubtitle')}</Typography>
        <div>
          <Button
            onClick={() => {
              downloadSampleFile();
            }}
            variant="outlined"
            disabled={isLoading}
            startIcon={<DownloadIcon />}
          >
            {t('qr.buttonDownload')}
          </Button>
        </div>
        <Typography className="font-bold sm:text-l">{t('qr.bulk.uploadCSVTitle')}</Typography>
        <Typography className="text-gray-500">{t('qr.bulk.uploadCSVSubtitle')}</Typography>
        {renderContent()}
        <div>
          <Button
            onClick={async () => {
              if (!file) {
                return;
              }
              mutate(file);
            }}
            disabled={!file}
            loading={isLoading}
          >
            <span>{t('qr.buttonNext')}</span>
          </Button>
        </div>
      </Container>
    </div>
  );
};

const downloadSampleFile = () => {
  const sampleData = [
    `Name,Website URL`,
    `First,https://example1.com`,
    `Second,https://example2.com`,
    `Third,https://example2.com`,
  ].join('\n');
  const blob = new Blob([sampleData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = 'sample-bulk.csv';
  link.click();

  URL.revokeObjectURL(url);
};
