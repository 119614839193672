import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { SmartProgressBar } from '@app/components/progress/SmartProgressBar';
import { Routes } from '@app/constants/routes';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { TypedLink } from '@app/router';
import { capitalizeWords } from '@app/utils/string.utils';
import { useTranslation } from 'react-i18next';

export const CustomerNavSidebarPlanStatus: React.FC = () => {
  const { t } = useTranslation();
  const { isOldPlanUser, currentUser: user } = useAuthorizedUser();
  const { planType } = useActivePlan();

  if (isOldPlanUser || !user) {
    return null;
  }

  const usage = {
    totalScans: user?.monthlyScans || 0,
    maxScans: user?.currentPlanDetails?.amountMaxScans || `∞`,
  };

  return (
    <div className="bg-slate-400/50 text-white p-4 rounded-t-lg flex flex-col gap-4">
      <div className="flex gap-2">
        <div className="font-semibold">{capitalizeWords(planType || '')}</div>
        <div>{t('billing.plan')}</div>
      </div>
      <div className="flex justify-between">
        <div>{t('subscription.qrScans')}</div>
        <div className="flex gap-2">
          <b>{usage.totalScans}</b>
          <div>{t('subscription.ofAmount')}</div>
          <b>{usage.maxScans}</b>
        </div>
      </div>
      <NextUsageReset newUsageResetDate={user.newUsageResetDate} />
      <div>
        <SmartProgressBar
          value={!user?.currentPlanDetails?.amountMaxScans ? -1 : user?.monthlyScans}
          max={user?.currentPlanDetails?.amountMaxScans || 100}
        />
      </div>
      <div>
        <TypedLink to={Routes.customer.account.subscription} className="text-s underline">
          {t('subscription.buttonManagePlan')}
        </TypedLink>
      </div>
    </div>
  );
};

const NextUsageReset: React.FC<{
  newUsageResetDate?: string | null;
}> = ({ newUsageResetDate }) => {
  const { t } = useTranslation();

  if (!newUsageResetDate) {
    return null;
  }

  try {
    const now = Date.now();
    const futureTime = new Date(newUsageResetDate).getTime();
    const deltaTime = futureTime - now;
    const daysLeft = Math.round(deltaTime / DAY_IN_MS);
    return <div className="text-xs">{t('limit.usageResetsInDays', { count: daysLeft })}</div>;
  } catch (e) {
    console.error('invalid usage reset date', e);
    return null;
  }
};

const DAY_IN_MS = 60e3 * 60 * 24;
