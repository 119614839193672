import { AdminEmailTable } from './components/AdminEmailTable';
import { GET_ADMIN_EMAIL_REPORTS_QUERY } from '@app/constants/query-api-configs';
import { SearchInput } from '@app/components/fields/search-input/SearchInput';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';
import { Page } from '@app/hoc';
import { OptionItem, Select } from '@app/components';
import { SelectChangeEvent } from '@mui/material';
import { oneOfEnum } from '@app/utils/yup.utils';
import { EEmailTopic, ELanguage } from '@app/swagger-types';

export const AdminEmailListPage: React.FC = () => {
  const [recipient, setRecipient] = useQueryParam(
    GET_ADMIN_EMAIL_REPORTS_QUERY.recipientParam,
    withDefault(StringParam, '')
  );
  const [topic, setTopic] = useQueryParam(GET_ADMIN_EMAIL_REPORTS_QUERY.topicParam, withDefault(StringParam, ''));
  const [language, setLanguage] = useQueryParam(
    GET_ADMIN_EMAIL_REPORTS_QUERY.languageParam,
    withDefault(StringParam, '')
  );

  return (
    <Page>
      <div className="flex items-end justify-end gap-2 p-5">
        <div className="text-xs italic text-gray-400">Email logs older 30 days are deleted every midnight UTC</div>
        <Select
          size="small"
          inputProps={{
            label: 'Language',
          }}
          displayEmpty
          value={language || ''}
          onChange={(e: SelectChangeEvent<string>) => {
            const val = e.target.value;
            // TODO auto validate select output, create reusable solution
            if (oneOfEnum(ELanguage).isValidSync(val)) {
              setLanguage(val);
            } else {
              setLanguage(undefined);
            }
          }}
        >
          <OptionItem value={''}>All</OptionItem>
          {ALL_LANGS.map((key) => (
            <OptionItem value={key} key={key}>
              {key}
            </OptionItem>
          ))}
        </Select>
        <Select
          size="small"
          inputProps={{
            label: 'Topic',
          }}
          displayEmpty
          value={topic || ''}
          onChange={(e: SelectChangeEvent<string>) => {
            const val = e.target.value;
            // TODO auto validate select output, create reusable solution
            if (oneOfEnum(EEmailTopic).isValidSync(val)) {
              setTopic(val);
            } else {
              setTopic(undefined);
            }
          }}
        >
          <OptionItem value={''}>All</OptionItem>
          {ALL_TOPICS.map((key) => (
            <OptionItem value={key} key={key}>
              {key}
            </OptionItem>
          ))}
        </Select>
        <SearchInput value={recipient} onChange={setRecipient} label="Recipient email" placeholder="name@gmail.com" />
      </div>
      <div className="p-5">
        <AdminEmailTable />
      </div>
    </Page>
  );
};

const ALL_TOPICS = Object.values(EEmailTopic);
const ALL_LANGS = Object.values(ELanguage);
