import { IS_LOCALHOST, typedEnv } from '@app/environment/typed-env';

export const WIDGET_AVAILABLE_COLORS = ['#000000', '#0055FF', '#F87171', '#FFC700'] as const;

export const WIDGET_DEFAULT_COLOR = WIDGET_AVAILABLE_COLORS[0];

export const WIDGET_LOGO_OPTION_APP_LOGO = 'app-logo';

/**
 * use via util ./widget.utils.ts/widgetPrepareAuthUrl
 * avoid using directly.
 */
export const WIDGET_SIGNUP_URL = `${IS_LOCALHOST ? window.location.origin : typedEnv.REACT_APP_HOST_URL}/auth/sign-up`;
export const WIDGET_SIGNIN_URL = `${IS_LOCALHOST ? window.location.origin : typedEnv.REACT_APP_HOST_URL}/auth/sign-in`;
