import { PaginatedResponse } from '@app/api/api.type';
import { apiService, validateAndAssert } from '@app/services/api-service';
import { ApiKeyOutDto } from '@app/swagger-types';
import { ApiKeyOutDtoSchema } from './apiKey.schema';
import { pageableData } from '@app/utils/yup.utils';

export abstract class ApiKeyApi {
  static async create() {
    const res = await validateAndAssert(apiService.post('/private/api-keys'), ApiKeyOutDtoSchema);
    return res;
  }

  static async getAll(): Promise<PaginatedResponse<ApiKeyOutDto>> {
    return validateAndAssert(apiService.get('/private/api-keys'), pageableData(ApiKeyOutDtoSchema));
  }

  static async delete({ id }: { id: string }) {
    apiService.delete(`/private/api-keys/${id}`);
  }
}
