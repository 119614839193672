import { useTranslation } from 'react-i18next';
import { LogoCruyffFoundation, LogoLoreal, LogoPuma, LogoRIJKS, LogoSamsung, LogoVlaamse } from './assets';
import { CheckCircleIcon } from '@app/assets';

export const AuthLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-8">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="flex flex-col gap-8">
          <div className="font-bold text-4xl">{t('auth.layout.title')}</div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-wrap gap-2 items-center">
              <CheckCircleIcon /> <b>{t('auth.layout.benefit1Bold')}</b> {t('auth.layout.benefit1Rest')}
            </div>
            <div className="flex flex-wrap gap-2 items-center">
              <CheckCircleIcon /> {t('auth.layout.benefit2')}
            </div>
            <div className="flex flex-wrap gap-2 items-center">
              <CheckCircleIcon /> {t('auth.layout.benefit3')}
            </div>
          </div>
        </div>
        <div className="max-w-md w-full md:ml-auto">{children}</div>
      </div>
      <div className="flex flex-col items-center gap-8">
        <div>{t('auth.layout.trustedAt')}</div>
        <div className="flex flex-wrap justify-center items-center gap-16">
          <LogoCruyffFoundation />
          <LogoLoreal />
          <LogoPuma />
          <LogoVlaamse />
          <LogoSamsung />
          <LogoRIJKS />
        </div>
      </div>
    </div>
  );
};
