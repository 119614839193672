import { DesignFormSchema } from '@app/domain/template/api/template.form.schema.api';
import { ECodeType, ELinkType } from '@app/swagger-types';
import { email, oneOfEnum } from '@app/utils/yup.utils';
import { object, InferType, string, number, array, MixedSchema } from 'yup';
import { DEFAULT_MULTILINK_FORM_VALUES } from '../constants';
const getPhoneInputLib = () => import('react-phone-number-input');

// use regex for protocol validation, because regex is limited
// and may not include special characters from
// different languages like https://web.site/path/withächaracter
// for full URL validation use validateURL from src/utils/url.util.ts
export const regexUrlWithoutProtocol = new RegExp(
  '^((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?', // fragment locator
  'i'
);

// use regex for protocol validation, because regex is limited
// and may not include special characters from
// different languages like https://web.site/path/withächaracter
// for full URL validation use validateURL from src/utils/url.util.ts
export const regexUrlWithProtocol = new RegExp(
  '^([a-zA-Z]+:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?', // fragment locator
  'i'
);

const QRLinkFormSchema = object({
  linkType: oneOfEnum(ELinkType),
  title: string().required('Required'),
  url: string().required('URL required'),
  libraryId: string(),
  library: object({
    id: string(),
  })
    .nullable()
    .optional(),
});
type QRLinkFormSchema = InferType<typeof QRLinkFormSchema>;

const QRSocialLinkFormSchema = object({
  linkType: oneOfEnum(ELinkType),
  url: string().required('URL required'),
});
type QRSocialLinkFormSchema = InferType<typeof QRSocialLinkFormSchema>;

const MulilinkShape = object({
  title: string().required('Required field'),
  description: string().nullable(),
  libraryId: string(),
  // TODO keep in sync with default values
  designBgColor: string().defined().default(DEFAULT_MULTILINK_FORM_VALUES.designBgColor),
  designTitleColor: string().defined().default(DEFAULT_MULTILINK_FORM_VALUES.designTitleColor),
  designDescriptionColor: string().defined().default(DEFAULT_MULTILINK_FORM_VALUES.designDescriptionColor),
  buttonBgColor: string().defined().default(DEFAULT_MULTILINK_FORM_VALUES.buttonBgColor),
  buttonTextColor: string().defined().default(DEFAULT_MULTILINK_FORM_VALUES.buttonTextColor),
  buttonHoverColor: string().defined().default(DEFAULT_MULTILINK_FORM_VALUES.buttonHoverColor),
  buttonBorderColor: string().defined().default(DEFAULT_MULTILINK_FORM_VALUES.buttonBorderColor),
  customLinks: array().of(QRLinkFormSchema),
  socialLinks: array().of(QRSocialLinkFormSchema),
});
export const MultilinkFormSchema = object()
  .notRequired()
  .nullable()
  .when<typeof MulilinkShape, MixedSchema<null | undefined>>(['codeType'], {
    is: (codeType: ECodeType) => codeType === ECodeType.MULTILINK,
    then: () => MulilinkShape,
  });
export type MultilinkFormSchema = InferType<typeof MultilinkFormSchema>;

const PdfShape = object({
  fileId: string().required(),
  file: object({
    fileName: string().required(),
  })
    .notRequired()
    .nullable(),
});
export const PdfFormSchema = object()
  .notRequired()
  .nullable()
  .when<typeof PdfShape, MixedSchema<null | undefined>>(['codeType'], {
    is: (codeType: ECodeType) => codeType === ECodeType.PDF,
    then: () => PdfShape,
  });
export type PdfFormSchema = InferType<typeof PdfFormSchema>;

const VCardShape = object({
  fullName: string().required('Required field'),
  phoneNumber: string()
    .required('Required field')
    .test('phone-number', 'Invalid phone number', async (phone) => {
      const { isValidPhoneNumber } = await getPhoneInputLib();
      return isValidPhoneNumber(phone || '');
    }),
  companyName: string().nullable(),
  jobPosition: string().nullable(),
  alternativePhoneNumber: string()
    .nullable()
    .test('phone-number', 'Invalid phone number', async (phone) => {
      if (!phone) {
        return true;
      }

      const { isValidPhoneNumber } = await getPhoneInputLib();
      return isValidPhoneNumber(phone || '');
    }),
  libraryId: string(),
  email: email().notRequired(),
  website: string()
    .nullable()
    .test('invalid-website-url', 'Invalid website url', (websiteUrl) => {
      return !websiteUrl || regexUrlWithProtocol.test(websiteUrl || '');
    }),
  street: string().nullable(),
  postalCode: string().nullable(),
  city: string().nullable(),
  state: string().nullable(),
  country: string().nullable(),
  // TODO keep in sync with default values
  designPrimaryColor: string().default('#000000'),
  designSecondaryColor: string().default('#999999'),
  designNameColor: string().default('#000000'),
  designTitleColor: string().default('#444444'),
  designTextColor: string().default('#FFFFFF'),
  designIconColor: string().default('#000000'),
  designTextHoverColor: string().default('#FFFFFF'),
  buttonText: string().default('ADD'),
  buttonSize: number().default(15),
  buttonBgColor: string().default('#000000'),
  buttonTextColor: string().default('#FFFFFF'),
  buttonHoverColor: string().default('#444444'),
  buttonBorderColor: string().default('#000000'),
  links: array().of(QRSocialLinkFormSchema),
});
export const VCardFormSchema = object()
  .notRequired()
  .nullable()
  .when<typeof VCardShape, MixedSchema<null | undefined>>(['codeType'], {
    is: (codeType: ECodeType) => codeType === ECodeType.V_CARD,
    then: () => VCardShape,
  });
export type VCardFormSchema = InferType<typeof VCardFormSchema>;

// TODO refactor schema to group all DesignFormSchema fields under "design" field
export const QRCodeFormSchema = object({
  templateId: string().nullable(),
  name: string().required('Required field'),
  codeType: oneOfEnum(ECodeType),
  websiteUrl: string()
    .test(
      'required-website-url',
      'Required field',
      (websiteUrl, { parent: { codeType } }: { parent: { codeType: ECodeType } }) => {
        if (codeType === ECodeType.WEBSITE) {
          return Boolean(websiteUrl);
        }
        return true;
      }
    )
    .test(
      'invalid-website-url',
      'Invalid website url',
      (websiteUrl, { parent: { codeType } }: { parent: { codeType: ECodeType } }) => {
        if (codeType === ECodeType.WEBSITE) {
          return regexUrlWithProtocol.test(websiteUrl || '');
        }
        return true;
      }
    ),
  multilink: MultilinkFormSchema,
  pdf: PdfFormSchema,
  vcard: VCardFormSchema,
}).concat(DesignFormSchema.omit(['template']));
export type QRCodeFormSchema = InferType<typeof QRCodeFormSchema>;

export const QRCodeNameFormSchema = QRCodeFormSchema.pick(['name']);
export type QRCodeNameFormSchema = InferType<typeof QRCodeNameFormSchema>;
