import { FiRrInfo } from '@app/assets';
import { useTranslation } from 'react-i18next';

export const QRCodeGS1GTINBanner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-3 items-start rounded-lg bg-gray-100 border border-gray-300 px-3 py-2">
      <FiRrInfo className="shrink-0 text-error mt-1" />
      <div className="flex flex-col gap-3">
        <p className="flex gap-1 flex-wrap font-bold text-m text-gray-800">{t('qr.gs1Input.banner.title')}</p>
        <p className="flex gap-1 flex-wrap text-gray-700">{t('qr.gs1Input.banner.description')}</p>
      </div>
    </div>
  );
};
