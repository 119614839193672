import { clsxm } from '@app/styles/clsxm';

export const QRCodePreviewWrap: React.FC<{ isRenderFromForm?: boolean; style?: React.CSSProperties }> = ({
  isRenderFromForm,
  children,
  style,
}) => {
  const innerWrap = (
    <div
      className={clsxm(
        'flex flex-col items-center justify-center bg-white',
        // TODO remove because buggy after build and seems redundant actually
        // 'h-full',
        isRenderFromForm && 'rounded-[34px] border border-gray-200',
        !isRenderFromForm && 'sm:py-6'
      )}
      style={style}
    >
      <div
        className={clsxm(
          'flex h-full min-h-[530px] w-full min-w-[244px] max-w-[640px] flex-col overflow-hidden sm:h-auto sm:rounded-[34px]',
          isRenderFromForm && 'w-[244px] max-w-[244px] rounded-[34px]'
        )}
      >
        {children}
      </div>
    </div>
  );

  if (!isRenderFromForm) {
    return <>{innerWrap}</>;
  }

  return (
    <div className="overflow-hidden rounded-[46px] bg-gray-200 p-1">
      <div className="overflow-hidden rounded-[42px] bg-white p-2">{innerWrap}</div>
    </div>
  );
};
