import { Container, Input, Typography } from '@app/components';
import { QRCodeFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGtinValidation } from '@app/domain/qr-code/api/qr-code.hooks.api';
import { useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { QRCodeGS1GTINBanner } from './QRCodeGS1GTINBanner';

export const GS1ProductInfo: React.FC<{
  disabledEditInput?: boolean;
  isEditMode?: boolean;
}> = ({ disabledEditInput, isEditMode }) => {
  const { t } = useTranslation();
  const { control, trigger, watch, setError, clearErrors } = useFormContext<QRCodeFormSchema>();

  const gtinValue = watch('gs1.gtin');
  const [debouncedGtin] = useDebounce(gtinValue, 1e3);
  const { data, isError, error, isLoading } = useGtinValidation(debouncedGtin);

  useEffect(() => {
    if (gtinValue && gtinValue.trim() !== '') {
      if (isError && error) {
        setError('gs1.gtin', {
          type: 'manual',
          message: error.message,
        });
        return;
      }
      if (data && !isLoading) {
        clearErrors('gs1.gtin');
        return;
      }
    }
  }, [data, isError, error, isLoading, gtinValue, setError, clearErrors]);

  return (
    <Container className="flex flex-col gap-3 sm:gap-4 xl:rounded-xl">
      <Typography className="font-bold sm:text-l">{t('qr.gs1Input.headerProductInfo')}</Typography>
      <Controller
        name="gs1.gtin"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Input
            {...field}
            value={value || ''}
            label={t('qr.gs1Input.productInfoInputTitle')}
            onChange={(e) => {
              if (fieldState?.error?.message) {
                trigger('gs1.gtin');
              }
              onChange(e);
            }}
            withBG
            placeholder={t('qr.gs1Input.productInfoInputPlacesholder')}
            errorMessage={fieldState?.error?.message || ''}
            disabled={disabledEditInput}
          />
        )}
      />
      {isEditMode && <QRCodeGS1GTINBanner />}
    </Container>
  );
};
