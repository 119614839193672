import { EQRCodeSort } from '@app/domain/qr-code/qr-code.types';
import { ECodeTypeALL } from '@app/domain/qr-code/components/search/QRCodeMobileTypes';
import { ECodeType, ELanguage } from '@app/swagger-types';
import { TFunction } from 'i18next';

export const renderFriendlyQRCodeSort = (qrCodeSort: EQRCodeSort, t: TFunction<'translation'>) => {
  const variants = {
    [EQRCodeSort.MOST_RECENT]: t('search.mostRecent'),
    [EQRCodeSort.NAME_A_Z]: t('search.nameAZ'),
    [EQRCodeSort.MOST_SCANNED]: t('search.mostScanned'),
    [EQRCodeSort.LAST_CREATED]: t('search.lastCreated'),
  };
  return variants[qrCodeSort];
};

export const renderFriendlyQRCodeTypes = (qrCodeTypes: ECodeType | ECodeTypeALL) => {
  const variants = {
    [ECodeTypeALL.ALL]: 'All',
    [ECodeTypeALL.NON_ALL]: '',
    [ECodeType.WEBSITE]: 'Website',
    [ECodeType.MULTILINK]: 'Multi links',
    [ECodeType.PDF]: 'Pdf',
    [ECodeType.V_CARD]: 'vCard Plus',
    [ECodeType.GS1]: 'GS1',
    [ECodeType.WIFI]: 'WIFI',
  };
  return variants[qrCodeTypes];
};

export const renderFriendlyLanguage = (language: ELanguage) => {
  const variants = {
    [ELanguage.DUTCH]: 'Nederlands',
    [ELanguage.ENGLISH]: 'English',
    [ELanguage.FRENCH]: 'Français',
    [ELanguage.GERMAN]: 'Deutsch',
    [ELanguage.ITALIAN]: 'italiano',
    [ELanguage.SPANISH]: 'español',
  };
  return variants[language];
};
