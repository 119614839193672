import { useMemo } from 'react';

import { IconButton, TableColumn } from '@app/components';

import { Typography } from '@mui/material';
import { InvoiceOutDto } from '@app/swagger-types';
import { NestedKeyOf } from '@app/utils/type.utils';
import { format } from 'date-fns';
import { FORMAT } from '@app/constants/formats';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { numberWithCommas, renderCurrencyAsSymbol } from '@app/utils/currency.util';
import { InvoiceStatusBadge } from '@app/domain/billing/InvoiceStatusBadge';
import { DownloadIcon } from '@app/assets';

const getEntityField = (v: NestedKeyOf<InvoiceOutDto>) => v;

export const useTableColumns = () => {
  const { t } = useTranslation();

  const tableColumns = useMemo<TableColumn<InvoiceOutDto>[]>(() => {
    return [
      {
        headerName: t('billing.invoiceTable.invoice'),
        field: getEntityField('name'),
        align: 'left',
        disableSort: true,
        render: ({ name }) => {
          return <Typography variant="xs">{name}</Typography>;
        },
      },
      {
        headerName: t('billing.invoiceTable.date'),
        field: getEntityField('date'),
        align: 'left',
        disableSort: true,
        render: ({ date }) => {
          return <Typography variant="xs">{format(new Date(date), FORMAT.FULL_TIME)}</Typography>;
        },
      },
      {
        headerName: t('billing.invoiceTable.plan'),
        field: getEntityField('planType'),
        align: 'center',
        firstClickSort: 'asc',
        disableSort: true,
        render: ({ planType }) => (
          <div className="text-center">
            {capitalize(planType)} {t('billing.plan')}
          </div>
        ),
      },
      {
        headerName: t('billing.invoiceTable.status'),
        field: getEntityField('status'),
        align: 'center',
        firstClickSort: 'asc',
        disableSort: true,
        render: ({ status, hasDisputed }) => <InvoiceStatusBadge status={status} hasDisputed={hasDisputed} />,
      },
      {
        headerName: t('billing.invoiceTable.amount'),
        field: getEntityField('amount'),
        align: 'center',
        firstClickSort: 'asc',
        disableSort: true,
        render: ({ amount, currency }) => (
          <Typography variant="xs" noWrap>
            {currency && renderCurrencyAsSymbol(currency)} <b>{numberWithCommas(amount / 100)}</b>
          </Typography>
        ),
      },
      {
        headerName: '',
        field: '_actions',
        align: 'center',
        disableSort: true,
        render: ({ urlInvoice }) => (
          <div>
            {urlInvoice && (
              <a href={urlInvoice} target="_blank" rel="noreferrer">
                <IconButton size="small" variant="transparent">
                  <DownloadIcon />
                </IconButton>
              </a>
            )}
          </div>
        ),
      },
    ];
  }, [t]);

  return tableColumns;
};
