import { useStore } from '@app/store/useStore.hook';

export const usePlanLimits = () => {
  const currentUser = useStore.useCurrentUser();
  const planDetails = currentUser?.currentPlanDetails;

  const noDispute = !planDetails?.hasDisputedPayment;
  return {
    allLimits: planDetails,
    currentUser,
    canCreateQRCode:
      noDispute &&
      (planDetails?.amountMaxQrCodes === null ||
        checkIsFirstNumberLess(currentUser?.totalCodes, planDetails?.amountMaxQrCodes)),
    canCreateTemplate: noDispute && planDetails?.hasTemplates,
  };
};

const checkIsFirstNumberLess = (first?: number, second?: number) => {
  if (typeof first !== 'number' || typeof second !== 'number') {
    return;
  }
  return first < second;
};
