import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { ELibraryType } from '@app/swagger-types';
import { useGetLibrary, useCreateLibrary, useDeleteLibrary } from './api/library.hooks.api';
import {
  DTO_PublicLibrarySchema,
  usePersistedPublicImages,
} from '../qr-code/components/input/usePersistedPublicImages';

export const useLibraryEditor = ({
  libraryType,
  selectedLibraryId = NO_LOGO_ID,
  forWidgetMode,
  publicLibraryType = libraryType,
}: {
  libraryType: ELibraryType;
  publicLibraryType?: ELibraryType;
  selectedLibraryId?: string;
  forWidgetMode?: boolean;
}) => {
  const { data: libraries, isLoading } = useGetLibrary({ libraryType }, { forWidgetMode });

  const { mutate: createLibrary, isLoading: isCreatingLibrary } = useCreateLibrary({ forWidgetMode });
  const { mutate: deleteLibrary, isLoading: isDeletingLibrary } = useDeleteLibrary({ forWidgetMode });

  const { libraries: publicLibs, setLibraries: setPublicLibs } = usePersistedPublicImages(publicLibraryType);
  const workLibs = forWidgetMode ? publicLibs : libraries;

  const images = workLibs?.result.map((r) => ({ imageId: r?.file?.id, libraryId: r.id, id: r.id })) || [];
  const selectedImageId = workLibs?.result.find((l) => l.id === selectedLibraryId)?.file?.id;

  return {
    libraryType,
    isLoading,
    images,
    libraries: workLibs,
    selectedImageId,
    createLibrary,
    isCreatingLibrary,
    deleteLibrary,
    isDeletingLibrary,
    forWidgetMode,
    publicConcatLibraries: (newPublicLibs: DTO_PublicLibrarySchema) => {
      if (!forWidgetMode) {
        return;
      }
      setPublicLibs((prev) => {
        if (!prev) {
          return newPublicLibs;
        }
        const newArr = prev.result.concat(newPublicLibs.result);
        return {
          result: newArr,
          total: newArr.length,
        };
      });
    },
    publicDeleteLibrary: (libId: string) => {
      if (!forWidgetMode) {
        return;
      }
      setPublicLibs((data) =>
        data
          ? {
              ...data,
              result: data?.result.filter((img) => img.id !== libId),
            }
          : data
      );
    },
  };
};
