import styles from './../NavSidebar.module.scss';
import React, { useCallback, useEffect, useMemo } from 'react';

import { SidebarRouteLink } from '@app/models/router/';

import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps, List, SwipeableDrawer, Toolbar } from '@mui/material';
import { NavSidebarLink } from '../nav-sidebar-link/NavSidebarLink';
import { HelpIcon } from '@app/assets';
import { IconButton } from '@app/components';
import { useStore } from '@app/store/useStore.hook';
import { clsxm } from '@app/styles/clsxm';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { HomeButton } from '@app/components/buttons/home-button/HomeButton';
import { TrialPeriodCounter } from '@app/domain/plan/components/TrialPeriodCounter';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { useTranslation } from 'react-i18next';
import { HELPCENTER_LINK_EXTERNAL } from '@app/modules/customer/pages/help/help.constants';
import { CustomerNavSidebarPlanStatus } from './CustomerNavSidebarPlanStatus';
import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';

type NavSidebarProps = {
  badgesData?: Record<string, number>;
  navItems: SidebarRouteLink[][];
} & MuiDrawerProps;

export const CustomerNavSidebar: React.FC<NavSidebarProps> = ({ badgesData, navItems, ...rest }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const {
    currentBreakpointDevice: { isDesktop },
    navMobileOpen,
    setNavMobile,
  } = useStore(({ currentBreakpointDevice, navMobileOpen, setNavMobile }) => ({
    navMobileOpen,
    currentBreakpointDevice,
    setNavMobile,
  }));

  const toggleNavMenu = useCallback(
    (open: boolean) => () => {
      setNavMobile(open);
    },
    [setNavMobile]
  );

  useEffect(() => {
    return () => {
      if (navMobileOpen) {
        toggleNavMenu(false)();
      }
    };
  }, [location, navMobileOpen, toggleNavMenu]);

  const { isOldPlanUser } = useAuthorizedUser();
  const { isTrial, isExpired } = useActivePlan();
  const isTrialExpired = isTrial && isExpired;

  const navContent = useMemo(() => {
    return (
      <div className="mt-5 flex h-full flex-col">
        <List disablePadding className="flex-1">
          <div className={styles.ItemsWrapper}>
            {navItems.map((group, groupIndex) => (
              <div key={groupIndex} className={styles.GroupWrapper}>
                {group.map((menuItem) => {
                  const badge = menuItem.badgeProp && badgesData ? badgesData[menuItem.badgeProp] : undefined;
                  return (
                    <NavSidebarLink
                      name={menuItem.translationKey ? t(menuItem.translationKey) : menuItem.name}
                      key={menuItem.name}
                      // intentionally fake link target to avoid "active" style
                      to={isTrialExpired ? 'trial-expired-fake-route' : menuItem.path}
                      icon={menuItem.icon}
                      nestedLinks={menuItem.nestedLinks}
                      badge={badge}
                      badgesData={badgesData}
                      tooltip={menuItem.tooltip}
                      disabled={isTrialExpired}
                    />
                  );
                })}
              </div>
            ))}
          </div>
        </List>
        <div className="mr-6 mb-6">
          {!isTrialExpired && (
            <a
              href={HELPCENTER_LINK_EXTERNAL}
              target="_blank"
              rel="noreferrer"
              className="flex gap-4 px-6 py-5 text-xs text-gray-300 hover:font-bold hover:text-white"
            >
              <HelpIcon /> {t('support.pageTitle')}
            </a>
          )}
        </div>
        {isOldPlanUser ? <TrialPeriodCounter /> : <CustomerNavSidebarPlanStatus />}
      </div>
    );
  }, [badgesData, navItems, isTrialExpired, t, isOldPlanUser]);

  if (!isDesktop) {
    return (
      <SwipeableDrawer
        classes={{ paper: clsxm(styles.Paper, 'w-[300px]'), root: styles.SwipeableRoot }}
        open={navMobileOpen}
        onOpen={toggleNavMenu(true)}
        onClose={toggleNavMenu(false)}
        ModalProps={{
          BackdropProps: { className: styles.MobileBackdrop },
        }}
        PaperProps={{
          className: 'min-w-[100%] sm:min-w-[300px]',
        }}
        elevation={0}
      >
        <div className="flex h-full flex-col justify-between">
          <Toolbar className="flex items-center gap-3 px-5">
            <IconButton
              size="medium"
              className={clsxm(styles['menu-icon-btn'], 'text-white')}
              variant="transparent"
              onClick={toggleNavMenu(false)}
            >
              <CloseIcon />
            </IconButton>
            <div className="flex flex-1 justify-center">
              <HomeButton className="-ml-[50px] text-white" />
            </div>
          </Toolbar>
          {navContent}
        </div>
      </SwipeableDrawer>
    );
  }

  return (
    <MuiDrawer variant="permanent" {...rest} classes={{ paper: styles.Paper }}>
      <Toolbar>
        <HomeButton className="text-white" />
      </Toolbar>
      {navContent}
    </MuiDrawer>
  );
};
