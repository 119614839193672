import { EAuthMethod } from '@app/auth/constant';
import { boolean, object, string } from 'yup';
import { oneOfEnum } from '../utils/yup.utils';
import { envOverride } from './env-override';

const EnvSchema = object({
  REACT_APP_TITLE: string().required(),
  REACT_APP_DESCRIPTION: string().required(),
  REACT_APP_ENV_NAME: string().required(),
  REACT_APP_OAUTH_GOOGLE_URL: string().required(),
  REACT_APP_API_BASE_URL: string().required(),
  REACT_APP_API_BRIEF_SERVICE_BASE_URL: string().required(),
  REACT_APP_HOST_URL: string().required(),
  NODE_ENV: string().required(),
  REACT_APP_AUTH_METHOD: oneOfEnum(EAuthMethod).required(),
  REACT_APP_SUPPORT_DARK_THEME: boolean().default(false),
  REACT_APP_GOOGLE_TAG_MANAGER_ID: string().required(),
});

export const typedEnv = EnvSchema.cast(
  {
    ...process.env,
    ...(process.env.REACT_APP_ENV_NAME !== 'production' && envOverride.state),
  },
  { stripUnknown: true }
);

export const IS_AUTH_METHOD_COOKIE = typedEnv.REACT_APP_AUTH_METHOD === EAuthMethod.COOKIE;
export const IS_AUTH_METHOD_TOKEN = typedEnv.REACT_APP_AUTH_METHOD === EAuthMethod.TOKEN;

export const IS_PROD_ENV = typedEnv.REACT_APP_ENV_NAME === 'production';
export const IS_NOT_PROD_ENV = !IS_PROD_ENV;
export const IS_LOCALHOST = window.location.hostname === 'localhost' || window.location.hostname.startsWith('192.168.');

/**
 * use to hide features not ready for release
 */
export const IS_FEATURE_READY_FOR_THIS_ENV = !IS_PROD_ENV;

export const IS_WORDPRESS_WIDGET =
  (window.location.hostname === 'trueqrcode.com' && window.location.protocol === 'https:') ||
  window.location.hostname.includes('onrocket.site') ||
  Boolean((window as any)._SPECIAL_WIDGET_ENV);
