import QRCodeSampleSrc from '@app/qr-widget/dialog/components/assets/qr-code-sample.png';
import { clsxm } from '@app/styles/clsxm';
import { ReactComponent as LinkIcon } from '@app/assets/icons/fi/fi-rr-link.svg';

type Props = {
  title?: string;
  link?: string;
  description?: string;
  className?: string;
};

export const QRCodeWidgetCard = ({
  title = 'PDF',
  link = 'https://examplelink.com',
  description = 'QR CODE 1',
  className,
}: Props) => {
  return (
    <div className={clsxm('w-32 px-2 py-2 flex items-center gap-1 rounded-md bg-white/65 backdrop-blur-sm', className)}>
      <div className="w-8 h-8 shrink-0">
        <img src={QRCodeSampleSrc} alt="qr-code" className="w-full h-full" />
      </div>
      <div className="flex flex-col">
        <h5 className="font-semibold text-[6px] text-accept-main">{title}</h5>
        <span className="flex gap-[2px] items-center">
          <LinkIcon className="text-gray-500" />
          <span className="text-[5px] text-gray-500">{link}</span>
        </span>
        <p className="font-bold text-[7px]">{description}</p>
      </div>
    </div>
  );
};
