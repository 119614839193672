import { UIStyledSection } from '../../components/UIStyledSection';
import { Button } from '@app/components';
import { EPlanLimitType, usePlanLimitDialog } from '@app/domain/plan/components/plan-limit-dialog/PlanLimitDialog';

export const UISectionPlanLimitDialog: React.FC = () => {
  const { openLimitDialog } = usePlanLimitDialog(null);

  return (
    <UIStyledSection title="PlanLimitDialog">
      <div>Click button below to show related limit dialog:</div>
      <div className="flex flex-wrap gap-4 items-center">
        <Button onClick={() => openLimitDialog(EPlanLimitType.CREATE_QR_CODE)}>Create QR code</Button>
        <Button onClick={() => openLimitDialog(EPlanLimitType.EDIT_QR_CODE)}>Edit QR code</Button>
        <Button onClick={() => openLimitDialog(EPlanLimitType.DOWNLOAD_SVG)}>Download in SVG</Button>
        <Button onClick={() => openLimitDialog(EPlanLimitType.CREATE_BULK)}>Create bulk</Button>
        <Button onClick={() => openLimitDialog(EPlanLimitType.GPS_SCAN)}>GPS scans</Button>
      </div>
      <div className="flex flex-wrap gap-4 items-center">
        <div>When selecting scan analytics duration more than 7 days:</div>
        <Button onClick={() => openLimitDialog(EPlanLimitType.UNLOCK_ANALYTICS_DURATION)}>
          Unlock deeper QR scan insights
        </Button>
      </div>
      <div className="flex flex-wrap gap-4 items-center">
        <Button onClick={() => openLimitDialog()}>Empty defaulte case</Button>
      </div>
    </UIStyledSection>
  );
};
