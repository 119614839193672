import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from '@app/components';
import { lazy, useMemo, useState } from 'react';
import { useHandler } from '@app/hooks/useHandler.hook';
import { gtag } from '@app/gtag/gtag';
import { DesignInDto, ECodeType, ECornerType, EFrameType, EPatternType } from '@app/swagger-types';
import { QRCodePatched } from '@app/domain/qr-code/components/QRCodePatched';
import { SelectQRCodeTypeContainer } from '@app/domain/qr-code/components/type/SelectQRCodeTypeContainer';
import { QRCodeRenderer } from '@app/domain/qr-code/components/QRCodeRenderer';
import { QRWidgetDesignAccordion } from './design/QRWidgetDesignAccordion';
import { clsxm } from '@app/styles/clsxm';
import { WIDGET_AVAILABLE_COLORS, WIDGET_DEFAULT_COLOR, WIDGET_LOGO_OPTION_APP_LOGO } from './widget.constants';

import { useMutation } from 'react-query';
import { WidgetApi } from './widget.api';
import { getLinkForCode } from '@app/domain/qr-code/qr-code.utils';

import { BulkIcon, FiRrArrowRight, FiRrCheck, ScanQrCodeIcon } from '@app/assets';
import { SvgScopeWrap } from '@app/utils/SvgScopeWrap';
import { useTranslation } from 'react-i18next';
import { IS_LOCALHOST } from '@app/environment/typed-env';
import { useMediaQuery, useTheme } from '@mui/material';
import { useGoogleClickId } from '@app/gtag/useGoogleClickId';
import { QRCodeInputContainer } from '@app/domain/qr-code/components/input/QRCodeInputContainer';
import { QRCodePreviewSection } from '@app/domain/qr-code/components/QRCodePreview.section';
import { QRCodeFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { DEFAULT_TEMPLATE_FORM_VALUES } from '@app/domain/template/constants';
import { DEFAULT_MULTILINK_FORM_VALUES, DEFAULT_VCARD_FORM_VALUES } from '@app/domain/qr-code/constants';
import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { EGTAGRegistrationPopUp } from '@app/gtag/gtag.type';

const LazyQRWidgetDialog = lazy(() =>
  import('./dialog/QRWidgetDialog').then(({ QRWidgetDialog }) => ({ default: QRWidgetDialog }))
);

export const QRWidgetContainer: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down('lg'));

  const formMethods = useForm<QRCodeFormSchema>({
    resolver: yupResolver(QRCodeFormSchema),
    defaultValues: {
      ...fakeNullFieldsAsUndefinedForApiInDto(DEFAULT_TEMPLATE_FORM_VALUES),
      multilink: DEFAULT_MULTILINK_FORM_VALUES,
      vcard: DEFAULT_VCARD_FORM_VALUES,
      codeType: ECodeType.WEBSITE,
      websiteUrl: '',
    },
  });
  const [qrCode, setQrCode] = useState<QRCodePatched | undefined>();
  const qrCodeHandler = useHandler((qr: QRCodePatched) => {
    setQrCode(qr);
  });

  const [frameType, setFrameType] = useState<EFrameType>(EFrameType.NONE);
  const [shape, setShape] = useState<EPatternType>(EPatternType.SQUARE);
  const [color, setColor] = useState<typeof WIDGET_AVAILABLE_COLORS[number]>(WIDGET_DEFAULT_COLOR);
  const [publicLogoType, setPublicLogoType] = useState<typeof WIDGET_LOGO_OPTION_APP_LOGO | undefined>();

  const [libraryId, setLibraryId] = useState<string | undefined>();

  const propsForDesign = useMemo(() => {
    let cornerType = ECornerType.SQUARE;
    if (shape === EPatternType.CLASSY_ROUNDED) {
      cornerType = ECornerType.ROUNDED;
    } else if (shape === EPatternType.DOTS) {
      cornerType = ECornerType.FULL_CIRCLE;
    }
    const textProps: Partial<DesignInDto> = {
      frameText: 'SCAN ME',
      frameTextSize: 15,
    };
    const design = {
      ...fakeNullFieldsAsUndefinedForApiInDto(DEFAULT_TEMPLATE_FORM_VALUES),
      ...textProps,
      frameType: frameType,
      frameTextColor: '#FFFFFF',
      cornerType,
      cornerColor: color,
      patternType: shape,
      patternColor: color,
      backgroundColor: '#FFFFFF',
      frameBackgroundColor: color,
      libraryId,
    };
    /**
     * TODO refactor to useEffect and useState?
     * Should not call formMethods.setValue directly in useMemo because of bad "setState" call
     */
    setTimeout(() => {
      formMethods.setValue('name', 'from-public-widget');
      formMethods.setValue('frameText', design.frameText);
      formMethods.setValue('frameTextSize', design.frameTextSize);
      formMethods.setValue('frameType', design.frameType);
      formMethods.setValue('frameTextColor', design.frameTextColor);
      formMethods.setValue('cornerType', design.cornerType);
      formMethods.setValue('cornerColor', design.cornerColor);
      formMethods.setValue('patternType', design.patternType);
      formMethods.setValue('patternColor', design.patternColor);
      formMethods.setValue('backgroundColor', design.backgroundColor);
      formMethods.setValue('frameBackgroundColor', design.frameBackgroundColor);
    });
    return design;
  }, [formMethods, color, shape, frameType, libraryId]);

  const [scheduledQRIdForDownload, setScheduledQRIdForDownload] = useState('');
  // set default value 'mock' for testing download dialog
  const { googleClickId } = useGoogleClickId();

  const createQRMutation = useMutation({
    mutationFn: async ({
      codeType,
      websiteUrl,
      multilink,
      pdf,
      vcard,
    }: Pick<QRCodeFormSchema, 'codeType' | 'websiteUrl' | 'multilink' | 'pdf' | 'vcard'>) => {
      const { ok, reason } = checkFormCodeData({
        codeType,
        websiteUrl,
        multilink,
        pdf,
        vcard,
      });
      if (!ok) {
        console.error(reason);
        return;
      }
      setScheduledQRIdForDownload('');
      gtag.downloadQRCode({
        codeType,
        websiteUrl,
        multilink: multilink?.title,
        pdf: pdf?.file?.fileName,
        vcard: vcard?.fullName,
      });
      // console.log({ websiteUrl, frameType, shape, color, logoUrl });
      const designValues = {
        ...fakeNullFieldsAsUndefinedForApiInDto(DEFAULT_TEMPLATE_FORM_VALUES),
        ...propsForDesign,
      };
      return WidgetApi.generateQRCode({
        codeType,
        website: websiteUrl
          ? {
              url: websiteUrl,
            }
          : undefined,
        multilink: multilink
          ? {
              ...fakeNullFieldsAsUndefinedForApiInDto(multilink),
              customLinks: multilink.customLinks || [],
              socialLinks: multilink.socialLinks?.map((e) => ({ ...e, title: 'social' })) || [],
            }
          : undefined,
        pdf: pdf || undefined,
        vcard: fakeNullFieldsAsUndefinedForApiInDto(vcard) || undefined,
        design: {
          ...designValues,
          libraryId: designValues?.libraryId === NO_LOGO_ID ? undefined : designValues.libraryId,
        },
        publicLogoType,
        googleClickId,
      });
    },

    onSuccess: (data) => {
      if (!data) {
        console.error('handle different codeTypes');
        return;
      }
      setTriggerSource(EGTAGRegistrationPopUp.DOWNLOAD);
      setScheduledQRIdForDownload(data.id);
      setIsDialogOpen(true);
      if (IS_LOCALHOST) {
        console.log(
          'schedule qr for download',
          data,
          getLinkForCode(data.id),
          `${window.location.origin}/qr/${data.id}`
        );
      }
    },
  });
  const serverSavedQR = createQRMutation.data;

  const [activeStep, setActiveStep] = useState<EWidgetStep>(EWidgetStep.PREVIEW);

  /**
   * need separate control for open dialog state because it may be not needed to be open
   * but still should have trigger source to avoid content flickering and effects trigger on change
   */
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [triggerSource, setTriggerSource] = useState<EGTAGRegistrationPopUp | null>(null);

  const codeType = formMethods.watch('codeType');
  const urlValue = formMethods.watch('websiteUrl');

  if (Object.keys(formMethods.formState.errors).length) {
    console.error('form errors', formMethods.formState.errors);
  }

  const onSubmit: SubmitHandler<QRCodeFormSchema> = (data) => {
    console.log('onsubmit', formMethods);
    if (!qrCode) {
      return;
    }
    createQRMutation.mutate({ ...data, codeType });
  };

  const isBaseDataProvided =
    (codeType === ECodeType.WEBSITE && Boolean(urlValue)) ||
    // TODO check partial schema validation for other codeType
    true;
  const isButtonDisabled =
    !qrCode || !isBaseDataProvided || Boolean(scheduledQRIdForDownload) || createQRMutation.isLoading;

  const isValidForm = formMethods.formState.isValid;
  // TODO merge logic with isBaseDataProvided
  const isFirstSectionFocused = !isValidForm;
  const isSecondSectionFocused = !isFirstSectionFocused;

  const shouldBlurQR = !isBaseDataProvided || createQRMutation.isLoading;

  const hasNoPreviewStep = codeType === ECodeType.WEBSITE || codeType === ECodeType.PDF;
  const hasPreviewStep = !hasNoPreviewStep;

  const renderPreview = () => {
    if (
      hasNoPreviewStep ||
      activeStep === EWidgetStep.QR_STYLE ||
      // needed for onDownloadHandler
      scheduledQRIdForDownload
    ) {
      return (
        <div
          className={clsxm(
            'flex-1 rounded-[10px] border-[1.5px] border-gray-200 bg-white p-6',
            isSecondSectionFocused && 'border-slate-400'
          )}
        >
          <div className="sticky top-6">
            {/* max height = qr min height + padding bottom */}
            <div className="mx-auto max-h-[228px] max-w-[204px] px-6 pb-6">
              <QRCodeRenderer
                asPreview
                onQRCode={qrCodeHandler}
                frameClassName={clsxm('bg-transparent min-h-[204px] mx-auto', shouldBlurQR && 'blur-sm')}
                {...propsForDesign}
                publicLogoType={publicLogoType}
              />
            </div>
            <QRWidgetDesignAccordion
              frameType={frameType}
              shape={shape}
              color={color}
              onFrameSelect={(frameType) => {
                setFrameType(frameType);
              }}
              onShapeSelect={(shape) => {
                setShape(shape);
              }}
              onColorSelect={(color) => {
                setColor(color);
              }}
              publicLogoType={publicLogoType}
              libraryId={libraryId}
              onLibSelect={(libId) => {
                console.log('on select', libId);
                if (libId === WIDGET_LOGO_OPTION_APP_LOGO) {
                  setPublicLogoType(WIDGET_LOGO_OPTION_APP_LOGO);
                  setLibraryId(undefined);
                } else {
                  setPublicLogoType(undefined);
                  setLibraryId(libId);
                }
              }}
              onSignUpTrigger={(source) => {
                setTriggerSource(source);
                setIsDialogOpen(true);
              }}
            />
            <div className="flex flex-col gap-4">
              <Button
                type="submit"
                disabled={isButtonDisabled}
                loading={createQRMutation.isLoading || Boolean(scheduledQRIdForDownload)}
                className="w-full rounded-lg"
              >
                {t('widget.download')}
              </Button>
              {hasPreviewStep && (
                <Button
                  type="button"
                  disabled={isButtonDisabled}
                  className="w-full rounded-lg"
                  variant="outlined"
                  onClick={() => {
                    setActiveStep(EWidgetStep.PREVIEW);
                  }}
                >
                  {t('widget.buttonBack')}
                </Button>
              )}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        className={clsxm(
          'flex-1 rounded-[10px] border-[1.5px] border-gray-200 bg-white p-6',
          isSecondSectionFocused && 'border-slate-400'
        )}
      >
        <div className="sticky top-6">
          <div className="max-w-[272px] mx-auto">
            <QRCodePreviewSection activeStep={'Input'} />
          </div>
          <div className="pt-8 mx-auto max-w-md flex flex-col gap-4">
            <Button
              type="button"
              disabled={isButtonDisabled}
              className="w-full rounded-lg"
              endIcon={<ChevronRightIcon />}
              onClick={() => {
                formMethods.trigger().then((isValid) => {
                  if (isValid) {
                    setActiveStep(EWidgetStep.QR_STYLE);
                  }
                });
              }}
            >
              {t('widget.buttonNext')}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const shouldDialogOpen = isDialogOpen && (scheduledQRIdForDownload ? Boolean(serverSavedQR) : Boolean(triggerSource));
  return (
    <FormProvider {...formMethods}>
      <LazyQRWidgetDialog
        // remount when link changed
        key={`dialog-${scheduledQRIdForDownload}`}
        open={shouldDialogOpen}
        onClose={() => {
          setScheduledQRIdForDownload('');
          setIsDialogOpen(false);
        }}
        serverSavedQRID={serverSavedQR?.id || null}
        googleClickId={googleClickId}
        triggerPopup={triggerSource}
      />
      <Form control={formMethods.control} onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="main-container flex flex-col gap-2 lg:flex-row">
          {/* QR type and input */}
          <div
            className={clsxm(
              'flex-[2] rounded-[10px] border-[1.5px] border-gray-200 bg-white',
              isFirstSectionFocused && 'border-slate-400'
            )}
          >
            <div className="flex h-full flex-col justify-between">
              <div>
                <SelectQRCodeTypeContainer className="h-auto bg-transparent" forWidgetMode />
                <QRCodeInputContainer forWidgetMode />
              </div>
              <div className="flex h-full items-end gap-4 px-5 py-6">
                <Button
                  variant="outlined"
                  className="rounded-lg py-2 xl:inline-flex"
                  size="medium"
                  onClick={() => {
                    setTriggerSource(EGTAGRegistrationPopUp.SCAN_TRACKING);
                    setIsDialogOpen(true);
                  }}
                  startIcon={<ScanQrCodeIcon />}
                >
                  {t('widget.scanTracking')}
                </Button>
                <Button
                  variant="outlined"
                  className="rounded-lg py-2 xl:inline-flex"
                  size="medium"
                  onClick={() => {
                    setTriggerSource(EGTAGRegistrationPopUp.CREATE_BULK);
                    setIsDialogOpen(true);
                  }}
                  startIcon={<BulkIcon />}
                >
                  {t('widget.createBulk')}
                </Button>
              </div>
            </div>
          </div>
          {/* circle at the middle */}
          <div>
            <div className="relative flex h-full flex-col">
              <div className="absolute left-1/2 lg:top-1/2 lg:left-auto">
                <div
                  className="relative -top-6 -left-6 flex h-12 w-12 items-center justify-center rounded-full border-gray-200 bg-white p-[1.5px]"
                  style={{
                    backgroundImage: `linear-gradient(to ${
                      isFirstSectionFocused ? (isBelowLg ? 'top' : 'left') : isBelowLg ? 'bottom' : 'right'
                    }, ${GRAY_200} 66.6%, ${SLATE_400} 66.6%)`,
                  }}
                >
                  <div className="flex h-full w-full items-center justify-center rounded-full bg-white">
                    <SvgScopeWrap>
                      {isValidForm ? (
                        <FiRrCheck className="fill-accept-main" />
                      ) : (
                        <FiRrArrowRight className="rotate-90 fill-accept-dark-blue lg:rotate-0" />
                      )}
                    </SvgScopeWrap>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* QR styling */}
          {renderPreview()}
        </div>
      </Form>
    </FormProvider>
  );
};

enum EWidgetStep {
  PREVIEW = 'PREVIEW',
  QR_STYLE = 'QR_STYLE',
}

// hardcode constants for less dependencies
const GRAY_200 = '#E5E7EB';
const SLATE_400 = '#94A3B8';

const checkFormCodeData = ({
  codeType,
  websiteUrl,
  multilink,
  pdf,
  vcard,
}: Partial<QRCodeFormSchema>): { ok: boolean; reason?: string } => {
  if (!codeType) {
    return { ok: false, reason: 'no code type' };
  }
  if (codeType === ECodeType.WEBSITE) {
    if (!websiteUrl) {
      return { ok: false, reason: 'no website' };
    }
    return { ok: true };
  }
  if (codeType === ECodeType.MULTILINK) {
    if (!multilink) {
      return { ok: false, reason: 'no multilink' };
    }
    return { ok: true };
  }
  if (codeType === ECodeType.PDF) {
    if (!pdf) {
      return { ok: false, reason: 'no pdf' };
    }
    return { ok: true };
  }
  if (codeType === ECodeType.V_CARD) {
    if (!vcard) {
      return { ok: false, reason: 'no vcard' };
    }
    return { ok: true };
  }
  return { ok: false, reason: 'code type not checked' };
};

type NullAsUndefined<T> = T extends null ? undefined : T;
type TreatNullFieldsAsUndefined<O> = { [key in keyof O]: NullAsUndefined<O[key]> };
const fakeNullFieldsAsUndefinedForApiInDto = <O,>(obj: O) => obj as unknown as TreatNullFieldsAsUndefined<O>;
