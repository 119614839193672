import { QRCodeFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MultilinkLinksEditor } from './MultilinkLinksEditor';
import { QRCodeSocialNetworks } from '../../QRCodeSocialNetworks';
import { MultilinkDesign } from './MultilinkDesign';
import { MultilinkHeaderEditor } from './MultilinkHeaderEditor';
import { MultilinkStepper } from './MultilinkStepper';

/*
TODO show form errors if any
With current behavior, if form invalid and related property not rendered
user will not see this error anyhow and will be blocked to navigate to other form steps.
Need a universal solution for this.
Maybe errors summary or at least snackbar.
But what if component rendered and focused on error - snackbar will be redundant in this case.
Maybe show snackbar only for null error types - which mean property was not buond to an input yet?
But also value may be not null and still invalid and still not linked to currently visible input
because input may be conditionally rendered in other form steps, for example.
*/

export const QRCodeMultilink: React.FC<{
  forWidgetMode?: boolean;
  disabledEditInput?: boolean;
}> = ({ forWidgetMode, disabledEditInput }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { trigger } = useFormContext<QRCodeFormSchema>();

  return (
    <div className="flex flex-col gap-4">
      <MultilinkStepper
        activeStep={activeStep}
        setActiveStep={async (nextStep) => {
          const canSetNextStep = await trigger('multilink', { shouldFocus: true });
          if (canSetNextStep) {
            setActiveStep(() => nextStep);
          }
        }}
      />

      {!activeStep ? (
        <>
          <MultilinkHeaderEditor forWidgetMode={forWidgetMode} disabledEditInput={disabledEditInput} />
          <MultilinkLinksEditor forWidgetMode={forWidgetMode} disabledEditInput={disabledEditInput} />
          <QRCodeSocialNetworks formPath="multilink.socialLinks" disabledEditInput={disabledEditInput} />
        </>
      ) : (
        <MultilinkDesign />
      )}
    </div>
  );
};
