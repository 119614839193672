import { Button, Container, Typography } from '@app/components';
import { useStore } from '@app/store/useStore.hook';
import { FormControlLabel, Switch } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUpdateCustomer } from '../api/user.hooks.api';
import { PermissionCrown } from '@app/domain/plan/components/permission-crown/PermissionCrown';
import { usePlanLimits } from '@app/domain/plan/hooks/usePlanLimits';
import { EPlanLimitType, usePlanLimitDialog } from '@app/domain/plan/components/plan-limit-dialog/PlanLimitDialog';

export const AccountQRCodePreferences: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser: user } = useStore(({ currentUser }) => ({
    currentUser,
  }));
  const { openLimitDialog: openGPSScanDialog } = usePlanLimitDialog(EPlanLimitType.GPS_SCAN);

  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();
  const { canTrackGPS } = usePlanLimits();
  const [useGPS, setUseGPS] = useState(user?.shouldAskForGPSCoordinates || false);

  const disableSubmitButton = user?.shouldAskForGPSCoordinates === useGPS && canTrackGPS;

  const handleToggleGPS = () => {
    if (!canTrackGPS) {
      openGPSScanDialog();
      return;
    }
    setUseGPS((prev) => !prev);
  };

  const handleSaveChanges = () => {
    if (!canTrackGPS) {
      openGPSScanDialog();
      return;
    }
    if (user) {
      updateCustomer(
        { shouldAskForGPSCoordinates: useGPS },
        {
          onSuccess: () => {
            toast.success(t('toaster.changesSaved'));
          },
        }
      );
    }
  };
  return (
    <Container className="flex flex-col gap-6 p-6 lg:rounded-xl">
      <Typography variant="m" className="font-bold flex items-center gap-2">
        {t('account.sectionQRPreferences.title')}
        <PermissionCrown requireUpgrade={!canTrackGPS} />
      </Typography>

      <div className="flex flex-col gap-4 lg:flex-row lg:gap-12">
        <div>
          <FormControlLabel
            onChange={handleToggleGPS}
            checked={useGPS}
            control={<Switch />}
            label={t('account.sectionQRPreferences.useGPSBasedLocation')}
          />
        </div>
      </div>
      <div>
        <Button
          onClick={handleSaveChanges}
          color="secondary"
          disabled={disableSubmitButton}
          variant="outlined"
          loading={isLoading}
          className="w-[220px]"
        >
          {t('dialog.saveChanges')}
        </Button>
      </div>
    </Container>
  );
};
