const SECOND_IN_MS = 1e3;
const MINUTE_IN_MS = SECOND_IN_MS * 60;
const HOUR_IN_MS = MINUTE_IN_MS * 60;
const DAY_IN_MS = HOUR_IN_MS * 24;

export const calcDuration = (deltaMs: number) => {
  let rest = deltaMs;
  const days = Math.floor(rest / DAY_IN_MS);
  rest -= days * DAY_IN_MS;
  const hours = Math.floor(rest / HOUR_IN_MS);
  rest -= hours * HOUR_IN_MS;
  const minutes = Math.floor(rest / MINUTE_IN_MS);
  rest -= minutes * MINUTE_IN_MS;
  const seconds = Math.floor(rest / SECOND_IN_MS);
  rest -= seconds * SECOND_IN_MS;
  return { days, hours, minutes, seconds, rest };
};
