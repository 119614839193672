export const translationForDomainKeys = {
  header: {
    amountOfCodes: '<0>{{amount}} of {{max}}</0> QR codes',
  },
  billing: {
    plan: 'plan',
    paymentMethod: 'Payment method',
    updatePaymentMethod: 'Update Payment Method',
    cardExpires: 'Expires',
    // Invoice,Date,Plan,Status,Amount,Billing and invoices,No invoices yet,Refetch
    invoiceTable: {
      invoice: 'Invoice',
      date: 'Date',
      plan: 'Plan',
      status: 'Status',
      amount: 'Amount',
      billingAndInvoices: 'Billing and invoices',
      placeholderNoInvoices: 'No invoices yet',
    },
    invoice: {
      /* #region  /**=========== status =========== */
      paid: 'Paid',
      failed: 'Failed',
      pending: 'Pending',
      disputed: 'Disputed',
      /* #endregion  /**======== status =========== */
    },
  },
  limit: {
    upgradeToUnlockThisFeature: 'Upgrade to unlock this feature.',
    streamlineWorkflowByCreatingMultipleCodes:
      'Save time and streamline your workflow by creating multiple QR codes at once.',
    keepYourCampaignsWithoutReprinting: `Keep your campaigns, product information, or promotions updated seamlessly without reprinting your QR codes.`,
    unlimitedQrCodeManagementForBusinessUsers:
      'Get unlimited control over your QR code database with API access—exclusively for Business plan users.',
    unlockMoreQrCodePossibilities:
      'Expand your QR code capabilities and create as many as you need without restrictions.',
    usageResetsInDays_one: 'Usage resets in {{count}} day',
    usageResetsInDays_other: 'Usage resets in {{count}} days',
    dialog: {
      common: {
        upgradeToUnlock: 'Upgrade to unlock this feature',
        testimonial: `“Trueqrcode Pro is a game-changer! Editable QR codes save us time and costs, and features like unlimited scans and analytics help drive our campaigns. Highly recommended for any business!”`,
        author: 'Sarah Johnson',
        authorPosition: 'Marketing Manager at Brightwave Solutions',
        upgradeMessage: 'Upgrade now to keep your QR codes flexible and up-to-date with <0>Trueqrcode Pro.</0>',
      },
      createQRCode: {
        upgradeToCreateMore: 'Upgrade to create more QR codes',
        title: 'QR Code creation limit reached',
        description: `You've hit the maximum number of QR codes for your current plan.`,
        availablePlans: 'Available plans',
        limitForFree: 'Limit of 2 QR codes',
        limitForPro: 'Up to 50 QR codes + Unlimited Scans',
        limitForBusiness: 'Unlimited QR codes + Unlimited Scans',
      },
      editQRCode: {
        title: 'Edit QR code',
        description:
          '<0>Stay in control with Trueqrcode Pro.</0> Easily edit the link or content behind your QR codes anytime you need.',
        includeInPro: 'Included in the Trueqrcode Pro',
        featureUnlimitedScans:
          '<0>Unlimited scans.</0> Reach as many people as you need without worrying about scan limits.',
        featureStayFlexible:
          '<0>Stay flexible -</0> Update URLs, contact information, or files without creating a new QR code.',
        featureSaveTime:
          '<0>Save time and resources -</0> Avoid reprinting or recreating designs when changes are needed.',
      },
      downloadSVG: {
        title: 'Download in SVG',
        description:
          '<0>Discover the benefits of Trueqrcode PRO.</0> Choose SVG format for your downloads to enjoy perfect scalability and quality.',

        includeInPro: 'Included in the Trueqrcode Pro',
        featureUnlimitedScans:
          '<0>Unlimited scans.</0> Reach as many people as you need without worrying about scan limits.',
        featureAlwaysEditableQR:
          '<0>Always Editable QR Codes.</0> Keep your codes up-to-date even after printing and sharing. Adjust links and content anytime to make sure your audience always has the latest information.',
      },
      unlockAnalyticsDuration: {
        title: 'Unlock deeper QR scan insights',
        description: `Gain more insights over time to see how your QR codes have performed.`,
        availablePlans: 'Available plans',
        limitForFree: '7 Days of Analytics',
        limitForPro: '6 Months of Analytics + Unlimited Scans',
        limitForBusiness: 'All unlimited',
      },
      GPSScan: {
        title: 'GPS Scan tracking',
        description:
          'Get an exact location of your scans. Instead of region-specific insights, you get meter-accurate locations.',
        includeInBusiness: 'Also included in the BUSINESS plan',
        featureUnlimitedScans: '<0>Unlimited scans.</0> Make sure your QR codes are always live.',
        featureUnlimitedQR: '<0>Unlimited QR codes.</0> Create as many QR codes as you need.',
        featureSaveTime: '<0>Editable:</0> Edit your QR codes, always. Even after printing and much more.',
      },
      createBulk: {
        title: 'Create Bulk',
        description:
          '<0>Effortlessly scale your QR code creation with Trueqrcode Pro.</0> Save time and streamline your workflow by creating multiple QR codes at once. ',

        includeInPro: 'Included in the Trueqrcode Pro',
        featureUnlimitedScans:
          '<0>Unlimited scans.</0> Reach as many people as you need without worrying about scan limits.',
        featureSeveTime: '<0>Save time -</0> Generate dozens or even hundreds of QR codes in just a few clicks.',
        featureIncreaseEfficiency:
          '<0>Increase efficiency -</0> Simplify your workflow for large campaigns or product labeling.',
      },
      apiKeys: {
        upgradeToBusiness: 'Upgrade to BUSINESS Plan to unlock this feature.',
        title: 'API Keys',
        description: 'Upgrade now to access advanced QR code options and additional features.',
        includeInBusiness: 'Also included in the BUSINESS plan',
        featureUnlimitedScans: '<0>Unlimited scans.</0> Make sure your QR codes are always live.',
        featureUnlimitedQRCodes: '<0>Unlimited QR codes.</0> Create as many QR codes as you need.',
        featureEditable: '<0>Editable:</0> Edit your QR codes, always. Even after printing and much more.',
      },
    },
  },
  subscription: {
    chooseTheMostSuitablePackage: 'Choose the most suitable package',
    selectAPlan: 'Select a plan',
    trialPlanEndsIn: 'Trial plan ends in:',
    planInfo: '({{maxQRCodes}} QR Codes, {{maxScans}} Scans)',
    month: 'month',
    offerEndsIn: 'Offer ends in',
    /* #region  /**=========== status =========== */
    active: 'Active',
    expired: 'Expired',
    canceled: 'Canceled',
    expiresOn: 'Expires on',
    renews: 'Renews',
    cancels: 'Cancels',
    /* #endregion  /**======== status =========== */
    billMonthly: 'Bill monthly',
    billQuarterly: 'Bill quarterly',
    billYearly: 'Bill yearly',
    selectCurrency: 'Select currency',
    currency: 'Currency',
    dynamicQRCodes: 'Dynamic QR Codes',
    qrCodes: 'QR Codes',
    unlimited: 'Unlimited',
    // @deprecated remove "scans" after release
    scans: 'scans',
    monthlyScans: 'monthly scans',
    qrScans: 'QR scans',
    scansUnlimited: 'Ulimited scans',
    ofAmount: 'of',
    perMonth: 'per month',
    // @deprecated remove "perQuarter" after release
    perQuarter: 'per quarter',
    perMonthQuarterly: 'per month, billed quarterly',
    perMonthYearly: 'per month, billed annually',
    upgradeTo: 'Upgrade to',
    downgradeTo: 'Downgrade to',
    subscribedToSuccessfuly: 'Subscribed to {{planName}} successfuly',
    changeToMonthly: 'Change to monthly',
    changeToQuarterly: 'Change to quarterly',
    changeToYearly: 'Change to yearly',
    monthly: 'monthly',
    quarterly: 'quarterly',
    yearly: 'yearly',
    qrCodesCreated: 'QR codes created',
    notAvailable: 'Not available',
    currentPlan: 'Current Plan',
    buttonUpgradePlan: 'Upgrade Plan',
    buttonUpgradePlanNow: 'Upgrade Now',
    buttonManagePlan: 'Manage Plan',
    buttonUpgradeToBusiness: 'Upgrade To Business',
    cancelPlan: 'Cancel Plan',
    cancelUpdate: 'Cancel Update',
    renewPlan: 'Renew Plan',
    areYouSureToCancelPlan: 'Are you sure you want to cancel {{planName}} plan?',
    areYouSureToCancelScheduledUpdate: 'Are you sure to cancel scheduled update to {{planName}} plan?',
    youCanRenewBeforeTheExpirationDate: 'You can renew it before the expiration date.',
    planUpdateScheduled: 'Update scheduled to plan {{planName}} ({{planCycle}}) on {{scheduledDate}}',
    includes: 'Includes',
    seeAllFeatures: 'See All Features',
    seeLessFeatures: 'See Less Features',
    discount: {
      freePlan: 'Get -20% additional discount today for <0>Pro</0> & <0>Business</0> subscription',
      common: {
        priceToday: 'Today’s price',
        inFirstDay: 'in the first 24 hours',
      },
    },
    downgradePlan: {
      confirmationDialogTitle: 'Just a double check...',
      confirmationDialogText:
        'Downgrading may cause your QR codes to no longer be active when the new (lower) package limit is exceeded.',
      buttonConfirm: 'Yes, I understand',
      buttonCancel: 'Keep my current plan',
    },
    feature: {
      yes: 'Yes',
      no: 'No',
      all: 'All',
      full: 'Full',
      sevenDays: '7 days',
      sixMonths: '6 months',
      oneYear: '1 year',
      maxScans: 'scans',
      templates: 'Templates',
      labels: 'Labels',
      qrAdjustable: 'Adjustable',
      scanAnalytics: 'Scan analytics',
      realTimeDataDashboard: 'Scan analytics (full)',
      tracksScansTimeOfDay: 'Tracks scans/time of day',
      tracksScansLocations: 'Tracks scans/locations',
      tracksScansDeviceType: 'Tracks scans/device type',
      tracksScansGPSCoordinates: 'GPS tracking ',
      qrDesignCustomisations: 'Design (full)',
      bulkQRCodeGeneration: 'Bulk creation',
      gdprCompliant: 'GDPR compliant',
      ccpaCompliant: 'CCPA compliant',
      knowledgeBase: 'Knowledge base',
      emailSupport: 'Email support',
      dedicatedCustomerSuccessManager: 'Dedicated customer success manager',
      fileExtensions: 'File extensions',
      qrTypes: 'QR types',
    },
    faq: {
      question1: 'What if something 1?',
      answer1: 'Yes, you can do it. For more details please contact our support 1.',
      question2: 'What if something 2?',
      answer2: 'Yes, you can do it. For more details please contact our support 2.',
      question3: 'What if something 3?',
      answer3: 'Yes, you can do it. For more details please contact our support 3.',
    },
    planError: {
      MAX_QR_CODE_USED_RESTRICTION: 'You have more QR codes in your account than the limit of this plan',
      PLAN_TEMPLATE_RESTRICTION: 'You used templates that are included in the STARTER package',
      PLAN_GPS_RESTRICTION: 'You used GPS tracking that is included in the STARTER package',
      PLAN_SCAN_RESTRICTION: 'You have more scans of QR codes than the limit of this plan',
    },
    globalBanner: {
      discountDescription: `Unlock all PRO features, always adjust your QR & keep it live. 20% discount, today only.`,
      regularDescription: `Unlock all PRO features, always keep your QR code(s) online.`,
      buttonGetStarted: `Get started`,
    },
    cancellation: {
      title: 'Cancel {{plan}} Plan',
      stepGeneral: {
        stepTitle: 'General info',
        bannerInfoTitle: 'By Canceling {{plan}} plan',
        youWillGet: `You will get`,
        featuresYouLoose: 'Features you will loose',
      },
      stepFeedback: {
        stepTitle: 'Confirmation & feedback',
        questions: {
          title: 'Please add your feedback (optional)',
          primaryReason: {
            title: 'What is the primary reason for cancelling your subscription?',
            choiceNoLongerNeeded: 'No longer needed the service',
            choiceBetterAlternative: 'Found a better alternative',
            choiceTooExpensive: 'Service too expensive',
            choiceTechnicalIssues: 'Technical issues',
            choiceLackOfFeatures: 'Lack of features',
            choicePoorSupport: 'Poor customer support',
            choiceOther: 'Other (please specify)',
          },
          featuresMissingOrImprove: {
            title: 'Were there any features you felt were missing or could be improved?',
            choiceYes: 'Yes (please specify)',
            choiceNo: 'No',
          },
          howToKeepYouAsCustomer: {
            title: 'Is there anything we could have done to keep you as a customer?',
            choiceYes: 'Yes (please specify)',
            choiceNo: 'No',
          },
          commentsOrSuggestions: {
            title: 'Any additional comments or suggestions?',
            placeholder: 'Type here...',
          },
        },
        fields: {
          textareaPlaceholder: 'Type here',
          textareaLabel: 'Your answer',
        },
      },
      buttonBack: 'Back',
      buttonContinue: 'Continue',
      buttonConfirm: 'Confirm & Cancel Plan',
    },
  },
  qr: {
    entityName: 'QR code',
    createQRCode: 'Create QR Code',
    editQRCode: 'Edit QR Code',
    preview: 'Preview',
    buttonNext: 'Next',
    buttonBack: 'Back',
    buttonConfirm: 'Confirm',
    buttonAndSave: 'And Save',
    buttonSaveChanges: 'Save changes',
    buttonDownload: 'Download',
    scans: 'Scans',
    socialNetworks: 'Social networks',
    selectNetwork: 'Select a network and paste a link',
    expired: {
      qrCodeNoLongerValid: 'QR code is no longer valid',
      /* #region  /**=========== TODO remove after release =========== */
      activateYourQr: 'Activate your qr now or share this link with the owner.',
      reactivateThisQRCode:
        'Reactivate this QR code if you are the owner or share this link with the owner of this QR code.',
      /* #endregion  /**======== TODO remove after release =========== */
      thanksForScanning: 'Thanks for scanning',
      notification: 'Notification',
      qrCodeNoLongerWorking: 'This QR code you tried to scan is no longer working for some reason.',
      yourScannerWorksWell: 'Your QR code scanner works well.',
      whatNow: 'What now?',
      haveYouScannedCode: 'Have you scanned the QR code?',
      informTheOwner: 'Inform the owner. So that person knows that the QR code no longer works.',
      areYouOwner: 'Are you the QR code owner?',
      clickOnIAmOwner: `Click on "I'm the owner" and log in to your account to activate the QR.`,
      buttonIAmOwner: `I'm the Owner`,
      ownerNotified: 'Owner notified',
      thankYou: 'Thank you!',
      //
      buttonClaimAndActivate: 'Claim and activate QR',
    },
    stepType: {
      stepTitle: 'Type',
      selectYourQRCodeType: 'Select your QR Code type',
      websiteURL: 'Website URL',
      websiteDescription: 'Website link to QR code',
      multiLinks: 'Multi links',
      multiLinksDescription: 'Link to multiple web pages',
      pdf: 'PDF',
      pdfDescription: 'Link to any PDF file',
      vCard: 'vCard Plus',
      vCardDescription: 'Your digital business card',
      gs1: 'Barcode QR code',
      gs1Description: 'GS1 Compliant',
      wifi: 'Wifi QR Code',
      wifiDescription: 'Link to any page on web',
    },
    stepInput: {
      stepTitle: 'Input',
      generalInfoTab: 'General info',
      designTab: 'Design',
      imageOptional: 'Image (optional)',
      addImageDescription: 'Add an image using the + button. Format: PNG, max file size 5MB.',
      noImage: 'No image',
    },
    stepCustomize: {
      stepTitle: 'Customize',
      logo: {
        tabTitle: 'Logo',
        description: 'Use an existing logo or add a new one using the + button. Format: PNG, max file size 5MB.',
        noLogo: 'No logo',
        deleteLogo: 'Delete logo?',
        yesDelete: 'Yes, delete',
      },
      frame: {
        tabTitle: 'Frame',
        noFrame: 'No frame',
      },
      pattern: {
        tabTitle: 'Pattern',
      },
      template: {
        tabTitle: 'Template',
        saveDesign: 'Save Design',
      },
    },
    stepDone: {
      stepTitle: 'Done',
      almostReady: 'Almost ready!',
      enterNameForYourQRCode: 'Enter a name for your QR code',
    },
    design: {
      frameCustomText: 'Custom text',
      frameTextSize: 'Size',
      frameTextColor: 'Text color',
      frameBackgroundColor: 'Frame background color',
      patternColor: 'QR pattern color',
      backgroundColor: 'QR background color',
      cornerColor: 'Corners color',
    },
    websiteInput: {
      title: 'Website URL',
      enterURL: 'Enter URL',
      yourCodeGeneratedAutomatically: 'Your QR Code will be generated automatically',
    },
    multilinkInput: {
      headerLinksTab: 'Header / Links / Social',
      header: 'Header',
      title: 'Title',
      description: 'Description (optional)',
      descriptionPlaceholder: 'Enter description',
      buttonAddLink: 'Add Link',
      link: 'link',
      linksSectionTitle: 'Links',
      linkTitleLabel: 'Link title',
      linkTitlePlaceholder: 'Enter title',
      linkURLLabel: 'Enter URL',
    },
    pdfInput: {
      content: 'Content',
      dragPdfOr: 'Drag PDF here or',
      browse: 'Browse',
      hint: 'When someone scans the QR code, they are immediately forwarded to the uploaded PDF',
    },
    vCardInput: {
      personalInformation: 'Personal information',
    },
    gs1Input: {
      title: 'Product title',
      descriptionPlaceholder: 'Description will be displayed here...',
      headerProductInfo: 'Product information',
      productInfoInputTitle: 'GTIN (EAN) Code',
      productInfoInputPlacesholder: 'e.g. 12345678910127',
      productInfoTab: 'Product information',
      linksTab: 'Header / Links / Social',
      designTab: 'Design',
      demoLinkProduct: 'Product Information',
      demoLinkAllergens: 'Allergens',
      demoLinkRecipes: 'Recipes',
      demoLinkCompany: 'Our company',
      banner: {
        title: 'Changing the GTIN will update your QR code',
        description: 'Once saved, the QR code will change, and the previous version will no longer be valid.',
      },
    },
    wifiInput: {
      title: 'Wifi QR Code',
      ssidLabel: 'Network name',
      ssidPlaceholder: 'SSID',
      checkboxLabel: 'Hidden',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Enter Password',
      encryptionLabel: 'Encryption',
    },
    bulk: {
      createBulkButton: 'Create Bulk',
      downloadExampleTitle: 'Download the CSV example',
      downloadExampleSubtitle: 'Download the CSV file and add your links to it.',
      uploadCSVTitle: 'Upload the CSV example',
      uploadCSVSubtitle: 'Complete the sample CSV and re-upload it.',
      dragCSVOr: 'Drag CSV here or',
      browse: 'Browse',
    },
  },
  template: {
    createTemplate: 'Create template',
    editTemplate: 'Edit template',
    title: 'Title',
    enterName: 'Enter a name for your template',
  },
  apikey: {
    title: 'API Keys',
    toastCopyMessageSuccess: 'API key successfully copied',
    toastCopyMessageError: 'API key could not be copied',
    entityName: 'API Key',
    access: {
      title: 'Your Keys',
      createKeyButton: 'Create API Key',
      deleteKeyButton: 'Delete API Key',
      liveAPIKey: 'Live API key',
      created: 'Created',
      LastUser: 'Last used',
      naver: 'Never',
      noAPIKey: 'No API Key Found',
      noAPIKeyDescription: 'You haven’t created an API key yet. Generate a key to start using our API.',
    },
    documentation: {
      title: 'API Documentation',
      description: 'Learn how to use our API and integrate it into your applications',
    },
  },
} as const;

// limit.dialog.common.upgradeToUnlock
// limit.dialog.unlockAnalyticsDuration.title;
// limit.dialog.unlockAnalyticsDuration.description;
// limit.dialog.unlockAnalyticsDuration.availablePlans;
// limit.dialog.unlockAnalyticsDuration.limitForFree;
// limit.dialog.unlockAnalyticsDuration.limitForPro;
// limit.dialog.unlockAnalyticsDuration.limitForBusiness;
