import { clsxm } from '@app/styles/clsxm';
import { Box } from '@mui/material';
import { FormStepBare } from './FormStepBare';
import DoneIcon from '@mui/icons-material/Done';
import { TranslationKey } from '@app/locales/defaultTranslation';
import { useTranslation } from 'react-i18next';
import { StepType } from './FormStepper';

export const FormStep = <T extends StepType>({
  index,
  step,
  steps,
  onChange,
  isActive = false,
  isCompleted = false,
  forWidgetMode = false,
  getStepTitleKey,
}: {
  index: number;
  step: T;
  steps: T[];
  onChange?: (step: T) => void;
  isActive?: boolean;
  isCompleted?: boolean;
  forWidgetMode?: boolean;
  getStepTitleKey: (step: T) => TranslationKey;
}) => {
  const { t } = useTranslation();
  return (
    <Box
      className={clsxm(
        'group cursor-pointer flex-auto pointer-events-none relative z-10 text-accept-dark-blue',
        onChange && 'pointer-events-auto hover:text-accept-main'
      )}
      onClick={() => onChange && onChange(step)}
    >
      <div
        className={clsxm(
          'flex items-center justify-center gap-2 py-2 font-medium',
          'sm:justify-start',
          forWidgetMode ? 'px-4 sm:px-8' : '',
          isCompleted && 'text-accept-main',
          isActive && 'text-accept-text'
        )}
      >
        <FormStepBare
          content={
            <div className="flex gap-2 items-center">
              <div
                className={clsxm(
                  'flex h-5 w-5 items-center justify-center rounded-[50%] border leading-5 border-gray-600 group-hover:border-accept-main',
                  (isActive || isCompleted) && 'border-accept-main bg-accept-main text-white'
                )}
              >
                {isCompleted ? <DoneIcon className="text-m" /> : index}
              </div>
              <span className="hidden sm:inline-block text-nowrap">{t(getStepTitleKey(step))}</span>
            </div>
          }
          {...checkStepPosition(step, steps)}
          isActive={isActive}
          isCompleted={isCompleted}
        />
      </div>
    </Box>
  );
};

const checkStepPosition = (
  step: string,
  steps: string[]
): {
  isFirstStep?: boolean;
  isMiddleStep?: boolean;
  isLastStep?: boolean;
} => {
  if (step === steps[0]) {
    return {
      isFirstStep: true,
    };
  }
  if (step === steps[steps.length - 1]) {
    return {
      isLastStep: true,
    };
  }
  return {
    isMiddleStep: true,
  };
};
