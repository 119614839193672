import { OTPHelpers } from '@app/auth/otp.helpers';
import { OTPResendCounter } from '@app/auth/components/OTPResendCounter';
import { useTranslation } from 'react-i18next';
import { FiRrArrowSmallRight } from '@app/assets';
import { Button, Input } from '@app/components';
import { BackButton } from '@app/components/buttons/back-button/BackButton';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { CheckEmailConfirmationInDto } from '@app/swagger-types';
import { AuthApi } from '@app/auth/api/auth.api';
import { maybeRenderError } from '@app/utils/maybeRenderError';

export const EnterOTPFlow: React.FC<{
  email: string;
  onCancel: () => void;
  onSuccess: () => void;
  onRequestResendOTP: () => void;
}> = ({ onCancel, email, onSuccess, onRequestResendOTP }) => {
  const { t } = useTranslation();
  const [otpCode, setOtpCode] = useState('');
  const [issue, setIssue] = useState<EIssue | undefined>();
  const [blockedUntilDate, setBlockedUntilDate] = useState<Date | undefined>();

  const {
    mutate: signUpVerifyEmail,
    isLoading: isLoadingStepVerify,
    // TODO show error
    error: errorStepVerify,
  } = useMutation(
    async (formData: CheckEmailConfirmationInDto) => {
      const { data } = await AuthApi.signUpVerifyEmail({ ...formData });
      return data;
    },
    {
      onSuccess: (res) => {
        if (res.hasVerifiedEmail) {
          onSuccess();
        } else {
          if (res.nextConfirmationEmailDate) {
            setIssue(EIssue.TOO_MANY_ATTEMPTS);
            try {
              setBlockedUntilDate(new Date(res.nextConfirmationEmailDate));
            } catch {}
          } else {
            setIssue(EIssue.INVALID_CODE);
          }
        }
      },
    }
  );

  const isSubmitDisabled = !otpCode || otpCode.length !== 6 || isLoadingStepVerify;

  const getNormalError = () => {
    if (errorStepVerify) {
      return errorStepVerify as Error;
    }
    if (issue === EIssue.INVALID_CODE) {
      return t('auth.otp.errorInvalidCode');
    }
    if (issue === EIssue.TOO_MANY_ATTEMPTS) {
      return t('auth.otp.errorTooManyAttempts');
    }
    if (issue) {
      return 'unknown error';
    }
  };

  return (
    <div className="flex flex-1 flex-col h-full gap-y-4 sm:flex-initial">
      <div className="flex flex-col h-full gap-4 justify-between">
        {/* content */}
        <div className="flex flex-col gap-4">
          <div className="flex gap-2 text-xl font-bold">
            <BackButton
              size="small"
              onClick={() => {
                onCancel();
              }}
            />
            {t('auth.otp.titleAlmostSignedUp')}
          </div>
          <div>
            {t('auth.otp.enterTheCodeSentTo')} <b>{email}</b> {t('auth.otp.toFinishSignUp')}
          </div>
          <Input
            label={t(OTPHelpers.translationTag)}
            placeholder={OTPHelpers.placeholder}
            value={otpCode}
            onChange={(e) => {
              setOtpCode(OTPHelpers.normalize(e.target.value));
            }}
            autoComplete={OTPHelpers.autoComplete}
            withBG
          />
          {maybeRenderError(getNormalError())}
          <OTPResendCounter
            onClickResend={() => {
              onRequestResendOTP();
            }}
            blockedUntilDate={blockedUntilDate}
          />
        </div>

        {/* actions */}
        <div>
          <Button
            endIcon={<FiRrArrowSmallRight className="fill-white" />}
            className="px-16"
            fullWidth
            disabled={isSubmitDisabled}
            onClick={() => {
              signUpVerifyEmail({
                email,
                confirmationCode: otpCode,
              });
            }}
          >
            {t('auth.signUpStep.proceed')}
          </Button>
        </div>
      </div>
    </div>
  );
};

enum EIssue {
  INVALID_CODE = 'INVALID_CODE',
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
}
