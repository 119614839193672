import { QrCodeIcon, ScanIcon } from '@app/assets';
import { clsxm } from '@app/styles/clsxm';
import { PlanLimitOutDto } from '@app/swagger-types';
import { useTranslation } from 'react-i18next';

export const SubscriptionPlanHeaderLimits: React.FC<{
  planLimit: PlanLimitOutDto;
  fullWidth?: boolean;
  className?: string;
}> = ({ planLimit, className, fullWidth }) => {
  const { t } = useTranslation();
  const hasUnlimitedScans = planLimit.amountMaxScans === null;

  return (
    <div className={clsxm('flex flex-col gap-4 items-center justify-center', className)}>
      <div
        className={clsxm(
          'flex items-center justify-center gap-2 rounded-xl bg-gray-100 p-2 font-semibold text-accept-dark-blue',
          fullWidth && 'w-full'
        )}
      >
        <QrCodeIcon />
        <span>
          {planLimit.amountMaxQrCodes || t('subscription.unlimited')} {t('subscription.qrCodes')}
        </span>
      </div>
      <div
        className={clsxm(
          'flex items-center justify-center gap-2 rounded-xl bg-gray-100 p-2 font-semibold text-accept-dark-blue',
          fullWidth && 'w-full'
        )}
      >
        <ScanIcon />
        {hasUnlimitedScans ? (
          <>
            <span>{t('subscription.scansUnlimited')}</span>
          </>
        ) : (
          <>
            <span>
              {planLimit.amountMaxScans} {t('subscription.monthlyScans')}
            </span>
          </>
        )}
      </div>
    </div>
  );
};
