import { FiRrArrowSmallRight } from '@app/assets';
import { Button, Container, PlaceholderBox, Typography } from '@app/components';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { Routes } from '@app/constants/routes';
import { useDeleteTemplate, useGetTemplates } from '@app/domain/template/api/template.hooks.api';
import { TemplateContainer } from '@app/domain/template/components/TemplateContainer';
import { useTypedNavigate } from '@app/router';
import { clsxm } from '@app/styles/clsxm';
import { DesignOutDto } from '@app/swagger-types';
import { buildSortParam } from '@app/utils/api.util';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const MyTemplatesDashboard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();
  const {
    data: paginatedTemplates,
    isFetching,
    isLoading,
  } = useGetTemplates({
    page: 0,
    sort: buildSortParam<DesignOutDto>('createdDate', 'desc'),
    size: 4,
  });
  const { mutate: deleteTemplate, isLoading: deleteLoading } = useDeleteTemplate();

  const handleDelete = useCallback(
    (design: DesignOutDto) => {
      if (design?.template?.id) {
        deleteTemplate(design?.template?.id, {
          onSuccess: () => {
            toast.success(t('toaster.templateDeleted'));
          },
        });
      }
    },
    [deleteTemplate, t]
  );

  const handleNavigate = useCallback(
    (id: string) => {
      navigate({ to: Routes.customer.templates.edit, preserveQueryParams: true, params: { id } });
    },
    [navigate]
  );

  const getNavigationUrl = (id: string) => {
    return Routes.customer.templates.edit.replace(':id', id);
  };

  const templatesPage = paginatedTemplates?.pages[0];
  const showLoader = isFetching && !templatesPage;

  return (
    <Container className="flex flex-col gap-4 lg:rounded-xl">
      <Typography className="font-bold sm:text-l">{t('templates.pageTitle')}</Typography>
      <div className="relative">
        {!templatesPage?.total && !isLoading ? (
          // TODO show link to create template
          <PlaceholderBox variant="primary" title={t('placeholder.noTemplatesCreated')} />
        ) : null}

        {showLoader ? (
          <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
            <ContentLoader isEmpty={false} loading entity="Templates" />
          </div>
        ) : null}
      </div>

      <div
        className={clsxm('grid gap-4 sm:grid-cols-2 md:grid-cols-4', showLoader && 'pointer-events-none opacity-80')}
      >
        {templatesPage?.result.map((template) => (
          <TemplateContainer
            key={template.id}
            template={template}
            deleteTemplate={handleDelete}
            handleNavigate={handleNavigate}
            getNavigationUrl={getNavigationUrl}
            disalbed={deleteLoading}
            qrClassName="max-w-[150px]"
          />
        ))}
      </div>

      <div className="flex justify-end">
        <a href={Routes.customer.templates.index} onClick={(e) => e.preventDefault()} aria-hidden>
          <Button
            className="group"
            endIcon={<FiRrArrowSmallRight className="fill-accept-main group-hover:fill-accept-hover" />}
            color="primary"
            variant="text"
            onClick={() => {
              navigate({ to: Routes.customer.templates.index });
            }}
          >
            {t('templates.viewAllTemplates')}
          </Button>
        </a>
      </div>
    </Container>
  );
};
