import { useState, useEffect } from 'react';
import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { FIRST_DAY_DISCOUNT_AVAILABLE_FOR_MS } from '../discount/first-day-discount.constant';
import { differenceInSeconds } from 'date-fns';
import { UserOutSchema } from '@app/auth/api/auth.schema';
import { calcDuration } from '@app/utils/date.utils';

// TODO remove after testing or make part of dev kit config
// const DEV_SHOW_MOCK_FIRST_DAY_DISCOUNT = IS_NOT_PROD_ENV && true;

export const useFirstDayDiscountAvailable = () => {
  const { currentUser } = useAuthorizedUser();
  const { isFreePlan } = useActivePlan();
  const [timeLeft, setTimeLeft] = useState<string | null>(null);

  const checkIsAvailable = () => {
    // if (DEV_SHOW_MOCK_FIRST_DAY_DISCOUNT) {
    //   return true;
    // }
    if (!isFreePlan) {
      return false;
    }
    if (!currentUser) {
      return false;
    }
    try {
      const date = new Date(currentUser.createdDate);
      const isTooLate = Date.now() > date.getTime() + FIRST_DAY_DISCOUNT_AVAILABLE_FOR_MS;
      return !isTooLate;
    } catch (error) {
      console.error(error);
    }
    return true;
  };

  const isAvailable = checkIsAvailable();

  const calculateFormattedTime = (currentUser: UserOutSchema | null) => {
    if (!currentUser) {
      return null;
    }

    const date = new Date(currentUser.createdDate);
    const endTime = date.getTime() + FIRST_DAY_DISCOUNT_AVAILABLE_FOR_MS;
    const deltaMs = differenceInSeconds(endTime, Date.now()) * 1e3;

    const duration = calcDuration(deltaMs);
    return deltaMs > 0
      ? [
          maybePrefixWithZero(duration.hours),
          maybePrefixWithZero(duration.minutes),
          maybePrefixWithZero(duration.seconds),
        ].join(':')
      : null;
  };

  useEffect(() => {
    if (!isAvailable) {
      return;
    }

    const updateTimer = () => {
      setTimeLeft(calculateFormattedTime(currentUser));
    };

    updateTimer();

    const interval = setInterval(updateTimer, 1e3);

    return () => clearInterval(interval);
  }, [currentUser, isAvailable]);

  return { isAvailable, timeLeft };
};

const maybePrefixWithZero = (digit: number) => {
  return `${digit}`.padStart(2, '0');
};
