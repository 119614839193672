import { clsxm } from '@app/styles/clsxm';
import { Dialog } from '@app/hoc';
import { useIsMobile } from '@app/hooks/useIsMobile.hook';
import { usePlanLimitGetDialogContent } from './usePlanLimitGetDialogContent';
import { FiRrCrossSmall } from '@app/assets';
import { ManagePlanButton } from '../ManagePlanButton';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useTranslation } from 'react-i18next';
import { PlanLimitProductPreview } from './PlanLimitProductPreview';
import { ReactElement } from 'react';
import { useHandler } from '@app/hooks/useHandler.hook';
import { marketingSelectors } from '@app/gtag/marketing.constant';

export const PlanLimitDialog = NiceModal.create(
  ({ planLimitType }: { planLimitType: EPlanLimitType | null; children?: ReactElement }) => {
    const { t } = useTranslation();

    const { remove: removePlanLimitDialog, visible } = useModal();
    const isMobile = useIsMobile();
    const { header, description, decorativeContent } = usePlanLimitGetDialogContent(planLimitType);

    const renderLeftSide = () => {
      let button: JSX.Element | null = null;

      switch (planLimitType) {
        case EPlanLimitType.EDIT_QR_CODE:
        case EPlanLimitType.DOWNLOAD_SVG:
        case EPlanLimitType.CREATE_BULK:
          button = (
            <ManagePlanButton
              variant="contained"
              size="medium"
              fullWidth
              onCloseModal={removePlanLimitDialog}
              className="mt-8"
              id={marketingSelectors.getLimitDialogButtonId(planLimitType)}
            >
              {t('subscription.buttonUpgradePlanNow')}
            </ManagePlanButton>
          );
          break;
        case EPlanLimitType.CREATE_QR_CODE:
        case EPlanLimitType.UNLOCK_ANALYTICS_DURATION:
          button = (
            <ManagePlanButton
              variant="contained"
              size="medium"
              fullWidth
              onCloseModal={removePlanLimitDialog}
              className="mt-4"
              id={marketingSelectors.getLimitDialogButtonId(planLimitType)}
            />
          );
          break;
        case EPlanLimitType.GPS_SCAN:
        case EPlanLimitType.API_KEYS:
          button = (
            <ManagePlanButton
              variant="contained"
              size="medium"
              fullWidth
              onCloseModal={removePlanLimitDialog}
              className="mt-8"
              id={marketingSelectors.getLimitDialogButtonId(planLimitType)}
            >
              {t('subscription.buttonUpgradeToBusiness')}
            </ManagePlanButton>
          );
          break;
        default:
          button = null;
      }

      return (
        <div className="flex flex-col h-full">
          {header}
          {description}
          {button}
        </div>
      );
    };

    const renderRightSide = () => {
      if (isMobile) {
        return null;
      }
      if (
        planLimitType === EPlanLimitType.DOWNLOAD_SVG ||
        planLimitType === EPlanLimitType.EDIT_QR_CODE ||
        planLimitType === EPlanLimitType.CREATE_BULK
      ) {
        return <PlanLimitProductPreview triggerPopup={planLimitType} hideFreeBadge />;
      }

      return <div className="bg-gray-100 pt-16 p-8 flex flex-col justify-between">{decorativeContent}</div>;
    };

    return (
      <Dialog
        open={visible}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') {
            // ok to close on backdrop
            // return;
          }
          removePlanLimitDialog();
        }}
        hideHeader
        paperClassName="text-accept-dark-blue shadow-none bg-transparent rounded-none"
        maxWidth="md"
        fullScreen={isMobile}
      >
        <div className="flex flex-col gap-4 pt-4 md:pt-0">
          <div
            className={clsxm(
              'rounded-2xl bg-white shadow overflow-hidden',
              // extra space needed on mobile to avoid overlap with support chat FAB on prod
              'pb-20 md:pb-0'
            )}
          >
            <div className={clsxm('flex w-full justify-end pr-4 pt-4', !isMobile && 'absolute top-0 right-0 z-10')}>
              <div className="cursor-pointer hover:bg-white rounded" onClick={removePlanLimitDialog} aria-hidden>
                <FiRrCrossSmall />
              </div>
            </div>
            <div className={clsxm('grid md:grid-cols-2')}>
              {/* left side */}
              <div className="bg-white p-8 md:min-h-[520px] md:min-w-96">{renderLeftSide()}</div>
              {/* right side */}
              {renderRightSide()}
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
);

export const usePlanLimitDialog = (defaultType: EPlanLimitType | null) => {
  const { remove: removeLimitDialog } = useModal(PlanLimitDialog);

  const openLimitDialog = useHandler((overrideType?: EPlanLimitType) => {
    NiceModal.show(PlanLimitDialog, {
      planLimitType: overrideType ?? defaultType ?? null,
    });
  });

  const memoizedRemoveLimitDialog = useHandler(() => {
    removeLimitDialog();
  });

  return { openLimitDialog, removeLimitDialog: memoizedRemoveLimitDialog };
};

export enum EPlanLimitType {
  CREATE_QR_CODE = 'CREATE_QR_CODE',
  EDIT_QR_CODE = 'EDIT_QR_CODE',
  DOWNLOAD_SVG = 'DOWNLOAD_SVG',
  UNLOCK_ANALYTICS_DURATION = 'UNLOCK_ANALYTICS_DURATION',
  CREATE_BULK = 'CREATE_BULK',
  GPS_SCAN = 'GPS_SCAN',
  API_KEYS = 'API_KEYS',
}
