import { QRCodeFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControlLabel, InputLabel, Radio, RadioGroup } from '@mui/material';
import { Checkbox, Container, Input, Typography } from '@app/components';
import { useTranslation } from 'react-i18next';
import { clsxm } from '@app/styles/clsxm';
import { WiFiQRCodeInDto } from '@app/swagger-types';

const encryptionOptions: WiFiQRCodeInDto['encryption'][] = ['NONE', 'WPA', 'WPA2', 'WEP'];

export const QRCodeWIFI: React.FC<{
  forWidgetMode?: boolean;
  disabledEditInput?: boolean;
  isEditMode?: boolean;
}> = ({ /*forWidgetMode, */ disabledEditInput /*, isEditMode */ }) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext<QRCodeFormSchema>();

  const ssidErrorMessage = errors.wifi && 'ssid' in errors.wifi ? errors.wifi.ssid?.message : undefined;
  const isPasswordEncryptionRequired = watch('wifi.encryption') === 'NONE';

  return (
    <Container className="flex flex-col gap-3 sm:gap-4 xl:rounded-xl">
      <Typography className="font-bold sm:text-l">{t('qr.wifiInput.title')}</Typography>

      <div className="grid grid-cols-2 gap-4 items-end">
        <Controller
          name="wifi.ssid"
          control={control}
          render={({ field: { value, onChange, ...field }, fieldState }) => (
            <Input
              {...field}
              value={value || ''}
              label={t('qr.wifiInput.ssidLabel')}
              onChange={(e) => {
                onChange(e);
              }}
              withBG
              placeholder={t('qr.wifiInput.ssidPlaceholder')}
              errorMessage={fieldState?.error?.message || ''}
              disabled={disabledEditInput}
            />
          )}
        />
        <div className={clsxm('mb-2', ssidErrorMessage && 'mb-6')}>
          <Controller
            name="wifi.hidden"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={<span className="text-gray-700">{t('qr.wifiInput.checkboxLabel')}</span>}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Controller
          name="wifi.password"
          control={control}
          render={({ field: { value, onChange, ...field }, fieldState }) => (
            <Input
              {...field}
              value={value || ''}
              label={t('qr.wifiInput.passwordLabel')}
              onChange={(e) => {
                onChange(e);
              }}
              withBG
              placeholder={t('qr.wifiInput.passwordPlaceholder')}
              errorMessage={fieldState?.error?.message || ''}
              disabled={disabledEditInput || isPasswordEncryptionRequired}
            />
          )}
        />
        {/* <Controller
          name="name"
          control={control}
          render={({ field: { value, onChange, ...field }, fieldState }) => (
            <Input
              {...field}
              value={value || ''}
              label={t('qr.gs1Input.productInfoInputTitle')}
              onChange={(e) => {
                // if (fieldState?.error?.message) {
                //   trigger('gs1.gtin');
                // }
                onChange(e);
              }}
              withBG
              placeholder={t('qr.gs1Input.productInfoInputPlacesholder')}
              errorMessage={fieldState?.error?.message || ''}
            />
          )}
        /> */}
      </div>

      <div className="flex flex-col gap-2">
        <InputLabel className={'text-gray-700'}>{t('qr.wifiInput.encryptionLabel')}</InputLabel>
        <Controller
          name="wifi.encryption"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field} className="flex flex-row">
              {encryptionOptions.map((option) => (
                <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
              ))}
            </RadioGroup>
          )}
        />
      </div>
    </Container>
  );
};
