import { MultiLinksIcon, PdfIcon, ScanIcon, WIFIIcon } from '@app/assets';
import { VCardPlusIcon, WebsiteUrlIcon } from '@app/assets';
import { ECodeType, GS1OutDto, MultilinkOutDto, VCardOutDto, WiFiQRCodeOutDto } from '@app/swagger-types';
import { QRCodeFormSchema } from './api/qr-code.form.schema.api';
import { QRCodeTypeItemType } from './qr-code.types';
import { IS_NOT_PROD_ENV } from '@app/environment/typed-env';

export const CODE_TYPE_ITEMS_ALL: QRCodeTypeItemType[] = [
  {
    icon: <WebsiteUrlIcon />,
    value: ECodeType.WEBSITE,
  },
  {
    icon: <MultiLinksIcon />,
    value: ECodeType.MULTILINK,
  },
  {
    icon: <PdfIcon />,
    value: ECodeType.PDF,
  },
  {
    icon: <VCardPlusIcon />,
    value: ECodeType.V_CARD,
  },
  {
    icon: <ScanIcon className="w-7 h-7" />,
    value: ECodeType.GS1,
  },
  // TODO show wifi on PROD when business requirements are ready
];

if (IS_NOT_PROD_ENV) {
  CODE_TYPE_ITEMS_ALL.push({
    icon: <WIFIIcon />,
    value: ECodeType.WIFI,
  });
}

// TODO trigger pdf field when ready
export const QR_CODE_INPUT_STEP_FORM_FIELDS: Array<keyof QRCodeFormSchema> = [
  'websiteUrl',
  'multilink',
  'pdf',
  'vcard',
  'gs1',
  'wifi',
];
export const QR_CODE_DONE_STEP_FORM_FIELDS: Array<keyof QRCodeFormSchema> = ['name'];

export const DEFAULT_MULTILINK_FORM_VALUES: Pick<
  MultilinkOutDto,
  | 'title'
  | 'designBgColor'
  | 'designTitleColor'
  | 'designDescriptionColor'
  | 'buttonBgColor'
  | 'buttonTextColor'
  | 'buttonHoverColor'
  | 'buttonBorderColor'
> = {
  // required
  title: '',
  // design
  designBgColor: '#F3F4F6',
  designTitleColor: '#1F2937',
  designDescriptionColor: '#6B7280',
  buttonBgColor: '#FFFFFF',
  buttonTextColor: '#000000',
  buttonHoverColor: '#48B240',
  buttonBorderColor: '#D1D5DB',
};

export const DEFAULT_GS1_FORM_VALUES: Pick<
  GS1OutDto,
  | 'gtin'
  | 'title'
  | 'description'
  | 'designBgColor'
  | 'designTitleColor'
  | 'designDescriptionColor'
  | 'buttonBgColor'
  | 'buttonTextColor'
  | 'buttonHoverColor'
  | 'buttonBorderColor'
> = {
  // required
  gtin: '',
  title: '',

  description: '',
  // design
  designBgColor: '#F3F4F6',
  designTitleColor: '#1F2937',
  designDescriptionColor: '#6B7280',
  buttonBgColor: '##F3F4F6',
  buttonTextColor: '#6B7280',
  buttonHoverColor: '#48B240',
  buttonBorderColor: '#D1D5DB',
};

export const DEFAULT_VCARD_FORM_VALUES: Pick<
  VCardOutDto,
  | 'fullName'
  | 'phoneNumber'
  | 'designPrimaryColor'
  | 'designSecondaryColor'
  | 'designNameColor'
  | 'designTextColor'
  | 'designIconColor'
  | 'designTextHoverColor'
  | 'designTitleColor'
  | 'buttonText'
  | 'buttonSize'
  | 'buttonBgColor'
  | 'buttonTextColor'
  | 'buttonHoverColor'
  | 'buttonBorderColor'
> = {
  // required
  fullName: '',
  phoneNumber: '',
  // design
  designPrimaryColor: '#132A4D',
  designSecondaryColor: '#F3F4F6',
  designNameColor: '#FFFFFF',
  designTextColor: '#132a4d',
  designIconColor: '#455773',
  designTextHoverColor: '#132a4d',
  designTitleColor: '#6b7280',
  buttonText: 'Add Contact',
  buttonSize: 14,
  buttonBgColor: '#FFFFFF',
  buttonTextColor: '#000000',
  buttonHoverColor: '#48B240',
  buttonBorderColor: '#E5E7EB',
};

export const DEFAULT_WIFI_FORM_VALUES: Pick<WiFiQRCodeOutDto, 'ssid' | 'encryption' | 'password' | 'hidden'> = {
  ssid: '',
  encryption: 'NONE',
  password: undefined,
  hidden: false,
};
