import { apiService, publicApiService, validateAndAssert } from '@app/services/api-service';
import { LibraryInDto } from '@app/swagger-types';
import { pageableData } from '@app/utils/yup.utils';
import { APILibraryQueryParams } from '../library.types';
import { LibraryOutDtoSchema } from './library.schema.api';

export abstract class LibraryApi {
  static async getAll(params: APILibraryQueryParams) {
    return validateAndAssert(apiService.get('/private/library', { params }), pageableData(LibraryOutDtoSchema));
  }
  static async publicGetById(id: string) {
    return validateAndAssert(publicApiService.get(`/public/library/${id}`), LibraryOutDtoSchema);
  }
  static async create(dto: LibraryInDto) {
    return validateAndAssert(apiService.post('/private/library', dto), pageableData(LibraryOutDtoSchema));
  }
  static async createPublic(dto: LibraryInDto) {
    return validateAndAssert(apiService.post('/public/library', dto), pageableData(LibraryOutDtoSchema));
  }
  static async delete(id: string) {
    return apiService.delete(`/private/library/${id}`);
  }
}
