import { enumValues } from '@app/utils/assertion.utils';
import { UIStyledSection } from '../../components/UIStyledSection';
import { TranslationKey } from '@app/locales/defaultTranslation';
import { FormStepper } from '@app/components/stepper/FormStepper';

enum ECancelPlanFormStep {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
}

export const UISectionStepper: React.FC = () => {
  const CancelPlanFormSteps = enumValues(ECancelPlanFormStep);

  const onChangeActiveStep = async (step: ECancelPlanFormStep, skipValidation?: boolean): Promise<void> => {
    console.log('Step changed to:', step, 'Skip validation:', skipValidation);
  };

  const getStepTitleKey = (step: ECancelPlanFormStep): TranslationKey => {
    console.log('Fetching translation key for step:', step);
    return 'translation.key.placeholder' as TranslationKey;
  };

  const completedSteps: ECancelPlanFormStep[] = [];
  const activeStep: ECancelPlanFormStep = 'SomeStep' as ECancelPlanFormStep;
  const canSaveQRCode = true;

  return (
    <UIStyledSection title="Stepper">
      <FormStepper<ECancelPlanFormStep>
        steps={CancelPlanFormSteps}
        onChange={onChangeActiveStep}
        completedSteps={completedSteps}
        activeStep={activeStep}
        disabled={!canSaveQRCode}
        getStepTitleKey={getStepTitleKey}
      />
    </UIStyledSection>
  );
};
