import { FiSrExclamation } from '@app/assets';
import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { Routes } from '@app/constants/routes';
import { ManagePlanButton } from '@app/domain/plan/components/ManagePlanButton';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { usePlanLimits } from '@app/domain/plan/hooks/usePlanLimits';
import { TypedLink } from '@app/router';
import { clsxm } from '@app/styles/clsxm';
import { EUsageWarning } from '@app/swagger-types';
import { useTranslation } from 'react-i18next';

export const GlobalWarningBanner: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuthorizedUser();
  const { isPlanCanceledStillActive, isPlanCanceled, plan, friendlyExpireDate, planDetails } = useActivePlan();
  const { allLimits } = usePlanLimits();

  const renderContent = () => {
    if (planDetails?.hasDisputedPayment) {
      return (
        <>
          <div className="flex gap-2">{t('globalWarning.disputedPayment.youHaveDisputedPayment')}</div>
          <div>{t('globalWarning.disputedPayment.payForYourSubscription')}</div>
          <div className="flex gap-2">
            {t('globalWarning.disputedPayment.view')}
            <TypedLink to={Routes.customer.account.billing} className="font-bold text-primary">
              {t('globalWarning.disputedPayment.myInvoices')}
            </TypedLink>
            {t('globalWarning.disputedPayment.forDetailedInfo')}
          </div>
        </>
      );
    }
    if (isPlanCanceledStillActive) {
      return (
        <>
          <div className="font-semibold">
            {t('globalWarning.scheduledPlanCancel.yourPlanIsAboutToBeCancelled', { planName: plan?.name })}
          </div>
          <div>
            {t('globalWarning.scheduledPlanCancel.currentPlanActiveUntil')} <b>{friendlyExpireDate}</b>
          </div>
        </>
      );
    }
    // TODO clarify - redundant case? Because we should be able to use free account and don't need to see
    // what plan was canceled
    if (isPlanCanceled) {
      return (
        <>
          <div className="font-semibold">
            {/* TODO show previous cancelled plan? */}
            {t('globalWarning.canceledPlan.yourPlanWasCanceled', { planName: plan?.name })}
          </div>
          <div>
            {t('globalWarning.canceledPlan.currentPlan')} <b>{plan?.name}</b>
          </div>
          <div className="flex gap-2">
            {t('globalWarning.canceledPlan.yourLimitOfScans')}
            <b>{allLimits?.amountMaxScans || `∞`}</b>
            {t('globalWarning.canceledPlan.scans')}
          </div>
        </>
      );
    }
    if (currentUser?.usageWarning === EUsageWarning.USAGE100PERCENT) {
      return (
        <>
          <div>
            {t('globalWarning.scanLimit100.youHaveReached')} <b>{t('globalWarning.scanLimit100.percent100')}</b>{' '}
            {t('globalWarning.scanLimit100.ofYourScans')}
          </div>
          <div>{t('globalWarning.scanLimit100.yourCodesAreNotWorkingAnymore')}</div>
        </>
      );
    }
    if (currentUser?.usageWarning === EUsageWarning.USAGE80PERCENT) {
      return (
        <>
          <div>
            {t('globalWarning.scanLimit80.youAreOver')} <b>{t('globalWarning.scanLimit80.percent80')}</b>{' '}
            {t('globalWarning.scanLimit80.ofYourScans')}
          </div>
          <div>{t('globalWarning.scanLimit80.pleaseUpgradeYourPlanNow')}</div>
        </>
      );
    }
  };

  const content = renderContent();
  if (!content) {
    return null;
  }

  const shouldShowActionButton = !planDetails?.hasDisputedPayment;

  return (
    <div className="lg:px-6 pt-6 mb-1">
      <div
        className={clsxm(
          'flex gap-4 items-start',
          'justify-between',
          'flex-col md:flex-row',
          'flex-wrap md:flex-nowrap',
          'p-4 outline outline-1 outline-gray-300 bg-gray-100',
          'lg:rounded-lg'
        )}
      >
        <div className="flex gap-4 items-start flex-wrap">
          <div className="flex items-center">
            <FiSrExclamation className="mt-1" />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <b>{t('globalWarning.warning')}</b>
            {content}
          </div>
        </div>
        {shouldShowActionButton && (
          <div className="flex justify-end w-full md:w-auto min-w-60">
            <ManagePlanButton />
          </div>
        )}
      </div>
    </div>
  );
};
