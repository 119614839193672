import { Routes } from '@app/constants/routes';
import { TypedLink } from '@app/router';
import { PermissionCrown } from '../plan/components/permission-crown/PermissionCrown';
import { useTranslation } from 'react-i18next';
import { FiRrCrossSmall } from '@app/assets';
import { useUpdateCustomer } from '../user/api/user.hooks.api';
import { useShouldShowSubscriptionBanner } from './useShouldShowSubscriptionBanner';
import clsx from 'clsx';
import { useFirstDayDiscountAvailable } from './hooks/useFirstDayDiscountAvailable';

type Props = {
  className?: string;
};

export const BuySubscriptionBanner: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const { isAvailable: isFirstDayDiscountAvailable } = useFirstDayDiscountAvailable();
  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();
  const shouldShowBanner = useShouldShowSubscriptionBanner();

  if (!shouldShowBanner) {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex items-start justify-between bg-[#DFE6ED] text-zinc-700 px-2 py-4 md:gap-4 md:px-6 text-s mb-4',
        className
      )}
    >
      <div />
      <div className="">
        <PermissionCrown className="inline-block w-5 h-5 align-top" />{' '}
        <span>
          {isFirstDayDiscountAvailable
            ? t('subscription.globalBanner.discountDescription')
            : t('subscription.globalBanner.regularDescription')}
        </span>{' '}
        <TypedLink to={Routes.customer.account.subscription} className="underline font-bold text-nowrap">
          {t('subscription.globalBanner.buttonGetStarted')}
        </TypedLink>
      </div>
      <button
        disabled={isLoading}
        className="cursor-pointer disabled:cursor-not-allowed hover:bg-white rounded"
        onClick={() => {
          updateCustomer({
            upsellTopBannerHiddenDate: new Date().toJSON(),
          });
        }}
      >
        <FiRrCrossSmall />
      </button>
    </div>
  );
};
