import { ELibraryType } from '@app/swagger-types';
import { useMemo, useState } from 'react';
import { array, object, string, InferType } from 'yup';

export const usePersistedPublicImages = (libraryType: 'vcard' | 'multilink' | 'shared' | ELibraryType) => {
  const [libraries, _setLibraries] = useState<DTO_MaybePublicLibrarySchema>(() => {
    const rawData = localStorage.getItem(`${STORAGE_KEY_PREFIX}${libraryType}`);
    if (!rawData) {
      return;
    }
    try {
      const data = PublicLibrarySchema.validateSync(JSON.parse(rawData));
      return data;
    } catch {}
  });

  const {
    set: setLibraries,
    // TODO
    // merge: mergeLibraries,
    // delete: deleteLibrary
  } = useMemo(() => {
    const set: typeof _setLibraries = (newVal) => {
      try {
        _setLibraries((oldVal) => {
          const data = typeof newVal === 'function' ? newVal(oldVal) : newVal;
          const strData = JSON.stringify(data);
          localStorage.setItem(`${STORAGE_KEY_PREFIX}${libraryType}`, strData);
          return data;
        });
      } catch {}
    };
    // TODO refactor to implement reusable code from MultilinkHeaderEditor
    // const merge = () => {};
    return { set };
  }, [libraryType]);

  return { libraries, setLibraries };
};
const STORAGE_KEY_PREFIX = 'persisted-public-images-';

const PublicLibrarySchema = object({
  result: array(
    object({
      file: object({
        id: string(),
      }),
      // id for library.id
      id: string(),
    })
  ).required(),
});
export type DTO_PublicLibrarySchema = InferType<typeof PublicLibrarySchema>;
type DTO_MaybePublicLibrarySchema = DTO_PublicLibrarySchema | undefined;
