export const AUTH_QUERY_PARAM_FOR_PREFILL_EMAIL = 'prefillEmail';
/**
 * for cases when user signed up after generating QR code on WP widget or when claiming QR code
 */
export const AUTH_QUERY_PARAM_AUTO_LINK_QR_ID = 'autoLinkQRID';
export const AUTH_QUERY_PARAM_AUTO_DOWNLOAD_QR_ID = 'autoDownloadQRID';
export const AUTH_QUERY_PARAM_LANGUAGE = 'language';
export const AUTH_QUERY_PARAM_SUCCESS_REDIRECT = 'success-redirect';

export const AUTH_QUERY_PARAM_GOOGLE_MODE = 'googleMode';
export const AUTH_QUERY_VALUE_GOOGLE_MODE_SIGN_IN = 'sign_in';

/**
 * example:
 * /auth/sign-in?sign-in-error=GOOGLE_EMAIL_NOT_REGISTERED&sign-in-email=some@mail.com
 */
export const AUTH_QUERY_PARAM_SIGN_IN_ERROR = 'sign-in-error';
export const AUTH_QUERY_VALUE_GOOGLE_EMAIL_NOT_REGISTERED = 'GOOGLE_EMAIL_NOT_REGISTERED';
export const AUTH_QUERY_PARAM_SIGN_IN_EMAIL = 'sign-in-email';

export const AUTH_QUERY_PARAM_TOKEN = 'token';
export const AUTH_QUERY_PARAM_SIGN_UP_REQUEST_TYPE = 'requestType';

/**
 * example:
 * /auth/oauth2/authorization?forwarded-source=generator&forwarded-popup=download
 */
export const AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_SOURCE = 'forwarded-source';
export const AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_POPUP = 'forwarded-popup';
