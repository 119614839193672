/**
 * TODO remove after build process fixed.
 * Currently, '@tailwindcss/container-queries' plugin seems to remove `space-*` classes from the build.
 * So, spaces relied on `space-*` classes are broken (not defined) on eventual build.
 * Build stopped working since commit on Sep 27, 2024 - git checkout 7d82da7987b24557bbf4de2e50cfc0498af27111
 * Maybe plugin and packages upgrade will fix the issue in future. May need to re-install lock file for dependencies.
 */
export const dirtyInjectGlobalStylesWorkaround = () => {
  const styles = `#twRoot :is(.space-y-6 > :not([hidden]) ~ :not([hidden])) {
      --tw-space-y-reverse: 0;
      margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
    }
    
    #twRoot :is(.space-y-4 > :not([hidden]) ~ :not([hidden])) {
      --tw-space-y-reverse: 0;
      margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
    }
    
    #twRoot :is(.space-y-3 > :not([hidden]) ~ :not([hidden])) {
      --tw-space-y-reverse: 0;
      margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
    }
    `;
  const el = document.createElement('style');
  el.innerHTML = styles;
  document.head.append(el);
};
