import { ECodeType } from '@app/swagger-types';

export interface IEventDownloadQRCode {
  codeType: ECodeType;
  websiteUrl?: string;
  multilink?: string;
  pdf?: string;
  vcard?: string;
}

export enum EGTAGRegistrationPopUp {
  SCAN_TRACKING = 'scan tracking',
  CREATE_BULK = 'create bulk',
  FRAME = 'frame',
  PATTERN = 'pattern',
  COLOR = 'color',
  DOWNLOAD = 'download',
}

export enum EGTAGRegistrationOrigin {
  /**
   * @deprecated clarify if needed anymore
   * account created from claim page
   */
  CLAIM = 'claim',
  /**
   * account created from popup from Wordpress Widget
   */
  GENERATOR = 'generator',
  /**
   * account created directly from sign up page
   */
  DIRECT = 'direct',
}
