import { StringParam, useQueryParam } from 'use-query-params';

export enum EBulkMode {
  NONE = 'NONE',
  BULK_WEBSITE = 'BULK_WEBSITE',
}

export const QR_CODE_BULK_MODE_QUERY_PARAM = 'bulkMode';

export const useBulkQueryParam = (defaultMode: EBulkMode) => {
  const [bulkMode = defaultMode, setBulkMode] = useQueryParam(QR_CODE_BULK_MODE_QUERY_PARAM, StringParam);

  return [bulkMode, setBulkMode] as const;
};
