import { ApiService } from '@app/services/api-service/api.service';
import { QRCodeInDto, QRCodeOutDto } from '@app/swagger-types';

const publicApiService = new ApiService();

export abstract class WidgetApi {
  static async generateQRCode({
    codeType,
    website,
    multilink,
    pdf,
    vcard,
    design,
    publicLogoType,
    googleClickId,
  }: Pick<
    QRCodeInDto,
    'codeType' | 'design' | 'publicLogoType' | 'googleClickId' | 'website' | 'multilink' | 'pdf' | 'vcard'
  >) {
    // TODO validate DTOs or keep as is to minimize widget bundle?
    const { data } = await publicApiService.post<QRCodeOutDto, QRCodeInDto>('/public/qr-code/', {
      name: 'qr-code',
      codeType,
      website,
      multilink,
      pdf,
      vcard,
      design,
      publicLogoType,
      googleClickId,
    });
    return data;
  }
}
