import { APIQueryParams } from '@app/models/api';
import {
  DesignOutDto,
  ECodeType,
  LibraryOutDto,
  QRCodeOutDto,
  TemplateOutDto,
  UserBaseOutDto,
  WebsiteOutDto,
} from '@app/swagger-types';

export type QRCodeTypeItemType = {
  icon: React.ReactNode;
  value: ECodeType;
};

export type QRCodeOutDtoOverridden = Omit<
  QRCodeOutDto,
  'design' | 'createdBy' | 'website' | 'amountOfScans' | 'createdWithFlow'
> & {
  design?:
    | (Omit<DesignOutDto, 'createdBy' | 'template' | 'library'> & {
        createdBy?: UserBaseOutDto | null;
        template?: TemplateOutDto | null;
        library?: LibraryOutDto | null;
      })
    | null;
  createdBy?: UserBaseOutDto | null;
  website?: WebsiteOutDto | null;
  amountOfScans?: number | null;
};

export type APIQRCodeQueryParams = APIQueryParams & {
  type?: string[];
};

// TODO refactor / remove enum and use from swagger when be ready api
export enum EQRCodeSort {
  MOST_RECENT = 'createdDate,desc',
  NAME_A_Z = 'name,asc',
  // TODO buildSortParam<QRCodeOutDto>('totalScans', 'desc')
  // Error: Computed values are not permitted in an enum with string valued members.
  // possible solution - rewrite to const object instead of enum
  MOST_SCANNED = 'totalScans,desc',
  LAST_CREATED = 'createdDate,asc',
}
