import { object, string, InferType, array, boolean, number } from 'yup';

import { oneOfEnum, oneOfEnumOptional } from '@app/utils/yup.utils';

import {
  ECurrency,
  EInvoiceStatus,
  ELanguage,
  EPlanCycle,
  EPlanType,
  ECustomerGen,
  ERole,
  ESettingType,
  EUsageWarning,
  EUserStatus,
  UserOutDto,
  UserSettingOutDto,
  ECustomerSegment,
} from '@app/swagger-types';
import { checkTypeCompliance } from '@app/utils/type.utils';
import { CurrentCustomerOutDto } from '@app/domain/user';
import { PlanDetailsOutSchema } from '@app/domain/plan/api/plan.schema.api';
import { CustomerSubscriptionOutDtoSchema } from '@app/domain/subscription/api/subscription.schema';

export const UserSettingOutSchema = object({
  id: string().required(),
  type: oneOfEnum(ESettingType).required(),
  isEnabled: boolean().required(),
});
type UserSettingOutSchema = InferType<typeof UserSettingOutSchema>;

checkTypeCompliance<UserSettingOutDto, UserSettingOutSchema>();

export const UserBaseOutSchema = object({
  id: string().defined(),
  firstName: string(),
  lastName: string(),
  email: string().defined(),
  phone: string(),
  address: string(),
  birthDate: string(),
  role: oneOfEnum(ERole),
  language: oneOfEnum(ELanguage),
  // planStatus: oneOfEnum(EPlanStatus),
  // planType: oneOfEnum(EPlanType),
  // endOfSubscriptionDate: string().required(),
  currency: oneOfEnum(ECurrency),
  avatarId: string(),
  status: oneOfEnum(EUserStatus),
  createdDate: string().required(),
  customerGen: oneOfEnum(ECustomerGen),
});
export type UserBaseOutSchema = InferType<typeof UserBaseOutSchema>;

// TODO check usages
export const UserOutSchema = object({
  id: string().defined(),
  firstName: string(),
  lastName: string(),
  email: string().defined(),
  phone: string(),
  address: string(),
  birthDate: string(),
  role: oneOfEnum(ERole),
  language: oneOfEnum(ELanguage),
  // planStatus: oneOfEnum(EPlanStatus),
  planType: oneOfEnum(EPlanType),
  // endOfSubscriptionDate: string(),
  currency: oneOfEnum(ECurrency),
  avatarId: string().nullable(),
  status: oneOfEnum(EUserStatus),
  createdDate: string().required(),
  settings: array(UserSettingOutSchema).required(),
  // maxCodesLimit: number(),
  // maxScansLimit: number(),
  totalCodes: number(),
  totalScans: number(),
  monthlyScans: number(),
  shouldAskForGPSCoordinates: boolean(),
  accountNotification: boolean(),
  qrNotification: boolean(),
  marketingNotification: boolean(),
  currentSubscriptionId: string().nullable(),
  previousSubscriptionId: string().nullable(),
  plan: object({
    id: string().required(),
    name: string().required(),
    price: object({
      // PriceOutDto
      id: string().nullable(),
      price: number().nullable(),
      planCurrency: oneOfEnum(ECurrency),
      planCycle: oneOfEnum(EPlanCycle),
      customerGen: oneOfEnum(ECustomerGen),
    }).nullable(),
    scheduledUpdate: object({
      planType: oneOfEnum(EPlanType),
      planCycle: oneOfEnum(EPlanCycle),
      nextPeriodStart: string().required(),
      scheduleSubscriptionId: string().required(),
    }).nullable(),
    lastInvoiceId: string().nullable(),
    lastInvoiceStatus: oneOfEnumOptional(EInvoiceStatus),
    canceledReason: string().nullable(),
    createdDate: string(),
  }),
  // make it nullable because admin has no plan
  currentPlanDetails: PlanDetailsOutSchema.nullable(),
  subscription: CustomerSubscriptionOutDtoSchema.nullable(),
  customerId: string().nullable(),
  chargebeeId: string().nullable(),
  activeCampaignId: string().nullable(),
  lastActiveCampaignSyncedDate: string().nullable(),
  stripeSyncedDate: string().nullable(),
  hasVerifiedEmail: boolean().nullable(),
  usageWarning: oneOfEnumOptional(EUsageWarning),
  anyCodeDownloaded: boolean().default(false),
  anyCodeCustomized: boolean().default(false),
  isOldPlanUser: boolean().default(false),
  newUsageResetDate: string().nullable(),
  customerGen: oneOfEnum(ECustomerGen),
  customerSegment: oneOfEnumOptional(ECustomerSegment),
  upsellTopBannerHiddenDate: string().nullable(),
});
export type UserOutSchema = InferType<typeof UserOutSchema>;

checkTypeCompliance<CurrentCustomerOutDto, UserOutSchema>();

// TODO comply with UserOutDto
type UnsafeUserOutDto = Omit<UserOutDto, 'plan' | 'currentPlanDetails'>;
checkTypeCompliance<UnsafeUserOutDto, UserOutSchema>();
