import { QR_CODE_STEPPER_STEPS_TYPE } from './QRCodeFormStepper';
import { clsxm } from '@app/styles/clsxm';
import styles from './QRCodeFormStepper.module.scss';
import { Box } from '@mui/material';
import { QRCodeFormStepSvg } from './QRCodeFormStepSvg';
import DoneIcon from '@mui/icons-material/Done';
import { TranslationKey } from '@app/locales/defaultTranslation';
import { useTranslation } from 'react-i18next';

export const QRCodeFormStep: React.FC<{
  isActive: boolean;
  isCompleted: boolean;
  index: number;
  step: QR_CODE_STEPPER_STEPS_TYPE;
  onChange?: (step: QR_CODE_STEPPER_STEPS_TYPE) => void;
  forWidgetMode?: boolean;
}> = ({ index, step, onChange, isActive = false, isCompleted = false, forWidgetMode = false }) => {
  const { t } = useTranslation();
  return (
    <Box
      className={clsxm(
        styles.stepRoot,
        'pointer-events-none relative z-10 flex-1 text-accept-dark-blue',
        onChange && 'pointer-events-auto hover:text-accept-main'
      )}
      onClick={() => onChange && onChange(step)}
    >
      <div
        className={clsxm(
          'flex items-center justify-center gap-2 py-2 font-medium',
          'sm:justify-start',
          forWidgetMode ? 'px-4 sm:px-8' : 'sm:px-6 2xl:px-8',
          isCompleted && 'text-accept-main',
          isActive && 'text-accept-text'
        )}
      >
        <div
          className={clsxm(
            styles.stepIndex,
            'flex h-5 w-5 items-center justify-center rounded-[50%] border leading-5',
            (isActive || isCompleted) && 'border-accept-main bg-accept-main text-white'
          )}
        >
          {isCompleted ? <DoneIcon className="text-m" /> : index}
        </div>
        <span className={clsxm('hidden', forWidgetMode ? (isActive ? 'sm:inline-block' : '') : 'sm:inline-block')}>
          {t(getStepTitleKey(step))}
        </span>
      </div>
      <QRCodeFormStepSvg step={step} isActive={isActive} isCompleted={isCompleted} />
    </Box>
  );
};

const getStepTitleKey = (step: QR_CODE_STEPPER_STEPS_TYPE): TranslationKey => {
  if (step === 'Type') {
    return 'qr.stepType.stepTitle';
  } else if (step === 'Input') {
    return 'qr.stepInput.stepTitle';
  } else if (step === 'Customize') {
    return 'qr.stepCustomize.stepTitle';
  } else if (step === 'Done') {
    return 'qr.stepDone.stepTitle';
  }
  throw new Error(`unknown step type: ${step}`);
};
