import { ECodeType } from '@app/swagger-types';
import { useFormContext } from 'react-hook-form';
import { QRCodeFormSchema } from '../../api/qr-code.form.schema.api';
import { QRCodeMultilink } from './multilink/QRCodeMultilink';
import { QRCodePdf } from './pdf/QRCodePdf';
import { QRCodeVCard } from './vcard/QRCodeVCard';
import { QRCodeWebsiteUrl } from './website/QRCodeWebsiteUrl';
import { useTranslation } from 'react-i18next';
import { usePlanLimits } from '@app/domain/plan/hooks/usePlanLimits';
import { UpgradeBanner } from '@app/domain/plan/components/UpgradeBanner';
import { EPlanLimitType } from '@app/domain/plan/components/plan-limit-dialog/PlanLimitDialog';
import { QRCodeGS1 } from './gs1/QRCodeGS1';
import { QRCodeWIFI } from './wifi/QRCodeWIFI';

export const QRCodeInputContainer: React.FC<{
  forWidgetMode?: boolean;
  isEditMode?: boolean;
}> = ({ forWidgetMode, isEditMode }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<QRCodeFormSchema>();
  const codeType = watch('codeType');
  const { canEditQrInput } = usePlanLimits();

  const getBody = () => {
    const disabledEditInput = isEditMode && !canEditQrInput && !forWidgetMode;
    if (codeType === ECodeType.WEBSITE) {
      if (forWidgetMode) {
        return <QRCodeWebsiteUrl className="h-auto" hint={t('qr.websiteInput.yourCodeGeneratedAutomatically')} />;
      }
      return <QRCodeWebsiteUrl disabledEditInput={disabledEditInput} />;
    }

    if (codeType === ECodeType.MULTILINK) {
      return <QRCodeMultilink forWidgetMode={forWidgetMode} disabledEditInput={disabledEditInput} />;
    }

    if (codeType === ECodeType.PDF) {
      return <QRCodePdf forWidgetMode={forWidgetMode} disabledEditInput={disabledEditInput} />;
    }

    if (codeType === ECodeType.V_CARD) {
      return <QRCodeVCard forWidgetMode={forWidgetMode} disabledEditInput={disabledEditInput} />;
    }

    if (codeType === ECodeType.GS1) {
      return <QRCodeGS1 forWidgetMode={forWidgetMode} isEditMode={isEditMode} disabledEditInput={disabledEditInput} />;
    }

    if (codeType === ECodeType.WIFI) {
      return <QRCodeWIFI forWidgetMode={forWidgetMode} isEditMode={isEditMode} disabledEditInput={disabledEditInput} />;
    }

    return (
      <div className="flex-1 bg-white px-5 py-6 xl:rounded-xl">
        {codeType} section
        <div>In progress</div>
      </div>
    );
  };

  if (forWidgetMode || canEditQrInput || !isEditMode) {
    return getBody();
  }

  return (
    <div className="flex flex-col gap-4">
      <UpgradeBanner planLimitDialogType={EPlanLimitType.EDIT_QR_CODE} />
      {getBody()}
    </div>
  );
};
