import { IconButton, Typography } from '@app/components';
import {
  Accordion as MuiAccordion,
  AccordionProps,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
} from '@mui/material';
import { useState } from 'react';
import { clsxm } from '@app/styles/clsxm';
import { FiRrAngleSmallRight, FiRrCheck, FiSrPlus } from '@app/assets';
import { FrameContainer } from '@app/domain/qr-code/components/frame/FrameContainer';
import { EFrameType, EPatternType } from '@app/swagger-types';
import { PatternContainer } from '@app/domain/template/components/pattern/PatternContainer';
import React from 'react';
import { SvgScopeWrap } from '@app/utils/SvgScopeWrap';
import { WIDGET_AVAILABLE_COLORS, WIDGET_LOGO_OPTION_APP_LOGO } from '../widget.constants';
import { useTranslation } from 'react-i18next';
import { LogosPublicGallery } from './LogosPublicGallery';
import { IS_NOT_PROD_ENV } from '@app/environment/typed-env';
import { EGTAGRegistrationPopUp } from '@app/gtag/gtag.type';

const Accordion: React.FC<AccordionProps> = ({ children, ...props }) => (
  <MuiAccordion
    disableGutters
    sx={{
      '&:before': {
        display: 'none',
      },
    }}
    elevation={0}
    className="rounded-lg ring-1 ring-gray-200"
    {...props}
  >
    {children}
  </MuiAccordion>
);

const AccordionSummary: React.FC<AccordionSummaryProps & { isExpanded?: boolean }> = ({
  children,
  isExpanded,
  className,
  ...props
}) => (
  <MuiAccordionSummary
    classes={{
      expandIconWrapper: clsxm('ml-2'),
    }}
    expandIcon={
      <IconButton variant="transparent" disableRipple>
        {isExpanded ? <FiRrAngleSmallRight className="rotate-90" /> : <FiRrAngleSmallRight />}
      </IconButton>
    }
    className={clsxm(
      'bg-gray-100 shadow-[0_1px] shadow-gray-200',
      isExpanded ? 'rounded-t-lg' : 'rounded-lg',
      className
    )}
    {...props}
  >
    {children}
  </MuiAccordionSummary>
);

export const QRWidgetDesignAccordion: React.FC<{
  frameType: EFrameType;
  shape: EPatternType;
  color: typeof WIDGET_AVAILABLE_COLORS[number];
  publicLogoType?: typeof WIDGET_LOGO_OPTION_APP_LOGO;
  libraryId?: string;
  onFrameSelect: (frameType: EFrameType) => void;
  onShapeSelect: (shape: EPatternType) => void;
  onColorSelect: (color: typeof WIDGET_AVAILABLE_COLORS[number]) => void;
  onLibSelect: (libId?: string) => void;
  onSignUpTrigger: (triggerSource: EGTAGRegistrationPopUp) => void;
}> = ({
  frameType,
  shape,
  color,
  publicLogoType,
  libraryId,
  onFrameSelect,
  onShapeSelect,
  onColorSelect,
  onLibSelect,
  onSignUpTrigger,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<'frame' | 'shape' | 'logo' | null>('frame');

  if (libraryId && publicLogoType && IS_NOT_PROD_ENV) {
    throw new Error('libraryId and publicLogoType are excluding options');
  }

  const handleChange = (panel: 'frame' | 'shape' | 'logo') => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : null);
  };

  return (
    <div className="flex flex-col gap-4 pb-8">
      <Accordion expanded={expanded === 'frame'} onChange={handleChange('frame')}>
        <AccordionSummary isExpanded={expanded === 'frame'}>
          <Typography variant="s" className="font-bold text-gray-800">
            {t('widget.design.frame')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="flex h-[142px] max-w-sm gap-2 rounded-b-lg bg-white p-4 py-6">
          {WIDGET_AVAILABLE_FRAMES.map((tileFrame) => {
            return (
              <FrameContainer
                key={tileFrame}
                className="flex-1 cursor-pointer px-3"
                type={tileFrame}
                isOption
                onClick={() => {
                  onFrameSelect(tileFrame);
                }}
                selected={tileFrame === frameType}
                fontSize={10}
                forWidgetMode
              />
            );
          })}
          <div
            className="flex flex-1 cursor-pointer items-center justify-center rounded-xl bg-gray-100 p-[10px] text-gray-600"
            onClick={() => onSignUpTrigger(EGTAGRegistrationPopUp.FRAME)}
            aria-hidden
          >
            <SvgScopeWrap>
              <FiSrPlus className="scale-75" />
            </SvgScopeWrap>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'shape'} onChange={handleChange('shape')}>
        <AccordionSummary isExpanded={expanded === 'shape'}>
          <Typography variant="s" className="font-bold text-gray-800">
            {t('widget.design.shapeAndColor')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="h-[142px] max-w-sm rounded-b-lg bg-white p-4 py-6">
          <div className="grid grid-cols-5 gap-2">
            {WIDGET_AVAILABLE_PATTERNS.map((tilePattern) => {
              return (
                <PatternContainer
                  key={tilePattern}
                  className={clsxm(
                    'flex-1 p-2 sm:p-2',
                    'border-[1.5px] border-transparent',
                    tilePattern === shape && 'border-accept-main'
                  )}
                  type={tilePattern}
                  onClick={() => {
                    onShapeSelect(tilePattern);
                  }}
                />
              );
            })}
            <div
              className="flex flex-1 cursor-pointer items-center justify-center rounded-lg bg-gray-200"
              onClick={() => onSignUpTrigger(EGTAGRegistrationPopUp.PATTERN)}
              aria-hidden
            >
              <SvgScopeWrap>
                <FiSrPlus className="scale-75" />
              </SvgScopeWrap>
            </div>
          </div>
          <div className="grid h-8 grid-cols-5 gap-2 pt-2">
            {WIDGET_AVAILABLE_COLORS.map((tileColor) => {
              return (
                <div
                  key={tileColor}
                  className="flex flex-1 cursor-pointer items-center justify-center rounded-lg"
                  style={{ background: tileColor }}
                  onClick={() => {
                    onColorSelect(tileColor);
                  }}
                  aria-hidden
                >
                  {color === tileColor && (
                    <SvgScopeWrap>
                      <FiRrCheck className="fill-white p-1" />
                    </SvgScopeWrap>
                  )}
                </div>
              );
            })}
            <div
              className="flex flex-1 cursor-pointer items-center justify-center rounded-lg bg-gray-200"
              onClick={() => onSignUpTrigger(EGTAGRegistrationPopUp.COLOR)}
              aria-hidden
            >
              <SvgScopeWrap>
                <FiSrPlus className="scale-75" />
              </SvgScopeWrap>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'logo'} onChange={handleChange('logo')}>
        <AccordionSummary isExpanded={expanded === 'logo'}>
          <Typography variant="s" className="font-bold text-gray-800">
            {t('widget.design.logo')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="p-4">
          <LogosPublicGallery
            publicLogoType={publicLogoType}
            libraryId={libraryId}
            onLibSelect={(libId) => {
              onLibSelect(libId);
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const WIDGET_AVAILABLE_FRAMES = [
  EFrameType.NONE,
  EFrameType.BORDER_LABEL_BOTTOM,
  EFrameType.ARROW_LABEL_BOTTOM,
] as const;

const WIDGET_AVAILABLE_PATTERNS = [
  EPatternType.SQUARE,
  EPatternType.CLASSY_ROUNDED,
  EPatternType.EXTRA_ROUNDED,
  EPatternType.DOTS,
] as const;
