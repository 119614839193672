import { useGetPlans } from '@app/domain/plan/api/plan.hooks.api';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { buildSubscriptionFeatureRenderer } from '@app/domain/user/components/subscription/subscription.utils';
import { ECurrency, PlanLimitOutDto } from '@app/swagger-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@app/components';
import { PlanLimitOutDtoKey } from '@app/domain/user';
import { NEW_SUBSCRIPTION_PLAN_LIMIT_KEYS, SUBSCRIPTION_PLAN_LIMIT_KEYS } from '@app/domain/user/user.const';
import { SubscriptionPlanHeaderLimits } from '@app/domain/user/components/subscription/SubscriptionPlanHeaderLimits';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';

export const PlanDowngradeInfo: React.FC = () => {
  const { t } = useTranslation();

  const { plan } = useActivePlan();

  const { data: plans } = useGetPlans({ currency: plan?.price?.planCurrency || ECurrency.EUR });

  const getPlanWithDetails = () => {
    if (plans) {
      return plans.result.find((p) => plan?.name === p.name);
    }
  };

  const userPlanWithDetails = getPlanWithDetails();
  const freePlan = plans?.result[0];

  const renderFeaturesComparison = () => {
    if (!freePlan || !userPlanWithDetails) {
      return <ContentLoader loading />;
    }
    return (
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          {t('subscription.cancellation.stepGeneral.youWillGet')}
          {freePlan && (
            <SubscriptionPlanHeaderLimits planLimit={freePlan.planLimit} className="justify-start flex-row flex-wrap" />
          )}
          <FeaturesChecklist
            newPlan={freePlan.planLimit}
            currentPlan={userPlanWithDetails.planLimit}
            displayMode="gainFeature"
          />
        </div>
        <div className="flex flex-col gap-4">
          <Typography variant="m" className="!mb-5 font-semibold text-gray-800">
            {t('subscription.cancellation.stepGeneral.featuresYouLoose')}:
          </Typography>
          <FeaturesChecklist
            newPlan={freePlan.planLimit}
            currentPlan={userPlanWithDetails.planLimit}
            displayMode="looseFeature"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="gap-6 flex flex-col p-8 border border-gray-200 rounded-lg">
      <div className="gap-3 flex flex-col">
        <h3 className="text-m font-bold">
          {t('subscription.cancellation.stepGeneral.bannerInfoTitle', { plan: plan?.name })}:
        </h3>
      </div>
      {renderFeaturesComparison()}
    </div>
  );
};

const FeaturesChecklist: React.FC<{
  newPlan: PlanLimitOutDto;
  currentPlan: PlanLimitOutDto;
  displayMode: 'looseFeature' | 'gainFeature';
}> = ({ newPlan: newPlan, currentPlan: currentPlan, displayMode }) => {
  const { t } = useTranslation();

  const isGainFeatureMode = displayMode === 'gainFeature';

  const renderFeatureItem = (basePlan: PlanLimitOutDto, planToCheck: PlanLimitOutDto) => {
    const renderFeatureForPreviousPlan = buildSubscriptionFeatureRenderer({
      t18n: t,
      planToCheck: planToCheck,
      forceIsAvailable: isGainFeatureMode,
    });
    const lostFeatures: React.ReactNode[] = [];

    const allKeys: PlanLimitOutDtoKey[] = isGainFeatureMode
      ? []
      : [...new Set<PlanLimitOutDtoKey>([...SUBSCRIPTION_PLAN_LIMIT_KEYS, ...NEW_SUBSCRIPTION_PLAN_LIMIT_KEYS])];

    allKeys.forEach((key) => {
      if (basePlan[key as keyof typeof basePlan] !== planToCheck[key as keyof typeof planToCheck]) {
        const feature = renderFeatureForPreviousPlan(key);
        if (feature) {
          lostFeatures.push(feature);
        }
      }
    });

    if (
      basePlan.scanAnalyticsQuantity !== planToCheck.scanAnalyticsQuantity ||
      basePlan.scanAnalyticsUnit !== planToCheck.scanAnalyticsUnit
    ) {
      const feature = renderFeatureForPreviousPlan('hasRealTimeDataDashboard');
      if (feature) {
        lostFeatures.push(feature);
      }
    }

    return lostFeatures;
  };

  return (
    <div className="flex flex-col gap-4">
      {isGainFeatureMode ? renderFeatureItem(currentPlan, newPlan) : renderFeatureItem(newPlan, currentPlan)}
    </div>
  );
};
