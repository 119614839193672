import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { Routes } from '@app/constants/routes';
import { useTypedNavigate } from '@app/router';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';
import { AUTH_QUERY_PARAM_AUTO_DOWNLOAD_QR_ID, AUTH_QUERY_PARAM_AUTO_LINK_QR_ID } from '../../auth/auth.constants';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { QRCodeApi } from '@app/domain/qr-code/api/qr-code.api';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useControlPersistedAutoLink, useGetPersistedAutoLink } from '@app/domain/qr-code/hooks/usePersistAutoLink';
import { ApiValidationError } from '@app/services/error-manager';
import { useStore } from '@app/store/useStore.hook';
import { IS_PROD_ENV } from '@app/environment/typed-env';

export const MaybeAutoClaim: React.FC = () => {
  const { currentUser } = useAuthorizedUser();
  const hasAdminTokens = useStore.useHasAdminTokens();
  const [paramAutoLinkQRID] = useQueryParam(AUTH_QUERY_PARAM_AUTO_LINK_QR_ID, withDefault(StringParam, null));
  const { autoLinkQRID: persistedAutoLinkQRID } = useGetPersistedAutoLink();

  const autoLinkQRID = paramAutoLinkQRID || persistedAutoLinkQRID;

  if (currentUser) {
    if (hasAdminTokens && IS_PROD_ENV) {
      // for cases when admin impersonated as customer
      // not-prod condition is convenience for testing
      return null;
    }
    if (!autoLinkQRID) {
      return null;
    }
    return <ClaimCode autoLinkQRID={autoLinkQRID} />;
  }

  return null;
};

const ClaimCode: React.FC<{
  autoLinkQRID: string;
}> = ({ autoLinkQRID }) => {
  const navigate = useTypedNavigate();
  const [maxAttemptsLeft, setMaxAttemptsLeft] = useState(3);
  const { reset } = useControlPersistedAutoLink();

  const {
    mutate: claim,
    isLoading,
    error,
  } = useMutation(
    async ({ autoLinkQRID }: { autoLinkQRID: string }) => {
      if (maxAttemptsLeft < 1) {
        return;
      }
      const data = await QRCodeApi.claim({ qrId: autoLinkQRID });
      return data;
    },
    {
      onError: (err) => {
        // probably QR code has been already assigned to other user
        if (err instanceof ApiValidationError && err.status === 400) {
          // console.log('err', err.status, err.message);
          setMaxAttemptsLeft(0);
          reset();
        } else {
          setMaxAttemptsLeft((i) => i - 1);
        }
      },
      onSuccess: (res) => {
        if (!res) {
          return;
        }
        setMaxAttemptsLeft(0);
        reset();
        const qrId = res.id;
        navigate({
          to: Routes.customer.my_qr_codes.index,
          queryParams: {
            [AUTH_QUERY_PARAM_AUTO_DOWNLOAD_QR_ID]: qrId,
          },
        });
      },
    }
  );

  useEffect(() => {
    if (!autoLinkQRID || isLoading) {
      return;
    }
    claim({ autoLinkQRID });
  }, [autoLinkQRID, claim, isLoading]);

  if (error) {
    return <ContentLoader error={error} />;
  }
  if (isLoading) {
    return <ContentLoader loading />;
  }
  return null;
};
