import { Button, Typography } from '@app/components';
import { Countdown } from '@app/components/countdown/Countdown';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { useGetPlans } from '@app/domain/plan/api/plan.hooks.api';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { SubscriptionApi } from '@app/domain/subscription/api/subscription.api';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';
import { ECurrency, EPlanCycle, EPlanStatus } from '@app/swagger-types';
import { clsxm } from '@app/styles/clsxm';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SubscriptionPlanContainer } from './SubscriptionPlanContainer';
import { CurrentSubscriptionTitleStatus } from './CurrentSubscriptionTitleStatus';
import { capitalize } from 'lodash';
import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { CurrencySelector } from '@app/domain/subscription/components/CurrencySelector';
import { useCancelSubscriptionPlan } from '@app/domain/subscription/hooks/useCancelSubscriptionPlan';

export const SubscriptionPlans: React.FC = () => {
  const { t } = useTranslation();
  const [isShowAllFeatures, setIsShowAllFeatures] = useState(false);
  const { isOldPlanUser } = useAuthorizedUser();

  const {
    isTrial,
    isExpired,
    planStatus,
    expireDate,
    plan,
    isFreePlan,
    planCycle: userCycle,
    scheduledUpdate,
    friendlyScheduledDate,
  } = useActivePlan();
  const isTrialExpired = isTrial && isExpired;

  const [planCycle, setPlanCycle] = useState(userCycle || EPlanCycle.YEARLY);
  const [selectedCurrency, setSelectedCurrency] = useState(plan?.price?.planCurrency || ECurrency.EUR);
  const { data: plans, isFetching } = useGetPlans({ currency: selectedCurrency });
  const { handleNavigateToCancelForm } = useCancelSubscriptionPlan();

  useEffect(() => {
    if (isTrialExpired) {
      setIsShowAllFeatures(true);
    }
  }, [isTrialExpired]);

  const cancelScheduledUpdateMutation = useMutation(
    async () => {
      return await SubscriptionApi.cancelScheduledUpdate();
    },
    {
      onSuccess: () => {
        window.location.reload();
      },
    }
  );
  const [, setAutoOpenKeyForCancelPlanConfirmation] = useState(0);
  const askToCancelPlan = () => {
    setAutoOpenKeyForCancelPlanConfirmation((i) => i + 1);
  };

  const shouldShowCancelPlanButton = !scheduledUpdate && planStatus === EPlanStatus.ACTIVE && !isFreePlan;
  const scheduledPlanName = scheduledUpdate ? capitalize(scheduledUpdate.planType) : '-';
  const scheduledPlanCycle = scheduledUpdate
    ? scheduledUpdate.planCycle === EPlanCycle.MONTHLY
      ? t('subscription.monthly')
      : scheduledUpdate.planCycle === EPlanCycle.QUARTERLY
      ? t('subscription.quarterly')
      : t('subscription.yearly')
    : '-';

  const renderCycleSwitch = () => {
    if (isTrial) {
      return null;
    }

    const FASTER_CYCLE = isOldPlanUser ? EPlanCycle.MONTHLY : EPlanCycle.QUARTERLY;

    return (
      <div className="flex flex-wrap items-center">
        <Box
          onClick={() => {
            setPlanCycle(() => FASTER_CYCLE);
          }}
          className={clsxm(
            'mr-5 whitespace-nowrap text-gray-500 hover:cursor-pointer',
            planCycle === FASTER_CYCLE && 'text-accept-dark-blue'
          )}
        >
          {isOldPlanUser ? t('subscription.billMonthly') : t('subscription.billQuarterly')}
        </Box>
        <FormControlLabel
          onChange={() => {
            setPlanCycle((prev) => (prev === EPlanCycle.YEARLY ? FASTER_CYCLE : EPlanCycle.YEARLY));
          }}
          checked={planCycle === EPlanCycle.YEARLY}
          control={<Switch />}
          className="mr-0"
          label=""
        />
        <Box
          onClick={() => {
            setPlanCycle(() => EPlanCycle.YEARLY);
          }}
          className={clsxm(
            'ml-2 whitespace-nowrap text-gray-600 hover:cursor-pointer',
            planCycle === EPlanCycle.YEARLY && 'text-accept-dark-blue'
          )}
        >
          {t('subscription.billYearly')}
        </Box>
        {/* TODO: set right value of discount */}
        <div className="ml-4 rounded-lg bg-accept-light px-1 font-medium text-accept-main">-30%</div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {!isTrial && (
        <div className="mb-5 flex flex-col items-start justify-between gap-4 px-6 lg:flex-row lg:px-0">
          <Typography variant="xl" className="font-bold text-gray-800 lg:text-xxxl">
            {t('subscription.chooseTheMostSuitablePackage')}
          </Typography>
          {shouldShowCancelPlanButton && (
            <Button variant="outlined" className="bg-transparent" onClick={handleNavigateToCancelForm}>
              {t('subscription.cancelPlan')}
            </Button>
          )}
          {scheduledUpdate && (
            <ConfirmDialog
              trigger={
                <Button variant="outlined" className="bg-transparent" loading={cancelScheduledUpdateMutation.isLoading}>
                  {t('subscription.cancelUpdate')}
                </Button>
              }
              containerClassName="max-w-[350px]"
              onConfirm={cancelScheduledUpdateMutation.mutate}
              textClassName="text-center"
              title={t('subscription.areYouSureToCancelScheduledUpdate', { planName: scheduledPlanName })}
              confirmText={t('dialog.confirm')}
            />
          )}
        </div>
      )}
      {scheduledUpdate && (
        <div className="rounded bg-gray-200 p-3">
          {t('subscription.planUpdateScheduled', {
            planName: scheduledPlanName,
            planCycle: scheduledPlanCycle,
            scheduledDate: friendlyScheduledDate,
          })}
        </div>
      )}
      <div className="flex flex-col gap-4 px-6 lg:flex-row lg:items-center lg:justify-between lg:px-0">
        <div className="flex">
          {isTrial ? (
            isExpired ? (
              <div className="px-4 py-[2px] font-bold">{t('subscription.selectAPlan')}</div>
            ) : (
              expireDate && (
                <div className="rounded-lg bg-red-100 px-4 py-[2px] text-red-400">
                  {t('subscription.trialPlanEndsIn')} <Countdown expireDate={expireDate} />
                </div>
              )
            )
          ) : (
            <CurrentSubscriptionTitleStatus />
          )}
        </div>
        <div className="flex flex-col gap-4 lg:flex-row lg:items-center">
          {renderCycleSwitch()}

          <div className="flex">
            <CurrencySelector value={selectedCurrency} onChange={(v) => setSelectedCurrency(v)} />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 px-6 lg:px-0 xl:flex-row">
        {!plans && isFetching ? <ContentLoader loading entity="Plans" isEmpty={false} /> : null}
        {plans?.result.map((plan) => (
          <SubscriptionPlanContainer
            key={plan.id}
            plan={plan}
            selectedPlanCycle={planCycle}
            selectedCurrency={selectedCurrency}
            isShowAllFeatures={isShowAllFeatures}
            setIsShowAllFeatures={setIsShowAllFeatures}
            askToDowngradeToFreePlan={askToCancelPlan}
            shouldAllowDowngradeToFreePlan={shouldShowCancelPlanButton}
          />
        ))}
      </div>
    </div>
  );
};
